import React, { useRef } from 'react';
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.css';
import styled from 'styled-components';
import { StyleProps } from '@summer/jst-react';
import './controls/seekBackward';
import './controls/seekForward';
import './controls/requestFrame';
import './vjs-ext.css';

export interface VideoJSProps {
  options: VideoJsPlayerOptions;
  onReady?: (player: VideoJsPlayer) => void;
}

const Container = styled.div`
  width: 100%;
`;

export const VideoJS = ({
  style,
  className,
  options,
  onReady,
}: VideoJSProps & StyleProps) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<VideoJsPlayer>();

  React.useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      // const videoElement = videoRef.current;
      // if (!videoElement) return;
      const videoElement = document.createElement('video-js');

      videoElement.classList.add('vjs-big-play-centered');
      videoRef?.current?.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      }));

      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      // player.autoplay(options.autoplay);
      options.sources && player.src(options.sources);
    }
  }, [options, onReady, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = undefined;
      }
    };
  }, []);

  // Wrap the player in a `div` with a `data-vjs-player` attribute, so Video.js
  // won't create additional wrapper in the DOM.
  // See: https://github.com/videojs/video.js/pull/3856
  return (
    <Container
      style={style}
      className={className}
      data-vjs-player
      ref={videoRef}
    ></Container>
  );
};

export default VideoJS;
