import styled, { css } from 'styled-components';
import { FC } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { routes } from 'routes';
import { LinkButton } from '@higo/ui';
import { FormattedMessage } from 'react-intl';
import { LanguageSwitch } from '@higo/common/lib/features/Locale';
import { AppVersion } from 'components/AppVersion';

const CenteredLayout = styled('div')(
  () => css`
    display: grid;
    min-height: 100vh;
    grid-template-rows: 2rem auto 1rem;
    grid-template-columns: 100%;
    align-items: center;
    justify-content: center;
    padding: 2.5rem;
  `,
);

const Content = styled.div`
  min-width: 14.5625rem;
  max-width: 25rem;
  width: 100%;
  margin: 0 auto;
`;

const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const VersionContainer = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.primary[500]};
    font-size: ${theme.fontSize.xs};
    margin-left: auto;
  `,
);

export const PublicLayout: FC = () => {
  const location = useLocation();
  const isNotRootRoute = routes.root !== location.pathname;
  return (
    <CenteredLayout>
      <TopBarContainer>
        <div>
          {isNotRootRoute && (
            <LinkButton as={Link} to={routes.root} id="back-button">
              <FormattedMessage id="authentication.backToLogin" />
            </LinkButton>
          )}
        </div>
        <LanguageSwitch />
      </TopBarContainer>
      <Content>
        <Outlet />
      </Content>
      <VersionContainer>
        <AppVersion />
      </VersionContainer>
    </CenteredLayout>
  );
};
