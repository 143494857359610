import { uniq } from 'rambda';
import { useCallback, useState } from 'react';
import { EntityId, TableSelection, TRowEntity } from 'react-table';

export const useSelection = <T extends TRowEntity>(
  initialSelectedIds?: EntityId[],
  disabledIds?: EntityId[],
): TableSelection<T> => {
  const [selectedIds, setSelectedIds] = useState(initialSelectedIds ?? []);

  const toggleRowSelection = useCallback(
    (row: EntityId) => {
      if (disabledIds?.includes(row)) {
        return;
      }

      setSelectedIds((stateRows) =>
        stateRows.includes(row)
          ? stateRows.filter((el) => el !== row)
          : [...stateRows, row],
      );
    },
    [disabledIds],
  );

  const selectRows = useCallback(
    (rows: EntityId[]) =>
      setSelectedIds((stateRows) =>
        uniq([
          ...stateRows,
          ...rows.filter((el) => !disabledIds?.includes(el)),
        ]),
      ),
    [disabledIds],
  );

  const deselectRows = useCallback(
    (rows: EntityId[]) =>
      setSelectedIds((stateRows) =>
        stateRows.filter((el) => !rows.some((row) => row === el)),
      ),
    [],
  );

  const togglePageRowsSelection = useCallback(
    (rows: EntityId[]) => {
      const isEveryRowSelected = rows.every(
        (row) => disabledIds?.includes(row) || selectedIds.includes(row),
      );

      if (isEveryRowSelected) {
        deselectRows(rows);
      } else {
        selectRows(rows);
      }
    },
    [selectedIds, disabledIds, selectRows, deselectRows],
  );

  const clearSelection = useCallback(() => {
    setSelectedIds([]);
  }, []);

  return {
    selectedIds,
    clearSelection,
    selectRows,
    deselectRows,
    toggleRowSelection,
    togglePageRowsSelection,
  };
};
