import { ErrorsDTO } from '@higo/api/src/models/apiError';

export interface ExaminationsAssignmentResultDTO {
  assignmentResults: ExaminationAssignmentResultDTO[];
}

export interface ExaminationAssignmentResultDTO {
  id: number;
  assignmentStatus: ExaminationAssignmentResultDTOStatus;
  errors: ErrorsDTO | null;
}

export enum ExaminationAssignmentResultDTOStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export const isExaminationsAssignmentResultDTO = (
  a: unknown,
): a is ExaminationsAssignmentResultDTO =>
  (a as ExaminationsAssignmentResultDTO)?.hasOwnProperty('assignmentResults') &&
  Array.isArray((a as ExaminationsAssignmentResultDTO).assignmentResults);
