import { QueryKey, useMutation, useQueryClient } from 'react-query';
import { assignExaminations } from '@higo/api/lib/services/examinationService';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';
import { DoctorQueryKey } from 'config/doctorQueryKey';
import { DoctorMutationKey } from 'config/doctorMutationKey';
import axios from 'axios';
import {
  ExaminationAssignmentResultDTO,
  isExaminationsAssignmentResultDTO,
} from '@higo/api/lib/models/examinationsAssignmentResultDTO';
import { toast } from 'react-toastify';
import { ToastMultipleMessagesTemplate } from '@higo/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  extractExaminationAssignmentResultIds,
  filterAssigned,
  filterNotAssigned,
} from 'utils/examinationAssignmentUtils';
import { useDispatchDoctorTrackedType } from 'hooks/useDispatchDoctorTrackedEvent';
import {
  DoctorTrackedType,
  TrackedAssignExaminationEventTrigger,
} from '@higo/common/lib/features/TrackedEvent';

const extractErrorMessages = (results: ExaminationAssignmentResultDTO[]) =>
  results.map(
    ({ id, errors }) =>
      `${id}: ${errors?.errors.map(({ message }) => message).join(', ')}`,
  );

export const useAssignExaminationsMutation = (mutationId?: QueryKey) => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { authorizedAxios } = useDoctorPortalApiContext();
  const sendTrackedEvent = useDispatchDoctorTrackedType();

  return useMutation(
    [DoctorMutationKey.AssignExaminations, mutationId],
    assignExaminations(authorizedAxios),
    {
      onSuccess: async (data) => {
        const assignedExaminations = filterAssigned(data.assignmentResults);
        const notAssignedExaminations = filterNotAssigned(
          data.assignmentResults,
        );

        if (assignedExaminations.length === data.assignmentResults.length) {
          toast.success(
            intl.formatMessage({
              id: 'examinationsAssignment.allAssignedSuccessfully',
            }),
          );

          extractExaminationAssignmentResultIds(assignedExaminations).forEach(
            (id) =>
              sendTrackedEvent({
                event: DoctorTrackedType.AssignExamination,
                eventTrigger:
                  TrackedAssignExaminationEventTrigger.AssignMultipleButtonClick,
                eventResult: 'success',
                examinationId: id,
              }),
          );
        } else {
          const assignedIds =
            extractExaminationAssignmentResultIds(assignedExaminations);
          const notAssignedIds = extractExaminationAssignmentResultIds(
            notAssignedExaminations,
          );

          assignedIds.forEach((id) =>
            sendTrackedEvent({
              event: DoctorTrackedType.AssignExamination,
              eventTrigger:
                TrackedAssignExaminationEventTrigger.AssignMultipleButtonClick,
              eventResult: 'success',
              examinationId: id,
            }),
          );

          notAssignedIds.forEach((id) =>
            sendTrackedEvent({
              event: DoctorTrackedType.AssignExamination,
              eventTrigger:
                TrackedAssignExaminationEventTrigger.AssignMultipleButtonClick,
              eventResult: 'error',
              examinationId: id,
            }),
          );

          toast.success(
            intl.formatMessage(
              { id: 'examinationsAssignment.someAssignedSuccessfully' },
              { IDs: assignedIds.join(', ') },
            ),
          );
          toast.error(
            <ToastMultipleMessagesTemplate
              title={
                <FormattedMessage id="examinationsAssignment.someNotAssignedSuccessfully" />
              }
              messages={extractErrorMessages(notAssignedExaminations)}
            />,
          );
        }

        await queryClient.invalidateQueries(
          DoctorQueryKey.UnassignedExaminationsList,
        );

        await queryClient.invalidateQueries(
          DoctorQueryKey.AssignedExaminationsList,
        );
      },
      onError: (error, variables) => {
        if (axios.isAxiosError(error)) {
          const responseError = error.response?.data;
          if (isExaminationsAssignmentResultDTO(responseError)) {
            toast.error(
              <ToastMultipleMessagesTemplate
                title={
                  <FormattedMessage id="examinationsAssignment.allNotAssignedSuccessfully" />
                }
                messages={extractErrorMessages(responseError.assignmentResults)}
              />,
            );
          }
        }

        variables.examinationIds.forEach((id) =>
          sendTrackedEvent({
            event: DoctorTrackedType.AssignExamination,
            eventTrigger:
              TrackedAssignExaminationEventTrigger.AssignMultipleButtonClick,
            eventResult: 'error',
            examinationId: id,
          }),
        );
      },
    },
  );
};
