import { IconBox, IconProps } from '../IconBox';

const RoundSurveyIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm-.75-18a.75.75 0 0 0 0 1.5h1.5a.75.75 0 0 0 0-1.5h-1.5ZM7.5 8.25A1.5 1.5 0 0 1 9 6.75 2.25 2.25 0 0 0 11.25 9h1.5A2.25 2.25 0 0 0 15 6.75a1.5 1.5 0 0 1 1.5 1.5v8.25A1.5 1.5 0 0 1 15 18H9a1.5 1.5 0 0 1-1.5-1.5V8.25Zm2.25 3a.75.75 0 0 0 0 1.5h.008a.75.75 0 0 0 0-1.5H9.75Zm2.25 0a.75.75 0 0 0 0 1.5h2.25a.75.75 0 0 0 0-1.5H12Zm-2.25 3a.75.75 0 0 0 0 1.5h.008a.75.75 0 0 0 0-1.5H9.75Zm2.25 0a.75.75 0 0 0 0 1.5h2.25a.75.75 0 0 0 0-1.5H12Z"
    />
  </IconBox>
);

export default RoundSurveyIcon;
