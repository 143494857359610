import { useState } from 'react';
import styled from 'styled-components';
import { createAspectRatio } from '@higo/ui';
import { VisualExaminationDiagnosticVideoViewerWithToggling } from './VisualExaminationDiagnosticVideoViewer';
import { VisualExaminationDiagnosticViewerProps } from './VisualExaminationDiagnosticViewerProps';
import { VisualExaminationDiagnosticGalleryViewer } from './VisualExaminationDiagnosticGalleryViewer';
import { BaseButton } from '@higo/ui';

const DiagnosticVideoContainer = styled.div`
  ${createAspectRatio(4, 3)}
  width: calc(100% - 4rem);
  display: flex;
  margin: 0 auto;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: calc(100% - 8rem);
  #gallery-button {
    margin-right: 1px;
  }
  #video-button {
    margin-left: 1px;
  }
`;

const StyledButton = styled(BaseButton)`
  height: 2.25rem;
  width: calc(100% - 2rem);
  align-items: center;
  justify-content: center;
`;

export type WithTogglingViewerButtonProps = {
  buttons: object;
  setButtons: any;
  handleButtonsChange: any;
};

export const WithTogglingViewer = (
  props: VisualExaminationDiagnosticViewerProps,
) => {
  const { data, isLoading, isError } =
    props.model.usePhysicalExaminationResultsQuery(props.examinationId);
  const diagnosticItemIndex = data?.diagnosticImageIndex;
  const diagnosticItemName = props.rowData
    ? props.rowData[diagnosticItemIndex]?.filename
    : undefined;

  const [buttons, setButtons] = useState([
    { label: 'Diagnostic Frames', value: true },
    { label: 'Full video', value: false },
  ]);

  const handleButtonsChange =
    (props: WithTogglingViewerButtonProps) => (label: string) => {
      const newButtonsState = buttons.map((button) => {
        if (button.label === label) {
          return (button = { label: button.label, value: true });
        }
        return {
          label: button.label,
          value: false,
        };
      });
      setButtons(newButtonsState);
    };

  const VisualExaminationDiagnosticTabButton = (
    label: string,
    value: boolean,
    isVideoButton: boolean,
  ) => {
    return (
      <StyledButton
        style={{
          background: value ? '#39C0FE' : '#FFFFFF',
          border: value ? 'none' : '1px solid #8E94B0',
          color: value ? '#FFFFFF' : '#8E94B0',
        }}
        id={isVideoButton ? 'video-button' : 'gallery-button'}
        onClick={() =>
          handleButtonsChange({
            buttons,
            setButtons,
            handleButtonsChange: undefined,
          })(label)
        }
      >
        {isVideoButton && !data?.video
          ? props.requestGenerateVideoButtonSlot
          : label}
      </StyledButton>
    );
  };

  return (
    <>
      <ButtonsContainer>
        {VisualExaminationDiagnosticTabButton(
          buttons[0].label,
          buttons[0].value,
          false,
        )}
        {VisualExaminationDiagnosticTabButton(
          buttons[1].label,
          buttons[1].value,
          true,
        )}
      </ButtonsContainer>

      <DiagnosticVideoContainer>
        {buttons[0].value && (
          <VisualExaminationDiagnosticGalleryViewer
            examinationId={props.examinationId}
            diagnosticImageIndex={data?.diagnosticImageIndex}
            diagnosticItemName={diagnosticItemName}
            model={props.model}
            galleryViewerProps={props.galleryViewerProps}
            id="visual-gallery-viewer"
            setActiveGalleryLink={props.setActiveGalleryLink}
          />
        )}

        {isLoading && <div>loading video</div>}
        {isError && <div>error video</div>}

        {buttons[1].value && (
          <DiagnosticVideoContainer id="diagnostic-video-container">
            <VisualExaminationDiagnosticVideoViewerWithToggling
              examinationId={props.examinationId}
              model={props.model}
            />
          </DiagnosticVideoContainer>
        )}
      </DiagnosticVideoContainer>
    </>
  );
};
