import { CopyIcon, IconButton } from '@higo/ui';
import { FC, RefObject } from 'react';
import { StyleProps } from '@summer/jst-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';

export type CopyTextContentButtonProps = {
  contentRef: RefObject<HTMLElement>;
  isAnamnesisSurveyButton?: boolean;
  isGeneralSurveyButton?: boolean;
  hasToastWhenCopy?: boolean;
};
export const CopyTextContentButton: FC<
  CopyTextContentButtonProps & StyleProps
> = ({
  className,
  style,
  contentRef,
  isAnamnesisSurveyButton,
  isGeneralSurveyButton,
  hasToastWhenCopy,
}) => {
  const intl = useIntl();

  return (
    <IconButton
      className={className}
      style={style}
      color="secondary"
      size="small"
      icon={<CopyIcon />}
      id="copy-button"
      onClick={() => {
        contentRef?.current?.textContent &&
          navigator.clipboard
            .writeText(contentRef?.current?.textContent)
            .then(() => {
              hasToastWhenCopy &&
                isAnamnesisSurveyButton &&
                toast.success(
                  intl.formatMessage({
                    id: 'anamnesisSurvey.copiedContextToClipboard',
                  }),
                );
              hasToastWhenCopy &&
                isGeneralSurveyButton &&
                toast.success(
                  intl.formatMessage({
                    id: 'generalSurvey.copiedContextToClipboard',
                  }),
                );
            });
      }}
    />
  );
};
