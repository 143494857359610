import { FC } from 'react';
import { Input } from '@higo/ui/src/components/Input';
import {
  FormControl,
  FormControlProps,
} from '@higo/ui/src/components/FormControl';
import { InputUnstyledOwnProps } from '@mui/base/InputUnstyled/InputUnstyled.types';

export type TextFieldProps = InputUnstyledOwnProps & FormControlProps;

export const TextField: FC<TextFieldProps> = ({
  style,
  className,
  label,
  hiddenLabel,
  required,
  disabled,
  error,
  defaultValue,
  helperText,
  horizontal = false,
  onChange,
  onBlur,
  onFocus,
  value,
  ...rest
}) => {
  // console.log('defaultValue', defaultValue, 'value:', value); // for debug purposes
  // console.log(rest.rows);
  return (
    <FormControl
      style={style}
      className={className}
      required={required}
      label={label}
      hiddenLabel={hiddenLabel}
      disabled={disabled}
      helperText={helperText}
      horizontal={horizontal}
      size={rest.size}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      error={error}
    >
      <Input {...rest} />
    </FormControl>
  );
};
