import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';
import {
  ButtonUnstyled,
  buttonUnstyledClasses,
  ButtonUnstyledProps,
} from '@mui/base';

type ButtonColor = 'primary' | 'secondary' | 'danger';
export type BaseButtonSize = 'default' | 'small';

const sizeStyleMap: Record<
  BaseButtonSize,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  default: css`
    height: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
  `,
  small: css`
    height: 2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  `,
};

const colorStyleMap: Record<
  ButtonColor,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  primary: css(
    ({ theme }) => css`
      color: ${theme.palette.white};
      background-color: ${theme.palette.primary['500']};
      box-shadow: 0 1px 1px rgba(46, 154, 203, 0.8);

      &.${buttonUnstyledClasses.disabled} {
        background-color: ${theme.palette.neutral['600']};
        box-shadow: none;
      }

      &:not(.${buttonUnstyledClasses.disabled}) {
        &:hover {
          background-color: ${theme.palette.primary['500']};
        }

        &.${buttonUnstyledClasses.focusVisible} {
          border: 1px solid ${theme.palette.primary['600']};
        }

        &:active {
          background-color: ${theme.palette.primary['600']};
          box-shadow: none;
        }
      }
    `,
  ),
  secondary: css(
    ({ theme }) =>
      css`
        color: ${theme.palette.secondary['500']};
        background-color: ${theme.palette.white};
        border: 1px solid ${theme.palette.secondary['500']};

        &.${buttonUnstyledClasses.disabled} {
          background-color: ${theme.palette.white};
          border: 1px solid ${theme.palette.secondary['200']};
          color: ${theme.palette.secondary['200']};
          box-shadow: none;
        }

        &:not(.${buttonUnstyledClasses.disabled}) {
          &:hover {
            color: ${theme.palette.white};
            border-color: ${theme.palette.primary['500']};
            background-color: ${theme.palette.primary['500']};
          }

          &.${buttonUnstyledClasses.focusVisible} {
            border: 1px solid ${theme.palette.primary['600']};
          }

          &:active {
            color: ${theme.palette.white};
            background-color: ${theme.palette.primary['600']};
            box-shadow: none;
          }
        }
      `,
  ),
  danger: css(
    ({ theme }) => css`
      color: ${theme.palette.white};
      background-color: ${theme.palette.error};
      box-shadow: 0 1px 1px rgba(46, 154, 203, 0.8);

      &.${buttonUnstyledClasses.disabled} {
        background-color: ${theme.palette.neutral['600']};
        box-shadow: none;
      }

      &:not(.${buttonUnstyledClasses.disabled}) {
        &:hover {
          background-color: ${theme.palette.error};
        }

        &.${buttonUnstyledClasses.focusVisible} {
          border: 1px solid ${theme.palette.error};
        }

        &:active {
          background-color: ${theme.palette.error};
          box-shadow: none;
        }
      }
    `,
  ),
};

export const BaseButton = styled(ButtonUnstyled)<BaseButtonProps>(
  ({ theme, size, color }) => css`
    border: none;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    font-family: ${theme.fontFamily.primary};
    font-size: 0.8125rem;
    font-weight: 700;
    line-height: 1;

    user-select: none;

    &.${buttonUnstyledClasses.disabled} {
      cursor: not-allowed;
    }

    ${sizeStyleMap[size ?? 'default']}
    ${colorStyleMap[color ?? 'primary']}
  `,
);

export type BaseButtonProps = {
  color?: ButtonColor;
  size?: BaseButtonSize;
} & ButtonUnstyledProps;
