import { useOnEventEmitterMessage } from 'contexts/EventEmitterProvider';
import { AppEvent } from 'types/appEvent';
import { memo, useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { messagesQueryKeys } from 'config/doctorQueryKey';
import { useExaminationsStoreActions } from 'store/examinationsStore';
import { DoctorQueryKey } from 'config/doctorQueryKey';

export const RegisterAppEventGlobalListeners = memo(() => {
  const queryClient = useQueryClient();
  const { addNewExamination, addTakenExamination } =
    useExaminationsStoreActions();

  useOnEventEmitterMessage(
    AppEvent.FcmNewComment,
    useCallback(
      () => queryClient.invalidateQueries(messagesQueryKeys.unreadList()),
      [queryClient],
    ),
  );

  useOnEventEmitterMessage(
    AppEvent.FcmNewExam,
    useCallback(
      (data) => {
        addNewExamination(data.examId);
      },
      [addNewExamination],
    ),
  );

  useOnEventEmitterMessage(
    AppEvent.FcmExaminationTaken,
    useCallback(
      (data) => {
        addTakenExamination(data.examId);
      },
      [addTakenExamination],
    ),
  );

  useOnEventEmitterMessage(
    AppEvent.FcmSurveyFilled,
    useCallback(() => {
      queryClient.invalidateQueries(DoctorQueryKey.UnassignedExaminationsList);
    }, [queryClient]),
  );

  return null;
});
