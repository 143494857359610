import styled, { css } from 'styled-components';
import { TD } from '@higo/ui/src/components/Table/components/TD';

export const TR = styled('div')(
  ({ theme }) => css`
    position: relative;
    height: 3.625rem;
    &:hover > ${TD} {
      background: ${theme.palette.neutral['300']};
    }
    & > .skeleton {
      margin: 4px 0;
    }
    &.selected > ${TD} {
      background: ${theme.palette.neutral['400']};
    }
    &.highlighted > ${TD} {
      background: ${theme.palette.neutral['400']};
    }
    &.clickable {
      cursor: pointer;
    }
    &.disabled {
      cursor: not-allowed;
      user-select: none;
      & > ${TD} {
        pointer-events: none;
        &:before {
          bottom: 0;
          left: 0 !important;
          right: 0 !important;
          z-index: 5;
        }
      }
      &:before {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        left: 4rem;
        right: 0;
        z-index: 3;
        border-radius: 0.5rem;
        background: linear-gradient(
          270deg,
          #eef0f4 17.1%,
          rgba(255, 255, 255, 0.7) 100%
        );
      }
    }
  `,
);

export const SkeletonTR = styled(TR)`
  display: flex;
  flex: 1 0 auto;
  min-width: 0px;
`;

export const InfoTR = styled(TR)`
  display: flex;
  flex: 1 0 auto;
  min-width: 0px;
  align-items: center;
  justify-content: center;
  height: auto;
`;

export const TRDisabledLabel = styled('span')(
  ({ theme }) => css`
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
    color: ${theme.palette.secondary['500']};
    ${theme.typography.body};
    font-weight: 600;
    text-align: right;
    z-index: 10;
  `,
);
