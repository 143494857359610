import { Button, Heading, RHFTextField, TooltipIcon } from '@higo/ui';
import styled, { css, DefaultTheme, useTheme } from 'styled-components';
import { FC, useRef } from 'react';
import { ExaminationTOCList } from './ExaminationTOCList';
import { inputUnstyledClasses } from '@mui/base';
import { CSSTransition } from 'react-transition-group';
import { FormattedMessage } from 'react-intl';
import { FloatingDiagnosisWindowProps } from './floatingDiagnosisWindowProps';
import { ExaminationPageSectionId } from '@higo/common/src/features/ExaminationPage/models/examinationPageSectionId';
import { ExaminationPageSummaryFormField } from '@higo/common/src/features/ExaminationPage/sections/ExaminationPageSummarySection/examinationPageSummaryFormConfig';

const Container = styled('div')(
  () => css`
    @media print {
      display: none;
    }

    position: sticky;
    top: 13rem;
  `,
);

const transition = (theme: DefaultTheme) => css`
  will-change: opacity;
  transition: ${theme.transitions.create(['opacity'], {
    duration: theme.transitions.duration.standard,
    easing: theme.transitions.easing.easeInOut,
  })};
`;

const FullWindowContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.secondary['500']};
    width: 20.5rem;
    padding: 1rem;
    border-radius: 16px;

    &.enter {
      opacity: 0;
    }
    &.enter-active {
      opacity: 1;
      ${transition(theme)}
    }
    &.exit {
      opacity: 1;
    }
    &.exit-active {
      opacity: 0;
      ${transition(theme)}
    }
  `,
);

const StandaloneExaminationTOCList = styled(ExaminationTOCList)(
  () => css`
    position: absolute;
    top: ${1 + 2}rem;
    right: ${1}rem;
    z-index: 1;
  `,
);

const Group = styled.div`
  margin-bottom: 1rem;
`;

const GroupHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const GroupHeading = styled(Heading)(
  ({ theme }) => css`
    color: ${theme.palette.white};

    margin: 0;
    padding: 0;
  `,
);
const GroupContent = styled.div`
  width: 100%;
  display: flex;
  gap: 1.25rem;
`;

const DiagnosisTocGroupContent = styled(GroupContent)`
  padding-right: calc(1.25rem + 1.5rem);
  gap: 0;
`;

const StyledRHFTextField = styled(RHFTextField)(
  () => css`
    flex-grow: 1;
    margin-bottom: 0;

    & .${inputUnstyledClasses.input} {
      resize: none;
    }
  `,
);

const StyledTooltipIcon = styled(TooltipIcon)(
  ({ theme }) => css`
    color: ${theme.palette.neutral['700']};
    width: 1rem;
    margin-right: 0.5rem;
  `,
);

export interface FloatingDiagnosisWindowDesktopProps
  extends FloatingDiagnosisWindowProps {
  isDesktopCollapsed: boolean;
}

export const FloatingDiagnosisWindowDesktop: FC<FloatingDiagnosisWindowDesktopProps> =
  ({
    isDesktopCollapsed,
    availableExaminationPageSections,
    diagnosisModel,
  }) => {
    const fullWindowContainerRef = useRef(null);
    const theme = useTheme();

    return (
      <Container>
        <StandaloneExaminationTOCList
          availableExaminationPageSections={availableExaminationPageSections}
          excludeDiagnosis
          colorMode={isDesktopCollapsed ? 'inverted' : undefined}
        />
        <CSSTransition
          in={!isDesktopCollapsed}
          nodeRef={fullWindowContainerRef}
          timeout={theme.transitions.duration.standard}
          unmountOnExit
          classNames={''}
        >
          <FullWindowContainer ref={fullWindowContainerRef}>
            <Group>
              <GroupHeader>
                <StyledTooltipIcon />
                <GroupHeading level={4}>
                  <FormattedMessage id="floatingDiagnosisWindow.examinationNotesSummary" />
                </GroupHeading>
              </GroupHeader>
              <DiagnosisTocGroupContent>
                {!diagnosisModel.isNotAvailable && (
                  <StyledRHFTextField
                    hiddenLabel
                    readOnly={diagnosisModel.isReadOnly || isDesktopCollapsed}
                    disabled={diagnosisModel.isReadOnly}
                    name={ExaminationPageSummaryFormField.Notes}
                    multiline
                    rows={14}
                  />
                )}
              </DiagnosisTocGroupContent>
            </Group>

            <Group style={{ marginBottom: '0.25rem' }}>
              <GroupHeader>
                <GroupHeading level={4}>
                  <FormattedMessage id="floatingDiagnosisWindow.diagnosis" />
                </GroupHeading>
              </GroupHeader>
              <GroupContent>
                {!diagnosisModel.isNotAvailable && (
                  <StyledRHFTextField
                    hiddenLabel
                    readOnly={diagnosisModel.isReadOnly || isDesktopCollapsed}
                    disabled={diagnosisModel.isReadOnly}
                    name={ExaminationPageSummaryFormField.Diagnosis}
                    multiline
                    rows={5}
                  />
                )}
              </GroupContent>
            </Group>

            <Button
              onClick={() =>
                document
                  .getElementById(ExaminationPageSectionId.Diagnosis)
                  ?.scrollIntoView()
              }
              fullWidth
            >
              <FormattedMessage id="floatingDiagnosisWindow.summary" />
            </Button>
          </FullWindowContainer>
        </CSSTransition>
      </Container>
    );
  };
