import { IconBox, IconProps } from '../IconBox';

const ChatIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.911 4.5c4.191 0 7.589 3.358 7.589 7.5 0 4.142-3.398 7.5-7.589 7.5a.943.943 0 0 1-.948-.938c0-.207.068-.4.184-.555h-.001a.984.984 0 0 0 .172-.558c0-.544-.442-.987-.99-.996a4.699 4.699 0 0 1-.48.024c-2.954 0-5.348-2.367-5.348-5.286 0-.475.063-.935.182-1.373l.035-.12a.19.19 0 0 1 .008-.036c.037-.111.074-.23.116-.338a7.568 7.568 0 0 1 7.07-4.824Zm.237 6.328a.943.943 0 0 0-.948.938c0 .517.424.937.948.937s.949-.42.949-.937a.943.943 0 0 0-.949-.938Zm3.32 0a.943.943 0 0 0-.948.938c0 .517.425.937.948.937.524 0 .949-.42.949-.937a.943.943 0 0 0-.949-.938Zm-6.64 0a.943.943 0 0 0-.949.938c0 .517.425.937.95.937.523 0 .948-.42.948-.937a.943.943 0 0 0-.949-.938Z"
    />
  </IconBox>
);

export default ChatIcon;
