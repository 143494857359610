import { QueryStorage } from '@higo/common/lib/QueryStorage';

export const sessionStorageQueryStorage: QueryStorage = {
  delete: (queryKey: string) => {
    sessionStorage.removeItem(queryKey);
  },

  read: (queryKey: string) => {
    const data = sessionStorage.getItem(queryKey);
    return data ? JSON.parse(data) : undefined;
  },

  write: (queryKey: string, data: unknown) => {
    sessionStorage.setItem(queryKey, JSON.stringify(data));
  },
};
