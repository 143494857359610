import { FC, ReactNode } from 'react';
import { StyleProps } from '@summer/jst-react';
import styled, { css } from 'styled-components';
import { CopiedTextOnly } from '@higo/common/src/components/CopiedTextOnly';
import { Divider } from '@higo/ui';

const Container = styled('div')`
  margin-bottom: 0.75rem;

  /* workaround for achieving non breakable blocks*/
  width: 100%;
  display: inline-block;
`;

const Header = styled('div')(
  ({ theme }) => css`
    ${theme.typography.h4};
    margin: 1rem 0;
  `,
);

export type AnamnesisSurveyGroupProps = {
  title: ReactNode;
} & StyleProps;

export const AnamnesisSurveyGroup: FC<AnamnesisSurveyGroupProps> = ({
  className,
  title,
  children,
}) => (
  <Container className={className} id="anamnesis-container">
    <Divider />
    <Header id="anamnesis-header">
      {title}
      <CopiedTextOnly id="copied-text">{'\r\n'}</CopiedTextOnly>
    </Header>
    <div>
      {children}
      <CopiedTextOnly id="copied-text-2nd">{'\r\n'}</CopiedTextOnly>
    </div>
  </Container>
);
