import { IconBox, IconProps } from '../IconBox';

const RoundCheckIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm6-12a6 6 0 1 1-1.356-3.8l-4.636 4.637-2.098-2.098a.75.75 0 1 0-1.06 1.06l2.627 2.629a.75.75 0 0 0 1.061 0l4.921-4.921c.347.759.541 1.603.541 2.493Z"
    />
  </IconBox>
);

export default RoundCheckIcon;
