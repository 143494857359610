import { FileResultDto } from '@higo/api';
import { AuscultationDTO } from '@higo/api/lib/models/auscultationDTO';
import { range } from 'rambda';

export const asculationPointsCreate = (
  fileResults: FileResultDto[] = [],
  pointsNumber: number,
  diagnosisData?: AuscultationDTO,
) => {
  const pointsFromData = fileResults.map((item) => item.pointer);

  return range(1, pointsNumber + 1).map((point) => {
    if (pointsFromData.includes(point)) {
      return `point ${point} - conducted${
        diagnosisData
          ? diagnosisData.tags.map((x) =>
              x.measurementPoint === point
                ? `; was tagged as ${x.tags.map((y) => `${y}; `)}`
                : null,
            )
          : ''
      } \n`;
    } else {
      return `point ${point} - omitted \n`;
    }
  });
};
