import { Modal } from '@higo/ui';
import { FC } from 'react';
import { useExaminationAzureResourceQuery } from '@higo/common/src/features/ExaminationPage/hooks/queries';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import LoaderIcon from '@higo/ui/lib/components/icons/LoaderIcon';

export type SingleEarFrameModalProps = {
  frameFileId: number;
  onClose: () => void;
};

const Img = styled.img`
  width: 100%;
  height: 100%;
  background-color: black;
  object-fit: contain;
`;

export const VisualExaminationDiagnosticFrameModal: FC<SingleEarFrameModalProps> =
  ({ frameFileId, onClose }) => {
    const intl = useIntl();
    const { data, isLoading } = useExaminationAzureResourceQuery(frameFileId);

    return (
      <Modal visible={!isNaN(frameFileId)} onClose={onClose}>
        {isLoading ? (
          <LoaderIcon size={16} />
        ) : (
          <Img
            src={data?.link}
            alt={`${intl.formatMessage({
              id: 'diagnosticPhotoAlt',
            })} ${frameFileId}`}
          />
        )}
      </Modal>
    );
  };
