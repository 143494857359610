import { IconBox, IconProps } from '../IconBox';

const PlayIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.752 12.832a1 1 0 0 0 0-1.664L9.055 4.036A1 1 0 0 0 7.5 4.87V19.13a1 1 0 0 0 1.555.832l10.697-7.13Z"
    />
  </IconBox>
);

export default PlayIcon;
