import { useIntl } from 'react-intl';
import {
  useExponentialBackoff,
  useReactQueryAutoSave,
} from '@higo/common/lib/hooks';
import { toast } from 'react-toastify';
import { ToastId } from 'config/toastId';
import { saveDraftExaminationDiagnosis } from '@higo/api';
import { ExaminationDoctorDiagnosisDTO } from '@higo/api/lib/models/examinationDoctorDiagnosisDTO';
import { appConfig } from 'config/appConfig';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';

// todo: extract and customize
const backoffOpts = {
  randomize: true,
  factor: 1.5,
  minTimeout: appConfig.diagnosisAutoSaveMinDelay,
  maxTimeout: appConfig.diagnosisAutoSaveMaxDelay,
};

export const useExaminationPageDiagnosisAutoSave = (
  id: number,
  enabled = true,
) => {
  const intl = useIntl();
  const { authorizedAxios } = useDoctorPortalApiContext();

  const { increaseAttempt, resetAttempt, delay } =
    useExponentialBackoff(backoffOpts);

  const { save, setDraft, saveStatus } = useReactQueryAutoSave({
    autoSaveOptions: { wait: delay },
    mutateEnabled: enabled,
    mutationOptions: {
      mutationFn: (dto: ExaminationDoctorDiagnosisDTO) =>
        saveDraftExaminationDiagnosis(authorizedAxios)(id, dto),
      onError: () => {
        toast.error(
          intl.formatMessage(
            { id: 'notification.diagnosisAutoSaveError' },
            { retryTime: `${increaseAttempt() / 1000}s` },
          ),
          {
            toastId: ToastId.ExaminationAutoSaveError,
          },
        );
      },
      onSuccess: () => {
        resetAttempt();
      },
    },
  });

  return {
    save,
    setDraft,
    saveStatus,
  };
};
