import styled, { css } from 'styled-components';

export const OverviewContainer = styled('div')(
  ({ theme }) => css`
    display: grid;
    grid-template-columns:
      minmax(12.5rem, min-content) min-content minmax(12.5rem, min-content)
      min-content 2fr;
    grid-column-gap: 1rem;
    padding: 1rem 0 1.5rem;
    background-color: ${theme.palette.neutral['400']};
  `,
);
