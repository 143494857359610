import { FC, memo, useMemo } from 'react';
import styled, { css, CSSProperties } from 'styled-components';
import { StyleProps } from '@summer/jst-react';
import { createPortal } from 'react-dom';
import { Transition, TransitionStatus } from 'react-transition-group';

export interface SnackbarProps extends StyleProps {
  visible: boolean;
  anchorSelector?: string;
}

const Wrapper = styled('div')(
  ({ theme }) => css`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 1.5rem;
    pointer-events: none;
    z-index: ${theme.layers.snackbar};
    opacity: 0;
    transform: translateY(1.5rem);
    transition: ${theme.transitions.create(['opacity', 'transform'], {
      duration: theme.transitions.duration.standard,
    })};
  `,
);

const Container = styled('div')(
  ({ theme }) => css`
    margin: 0 auto;
    width: 100%;
    max-width: 90rem; // 1440px
    padding: 0 2.5rem;
    ${theme.media.desktop.max} {
      padding: 0 1.25rem;
    }
  `,
);

const Content = styled('div')(
  ({ theme }) => css`
    margin-left: auto;
    padding: 0.5rem;
    background: ${theme.palette.secondary['500']};
    box-shadow: 0px 1.25rem 1rem rgba(114, 121, 156, 0.4);
    border-radius: 1rem;
    max-width: max-content;
    pointer-events: all;
  `,
);

const transitionStyles: Partial<Record<TransitionStatus, CSSProperties>> = {
  entered: { opacity: 1, transform: 'translateY(0)' },
  unmounted: { visibility: 'hidden' },
  exited: { visibility: 'hidden' },
};

export const Snackbar: FC<SnackbarProps> = memo<SnackbarProps>(
  ({ children, visible, anchorSelector = 'body', ...props }) => {
    const rootElement = useMemo(
      () => document.querySelector(anchorSelector),
      [anchorSelector],
    );

    if (!rootElement) {
      return null;
    }

    return createPortal(
      <Transition in={visible} timeout={100}>
        {(state) => (
          <Wrapper style={transitionStyles[state]} {...props}>
            <Container>
              <Content>{children}</Content>
            </Container>
          </Wrapper>
        )}
      </Transition>,
      rootElement,
    );
  },
);

Snackbar.displayName = 'Snackbar';
