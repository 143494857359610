import React, { FC, memo } from 'react';
import { MeasurementEntry } from '@higo/common/src/features/ExaminationPage/sections/VitalSignsSection/common/MeasurementEntry';
import { AudioPlaybackButton } from '@higo/ui/lib/components/AudioPlayer';
import { MeasurementSkeleton } from '@higo/common/src/features/ExaminationPage/sections/VitalSignsSection/common/MeasurementSkeleton';
import { MeasurementRenderer } from '@higo/common/src/components/MeasurementRenderer';
import {
  useHeartRatePhysicalExaminationQuery,
  useHeartRateWavPhysicalExaminationQuery,
} from '@higo/common/src/features/ExaminationPage/hooks/queries';
import { useExaminationAzureResourceQuery } from '@higo/common/src/features/ExaminationPage/hooks/queries';
import { isNil } from 'rambda';

export type HeartRateMeasurementEntryProps = {
  examinationId: number;
  isAvailable?: boolean;
};

export const HeartRateMeasurementEntry: FC<HeartRateMeasurementEntryProps> =
  memo(({ examinationId, isAvailable }) => {
    const { data: heartRateData, isLoading: isHeartRateLoading } =
      useHeartRatePhysicalExaminationQuery(examinationId, isAvailable);

    const { data: heartRateWavData, isLoading: isHeartRateWavDataLoading } =
      useHeartRateWavPhysicalExaminationQuery(examinationId, isAvailable);

    const {
      data: heartRateWavResourceData,
      isFetching: isHeartRateWavResourceLoading,
    } = useExaminationAzureResourceQuery(
      heartRateWavData?.heartRateWavResults?.[0]?.fileId ?? NaN,
      isAvailable && !isNil(heartRateWavData?.heartRateWavResults?.[0]?.fileId),
    ); //todo: clarify with business, most likely there should be only one file

    const isHeartRateWavLoading =
      isHeartRateWavDataLoading || isHeartRateWavResourceLoading;

    return (
      <MeasurementEntry
        title="Heart rate"
        measurement={
          isHeartRateLoading ? (
            <MeasurementSkeleton />
          ) : (
            <MeasurementRenderer data={heartRateData} />
          )
        }
      >
        {(isHeartRateWavLoading || heartRateWavResourceData) && (
          <AudioPlaybackButton
            isLoading={isHeartRateWavLoading}
            url={heartRateWavResourceData?.link}
            id="heart-playback-button"
          />
        )}
      </MeasurementEntry>
    );
  });
