import { generatePath } from 'react-router-dom';

// todo: support nested routes
export const routes = {
  root: '/',
  resetPasswordRoute: 'reset-password',
  setNewPassword: 'set-password/:token',

  dashboard: {
    root: '/dashboard/*',
    unassigned: 'unassigned',
    assigned: 'assigned',
    history: 'history',
  },

  patients: {
    root: '/patients',
  },

  messages: 'messages',

  examination: '/examinations/:id',
};

export const getSetNewPasswordRoute = (token: string) =>
  generatePath(routes.setNewPassword, { token });

export const getDashboardChildRoute = (childRoute: string) =>
  generatePath(routes.dashboard.root, { '*': childRoute });

export const getPatientsChildRoute = (childRoute: string) =>
  generatePath(`${routes.patients.root}/*`, { '*': childRoute });

export const getExaminationRoute = (id: string | number) =>
  generatePath(routes.examination, { id: `${id}` });
