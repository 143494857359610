import { mapToUserSettings } from 'models/user/userSettings';
import { ListWrapperDTO, unwrap } from '@higo/api/lib/ext/listWrapper';
import { flatten } from '@higo/api/lib/utils';
import { DoctorUserDTO, UserPasswordDTO, UserSettingsDTO } from '@higo/api';
import { AxiosInstance } from 'axios';

export const fetchUserSettings = (axios: AxiosInstance) => () =>
  axios
    .get<ListWrapperDTO<UserSettingsDTO>>('/api/users/settings')
    .then(flatten)
    .then(unwrap)
    .then(mapToUserSettings);

export const setUserData =
  (axios: AxiosInstance) => (data: Partial<DoctorUserDTO>) =>
    axios.put('/api/users/doctorData', data).then(flatten);

export const changeUserPassword =
  (axios: AxiosInstance) => (data: UserPasswordDTO) =>
    axios.post('/api/users/password', data).then(flatten);

export const requestPasswordReset = (axios: AxiosInstance) => (email: string) =>
  axios.post('/api/users/password/remind', { email }).then(flatten);
