import { IconBox, IconProps } from '../IconBox';

const RoundThroatIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12ZM6.86 9.961c.067-2.274 1.871-4.137 4.109-4.242 2.477-.116 4.52 1.889 4.52 4.378 0 .062-.001.125-.004.186-.014.328.053.654.194.949.19.398.47.942.832 1.551a.425.425 0 0 1-.358.644h-.731a.214.214 0 0 0-.212.186l-.033.24a.21.21 0 0 1-.236.18l-.077-.01c-4.381-.81-5.542 2.432-5.713 3.011a.077.077 0 0 1-.107.049l-.303-.14a.398.398 0 0 1-.224-.425c.11-.692.282-2.442-.578-3.519a4.404 4.404 0 0 1-1.08-3.038Zm3.538 7.649c.857-2.772 3.477-2.415 4.186-2.28.02.006.042.012.064.016l.113.022c.113.023.188.131.172.246l-.086.622a.367.367 0 0 1-.422.315l-1.233-.203a.874.874 0 0 0-1.007.795l-.068.78a.392.392 0 0 1-.552.326l-1.142-.528a.066.066 0 0 1-.035-.077l.01-.034Z"
    />
  </IconBox>
);

export default RoundThroatIcon;
