import { TSelectValue } from '@mui/base/SelectUnstyled';
import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import {
  MultiSelectField,
  MultiSelectFieldProps,
  SelectField,
  SelectFieldProps,
} from '@higo/ui/src/components/Select/SelectField';
import { ErrorMessage } from '@higo/ui/src/components/ErrorMessage';

// todo: RHS select and multiselect should not allow on change prop
export type RHFSelectFieldProps<T, K> = UseControllerProps<T> &
  SelectFieldProps<K>;

export type RHFMultiSelectFieldProps<T, K> = UseControllerProps<T> &
  MultiSelectFieldProps<K>;

export const RHFSelectField = <
  TFieldValues extends FieldValues,
  K extends TSelectValue,
>({
  name,
  control,
  onChange: externalOnChange,
  onBlur: externalOnBlur,
  ...props
}: RHFSelectFieldProps<TFieldValues, K>) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleChange = (value: K | null) => {
    onChange(value);
    externalOnChange?.(value);
  };

  return (
    <SelectField<K>
      name={name}
      {...props}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      error={!!error}
      helperText={<ErrorMessage error={error} label={props.label} />}
    />
  );
};

export const RHFMultiSelectField = <
  TFieldValues extends FieldValues,
  K extends TSelectValue,
>({
  name,
  control,
  ...props
}: RHFMultiSelectFieldProps<TFieldValues, K>) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <MultiSelectField
      name={name}
      {...props}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={!!error}
      helperText={<ErrorMessage error={error} label={props.label} />}
      clearValue={() => onChange([])}
    />
  );
};
