import { bg } from 'date-fns/locale';
import { LocaleDefinition } from '../localeDefinition';
import messages from './bg.json';

const definition: LocaleDefinition = {
  code: 'bg-BG',
  messages,
  dateFnsLocale: bg,
};

export default definition;