import styled, { css } from 'styled-components';
import { Heading } from '@higo/ui';
import { FC } from 'react';

export const StyledHeading = styled(Heading)(
  ({ theme }) => css`
    font-size: ${theme.fontSize.m};
    margin: 0.5rem 0.5rem 0.75rem;
  `,
);

export const HeaderNotificationMenuHeaderMenuItem: FC = ({ children }) => (
  <li>
    <StyledHeading level={3}>{children}</StyledHeading>
  </li>
);
