import { AxiosRequestConfig } from 'axios';

export const setAcceptLanguageHeader = (
  language: string,
  config: AxiosRequestConfig,
): AxiosRequestConfig => ({
  ...config,
  headers: {
    ...config.headers,
    'Accept-Language': language,
  },
});

export const createAcceptLanguageInterceptor =
  (getAcceptLanguage: () => string) => (config: AxiosRequestConfig) =>
    setAcceptLanguageHeader(getAcceptLanguage(), config);
