import { FC, useMemo } from 'react';
import { useExaminationNotesQuery } from './hooks/useExaminationNotesQuery';
import { MedicalOperatorNotesSkeleton } from '@higo/common/src/features/ExaminationPage/sections/AnamnesisSection/MedicalOperatorNotes/MedicalOperatorNotesSkeleton';
import styled, { css } from 'styled-components';
import {
  MedicalOperatorNoteForm,
  MedicalOperatorNoteFormModel,
} from '@higo/common/src/features/ExaminationPage/sections/AnamnesisSection/MedicalOperatorNotes/MedicalOperatorNoteForm';
import { useAddExaminationNoteMutation } from './hooks/useAddExaminationNoteMutation';
import { Text } from '@higo/ui';
import { FormattedMessage } from 'react-intl';
import { StyleProps } from '@summer/jst-react';

const Header = styled('div')(
  ({ theme }) => css`
    ${theme.typography.h4};
    margin: 1rem 0;
  `,
);

export type MedicalOperatorNotesProps = {
  examinationId: number;
  isMedicalOperatorExaminer: boolean;
} & StyleProps;

export const MedicalOperatorNotes: FC<MedicalOperatorNotesProps> = ({
  className,
  style,
  examinationId,
  isMedicalOperatorExaminer,
}) => {
  const { data, isLoading } = useExaminationNotesQuery(examinationId);
  const { mutateAsync } = useAddExaminationNoteMutation(examinationId);

  //todo: requires clarification with backend and business, as backend supports list of notes which never been used
  const note = useMemo(() => data?.[0], [data]);
  const isNoteAvailable = !isLoading && note;

  const handleSubmit = (data: MedicalOperatorNoteFormModel) =>
    mutateAsync(data.note);

  return (
    <div style={style} className={className}>
      <Header>
        <FormattedMessage id="examinationPage.section.anamnesis.medicalOperatorNote.title" />
        :
      </Header>
      {isLoading && <MedicalOperatorNotesSkeleton />}

      {!isNoteAvailable && isMedicalOperatorExaminer && (
        <MedicalOperatorNoteForm onSubmit={handleSubmit} />
      )}

      {!isNoteAvailable && !isMedicalOperatorExaminer && (
        <Text variant="body2">
          <FormattedMessage id="examinationPage.section.anamnesis.medicalOperatorNote.noNote" />
        </Text>
      )}

      {isNoteAvailable && <Text variant="body2">{note.noteText}</Text>}
    </div>
  );
};
