import { ExaminationPatientDTO } from '@higo/api/src/models/examinationPatientDTO';
import { ExaminationDoctorDTO } from '@higo/api/src/models/examinationDoctorDTO';
import { ExaminationDTOStatus } from '@higo/api/src/models/examinationDTO';

export interface DoctorBaseDiagnosisDTO {
  date: string;
  diagnosisId: number;
  doctor: ExaminationDoctorDTO;
}

export interface DoctorBaseExaminationDTO {
  date: string;
  diagnosis: DoctorBaseDiagnosisDTO;
  examinationId: number;
  status: ExaminationDTOStatus;
}

export interface DoctorPatientSearchResultDTO {
  latestExamination: DoctorBaseExaminationDTO;
  patient: ExaminationPatientDTO;
}

export enum DoctorPatientSearchResultSortByField {
  PATIENT_FIRSTNAME = 'PATIENT_FIRSTNAME',
  PATIENT_LASTNAME = 'PATIENT_LASTNAME',
  PATIENT_AGE = 'PATIENT_AGE',
  PATIENT_GENDER = 'PATIENT_GENDER',
  DOCTOR_FIRSTNAME = 'DOCTOR_FIRSTNAME',
  DOCTOR_LASTNAME = 'DOCTOR_LASTNAME',
}
