import { enGB } from 'date-fns/locale';
import { LocaleDefinition } from '../localeDefinition';
import messages from './en.json';

const definition: LocaleDefinition = {
  code: 'en-GB',
  messages,
  dateFnsLocale: enGB,
};

export default definition;
