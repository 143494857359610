import {
  IconBox as JSTIconBox,
  IconProps as JSTIconProps,
} from '@summer/jst-react-icon';
import { forwardRef } from 'react';

export type IconProps = Omit<JSTIconProps, 'ref'>;
export const IconBox = forwardRef<SVGSVGElement, IconProps>(
  ({ size, width, height, ...props }, ref) => (
    <JSTIconBox
      viewBox="0 0 24 24"
      {...props}
      width={width ?? size ?? 24}
      height={height ?? size ?? 24}
      ref={ref}
    />
  ),
);

IconBox.displayName = 'IconBox';
