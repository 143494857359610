import { format, isDate, isValid, parseJSON } from 'date-fns';

// todo: move to renderers or common utils
export const formatDate = <T extends string | undefined>(
  date: Date | string | undefined,
  dateFormat: string,
  fallback?: T,
): T extends string ? string : string | undefined => {
  const coercedDate = isDate(date)
    ? (date as Date)
    : date && isValid(parseJSON(date))
    ? parseJSON(date)
    : undefined;

  return coercedDate ? format(coercedDate, dateFormat) : (fallback as string);
};
