import { IconBox, IconProps } from '../IconBox';

const HistoryIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.188 5.938a.938.938 0 0 0 0 1.875h5.624a.937.937 0 1 0 0-1.875H9.189Zm-2.813 3.75c0-.518.42-.938.938-.938h9.375a.937.937 0 1 1 0 1.875H7.313a.937.937 0 0 1-.938-.938ZM4.5 13.438c0-1.036.84-1.876 1.875-1.876h11.25c1.036 0 1.875.84 1.875 1.876v3.75c0 1.035-.84 1.875-1.875 1.875H6.375A1.875 1.875 0 0 1 4.5 17.188v-3.75Z"
    />
  </IconBox>
);

export default HistoryIcon;
