import styled, { css } from 'styled-components';
// import { FormattedMessage } from 'react-intl'; -> toDo translations
import { createAspectRatio } from '@higo/ui';

const Container = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.neutral['500']};
    width: calc(100% - 4rem);
    margin: 0 auto;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    ${theme.typography.subtitle1};
    color: ${theme.palette.secondary[500]};
    ${createAspectRatio(4, 3)};
  `,
);

export const InfoContainer = styled('div')`
  margin: 0 auto;
  text-align: center;
  vertical-align: middle;
  line-height: 400px;
`;

export const DiagnosticPhotosGalleryNoDataInfo = () => {
  return (
    <Container>
      <InfoContainer>Sorry, video processing failed</InfoContainer>
    </Container>
  );
};
