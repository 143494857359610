import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { isEmpty, isNil, mapObjIndexed } from 'rambda';
import { useLiveRef, closestTo } from '@higo/ui/src/utils';

export interface TocListContext {
  register: (id: string, ref: HTMLElement) => void;
  remove: (id: string) => void;
}

const Context = createContext<TocListContext>({
  register: () => ({}),
  remove: () => ({}),
});

const getElementTocId = (element: Element) => {
  const dataId = element.attributes.getNamedItem('data-id');

  if (isNil(dataId)) {
    // this shouldn't happen if useTocAnchor is used and properly set on element
    throw new Error(`${element} doesn't have required data-id`);
  }

  return dataId.value;
};

const createIntersectionObserver = (
  onChange: (id: string, element: Element) => void,
  trigger: number,
  threshold: number,
) => {
  const elementsMap: Record<string, Element> = {};
  let prevTocElementBoundingRect: Record<string, DOMRect> = {}; //we need to keep this as map as we can dynamically register / remove elements

  const observer = new IntersectionObserver(
    (_) => {
      // we treat intersection event as evaluation trigger

      const tocElementBoundsMap = mapObjIndexed(
        (x) => x.getBoundingClientRect(),
        elementsMap,
      );

      if (isEmpty(prevTocElementBoundingRect)) {
        prevTocElementBoundingRect = tocElementBoundsMap;
        return;
      }

      const tocElementsEntries = Object.entries(elementsMap);

      const isScrollingDown =
        prevTocElementBoundingRect[tocElementsEntries[0][0]].top >
        tocElementsEntries[0][1].getBoundingClientRect().top;

      const sections = Object.values(elementsMap);
      const sectionRect = sections.map((x) => x.getBoundingClientRect());
      const sectionsTop = sectionRect.map((x) => x.top);
      const sectionsBottom = sectionRect.map((x) => x.bottom);

      const directionTrigger =
        trigger + (isScrollingDown ? threshold : -threshold); // intersection observer + threshold (margin between sections which may interfere with calculations)
      const closestTopVal = closestTo(directionTrigger, sectionsTop);
      const closestBottomVal = closestTo(directionTrigger, sectionsBottom);
      const closestVal = closestTo(directionTrigger, [
        closestTopVal,
        closestBottomVal,
      ]);

      const closesSectionsArray =
        closestTopVal === closestVal ? sectionsTop : sectionsBottom;
      const closesIndex = closesSectionsArray.indexOf(closestVal);

      const activeSection = getElementTocId(sections[closesIndex]);
      onChange?.(activeSection, sections[closesIndex]);

      prevTocElementBoundingRect = tocElementBoundsMap;
    },
    {
      rootMargin: `-${trigger}px 0px 0px`,
    },
  );

  const register = (id: string, ref: Element) => {
    elementsMap[id] = ref;
    observer.observe(ref);
  };

  const remove = (id: string) => {
    const el = elementsMap[id];
    if (el) {
      observer.unobserve(el);
      delete elementsMap[id];
    }
  };

  return {
    observer,
    register,
    remove,
  };
};

/**
 * trigger an threshold can only be set up once during initialization
 */
export const TocContainer: FC<{
  onChange?: (id: string, element?: Element) => void;
  initialTrigger?: number; // position from top
  initialThreshold?: number;
}> = ({ children, onChange, initialTrigger = 0, initialThreshold = 0 }) => {
  const liveOnChange = useLiveRef(onChange);

  const [{ observer, remove, register }] = useState(() =>
    createIntersectionObserver(
      (id: string, element: Element) => {
        liveOnChange.current?.(id, element);
      },
      initialTrigger,
      initialThreshold,
    ),
  );

  const value = useMemo(
    () => ({
      register,
      remove,
    }),
    [register, remove],
  );

  // cleanup
  useEffect(() => {
    return () => {
      observer.disconnect();
    };
  }, [observer]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useToc = () => {
  return useContext(Context);
};

export const useTocAnchor = (id: string | undefined) => {
  const { register, remove } = useContext(Context);

  return useMemo(
    () =>
      id
        ? {
            'data-id': id,
            ref: (ref: HTMLElement | null) =>
              ref ? register(id, ref) : remove(id),
          }
        : undefined,
    [id, register, remove],
  );
};
