import { ExaminationType } from '@higo/api/src/models/examinationDetailsDTO';

export interface ExaminationBaseDTO {
  id: number;
  patientFirstName: string;
  patientLastName: string;
  type: ExaminationType;
}

export type ExaminationBase = ExaminationBaseDTO;

export const mapToExaminationBase = (
  dto: ExaminationBaseDTO,
): ExaminationBase => dto;
