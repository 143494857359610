import React from 'react';
import { CellProps } from 'react-table';
import styled from 'styled-components';
import { PatientExaminationsModal } from 'components/PatientExaminationsModal';

export type ActionButtonsRendererValue = {
  patientId: number;
};

const ButtonContainer = styled.div`
  width: 7.5rem;
`;

export const ActionButtonsCellRenderer = <T extends Record<string, unknown>>({
  cell,
}: CellProps<T, ActionButtonsRendererValue>) => (
  <ButtonContainer>
    <PatientExaminationsModal patientId={cell.value?.patientId} />
  </ButtonContainer>
);
