import { IconBox, IconProps } from '../IconBox';

const CopyIcon = (props: IconProps) => (
  <IconBox {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M17.496 20H9.705A2.505 2.505 0 0 1 7.2 17.496V9.705A2.504 2.504 0 0 1 9.705 7.2h7.79A2.505 2.505 0 0 1 20 9.705v7.79A2.505 2.505 0 0 1 17.496 20Z" />
      <path d="M8.503 5.688H16.8A2.537 2.537 0 0 0 14.412 4h-7.88A2.533 2.533 0 0 0 4 6.533v7.88A2.537 2.537 0 0 0 5.688 16.8V8.503a2.814 2.814 0 0 1 2.815-2.815Z" />
    </g>
  </IconBox>
);

export default CopyIcon;
