import { useMutation, useQueryClient } from 'react-query';
import { deleteDoctorExamination } from '@higo/api/lib/services/examinationService';
import { toast } from 'react-toastify';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';
import { DoctorQueryKey } from 'config/doctorQueryKey';
import { useDispatchDoctorTrackedType } from 'hooks/useDispatchDoctorTrackedEvent';
import {
  DoctorTrackedType,
  TrackedAsyncEvent,
} from '@higo/common/lib/features/TrackedEvent';
import { useIntl } from 'react-intl';

export const useUnassignDoctorExaminationMutation = (
  TrackedAsyncEventData?: Pick<TrackedAsyncEvent, 'patientId'>,
) => {
  const { authorizedAxios } = useDoctorPortalApiContext();
  const intl = useIntl();

  const queryClient = useQueryClient();
  const sendTrackedEvent = useDispatchDoctorTrackedType();

  return useMutation(deleteDoctorExamination(authorizedAxios), {
    onSuccess: (_, variables) => {
      toast.success(
        intl.formatMessage({
          id: 'notification.operationSuccessful',
        }),
      );

      sendTrackedEvent({
        event: DoctorTrackedType.UnassignExamination,
        eventResult: 'success',
        examinationId: variables,
        ...TrackedAsyncEventData,
      });

      queryClient.invalidateQueries([DoctorQueryKey.AssignedExaminationsList]);
    },

    onError: (error, variables, context) => {
      sendTrackedEvent({
        event: DoctorTrackedType.UnassignExamination,
        eventResult: 'error',
        examinationId: variables,
        ...TrackedAsyncEventData,
      });

      // call global error handler
      return queryClient
        .getDefaultOptions()
        ?.mutations?.onError?.(error, variables, context);
    },
  });
};
