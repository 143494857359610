import styled, { css } from 'styled-components';
import { FC, useMemo } from 'react';
import { ColorMode, ExaminationTOCButton } from './ExaminationTOCButton';
import {
  RoundCheckIcon,
  RoundCoughIcon,
  RoundEarsIcon,
  RoundHeartIcon,
  RoundLungsIcon,
  RoundSkinIcon,
  RoundStomachIcon,
  RoundSurveyIcon,
  RoundThroatIcon,
  RoundVitalSignsIcon,
} from '@higo/ui';
import { StyleProps } from '@summer/jst-react';
import { useActiveSection } from './useActiveSection';
import { ExaminationPageSectionId } from '@higo/common/src/features/ExaminationPage/models/examinationPageSectionId';

const fullTocList = [
  {
    id: ExaminationPageSectionId.ExaminationContext, //
    icon: RoundVitalSignsIcon,
  },
  {
    id: ExaminationPageSectionId.AnamnesisSurvey,
    icon: RoundSurveyIcon,
  },
  {
    id: ExaminationPageSectionId.Skin,
    icon: RoundSkinIcon,
  },
  {
    id: ExaminationPageSectionId.Heart,
    icon: RoundHeartIcon,
  },
  {
    id: ExaminationPageSectionId.Lungs,
    icon: RoundLungsIcon,
  },
  {
    id: ExaminationPageSectionId.Cough,
    icon: RoundCoughIcon,
  },
  {
    id: ExaminationPageSectionId.Ears,
    icon: RoundEarsIcon,
  },
  {
    id: ExaminationPageSectionId.Throat,
    icon: RoundThroatIcon,
  },
  {
    id: ExaminationPageSectionId.Abdominal,
    icon: RoundStomachIcon,
  },
  {
    id: ExaminationPageSectionId.Diagnosis,
    icon: RoundCheckIcon,
  },
];

const StyledContainer = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
  `,
);

export type ExaminationTOCListProps = {
  availableExaminationPageSections: Set<ExaminationPageSectionId>;
  colorMode?: ColorMode;
  excludeDiagnosis?: boolean;
} & StyleProps;

export const ExaminationTOCList: FC<ExaminationTOCListProps> = ({
  availableExaminationPageSections,
  excludeDiagnosis = false,
  colorMode = 'default',
  ...props
}) => {
  const tocList = useMemo(
    () => (excludeDiagnosis ? fullTocList.slice(0, -1) : fullTocList),
    [excludeDiagnosis],
  );
  const activeSection = useActiveSection();

  return (
    <StyledContainer {...props} id="examination-toc-list">
      {tocList.map(({ id, icon: Icon }) => (
        <ExaminationTOCButton
          key={id}
          id={`${id}-icon`}
          // $isChecked={testIcons}
          $isActive={id === activeSection}
          $isAvailable={
            availableExaminationPageSections.has(id) ||
            ExaminationPageSectionId.Diagnosis === id
          }
          $colorMode={colorMode}
          onClick={() => {
            document.getElementById(id)?.scrollIntoView();
            console.log(document.getElementById(id));
          }}
          disabled={
            !availableExaminationPageSections.has(id) &&
            ExaminationPageSectionId.Diagnosis !== id
          }
        >
          <Icon />
        </ExaminationTOCButton>
      ))}
    </StyledContainer>
  );
};
