import { FC } from 'react';
import useMedia from 'react-use/lib/useMedia';
import { isTabletQuery } from '@higo/ui';
import { FloatingDiagnosisWindowDesktop } from './FloatingDiagnosisWindowDesktop';
import { FloatingDiagnosisWindowTablet } from './FloatingDiagnosisWindowTablet';
import { FloatingDiagnosisWindowProps } from './floatingDiagnosisWindowProps';
import { useActiveSection } from './useActiveSection';
import { ExaminationPageSectionId } from '@higo/common/src/features/ExaminationPage/models/examinationPageSectionId';

export const ExaminationPageFloatingDiagnosisWindow: FC<FloatingDiagnosisWindowProps> =
  (props) => {
    const isTabletView = useMedia(`${isTabletQuery}`);
    const activeSection = useActiveSection();

    return isTabletView ? (
      <FloatingDiagnosisWindowTablet {...props} />
    ) : (
      <FloatingDiagnosisWindowDesktop
        isDesktopCollapsed={
          props.diagnosisModel.isNotAvailable ||
          ExaminationPageSectionId.Diagnosis === activeSection
        }
        {...props}
      />
    );
  };
