import {
  ElementType,
  FC,
  forwardRef,
  MouseEvent,
  PropsWithChildren,
} from 'react';
import styled, { css } from 'styled-components';
import { ButtonUnstyled, buttonUnstyledClasses } from '@mui/base';
import { IconProps } from './IconBox';
import { StyleProps } from '@summer/jst-react';
import { CloseIcon } from './icons';

const BasicCounterChip = styled('div')(
  ({ theme }) => css`
    font-family: ${theme.typography.fontFamily};
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1;
    height: 1rem; // (32-2px border)
    display: inline-flex;
    align-items: center;

    user-select: none;
    transition: ${theme.transitions.create('all')};
    padding: 0 0.375rem;

    outline: none;
    border: 1px solid ${theme.palette.secondary['500']}; // outline in this case just dont look good
    border-radius: 0.5rem;

    color: ${theme.palette.white};
    background-color: ${theme.palette.secondary['500']};
  `,
);

const CounterChipRoot = styled(BasicCounterChip).attrs({ as: 'button' })(
  ({ theme }) => css`
    cursor: pointer;
    &.${buttonUnstyledClasses.disabled} {
      cursor: not-allowed;
      background-color: ${theme.palette.secondary['200']};
      border-color: ${theme.palette.secondary['200']};
    }

    &:hover {
      background-color: ${theme.palette.primary['500']};
      border-color: ${theme.palette.primary['500']};
    }

    &.${buttonUnstyledClasses.focusVisible} {
      background-color: ${theme.palette.secondary['500']};
      border: 1px solid ${theme.palette.primary['600']};
    }

    &:active {
      background-color: ${theme.palette.primary['600']};
      border-color: ${theme.palette.primary['600']};
    }
  `,
);

const StyledCloseIcon = styled(CloseIcon)`
  margin-left: 0.125rem;
`;

export type CounterChipProps = {
  onDelete?: (e: MouseEvent) => void;
  icon?: FC<IconProps>;
  disabled?: boolean;
} & StyleProps;

export const CounterChip = forwardRef<
  HTMLElement,
  PropsWithChildren<CounterChipProps>
>(({ children, onDelete, icon, ...props }, ref) => {
  const Component: ElementType = onDelete ? ButtonUnstyled : BasicCounterChip;

  const moreProps = onDelete
    ? { component: CounterChipRoot, onClick: onDelete, ...props }
    : {};

  return (
    <Component ref={ref} {...props} {...moreProps}>
      <span>
        {!onDelete && '+'}
        {children}
      </span>
      {onDelete && <StyledCloseIcon as={icon} size={16} />}
    </Component>
  );
});
