import { useNewExaminationNotificationQuery } from './hooks/useNewExaminationNotificationQuery';
import { MenuItem, MenuItemProps, Skeleton } from '@higo/ui';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { ExamTypeLabel } from 'components/ExamTypeLabel';
import { ExaminationType } from '@higo/api';
import { format } from 'date-fns';
import { MouseEvent, useCallback } from 'react';
import { getExaminationRoute } from 'routes';
import { useNavigate } from 'react-router-dom';

export type HeaderNewExaminationsNotificationsMenuItemProps = {
  examinationId: number;
};

const StyledLink = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.26rem;
  padding-left: 1rem;
`;

const ExaminationSkeleton = styled(Skeleton)`
  height: 2rem;
`;

const DateSkeleton = styled(Skeleton)`
  height: 1rem;
`;

const Message = styled.div(
  ({ theme }) => css`
    ${theme.typography.subtitle1};
    color: ${theme.palette.secondary['900']};
    font-weight: 700;
  `,
);

const MessageDate = styled.div(
  () => css`
    font-weight: 400;
  `,
);

export const HeaderNewExaminationsNotificationsMenuItem = ({
  examinationId,
  ...props
}: HeaderNewExaminationsNotificationsMenuItemProps & MenuItemProps) => {
  const { isLoading, data, dataUpdatedAt } =
    useNewExaminationNotificationQuery(examinationId);

  const navigate = useNavigate();

  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();

      navigate(getExaminationRoute(examinationId));
    },
    [examinationId, navigate],
  );

  return (
    <MenuItem {...props}>
      <StyledLink onClick={handleClick}>
        {isLoading && (
          <>
            <ExaminationSkeleton />
            <DateSkeleton />
          </>
        )}
        {!isLoading && data && (
          <>
            <Message>
              <FormattedMessage
                id="header.notifications.examinations.newExaminationMessage"
                values={{
                  type: (
                    <ExamTypeLabel
                      type={ExaminationType.Full}
                      short={ExaminationType.Full === data.type}
                    />
                  ),
                  fullName: `${data.patientFirstName} ${data.patientLastName}`,
                }}
              />
            </Message>
            {/*todo: change dateUpdatedAt to date received from backend*/}
            <MessageDate>{format(dataUpdatedAt, 'HH:mm')}</MessageDate>
          </>
        )}
      </StyledLink>
    </MenuItem>
  );
};
