import { useQuery, UseQueryOptions } from 'react-query';
import { fetchPatientsMessages } from '@higo/api/lib/services';
import {
  mapToPatientMessage,
  PaginatedListReqDTO,
  PaginatedListResultDTO,
  PatientMessage,
} from '@higo/api/lib/models';
import { mapPaginatedListResult } from '@higo/api/lib/utils/mapPaginatedListResult';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';
import { DoctorQueryKey } from 'config/doctorQueryKey';

export const useMessagesQuery = (
  data?: PaginatedListReqDTO,
  options?: Omit<
    UseQueryOptions<
      PaginatedListResultDTO<PatientMessage>,
      unknown,
      PaginatedListResultDTO<PatientMessage>,
      (PaginatedListReqDTO | DoctorQueryKey | undefined)[]
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  const { authorizedAxios } = useDoctorPortalApiContext();

  return useQuery(
    [DoctorQueryKey.Messages, data],
    () => fetchPatientsMessages(authorizedAxios)(data).then(mapResults),
    {
      keepPreviousData: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      ...options,
    },
  );
};

const mapResults = mapPaginatedListResult(mapToPatientMessage);
