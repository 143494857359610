import styled from 'styled-components';
import { LungsAuscultationLegendContainer } from './LungsAuscultationLegendContainer';
import { FrontBodyImage } from './BodyImage/FrontBodyImage';
import { FormattedMessage } from 'react-intl';
import { FC } from 'react';
import { LungsAuscultationLegendProps } from './LungsAuscultationLegendProps';

const StyledFrontBodyImage = styled(FrontBodyImage)`
  max-width: 100%;
`;
export const LungsFrontAuscultationLegend: FC<LungsAuscultationLegendProps> = ({
  gender,
  maturity,
}) => {
  return (
    <LungsAuscultationLegendContainer
      heading={<FormattedMessage id="lungAuscultation.anterior" />}
    >
      <StyledFrontBodyImage gender={gender} maturity={maturity} />
    </LungsAuscultationLegendContainer>
  );
};
