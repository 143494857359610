import { useQuery } from 'react-query';
import {
  fetchLeftEarExaminationResults,
  fetchRightEarExaminationResults,
} from '@higo/api/lib/services/physicalExaminationService';
import { useExaminationApiContext } from '@higo/common/src/features/ExaminationPage/providers';
import { ExaminationQueryKey } from '@higo/common/src/features/ExaminationPage/ExaminationQueryKey';
import { EarResultDTO } from '@higo/api/lib/models/examinationResultsDTO';

export const useRightEarExaminationResultsQuery = <TData = EarResultDTO>(
  id: number,
  select?: (data: EarResultDTO) => TData,
) => {
  const axios = useExaminationApiContext();

  return useQuery(
    [ExaminationQueryKey.RightEarExaminationResults, id],
    () => fetchRightEarExaminationResults(axios)(id),
    {
      enabled: !isNaN(id),
      select,
    },
  );
};

export const useLeftEarExaminationResultsQuery = <TData = EarResultDTO>(
  id: number,
  select?: (data: EarResultDTO) => TData,
) => {
  const axios = useExaminationApiContext();

  return useQuery(
    [ExaminationQueryKey.LeftEarExaminationResults, id],
    () => fetchLeftEarExaminationResults(axios)(id),
    {
      enabled: !isNaN(id),
      select,
    },
  );
};
