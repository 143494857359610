import { useDictionaryCollector } from '@summer/jst-react-dictionaries';
import styled from 'styled-components';
import {
  Chip,
  RHFExhaustiveMultiSelectField,
  RHFSelectTriggerButton,
} from '@higo/ui';
import { FC, useMemo } from 'react';
import { useController } from 'react-hook-form';
import {
  DictionaryCollector,
  DictionarySelector,
} from '@summer/jst-dictionaries';
import { useDictionaryMap } from '@higo/common/src/hooks';
import { AuscultationTagDictionaryItemMetadata } from '@higo/common/src/features/Dictionary';

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(12.5rem, 1fr) 2rem;
  grid-column-gap: 1rem;
`;

const ChipContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export type AusultationTagSelectProps = {
  name: string;
  readonly?: boolean;
  dictionarySelector: DictionarySelector<
    string,
    AuscultationTagDictionaryItemMetadata
  >;
  dictionaryItemsCollector: DictionaryCollector<
    string,
    AuscultationTagDictionaryItemMetadata
  >;
};

/**
 * Dedicated control for tagged auscultation, to be used in form context
 */
export const AusultationTagSelect: FC<AusultationTagSelectProps> = ({
  name,
  readonly = false,
  dictionarySelector,
  dictionaryItemsCollector,
}) => {
  const {
    field: { onChange, value: selectedValues },
  } = useController({
    name,
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    defaultValue: [] as any, // todo: fixme
  });

  const dictionary = useDictionaryMap(dictionarySelector);
  const auscultationItems = useDictionaryCollector(dictionaryItemsCollector);

  const options = useMemo(
    () =>
      auscultationItems.map(({ name, code, jsonNodeMetaData }) => ({
        label: name,
        value: code,
        exhaustive: jsonNodeMetaData?.blocking ?? false,
      })),
    [auscultationItems],
  );

  return (
    <Container id="asculation-tags">
      <ChipContainer>
        {selectedValues &&
          selectedValues.map((value: string) => (
            <Chip
              key={value}
              id={value}
              onDelete={
                readonly
                  ? undefined
                  : () => {
                      /* eslint-disable  @typescript-eslint/no-explicit-any */
                      onChange(
                        (selectedValues as any[]).filter((v) => v !== value),
                      );
                    }
              }
            >
              {dictionary[value]?.name}
            </Chip>
          ))}
      </ChipContainer>
      <div>
        {!readonly && (
          <RHFExhaustiveMultiSelectField
            options={options}
            name={name}
            components={{ Root: RHFSelectTriggerButton }}
            hiddenLabel
          />
        )}
      </div>
    </Container>
  );
};
