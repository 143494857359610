import {
  useExaminationAzureResourceQuery,
  useSkinExaminationResultsQuery,
} from '@higo/common/src/features/ExaminationPage/hooks/queries';
import { SkinExaminationDTO } from '@higo/api/lib/models/examinationResultsDTO';
import { map, pick, pipe, prop } from 'rambda';
import { slidingWindowSlice } from '@higo/common/src/utils/slidingWindowSlice';
import { useExaminationAzureResourcesQuery } from '@higo/common/src/features/ExaminationPage/hooks/queries/useExaminationAzureResourcesQuery';

const selectFileResultIds: (data: SkinExaminationDTO) => number[] = pipe(
  prop('skinResults'),
  map(prop('fileId')),
);

const selectGalleryFileResultIds: (data: SkinExaminationDTO) => number[] = (
  data,
) =>
  slidingWindowSlice(
    data.skinResults.map(prop('fileId')),
    data.diagnosticImageIndex,
  );

export const useSkinExaminationResultsLinks = (id: number) => {
  const { data: ids } = useSkinExaminationResultsQuery(
    id,
    selectGalleryFileResultIds,
  );
  return useExaminationAzureResourcesQuery(
    ids ?? [],
    map(pick(['fileName', 'link'])),
  );
};

export const useSkinExaminationVideoFramesIds = (id: number) =>
  useSkinExaminationResultsQuery(id, selectFileResultIds);

export const useSkinExaminationVideoResultsLink = (id: number) => {
  const { data: fileId } = useSkinExaminationResultsQuery(
    id,
    ({ video }) => video?.fileId,
  );
  return useExaminationAzureResourceQuery(fileId);
};
