import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { Button, RHFTextField } from '@higo/ui';
import { FormattedMessage } from 'react-intl';
import { object, string } from 'yup';
import { setYupDefaultMessages } from 'config/yupLocale';

setYupDefaultMessages();
const schema = object({
  email: string().email().max(100).required(),
});

export interface ResetPasswordFormModel {
  email: string;
}

const defaultValues: ResetPasswordFormModel = {
  email: '',
};

export type ResetPasswordFormProps = {
  onSubmit: (value: ResetPasswordFormModel) => Promise<unknown>;
};

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ onSubmit }) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<ResetPasswordFormModel>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RHFTextField
        control={control}
        label="common.email"
        name="email"
        autoComplete="email"
        id="email-text-field"
      />
      <Button
        type="submit"
        disabled={isSubmitting}
        isLoading={isSubmitting}
        fullWidth
        id="submit-reset-button"
      >
        <FormattedMessage id="authentication.sendResetLink" />
      </Button>
    </form>
  );
};
