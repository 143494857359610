import { ExaminationPatientDTO } from '@higo/api/src/models/examinationPatientDTO';
import { evolve } from 'rambda';
import { parseJSON } from 'date-fns';

export interface PatientUnreadMessageDTO {
  createDate: string;
  examinationId: number;
  id: number;
  message: string;
  patient: ExaminationPatientDTO;
}

export type PatientUnreadMessage = Omit<
  PatientUnreadMessageDTO,
  'createDate'
> & {
  createDate: Date;
};

export const mapToPatientUnreadMessage: (
  a: PatientUnreadMessageDTO,
) => PatientUnreadMessage = evolve({
  createDate: parseJSON,
});
