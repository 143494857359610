import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, UseMutationOptions } from 'react-query';
import { EmptyDebounceFunc } from '@higo/common/src/utils/emptyDebounceFunc';
import debounce from 'just-debounce-it';

export interface AutoSaveOptions {
  wait: number;
}

export type ReactQueryAutoSaveSaveStatus =
  | 'saving'
  | 'saved'
  | 'unsaved'
  | 'error';

/**
 * Slightly modified /reduced
 * https://github.com/lukesmurray/react-query-autosync/blob/main/lib/useReactQueryAutoSave.ts
 */
export const useReactQueryAutoSave = <
  TData = unknown,
  TMutationData = unknown,
  TMutationError = unknown,
  TMutationContext = unknown,
>({
  mutationOptions,
  autoSaveOptions,
  mutateEnabled = true,
}: {
  mutationOptions: UseMutationOptions<
    TMutationData,
    TMutationError,
    TData,
    TMutationContext
  >;
  autoSaveOptions?: AutoSaveOptions;
  mutateEnabled?: boolean;
}) => {
  const [draft, setDraft] = useState<TData | undefined>(undefined);

  // create a stable ref to the draft so we can memoize the save function
  const draftRef = useRef<TData | undefined>(undefined);
  draftRef.current = draft;

  const { mutate, isLoading, isError } = useMutation({
    ...mutationOptions,
    onMutate: async (draft) => {
      // optimistically clear our draft state
      setDraft(undefined);
      // Return a context object with the snapshotted value
      return {
        ...mutationOptions.onMutate?.(draft),
        /* eslint-disable  @typescript-eslint/no-explicit-any */
      } as any;
    },
    onError: (err, prevDraft, context) => {
      // if the user has not made any more local changes reset the draft
      // to last known state
      if (draftRef.current === undefined) {
        setDraft(prevDraft);
      }

      return mutationOptions.onError?.(err, prevDraft, context);
    },
  });

  const pendingSave = useRef(false);
  const mutateEnabledRef = useRef(mutateEnabled);
  mutateEnabledRef.current = mutateEnabled;

  const save = useCallback(() => {
    if (draftRef.current !== undefined) {
      if (!mutateEnabledRef.current) {
        pendingSave.current = true;
      } else {
        mutate(draftRef.current);
      }
    }
  }, [mutate]);

  // memoize a debounced save function
  const saveDebounced = useMemo(() => {
    return autoSaveOptions?.wait === undefined
      ? EmptyDebounceFunc
      : debounce(save, autoSaveOptions.wait);
  }, [autoSaveOptions?.wait, save]);

  // clean up saveDebounced on unmount to avoid leaks
  useEffect(() => {
    const prevSaveDebounced = saveDebounced;

    return () => {
      prevSaveDebounced.cancel();
    };
  }, [saveDebounced]);

  // create a function which saves and cancels the debounced save
  const saveAndCancelDebounced = useMemo(
    () => () => {
      saveDebounced.cancel();
      save();
    },
    [save, saveDebounced],
  );

  // automatically save if we enable mutation and are pending a save
  if (mutateEnabledRef.current && pendingSave.current) {
    pendingSave.current = false;
    saveAndCancelDebounced();
  }

  // call saveDebounced when the draft changes
  useEffect(() => {
    // check that autoSave is enabled and there are local changes to save
    if (autoSaveOptions?.wait !== undefined && draft !== undefined) {
      saveDebounced();
    }
  }, [saveDebounced, draft, autoSaveOptions?.wait]);

  const saveStatus: ReactQueryAutoSaveSaveStatus = isLoading
    ? 'saving'
    : isError
    ? 'error'
    : draft === undefined
    ? 'saved'
    : 'unsaved';

  return {
    save: saveAndCancelDebounced,
    setDraft,
    draft,
    saveStatus,
  };
};
