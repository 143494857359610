import { CellProps, TRowEntity } from 'react-table';
import { AssignIcon, IconButton, LoaderIcon } from '@higo/ui';
import { MouseEvent, useCallback } from 'react';
import {
  useAssignExaminationMutation,
  useIsAnyAssignExaminationMutating,
} from 'hooks/mutations/assignExamination';
import { TrackedAssignExaminationEventTrigger } from '@higo/common/lib/features/TrackedEvent';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';

export type AssignExaminationCellValue = {
  examinationId: number;
  patientId?: number;
};

const Button = styled(IconButton)`
  margin: 0 10%;
`;

export const AssignExaminationCellRenderer = <T extends TRowEntity>({
  cell,
  selection,
}: CellProps<T, AssignExaminationCellValue>) => {
  const examinationId = cell.value.examinationId;

  const { mutate, isLoading: isMutating } = useAssignExaminationMutation(
    examinationId,
    {
      eventTrigger: TrackedAssignExaminationEventTrigger.AssignButtonClick,
      patientId: cell.value.patientId,
    },
  );

  const isAnyExamMutating = useIsAnyAssignExaminationMutating();
  const isAnyExamSelected = Number(selection?.selectedIds.length) > 0;

  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      mutate(examinationId);
      // assignment button only assigns without redirect
    },
    [examinationId, mutate],
  );

  return (
    <Tooltip
      placement="right"
      title={<FormattedMessage id="table.header.consultLater" />}
      color="#72799C"
    >
      <Button
        color="secondary"
        size="small"
        icon={isMutating ? <LoaderIcon /> : <AssignIcon />}
        onClick={handleClick}
        disabled={!!isAnyExamMutating || isAnyExamSelected}
        id="assign-icon-button"
      />
    </Tooltip>
  );
};
