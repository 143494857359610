import styled, { css } from 'styled-components';
import ReactPlayer, { Config } from 'react-player';
import { FC, memo, useEffect } from 'react';
import { TrackProgressControl } from './TrackProgressControl';
import { formatDuration } from './utils';
import {
  useAudioReactPlayer,
  UseAudioReactPlayerOptions,
} from './useAudioReactPlayer';
import { PlayPauseButton } from './PlayPauseButton';
import { useAudioTrackGroup } from './AudioTrackGroup';

const Content = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr 2.25rem;
  align-items: center;
  grid-column-gap: 0.75rem;
  height: 2rem;
`;

const TrackTime = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.secondary[500]};
    ${theme.typography.subtitle2}
  `,
);

export interface AudioTrackProps {
  id?: string;
  url: string;
  playing?: boolean;
  options?: UseAudioReactPlayerOptions;
  onPlayPauseClick?: (playing: boolean) => void;
}

const config: Config = {
  file: {
    forceAudio: true,
  },
};

export const AudioTrack: FC<AudioTrackProps> = memo(({ id, url, options }) => {
  const ctx = useAudioTrackGroup();
  const _id = id ?? url;

  const { playerRef, state, api, playerProps, handleEnded } =
    useAudioReactPlayer(options);

  useEffect(() => {
    ctx?.register(_id, api);
    // for some reason linter can't handle register properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx?.register, _id, api]);

  return (
    <>
      <ReactPlayer
        ref={playerRef}
        config={config}
        url={url}
        width={0}
        height={0}
        playing={state.playing}
        onEnded={() => {
          handleEnded();
          ctx?.onTrackEnd(_id);
        }}
        {...playerProps}
      />
      <Content id={id}>
        <PlayPauseButton
          isPlaying={state.playing}
          played={state.played}
          onClick={() => (ctx ? ctx.playPause(_id) : api.playPause())}
          disabled={!state.ready}
          id={`playbutton-${id}`}
        />
        <TrackProgressControl
          duration={state.duration}
          progress={state.playedSeconds}
          onSeekStart={api.seekStart}
          onSeekChange={api.seekChange}
          onSeekEnd={api.seekStop}
          disabled={!state.ready}
        />
        <TrackTime>
          {state.playing
            ? formatDuration(state.playedSeconds)
            : formatDuration(state.duration)}
        </TrackTime>
      </Content>
    </>
  );
});
