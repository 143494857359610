import { useQuery } from 'react-query';
import { fetchUserAnsweredSurvey } from '@higo/api/lib/services/surveyService';
import { purgeQuestionWithUserAnswerDTO } from '@higo/api/lib/utils/purgeQuestionWithUserAnswerDTO';
import { evolve, pipe } from 'rambda';
import { Gender } from '@higo/api/lib/models/gender';
import { isNotNil } from '@higo/api/lib/utils/isNotNil';
import { mapToAnsweredSurvey } from '@higo/common/src/features/FilledSurvey/mapToAnsweredSurvey';
import { UserAnsweredSurveyDTO } from '@higo/api/lib/models';
import { AnsweredSurvey } from '@higo/common/src/features/FilledSurvey/model';
import { useCallback } from 'react';
import { useExaminationApiContext } from '@higo/common/src/features/ExaminationPage/providers';
import { ExaminationQueryKey } from '@higo/common/src/features/ExaminationPage/ExaminationQueryKey';

// returns raw backend survey (whole schema and answers)
export const useUserAnsweredGeneralSurveyQuery = <TData>(
  id: number,
  enabled: boolean = !isNaN(id),
  select?: (data: UserAnsweredSurveyDTO) => TData,
) => {
  const axios = useExaminationApiContext();

  return useQuery(
    [ExaminationQueryKey.UserAnsweredGeneralSurvey, id],
    () => fetchUserAnsweredSurvey(axios)(id),
    {
      select,
      enabled,
    },
  );
};

// returns raw backend survey (whole schema and answers)
export const useUserAnsweredAnamnesisSurveyQuery = <TData>(
  id: number,
  enabled: boolean = !isNaN(id),
  select?: (data: UserAnsweredSurveyDTO) => TData,
) => {
  const axios = useExaminationApiContext();

  return useQuery(
    [ExaminationQueryKey.UserAnsweredAnamnesisSurvey, id],
    () => fetchUserAnsweredSurvey(axios)(id),
    {
      select,
      enabled,
    },
  );
};

const createPatientGenderSurveyMapper = (
  patientAge: number,
  gender?: Gender,
): ((v: UserAnsweredSurveyDTO) => AnsweredSurvey) =>
  pipe(
    evolve({
      questionsWithUserAnswers: purgeQuestionWithUserAnswerDTO(
        gender!, // should only execute if patient gender is provided
        patientAge,
      ),
    }),
    mapToAnsweredSurvey,
  );

// Returns user answered survey with applicable schema structure
export const useUserAnsweredGeneralSurvey = (
  id: number,
  patientAge: number,
  gender?: Gender,
) =>
  useUserAnsweredGeneralSurveyQuery(
    id,
    !isNaN(id) && !isNaN(patientAge) && isNotNil(gender),
    useCallback(
      (dto) => createPatientGenderSurveyMapper(patientAge, gender)(dto),
      [patientAge, gender],
    ),
  );

// Returns user answered survey with applicable schema structure
export const useUserAnsweredAnamnesisSurvey = (
  id: number,
  patientAge: number,
  gender?: Gender,
) =>
  useUserAnsweredAnamnesisSurveyQuery(
    id,
    !isNaN(id) && !isNaN(patientAge) && isNotNil(gender),
    useCallback(
      (dto) => createPatientGenderSurveyMapper(patientAge, gender)(dto),
      [patientAge, gender],
    ),
  );
