import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Heading } from '@higo/ui/src/components/typography/Heading';

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const StyledHeading = styled(Heading)`
  margin: 0;
`;

export interface ToastMultipleMessagesTemplateProps {
  title?: ReactNode;
  messages: ReactNode[];
}

export const ToastMultipleMessagesTemplate: FC<ToastMultipleMessagesTemplateProps> =
  ({ title, messages }) => (
    <div>
      {title && <StyledHeading level={4}>{title}</StyledHeading>}
      <List>
        {messages?.map((message, i) => (
          <li key={i}>{message}</li>
        ))}
      </List>
    </div>
  );
