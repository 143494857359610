export type TimeObserver = () => void;

class TickEmitter {
  private observers: TimeObserver[] = [];
  private intervalId: ReturnType<typeof setInterval> | null = null;
  private interval = 1000;

  constructor() {
    this.initialize();
  }

  public attach(observer: TimeObserver) {
    this.observers.push(observer);
  }

  public detach(observerToRemove: TimeObserver) {
    this.observers = this.observers.filter(
      (observer) => observerToRemove !== observer,
    );
  }

  private notify() {
    this.observers.forEach((observer) => observer());
  }

  private initialize() {
    this.clear();
    this.intervalId = setInterval(() => {
      this.notify();
    }, this.interval);
  }

  private clear() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }
}

export const eachSecondEmitter = new TickEmitter();

class MinuteTickEmitter {
  private counter = 0;
  private observers: TimeObserver[] = [];

  constructor() {
    eachSecondEmitter.attach(this.updateCounter.bind(this));
  }

  public attach(observer: TimeObserver) {
    this.observers.push(observer);
  }

  public detach(observerToRemove: TimeObserver) {
    this.observers = this.observers.filter(
      (observer) => observerToRemove !== observer,
    );
  }

  private notify() {
    this.observers.forEach((observer) => observer());
  }

  private updateCounter() {
    if (this.counter === 60) {
      this.counter = 0;
      this.notify();
    }
    this.counter++;
  }
}

export const eachMinuteEmitter = new MinuteTickEmitter();
