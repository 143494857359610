import { createContext, FC, useContext } from 'react';
import { TrackedEventBasicData } from './trackedEvent';

export type TrackedEventDataContext = TrackedEventBasicData;

const Context = createContext<TrackedEventDataContext | {}>({});

export const TrackedEventDataContextProvider: FC<{
  value: TrackedEventBasicData;
}> = ({ value, children }) => (
  <Context.Provider value={value}>{children}</Context.Provider>
);

export const useTrackedEventDataContext = () => {
  return useContext(Context);
};
