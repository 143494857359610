import styled, { css } from 'styled-components';

export const RoundIndicator = styled('div')(
  ({ theme }) => css`
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;

    background: ${theme.palette.primary['500']};
  `,
);
