import styled, { css } from 'styled-components';
import { StyleProps } from '@summer/jst-react';
import { FC } from 'react';
import { FileResource } from '@higo/common/src/types';
import { FormattedMessage } from 'react-intl';
import {
  AudioTrack,
  MissingAudioTrack,
} from '@higo/ui/lib/components/AudioPlayer';

const Container = styled('li')(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 8.25rem 1fr;
    grid-template-rows: 2.5rem;
    align-items: center;
    grid-row-gap: 0.5rem;

    padding: 1rem 1rem 1.5rem;
    position: relative;

    &:hover {
      background-color: ${theme.palette.neutral[300]};
      border-radius: 0.5rem;
    }

    &:before {
      content: '';
      border-top: 1px solid ${theme.palette.neutral[500]};
      position: absolute;
      top: 0;
      left: 1rem;
      right: 1rem;
    }
    &:last-of-type {
      border-bottom: none;
    }
  `,
);

const Tagline = styled('div')(
  ({ theme }) => css`
    ${theme.typography.subtitle2};
    color: ${theme.palette.secondary[500]};
  `,
);

const Label = styled('div')(
  ({ theme }) => css`
    ${theme.typography.h4};
    color: ${theme.palette.secondary[900]};
  `,
);

const ChildrenContainer = styled.div`
  text-align: right;
  grid-area: 2/2;
`;

export type AuscultationPointListItemProps = {
  id: string;
  tagline?: string;
  label?: string;
  file?: FileResource;
} & StyleProps;

export const AuscultationPointListItem: FC<AuscultationPointListItemProps> = ({
  id,
  tagline,
  label,
  file,
  children,
}) => (
  <Container id={id}>
    <div>
      <Tagline id={`tagline-${id}`}>{tagline}</Tagline>
      <Label id={`label-${id}`}>{label}</Label>
    </div>
    <div>
      {file ? (
        <AudioTrack id={`audiotrack-${id}`} url={file.link} />
      ) : (
        <MissingAudioTrack
          id={id}
          label={<FormattedMessage id="examinationPage.omittedByTheUser" />}
        />
      )}
    </div>
    {file && <ChildrenContainer>{children}</ChildrenContainer>}
  </Container>
);
