import styled, { css } from 'styled-components';

export const HorizontalDivider = styled('div')(
  ({ theme }) => css`
    height: 3px;
    width: 100%;
    border-radius: 1px;
    background-color: ${theme.palette.neutral['500']};
    margin-bottom: 0.5rem;
  `,
);
