import { useQuery } from 'react-query';
import { ApiQueryKey } from '@higo/api/lib/apiQueryKey';
import { useExaminationApiContext } from '@higo/common/src/features/ExaminationPage/providers';
import { fetchFiles } from '@higo/api';
import { isEmpty } from 'rambda';
import { FileAccessLinkExtendedDTO } from '@higo/api/lib/models/fileAccessLinkExtendedDTO';

export const useAudioAzureResourcesQuery = <
  TData = FileAccessLinkExtendedDTO[],
>(
  resourceIds: number[] = [],
  rawAudio: boolean = false,
  select?: (data: FileAccessLinkExtendedDTO[]) => TData,
) => {
  const axios = useExaminationApiContext();

  return useQuery(
    [ApiQueryKey.AzureResource, resourceIds, rawAudio],
    () => fetchFiles(axios)(resourceIds, rawAudio),
    {
      enabled: false,
      refetchOnWindowFocus: false,
    },
  );
};
