import { useEventEmitter } from 'contexts/EventEmitterProvider';
import { useCallback, useEffect, useState } from 'react';
import { isFcmMessagePayload, isFcmMessageSupported } from 'types/fcmMessage';
import {
  fcmMessageCodeAppEventMap,
  fcmMessageDataToAppEventArgs,
} from 'types/appEvent';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from 'config/firebaseConfig';
import { useFirebaseMessaging } from 'features/FirebaseEvents/useFirebaseMessaging';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { useRegisterUserFcmTokenMutation } from 'features/FirebaseEvents/useRegisterUserFcmTokenMutation';

export const FirebaseEventEmitter = () => {
  const { isLoggedIn } = useIsLoggedIn();

  const [firebaseApp] = useState(() => initializeApp(firebaseConfig.options));
  const eventEmitter = useEventEmitter();
  const { mutate: registerUserFcmToken } = useRegisterUserFcmTokenMutation();

  const handleFirebaseMessage = useCallback(
    (payload) => {
      console.log('Message received. ', payload);

      if (
        isFcmMessagePayload(payload.data) &&
        isFcmMessageSupported(payload.data.code)
      ) {
        const appEvent = fcmMessageCodeAppEventMap[payload.data.code];

        if (appEvent) {
          eventEmitter.emit(
            appEvent,
            ...fcmMessageDataToAppEventArgs(appEvent, payload.data),
          );
          return;
        }
      }
      console.error(`Received unsupported message`, payload);
    },
    [eventEmitter],
  );

  const token = useFirebaseMessaging(
    firebaseApp,
    firebaseConfig.vapid,
    handleFirebaseMessage,
    isLoggedIn,
  );

  useEffect(() => {
    const prevToken = token;
    token && registerUserFcmToken({ token });

    return () => {
      // todo: deregister when backend provide proper method
      prevToken && console.log('deregister', token);
    };
  }, [registerUserFcmToken, token]);

  return null;
};
