import { useQueryClient } from 'react-query';
import { useOnEventEmitterMessage } from '@higo/doctor/src/contexts/EventEmitterProvider';
import {
  AppEvent,
  AppVideoCreatedPayload,
} from '@higo/doctor/src/types/appEvent';
import { useCallback } from 'react';

export const useVideoProcessQuery = (examinationId: number) => {
  const queryClient = useQueryClient();

  enum VideoQueryNames {
    'SKIN_RESULT_FILE' = 'examination/examinationResults/skin',
    'THROAT_RESULT_FILE' = 'examination/examinationResults/throat',
    'RIGHT_EAR_RESULT_FILE' = 'examination/examinationResults/rightEar',
    'LEFT_EAR_RESULT_FILE' = 'examination/examinationResults/leftEar',
  }

  useOnEventEmitterMessage(
    AppEvent.FcmVideoCreated,
    useCallback(
      (data: AppVideoCreatedPayload) => {
        const getVideoTypeByValue = (key: string) => {
          const indexOfType = Object.keys(VideoQueryNames).indexOf(
            key as unknown as VideoQueryNames,
          );
          const value = Object.values(VideoQueryNames)[indexOfType];
          return value;
        };

        if (data.examId === examinationId) {
          queryClient.invalidateQueries([
            getVideoTypeByValue(data.type),
            data.examId,
          ]);
        }
      },
      [queryClient, examinationId, VideoQueryNames],
    ),
  );

  useOnEventEmitterMessage(
    AppEvent.FcmVideoFailed,
    //ToDo: add error handler / toast when designs will be ready
    useCallback(() => console.log('video processing failed'), []),
  );
};
