import { IconBox, IconProps } from '../IconBox';

const transforms = {
  up: 180,
  down: 0,
  left: 90,
  right: -90,
};

export interface ChevronIconProps extends IconProps {
  direction?: 'up' | 'down' | 'left' | 'right';
}

const ChevronIcon = ({ direction = 'down', ...props }: ChevronIconProps) => (
  <IconBox {...props}>
    <g transform={`rotate(${transforms[direction]} 12 12)`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.06 7.94a1.5 1.5 0 0 0-2.12 0L12 12.878l-4.94-4.94a1.5 1.5 0 0 0-2.12 2.122l6 6a1.5 1.5 0 0 0 2.12 0l6-6a1.5 1.5 0 0 0 0-2.122Z"
      />
    </g>
  </IconBox>
);

export default ChevronIcon;
