import styled from 'styled-components';
import { FC, ReactElement, ReactNode, useMemo } from 'react';
import { AuscultationPointListItem } from './AuscultationPointListItem';
import { FileResource } from '@higo/common/src/types';
import { AudioTrackGroupProvider } from '@higo/ui/lib/components/AudioPlayer';

const Container = styled.div`
  //display: grid;
  //grid-column-gap: 1rem;
  //grid-template-columns: 9.75rem 1fr;

  display: flex;
  column-gap: 1rem;
`;

const LegendContainer = styled.div``;

const ListContainer = styled.ul`
  flex-grow: 1;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export type AuscultationPointTagSelectFn = (fieldName: string) => ReactNode;

export interface AuscultationGroupPoint {
  id: string;
  tagFieldName: string;
  tagline?: string;
  label?: string;
  file?: FileResource;
}

export interface AuscultationGroupProps {
  legend?: ReactElement;
  auscultationPoints: AuscultationGroupPoint[];
  tag?: AuscultationPointTagSelectFn;
}

export const AuscultationGroup: FC<AuscultationGroupProps> = ({
  legend,
  auscultationPoints,
  tag = null,
}) => {
  const trackOrder = useMemo(
    () => auscultationPoints.filter((x) => x.file).map((x) => x.id),
    [auscultationPoints],
  );

  return (
    <AudioTrackGroupProvider trackOrder={trackOrder}>
      <Container id="asculation-group">
        {legend && (
          <LegendContainer id="legend-container">{legend}</LegendContainer>
        )}
        <ListContainer id="asculation-list-container">
          {auscultationPoints.map(
            ({ id, tagline, tagFieldName, label, file }) => (
              <AuscultationPointListItem
                id={id}
                key={id}
                tagline={tagline}
                label={label}
                file={file}
              >
                {tag && tag(tagFieldName)}
              </AuscultationPointListItem>
            ),
          )}
        </ListContainer>
      </Container>
    </AudioTrackGroupProvider>
  );
};
