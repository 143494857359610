import styled, { css } from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const CustomToastContainer = styled(ToastContainer)(
  ({ theme }) => css`
    &&&.Toastify__toast-container {
      width: 18.9375rem;
    }

    .Toastify__toast {
      border-radius: 8px;
    }

    .Toastify__toast--success {
      background-color: ${theme.palette.success};
      color: ${theme.palette.white};
      --toastify-icon-color-success: ${theme.palette.white};
    }

    .Toastify__toast--warning {
      background-color: ${theme.palette.warning};
      color: ${theme.palette.secondary['900']};
      --toastify-icon-color-warning: ${theme.palette.secondary['900']};
    }

    .Toastify__toast--error {
      background-color: ${theme.palette.error};
      color: ${theme.palette.white};
      --toastify-icon-color-error: ${theme.palette.white};
    }

    .Toastify__toast--info {
      color: ${theme.palette.secondary['500']};
      --toastify-icon-color-info: ${theme.palette.secondary['500']};
    }

    // .Toastify__toast--error::before {
    //   content: url('../../../ui/src/components/icons/RoundCloseIcon.tsx');
    //   color: ${theme.palette.white};
    // }

    .Toastify__toast-body {
      ${theme.typography.body}
      color: inherit;
      height: 1rem;
    }
  `,
);
