import { useQuery } from 'react-query';
import { ApiQueryKey } from '@higo/api/lib/apiQueryKey';
import { useExaminationApiContext } from '@higo/common/src/features/ExaminationPage/providers';
import { fetchFiles } from '@higo/api';
import { isEmpty } from 'rambda';
import { FileAccessLinkExtendedDTO } from '@higo/api/lib/models/fileAccessLinkExtendedDTO';

// todo: add option for conditional refetchInterval with latest data (parse azure resource timeout),
export const useExaminationAzureResourcesQuery = <
  TData = FileAccessLinkExtendedDTO[],
>(
  resourceIds: number[] = [],
  select?: (data: FileAccessLinkExtendedDTO[]) => TData,
) => {
  const axios = useExaminationApiContext();

  return useQuery(
    [ApiQueryKey.AzureResource, resourceIds],
    () => fetchFiles(axios)(resourceIds),
    {
      enabled: !isEmpty(resourceIds),
      select,
    },
  );
};
