import { useDoctorPortalApiContext } from 'contexts/ApiContext';
import { useQuery } from 'react-query';
import { ExaminationBaseDTO, fetchBaseExamination } from '@higo/api';
import { head } from 'rambda/immutable';
import { examinationNotifiactionQueryKeys } from 'config/doctorQueryKey';

export const useNewExaminationNotificationQuery = (examinationId: number) => {
  const { authorizedAxios } = useDoctorPortalApiContext();

  return useQuery(examinationNotifiactionQueryKeys.details(examinationId), () =>
    fetchBaseExamination(authorizedAxios)([examinationId]).then(
      (x) => head<ExaminationBaseDTO>(x), // workaround for prettier as head<ExaminationBaseDTO> throws error :/
    ),
  );
};
