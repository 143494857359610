import { parseJSON } from 'date-fns';
import { evolve } from 'rambda/immutable';
import { ExaminationDTOStatus } from '@higo/api/src/models/examinationDTO';
import { SurveyAilments } from '@higo/api/src/models/surveyDTO';
import {
  HeartRateResultDTO,
  TemperatureResultDTO,
} from '@higo/api/src/models/physicalExaminations';
import {
  roundPulseMeasurementValue,
  roundTemperatureMeasurementValue,
} from '@higo/api/src/utils/roundMeasurementValue';

export interface PhysicalExaminationDetailsDTO {
  examStatus: PhysicalExaminationStatus;
  examType: PhysicalExaminationType;
}

//todo: clarify this interface
export interface ExaminationDetailsDTO {
  ailments: SurveyAilments[];
  contextPhotoURL: string;
  createDate: string;
  examinationId: number;
  heartRateResult: HeartRateResultDTO;
  patient?: any; // todo: PatientExcerptDTO
  physicalExaminationDetails: PhysicalExaminationDetailsDTO[];
  status: ExaminationDTOStatus;
  temperatureResult: TemperatureResultDTO;
}

export enum ExaminationType {
  ChosenByUser = 'CHOSEN_BY_USER',
  Custom = 'CUSTOM',
  FollowUp = 'FOLLOW_UP',
  Full = 'FULL_EXAM',
  Quick = 'QUICK_CHECK',
}

export enum PhysicalExaminationStatus {
  CanceledByUser = 'CANCELED_BY_USER',
  DeviceEnded = 'DEVICE_ENDED',
  InterventionStop = 'INTERVENTION_STOP',
  MetadataSaved = 'METADATA_SAVED',
  PlannedByDoctor = 'PLANNED_BY_DOCTOR',
  ResultPending = 'RESULT_PENDING',
  StartedByUser = 'STARTED_BY_USER',
  StartedOnDevice = 'STARTED_ON_DEVICE',
  WaitingOnReply = 'WAITING_FOR_RESULT',
}

export enum PhysicalExaminationType {
  AbdominalAuscultation = 'ABDOMINAL_AUSCULTATION_EXAM',
  Cough = 'COUGH_EXAM',
  Ear = 'EAR_EXAM',
  HeartAuscultation = 'HEART_AUSCULTATION_EXAM',
  HeartRate = 'HEART_RATE_EXAM',
  HeartRateWav = 'HEART_RATE_WAV_EXAM',
  LeftEar = 'LEFT_EAR_EXAM',
  LungsAuscultation = 'LUNGS_AUSCULTATION_EXAM',
  RightEar = 'RIGHT_EAR_EXAM',
  Skin = 'SKIN_EXAM',
  Temperature = 'TEMPERATURE_EXAM',
  Throat = 'THROAT_EXAM',
}

export interface ExaminationDetails
  extends Omit<ExaminationDetailsDTO, 'createDate'> {
  createDate: Date;
}

export const mapExaminationDetailsData = (
  data: ExaminationDetailsDTO,
): ExaminationDetails => {
  const transformations = {
    createDate: parseJSON,
    heartRateResult: roundPulseMeasurementValue,
    temperatureResult: roundTemperatureMeasurementValue,
  };
  return evolve(transformations, data);
};
