import { parseJSON } from 'date-fns';
import { evolve } from 'rambda';

export interface ExaminationCommentDTO {
  authorUserId: number;
  commentText: string;
  createDate: string;
  examinationId: number;
  id: number;
  read: boolean;
}

export interface ExaminationComment
  extends Omit<ExaminationCommentDTO, 'createDate'> {
  createDate: Date;
}

export interface CreateExamCommentDTO {
  commentText: string;
}

export const mapExaminationCommentsData = (
  data: ExaminationCommentDTO[],
): ExaminationComment[] => {
  const transformations = {
    createDate: (date: string) => parseJSON(date),
  };
  return data.map((item) => evolve(transformations, item));
};
