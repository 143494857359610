import { Gender } from '@higo/api/lib/models/gender';
import { createElement, FC } from 'react';
import { ResponsiveImage } from '@higo/ui';
import { Maturity } from '@higo/common/src/types';
import man from './man.png';
import man2 from './man@2x.png';
import man4 from './man@4x.png';
import woman from './woman.png';
import woman2 from './woman@2x.png';
import woman4 from './woman@4x.png';
import boy from './boy.png';
import boy2 from './boy@2x.png';
import boy4 from './boy@4x.png';
import girl from './girl.png';
import girl2 from './girl@2x.png';
import girl4 from './girl@4x.png';
import baby from './baby.png';
import baby2 from './baby@2x.png';
import baby4 from './baby@4x.png';
import { BodyImageProps } from '@higo/common/src/components/Auscultation/bodyImageProps';

const manBody = {
  srcSet: [
    {
      src: man,
      media: '1x',
    },
    {
      src: man2,
      media: '2x',
    },
    {
      src: man4,
      media: '4x',
    },
  ],
};
const boyBody = {
  srcSet: [
    {
      src: boy,
      media: '1x',
    },
    {
      src: boy2,
      media: '2x',
    },
    {
      src: boy4,
      media: '4x',
    },
  ],
};
const babyBody = {
  srcSet: [
    {
      src: baby,
      media: '1x',
    },
    {
      src: baby2,
      media: '2x',
    },
    {
      src: baby4,
      media: '4x',
    },
  ],
};

const bodies = {
  [Gender.Male]: {
    [Maturity.Adult]: manBody,
    [Maturity.Teen]: boyBody,
    [Maturity.Baby]: babyBody,
  },
  [Gender.Female]: {
    [Maturity.Adult]: {
      srcSet: [
        {
          src: woman,
          media: '1x',
        },
        {
          src: woman2,
          media: '2x',
        },
        {
          src: woman4,
          media: '4x',
        },
      ],
    },
    [Maturity.Teen]: {
      srcSet: [
        {
          src: girl,
          media: '1x',
        },
        {
          src: girl2,
          media: '2x',
        },
        {
          src: girl4,
          media: '4x',
        },
      ],
    },
    [Maturity.Baby]: babyBody,
  },
  [Gender.Other]: {
    [Maturity.Adult]: manBody,
    [Maturity.Teen]: boyBody,
    [Maturity.Baby]: babyBody,
  },
  [Gender.Unknown]: {
    [Maturity.Adult]: manBody,
    [Maturity.Teen]: boyBody,
    [Maturity.Baby]: babyBody,
  },
};

export const BodyImage: FC<BodyImageProps> = ({
  gender,
  maturity,
  ...params
}) =>
  createElement(ResponsiveImage, {
    ...params,
    ...bodies[gender][maturity],
    alt: 'Heart auscultation points image',
  });
