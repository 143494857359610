import { concat, indexBy, isEmpty, merge, mergeWith, omit, prop } from 'rambda';

import {
  AnswerTriggeredActionType,
  QuestionWithUserAnswerDTO,
  UserAnsweredSurveyDTO,
} from '@higo/api/lib/models';
import { sortAlphaNum } from '@higo/api/lib/ext/sort/alpha-num';
import { QuestionAnswerDTO, SurveyAnswerTag } from '@higo/api';
import {
  AnsweredQuestion,
  AnsweredSurvey,
  QuestionAnswerOption,
} from '@higo/common/src/features/FilledSurvey/model';
import { groupByMultiple } from '@higo/common/src/utils';

/**
 * Refactoring:
 * 1.During sort, remap DTO to proper models
 */

const utilityQuestionAnswerTags = [
  SurveyAnswerTag.OnlyMen,
  SurveyAnswerTag.OnlyWomen,
  SurveyAnswerTag.ForMen,
  SurveyAnswerTag.ForWomen,
  SurveyAnswerTag.ForOther,
  SurveyAnswerTag.ForUnknown,
];

const omitUtilityQuestionAnswerTags = omit(utilityQuestionAnswerTags);

const excludeSelectedTriggerActions = [
  AnswerTriggeredActionType.FinishLoop,
  AnswerTriggeredActionType.Intervention,
];

const mapToQuestionAnswerOption = ({
  id,
  code,
  value,
  answerTags,
  triggeredAction,
  userFriendlyLabel,
}: QuestionAnswerDTO) => ({
  id,
  code,
  value,
  userFriendlyLabel,
  triggeredAction,
  childQuestions: [],
  tags: answerTags ?? [],
});

export const createAnsweredQuestion = ({
  surveyQuestionDTO,
  userSurveyAnswerDTO,
}: QuestionWithUserAnswerDTO): AnsweredQuestion => {
  // todo: Filter applicable answers only (gender based)
  // Remap answers (map on whole collection?) // moze tylko to wrzucic na ta odpowiedz otagowana? bo tam wybieramy tylko tylko wszystkie mozliwe odpowiedzi......

  const selectableAnswers =
    surveyQuestionDTO.questionAnswers.map<QuestionAnswerOption>(
      mapToQuestionAnswerOption,
    );

  // Pick selected answers only
  const selectedAnswers = selectableAnswers.filter(({ id }) =>
    (userSurveyAnswerDTO.answersId ?? []).includes(id),
  );

  const isAnyAnswerSelected = !isEmpty(
    selectedAnswers.filter(
      (answer) =>
        !answer.triggeredAction ||
        !excludeSelectedTriggerActions.includes(answer.triggeredAction.type),
    ),
  );

  // build tagged selected answers lookup without utility tags
  const taggedSelectedAnswers = omitUtilityQuestionAnswerTags(
    groupByMultiple(
      prop('tags'),
      selectedAnswers.filter(({ tags }) => !isEmpty(tags)),
    ),
  );

  return {
    level: surveyQuestionDTO.level, // todo: calculate level by yourself
    questionCode: surveyQuestionDTO.code,
    questionType: surveyQuestionDTO.questionType,
    question: surveyQuestionDTO.value,
    questionUserFriendlyLabel: surveyQuestionDTO.userFriendlyLabel,
    loopLevel: surveyQuestionDTO.loopLevel,
    numericQuestionUnit: surveyQuestionDTO.numericQuestionUnit,
    answer: userSurveyAnswerDTO.answer,
    answered: !isEmpty(userSurveyAnswerDTO.answer) || isAnyAnswerSelected,
    surveyQuestionTag: surveyQuestionDTO.surveyQuestionTags,
    selectedAnswers,
    selectableAnswers,
    taggedSelectedAnswers,
    openAnswerType: surveyQuestionDTO.openAnswerType,
  };
};

export const buildAnsweredSurveyTree = (
  questionWithUserAnswerDTO: QuestionWithUserAnswerDTO[],
): AnsweredSurvey => {
  // sort using questions code
  questionWithUserAnswerDTO.sort((a, b) =>
    sortAlphaNum(a.surveyQuestionDTO.code, b.surveyQuestionDTO.code),
  );

  // map QuestionWithUserAnswerDto[] to AnsweredQuestionVM[]
  const allAnsweredQuestionsList = questionWithUserAnswerDTO.map(
    createAnsweredQuestion,
  );

  // aggregate all tagged answers
  const taggedAnswers = allAnsweredQuestionsList.reduce(
    (acc, v) => mergeWith(concat, acc, v.taggedSelectedAnswers),
    {},
  );

  const answeredQuestionsList = allAnsweredQuestionsList.filter(
    (x) => x.answered,
  );

  //create AnsweredQuestion lookup
  const answeredQuestionLookup = indexBy(
    prop('questionCode'),
    answeredQuestionsList,
  );

  // create QuestionAnswerOption and tagged questions lookup
  const questionAnswerOptionLookup = answeredQuestionsList.reduce<{
    [key: string]: QuestionAnswerOption;
  }>((acc, { selectedAnswers }) => {
    const questionAnswerOptions = indexBy(prop('code'), selectedAnswers);
    return merge(acc, questionAnswerOptions);
  }, {});

  // Mutate AnsweredQuestionVM's to create tree structure
  // As questions were sorted previously, push should add(related to the answer) them in proper order
  questionWithUserAnswerDTO.forEach(({ surveyQuestionDTO }) => {
    surveyQuestionDTO.answerEnableQuestions.forEach(({ answerCode }) => {
      const question = answeredQuestionLookup[surveyQuestionDTO.code];

      // question enabling answer might have not been selected
      if (question && questionAnswerOptionLookup[answerCode]) {
        questionAnswerOptionLookup[answerCode].childQuestions.push(question);
      }
    });
  });

  const taggedQuestions = groupByMultiple(
    prop('surveyQuestionTag'),
    answeredQuestionsList.filter(({ answered }) => answered),
  );

  // 5 return only root (level == 1) AnsweredQuestionVM
  return {
    surveyTreeList: answeredQuestionsList.filter(
      (answeredQuestion) => 1 === answeredQuestion.level,
    ),
    taggedAnswers,
    taggedQuestions,
  };
};

export const mapToAnsweredSurvey = (
  userAnsweredSurveyDTO?: UserAnsweredSurveyDTO,
): AnsweredSurvey =>
  userAnsweredSurveyDTO
    ? buildAnsweredSurveyTree(userAnsweredSurveyDTO.questionsWithUserAnswers)
    : {
        surveyTreeList: [],
        taggedAnswers: {},
        taggedQuestions: {},
      };
