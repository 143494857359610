import { FC, ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FullFrameDiagnosticPhotosGalleryContainer } from '@higo/common/src/components';
import { useThroatExaminationResultsQuery } from '@higo/common/src/features/ExaminationPage/hooks/queries';
import {
  useThroatExaminationResultsLinks,
  useThroatExaminationVideoFramesIds,
  useThroatExaminationVideoResultsLink,
} from '@higo/common/src/features/ExaminationPage/sections/ThroatExaminationSection/hooks';
import { VisualExaminationDiagnosticViewerModel } from '@higo/common/src/features/ExaminationPage/components/VisualExaminationDiagnosticViewer/VisualExaminationDiagnosticViewerProps';
import { ExaminationPageSection } from '@higo/common/src/features/ExaminationPage/sections/ExaminationPageSection';
import { ExaminationPageSectionId } from '@higo/common/src/features/ExaminationPage/models';
import { VisualExaminationDiagnosticViewerForPublic } from '@higo/common/src/features/ExaminationPage/components/VisualExaminationDiagnosticViewer';
import { WithTogglingViewer } from '@higo/common/src/features/ExaminationPage/components/VisualExaminationDiagnosticViewer/WithTogglingViewer';

export interface ThroatExaminationSectionProps {
  examinationId: number;
  requestGenerateThroatExaminationVideoButtonSlot?: ReactNode;
  hasTogglingGalleryVideo?: boolean;
  handleThroatImageForPDFChange?: (index: string) => void;
  handleIsThroatImageDiagnosticChange?: (index: boolean) => void;
  handleIsThroatDataLoadingChange?: (index: boolean) => void;
}

const model: VisualExaminationDiagnosticViewerModel = {
  usePhysicalExaminationResultsQuery: useThroatExaminationResultsQuery,
  usePhysicalExaminationGalleryResultsLinks: useThroatExaminationResultsLinks,
  usePhysicalExaminationVideoFramesIds: useThroatExaminationVideoFramesIds,
  usePhysicalExaminationVideoResultsLink: useThroatExaminationVideoResultsLink,
};

const galleryViewerProps = {
  thumbnailsSlidesPerView: 8,
};

export const ThroatExaminationSection: FC<ThroatExaminationSectionProps> = ({
  examinationId,
  requestGenerateThroatExaminationVideoButtonSlot,
  hasTogglingGalleryVideo,
  handleThroatImageForPDFChange,
  handleIsThroatImageDiagnosticChange,
  handleIsThroatDataLoadingChange,
}) => {
  const { data, isLoading: isLoadingData } =
    useThroatExaminationResultsQuery(examinationId);
  const rowData = data?.throatResults;

  const { data: galleryResults, isLoading: isLoadingGallery } =
    model.usePhysicalExaminationGalleryResultsLinks(examinationId);

  const [throatGalleryActiveLink, setThroatGalleryActiveLink] = useState('');

  const handleThroatGalleryActiveLinkChange = (index: string) => {
    setThroatGalleryActiveLink(index);
  };

  const isFullData =
    data && !isLoadingData && galleryResults && !isLoadingGallery;

  const diagnosticIndex = isFullData && data.diagnosticImageIndex;

  const throatDiagnosticImageLink =
    data?.diagnosticImageIndex &&
    isFullData &&
    galleryResults.find(
      (item) =>
        diagnosticIndex &&
        item.fileName === data.throatResults[diagnosticIndex].filename,
    )?.link;

  const throatMiddleImageLink =
    data?.throatResults &&
    isFullData &&
    galleryResults[Math.floor(data?.throatResults.length / 2)]?.link;

  const throatImageForPDF =
    throatDiagnosticImageLink ||
    throatGalleryActiveLink ||
    throatMiddleImageLink;
  throatImageForPDF &&
    handleThroatImageForPDFChange &&
    handleThroatImageForPDFChange(throatImageForPDF);
  isFullData &&
    throatImageForPDF &&
    handleIsThroatImageDiagnosticChange &&
    handleIsThroatImageDiagnosticChange(data.hasDiagnosticImage);
  handleIsThroatDataLoadingChange &&
    handleIsThroatDataLoadingChange(isLoadingData || isLoadingGallery);

  return (
    <ExaminationPageSection
      title={
        <FormattedMessage id="examinationPage.section.throatExamination.title" />
      }
      sectionId={ExaminationPageSectionId.Throat}
    >
      <FullFrameDiagnosticPhotosGalleryContainer>
        {hasTogglingGalleryVideo ? (
          <WithTogglingViewer
            examinationId={examinationId}
            model={model}
            requestGenerateVideoButtonSlot={
              requestGenerateThroatExaminationVideoButtonSlot
            }
            galleryViewerProps={galleryViewerProps}
            rowData={rowData}
            setActiveGalleryLink={handleThroatGalleryActiveLinkChange}
          />
        ) : (
          <VisualExaminationDiagnosticViewerForPublic
            examinationId={examinationId}
            model={model}
            galleryViewerProps={galleryViewerProps}
            rowData={rowData}
          />
        )}
      </FullFrameDiagnosticPhotosGalleryContainer>
    </ExaminationPageSection>
  );
};
