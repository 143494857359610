import { CellProps } from 'react-table';
import { HigoModeLogoWithSurveyInfo } from '@higo/ui';
import { ExaminationPatientDTOPatientType } from '@higo/api/lib/models';

export type HigoModeCellRendererValue = {
  mode: ExaminationPatientDTOPatientType;
  anamnesisSurveyFilled?: boolean | undefined;
};

export const HigoModeCellRenderer = <T extends Record<string, unknown>>({
  cell,
}: CellProps<T, HigoModeCellRendererValue>) => (
  <HigoModeLogoWithSurveyInfo
    mode={cell.value?.mode}
    anamnesisSurveyFilled={cell.value?.anamnesisSurveyFilled}
  />
);
