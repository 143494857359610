import { FC, memo } from 'react';
import { OverviewInfoList } from './OverviewInfoList';
import { defaultToNA } from '@higo/common/src/utils/defaultValue';
import { PatientOverview } from '@higo/common/src/features/ExaminationPage/models';

export interface OverviewGeneralPatientInformationProps {
  patientOverview: PatientOverview;
}

export const OverviewGeneralPatientInformation: FC<OverviewGeneralPatientInformationProps> =
  memo(({ patientOverview }) => (
    <OverviewInfoList
      items={mapToExaminationOverviewInfoList(patientOverview)}
    />
  ));

const mapToExaminationOverviewInfoList = (patientOverview: PatientOverview) => [
  {
    translationKey: 'common.gender',
    value: defaultToNA(patientOverview.gender),
  },
  {
    translationKey: 'common.age',
    value: defaultToNA(
      patientOverview.age &&
        `${patientOverview.age?.value} ${patientOverview.age?.unit}`,
    ),
  },
  {
    translationKey: 'common.height',
    value: defaultToNA(
      patientOverview.height &&
        `${patientOverview.height?.value} ${
          patientOverview.height.unit ? patientOverview.height.unit : 'cm'
        }`,
    ),
  },
  {
    translationKey: 'common.weight',
    value: defaultToNA(
      patientOverview.weight &&
        `${patientOverview.weight?.value} ${
          patientOverview.weight.unit ? patientOverview.weight.unit : 'kg'
        }`,
    ),
  },
  {
    translationKey: 'common.bmi',
    value: defaultToNA(patientOverview.bmi),
  },
];
