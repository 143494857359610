import { IconBox, IconProps } from '../IconBox';

const DiagnosticFrameIcon = (props: IconProps) => (
  <IconBox {...props}>
    <g>
      <svg fill="none">
        <path
          d="M9.214 1.5H4.071A2.571 2.571 0 0 0 1.5 4.071v5.143m0 5.572v5.143A2.571 2.571 0 0 0 4.071 22.5h5.143m5.572 0h5.143a2.571 2.571 0 0 0 2.571-2.571v-5.143m0-5.572V4.071A2.571 2.571 0 0 0 19.929 1.5h-5.143"
          stroke="#00B818"
          strokeWidth="2.25"
          strokeLinecap="round"
        />
        <circle cx="12" cy="12" r="6.857" fill="#00B818" />
        <path
          d="M8.59 11.95a.75.75 0 0 0 0 1.06l1.814 1.814.005.005.151.151a.75.75 0 0 0 1.06 0l.153-.15a1.27 1.27 0 0 0 .011-.013l3.625-3.625a.75.75 0 0 0 0-1.06l-.151-.152a.75.75 0 0 0-1.061 0l-3.106 3.106L9.803 11.8a.75.75 0 0 0-1.06 0l-.152.151Z"
          fill="white"
        />
      </svg>
    </g>
  </IconBox>
);

export default DiagnosticFrameIcon;
