import * as process from 'process';

export const appConfig = {
  apiUrl: process.env.DOCTOR_API_URL,
  bearerToken: process.env.DOCTOR_API_BEARER_TOKEN,
  // baseAuth,
  sessionTimeout:
    process.env.DOCTOR_SESSION_TIMEOUT &&
    parseFloat(process.env.DOCTOR_SESSION_TIMEOUT),
  gtmContainerId: process.env.DOCTOR_GTM_CONTAINER_ID,
  termsAndConditionsLink: process.env.DOCTOR_TERMS_AND_CONDITIONS_LINK,
  privacyPolicyLink: process.env.DOCTOR_PRIVACY_POLICY_LINK,
  diagnosisAutoSaveMinDelay: 500, // ms
  diagnosisAutoSaveMaxDelay: 5000, // ms
  version: process.env.REACT_APP_VERSION,

  // type,
  // isDevelopment,
};
