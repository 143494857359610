import { FC } from 'react';
import { Skeleton } from '@higo/ui';
import styled from 'styled-components';

const Image = styled.img`
  margin: auto;
`;

export interface ContextPhotoProps {
  src?: string;
  alt?: string;
  isLoading?: boolean;
}

export const ContextPhoto: FC<ContextPhotoProps> = ({
  src,
  alt = '',
  isLoading,
}) => {
  return isLoading ? (
    <Skeleton />
  ) : (
    <Image src={src} alt={alt} id="context-photo" />
  );
};
