import { AppHeader } from 'components/Header';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { IdleAutoLogout } from 'components/IdleAutoLogout';
import { LayoutContextProvider } from '@higo/ui/lib/contexts';
import { RegisterAppEventGlobalListeners } from 'features/FirebaseEvents';

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
`;

export const PrivateLayout: FC = () => (
  <LayoutWrapper>
    <LayoutContextProvider>
      <RegisterAppEventGlobalListeners />
      <IdleAutoLogout />
      <AppHeader />
      <Outlet />
    </LayoutContextProvider>
  </LayoutWrapper>
);
