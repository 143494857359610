import styled, { css } from 'styled-components';
import { CloseIcon } from '@higo/ui/src/components/icons';

const CloseButton = styled(CloseIcon)`
  position: absolute;
  width: 1.5rem;
  padding: 0.275rem;
  border-radius: 50%;
  cursor: pointer;
`;

export const DesktopCloseButton = styled(CloseButton)(
  ({ theme }) => css`
    background-color: ${theme.palette.white};
    color: ${theme.palette.secondary['500']};
    position: relative;
    flex-shrink: 0;
  `,
);

export const TabletCloseButton = styled(CloseButton)(
  ({ theme }) => css`
    background-color: ${theme.palette.secondary['500']};
    color: ${theme.palette.white};
    top: 2rem;
    right: 1.25rem;
  `,
);
