import { useQuery } from 'react-query';
import { fetchExaminationsSummary } from '@higo/api/lib/services/examinationService';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';
import { DoctorQueryKey } from 'config/doctorQueryKey';

export const useExaminationsSummaryQuery = (id: number) => {
  const { authorizedAxios } = useDoctorPortalApiContext();

  return useQuery(
    [DoctorQueryKey.ExaminationsSummary, id],
    () => fetchExaminationsSummary(authorizedAxios)(id),
    {
      enabled: !isNaN(id),
    },
  );
};
