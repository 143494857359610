import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { Navigate, useLocation } from 'react-router-dom';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { isLoggedIn } = useIsLoggedIn();
  const location = useLocation();

  return isLoggedIn ? (
    children
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};
