import { filter, map, prop } from 'rambda';
import { ExaminationAssignmentResultDTO } from '@higo/api/lib/models/examinationsAssignmentResultDTO';
import { propEq } from 'rambda/immutable';
import { ExaminationAssignmentResultDTOStatus } from '@higo/api';

export const filterAssigned = filter<ExaminationAssignmentResultDTO>(
  propEq('assignmentStatus', ExaminationAssignmentResultDTOStatus.SUCCESS),
);

export const filterNotAssigned = filter<ExaminationAssignmentResultDTO>(
  propEq('assignmentStatus', ExaminationAssignmentResultDTOStatus.FAILED),
);

export const extractExaminationAssignmentResultIds = map<
  ExaminationAssignmentResultDTO,
  number
>(prop('id'));
