import { useCallback, useMemo, useState } from 'react';
import { Cell, CellProps } from 'react-table';
import { DoubleFieldCellRenderer, DoubleFieldRendererValue } from '@higo/ui';
import { formatDate } from '@higo/common/lib/utils/formatDate';
import { calculateWaitingTime } from '@higo/common/lib/utils/dateTimeUtils';
import { useEachMinute } from '@higo/common/lib/hooks';

export const DateWaitingCellRenderer = <T extends Record<string, unknown>>(
  props: CellProps<T, string>,
) => {
  const getWaitingTime = useCallback(
    () => calculateWaitingTime(props.cell.value),
    [props.cell.value],
  );

  const [waiting, setWaiting] = useState(getWaitingTime);

  const updateWaitingTime = useCallback(
    () => setWaiting(getWaitingTime),
    [getWaitingTime],
  );

  useEachMinute(updateWaitingTime);

  const cell: Cell<T, DoubleFieldRendererValue> = useMemo(
    () => ({
      ...props.cell,
      value: {
        highlighted: formatDate(props.cell.value, 'HH:mm'),
        text: waiting,
      },
    }),
    [props.cell, waiting],
  );

  return <DoubleFieldCellRenderer {...props} cell={cell} />;
};
