import { FC, memo } from 'react';
import styled, { css } from 'styled-components';
import { capitalize } from '../utils';
import { StyleProps } from '@summer/jst-react';

// todo: extract HigoMode from api so that it may be reused here
type HigoMode = 'FAMILY' | 'PRO' | 'FAMILY_AND_PRO';

export interface HigoModeProps extends StyleProps {
  mode: HigoMode;
  anamnesisSurveyFilled?: boolean;
}

const Text = styled('span')<{
  $higoMode: HigoMode;
}>(
  ({ theme, $higoMode }) => css`
    display: inline-block;
    font-family: ${theme.fontFamily};
    font-weight: 700;
    font-size: ${theme.fontSize.l};
    line-height: 1rem;
    color: ${() => {
      switch ($higoMode) {
        case 'FAMILY':
          return theme.palette.primary['500'];
        case 'PRO':
          return theme.palette.pro['100'];
        case 'FAMILY_AND_PRO':
          return theme.palette.primary['500'];
        default:
          return 'inherit';
      }
    }};
  `,
);

export const HigoModeLogo: FC<HigoModeProps> = memo<HigoModeProps>(
  ({ mode, ...props }) => (
    <Text id="higo-mode-logo" {...props} $higoMode={mode}>
      {capitalize(mode)}
    </Text>
  ),
);

export const HigoModeLogoWithSurveyInfo: FC<HigoModeProps> =
  memo<HigoModeProps>(({ mode, anamnesisSurveyFilled, ...props }) => (
    <Text id="higo-mode-logo" {...props} $higoMode={mode}>
      {capitalize(mode)}
      {mode !== 'FAMILY' && anamnesisSurveyFilled && '+'}
    </Text>
  ));

HigoModeLogo.displayName = 'HigoMode';
