import {
  isApiError,
  isOAuthError,
  TranslatedError,
} from '@higo/common/src/ext/ApiError/types';
import { IntlShape } from 'react-intl/src/types';
import { hasTranslationMessage } from '@higo/common/src/ext/intl/hasTranslationMessage';

export const getIntlTranslatedError = (
  intl: IntlShape,
  defaultMessage: string,
  translationKeys: string[],
) => {
  const tk = translationKeys.find((x) => hasTranslationMessage(intl, x));
  return tk ? intl.formatMessage({ id: tk }) : defaultMessage;
};

export const getTranslatedApiErrorResponse = (
  data: unknown,
  originalError: Error,
  intl: IntlShape,
): TranslatedError | undefined => {
  if (isOAuthError(data)) {
    return {
      data: data,
      originalError,
      message: getIntlTranslatedError(intl, data.error_description, [
        data.error,
        data.error_description,
      ]),
    };
  } else if (isApiError(data)) {
    const translateMessages = data.errors.map((error) =>
      getIntlTranslatedError(intl, error.message, [
        error.message,
        error.errorCode,
      ]),
    );
    return {
      data,
      originalError,
      message:
        translateMessages.length === 1
          ? translateMessages[0]
          : translateMessages,
    };
  }
};
