import videojs, { VideoJsPlayer } from 'video.js';
import { mergeLeft } from 'rambda';
import { VideoJsPluginOptions } from '@higo/common/src/ext/VideoJs/controls/videoJsPluginOptions';
import registerComponent = videojs.registerComponent;

const Button = videojs.getComponent('Button');

export interface RequestFrameOptions extends VideoJsPluginOptions {
  onRequestFrame?: (s: number) => void;
}

class RequestFrame extends Button {
  onRequestFrame;

  constructor(player: VideoJsPlayer, options: RequestFrameOptions) {
    super(player, options);
    this.onRequestFrame = options.onRequestFrame;
  }

  buildCSSClass() {
    return `vjs-seek-download-request-frame-control ${super.buildCSSClass()}`;
  }

  createEl(tag: string, props = {}, attributes = {}) {
    tag = 'button';

    props = mergeLeft(
      {
        className: this.buildCSSClass(),
      },
      props,
    );

    attributes = mergeLeft(
      {
        type: 'button',
      },
      attributes,
    );

    const el = videojs.dom.createEl(
      tag,
      props,
      attributes,
    ) as HTMLButtonElement;

    this.createControlTextEl(el);

    return el;
  }

  handleClick() {
    const player = this.player();
    player.pause();
    this.onRequestFrame?.(player.currentTime());
  }
}

registerComponent('RequestFrame', RequestFrame);
