import { useQuery } from 'react-query';

import { isNil, prop } from 'rambda';
import { useMemo } from 'react';
import { useLogout } from 'hooks/useLogout';
import { DoctorUserDTO, UserRole } from '@higo/api';
import {
  fetchDoctorData,
  fetchMedicalOperatorData,
} from '@higo/api/lib/services';
import { useUserRole } from 'hooks/useUserRole';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';
import { DoctorQueryKey } from 'config/doctorQueryKey';

const profileRoleFetchMap = {
  [UserRole.Doctor]: fetchDoctorData,
  [UserRole.SuperDoctor]: fetchDoctorData,
  [UserRole.MedicalOperator]: fetchMedicalOperatorData,
  [UserRole.DemoAccount]: fetchDoctorData,
};

// todo: convert to application error and disable retry for such cases
const unsupportedProfileFn = () => Promise.reject(`Role not supported`);

export const useProfileDataQuery = <TData = DoctorUserDTO>(
  select?: (dto: DoctorUserDTO) => TData,
) => {
  const { authorizedAxios } = useDoctorPortalApiContext();

  const logout = useLogout();
  const { role } = useUserRole();

  const queryFn = useMemo(() => {
    return role ? profileRoleFetchMap[role] : undefined;
  }, [role]);

  return useQuery(
    DoctorQueryKey.ProfileData,
    () => (isNil(queryFn) ? unsupportedProfileFn() : queryFn(authorizedAxios)),

    {
      enabled: !isNil(role),
      onError: () => {
        logout();
      },
      select,
      // retry: false,
    },
  );
};

const profileUserIdProp: (dto: DoctorUserDTO) => number | undefined =
  prop('userId');

export const useProfileUserId = () => useProfileDataQuery(profileUserIdProp);
