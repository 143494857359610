import { FC, useMemo } from 'react';
import {
  ExaminationDiagnosisMode,
  ExaminationPageDiagnosisModel,
  ExaminationPagePatientModel,
  ExaminationPageSectionId,
} from '../models';
import { FormattedMessage } from 'react-intl';
import {
  AuscultationGroup,
  AuscultationPointTagSelectFn,
} from '@higo/common/src/components';
import styled from 'styled-components';
import { AusultationTagSelect } from '../components/AusultationTagSelect';
import {
  collectHeartAuscultationDictionaryItems,
  selectHeartAuscultationDictionary,
} from '@higo/common/src/features/Dictionary';
import { ExaminationPageSection } from '../sections/ExaminationPageSection';
import {
  useHeartAuscultationPhysicalExaminationQuery,
  useHeartAuscultationPhysicalExaminationResourceFiles,
} from '@higo/common/src/features/ExaminationPage/hooks/queries';
import { HeartAuscultationLegend } from '@higo/common/src/components/Auscultation/HeartAuscultationLegend/HeartAuscultationLegend';
import { buildAuscultationPoints } from '@higo/common/src/features/ExaminationPage/utils/buildAuscultationPoints';
import { auscultationPointsCount } from '@higo/common/src/config/auscultationPointsCount';
import { PhysicalExaminationType } from '@higo/api';

const AuscultationGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export interface HeartAuscultationSectionProps {
  examinationId: number;
  patientModel: ExaminationPagePatientModel;
  diagnosisModel: ExaminationPageDiagnosisModel;
}

const diagnosisModeAuscultationTagStrategy: Record<
  ExaminationDiagnosisMode,
  AuscultationPointTagSelectFn
> = {
  [ExaminationDiagnosisMode.Editable]: (fieldName) => (
    <AusultationTagSelect
      name={`heartAuscultation.tags.${fieldName}.tags`}
      dictionarySelector={selectHeartAuscultationDictionary}
      dictionaryItemsCollector={collectHeartAuscultationDictionaryItems}
    />
  ),
  [ExaminationDiagnosisMode.ReadOnly]: (fieldName) => (
    <AusultationTagSelect
      readonly
      name={`heartAuscultation.tags.${fieldName}.tags`}
      dictionarySelector={selectHeartAuscultationDictionary}
      dictionaryItemsCollector={collectHeartAuscultationDictionaryItems}
    />
  ),
  [ExaminationDiagnosisMode.NotAvailable]: () => null,
};

export const HeartAuscultationSection: FC<HeartAuscultationSectionProps> = ({
  examinationId,
  patientModel,
  diagnosisModel,
}) => {
  const { data } = useHeartAuscultationPhysicalExaminationQuery(examinationId);
  const { data: fileResourceMap } =
    useHeartAuscultationPhysicalExaminationResourceFiles(examinationId);

  const auscultationPoints = useMemo(
    () =>
      buildAuscultationPoints(
        data?.heartAuscultationResults ?? [],
        fileResourceMap ?? {},
        auscultationPointsCount[PhysicalExaminationType.HeartAuscultation],
      ),
    [data?.heartAuscultationResults, fileResourceMap],
  );

  const filesIds = useMemo(
    () => auscultationPoints.filter((x) => x.file).map((x) => x.file?.fileId),
    [auscultationPoints],
  );

  return (
    <ExaminationPageSection
      title={
        <FormattedMessage id="examinationPage.section.heartAuscultation.title" />
      }
      sectionId={ExaminationPageSectionId.Heart}
      audioDownload="double"
      filesIds={filesIds}
    >
      <AuscultationGroupContainer>
        <AuscultationGroup
          auscultationPoints={auscultationPoints}
          legend={
            <HeartAuscultationLegend
              gender={patientModel.gender}
              maturity={patientModel.maturity}
            />
          }
          tag={diagnosisModeAuscultationTagStrategy[diagnosisModel.mode]}
        />
      </AuscultationGroupContainer>
    </ExaminationPageSection>
  );
};
