import { EarResultDTO } from '@higo/api/lib/models/examinationResultsDTO';
import { slidingWindowSlice } from '@higo/common/src/utils/slidingWindowSlice';
import { map, pick, pipe, prop } from 'rambda';
import { useExaminationAzureResourcesQuery } from '@higo/common/src/features/ExaminationPage/hooks/queries/useExaminationAzureResourcesQuery';
import {
  useExaminationAzureResourceQuery,
  useLeftEarExaminationResultsQuery,
  useRightEarExaminationResultsQuery,
} from '@higo/common/src/features/ExaminationPage/hooks/queries';

const selectFileResultIds: (data: EarResultDTO) => number[] = pipe(
  prop('earResults'),
  map(prop('fileId')),
);

const selectGalleryFileResultIds: (data: EarResultDTO) => number[] = (data) =>
  slidingWindowSlice(
    data.earResults.map(prop('fileId')),
    data.diagnosticImageIndex,
  );

export const useRightEarExaminationGalleryResultsLinks = (id: number) => {
  const { data: ids } = useRightEarExaminationResultsQuery(
    id,
    selectGalleryFileResultIds,
  );
  return useExaminationAzureResourcesQuery(
    ids ?? [],
    map(pick(['fileName', 'link'])),
  );
};

export const useLeftEarExaminationGalleryResultsLinks = (id: number) => {
  const { data: ids } = useLeftEarExaminationResultsQuery(
    id,
    selectGalleryFileResultIds,
  );

  return useExaminationAzureResourcesQuery(
    ids ?? [],
    map(pick(['fileName', 'link'])),
  );
};

export const useLeftEarExaminationVideoFramesIds = (id: number) =>
  useLeftEarExaminationResultsQuery(id, selectFileResultIds);

export const useLeftEarExaminationVideoResultsLink = (id: number) => {
  const { data: fileId } = useLeftEarExaminationResultsQuery(
    id,
    ({ video }) => video?.fileId,
  );
  return useExaminationAzureResourceQuery(fileId);
};

export const useRightEarExaminationVideoFramesIds = (id: number) =>
  useRightEarExaminationResultsQuery(id, selectFileResultIds);

export const useRightEarExaminationVideoResultsLink = (id: number) => {
  const { data: fileId } = useRightEarExaminationResultsQuery(
    id,
    ({ video }) => video?.fileId,
  );
  return useExaminationAzureResourceQuery(fileId);
};
