import { ExaminationPatientDTO } from '@higo/api/src/models/examinationPatientDTO';
import { evolve } from 'rambda';
import { parseJSON } from 'date-fns';

export interface PatientMessageDTO {
  createDate: string;
  examinationId: number;
  id: number;
  isRead: boolean;
  message: string;
  patient: ExaminationPatientDTO;
}

export type PatientMessage = Omit<PatientMessageDTO, 'createDate'> & {
  createDate: Date;
};

export const mapToPatientMessage: (a: PatientMessageDTO) => PatientMessage =
  evolve({
    createDate: parseJSON,
  });
