import styled, { css } from 'styled-components';
import { FC } from 'react';

export interface SubtitleProps {
  variant: 'default' | 'bold';
}
const SubtitleBase = styled.p<{ $variant: SubtitleProps['variant'] }>`
  ${({ theme, $variant }) => css`
    font-family: ${({ theme }) => theme.fontFamily.primary};
    font-size: ${theme.fontSize.xs};
    font-weight: ${$variant === 'bold' ? 700 : 600};
  `}
`;

export const Subtitle: FC<SubtitleProps> = ({ children, variant }) => (
  <SubtitleBase $variant={variant}>{children}</SubtitleBase>
);
