import React, { FC, ReactNode, useCallback, useState } from 'react';
import { format } from 'date-fns';
import { ExaminationPatientDTOPatientType } from '@higo/api';
import { useEachMinute } from '@higo/common/src/hooks/useEachMinute';
import { calculateWaitingTime } from '@higo/common/src/utils/dateTimeUtils';
import { HigoModeLogo } from '@higo/ui';
import { FormattedMessage } from 'react-intl';
import { usePhoneContactDetails } from '@higo/common/src/features/ExaminationPage/hooks';
import { InfoBarListContainer } from './InfoBarListContainer';
import { InfoBarListItem } from './InfoBarListItem';
import { InfoBarLabeledValue } from './InfoBarLabeledValue';
import { last } from 'rambda';

interface ExaminationPageInfoBarProps {
  examinationCreateDate: Date;
  higoMode: ExaminationPatientDTOPatientType;
  executedBy: any;
  phoneNumber?: string;
  chatModalSlot?: ReactNode;
  shareModalSlot?: ReactNode;
  hasOperatorData: boolean;
  partnerName?: string;
}

export const ExaminationPageInfoBar: FC<ExaminationPageInfoBarProps> = ({
  examinationCreateDate,
  higoMode,
  executedBy,
  chatModalSlot,
  shareModalSlot,
  hasOperatorData,
  partnerName,
}) => {
  const formattedExamDate = format(examinationCreateDate, 'HH:mm, dd/MM/yyyy');

  const getWaitingTime = useCallback(
    () => calculateWaitingTime(examinationCreateDate),
    [examinationCreateDate],
  );
  const [waiting, setWaiting] = useState(getWaitingTime);
  const updateWaitingTime = useCallback(
    () => setWaiting(getWaitingTime),
    [getWaitingTime],
  );
  useEachMinute(updateWaitingTime);

  const phoneContactDetails = usePhoneContactDetails(executedBy.contactDetails);
  const formattedPhoneNumber = phoneContactDetails?.value
    ?.match(/.{1,3}/g)
    ?.join(' ');

  const cuntCuttingMOnameLength = (firtsName: string, lastName: string) => {
    const MOfullName = lastName + ' ' + firtsName;
    const lengthOfMOname = MOfullName.length;

    return lengthOfMOname > 13 ? `${MOfullName.slice(0, 13)}...` : MOfullName;
  };

  return (
    <div>
      <InfoBarListContainer>
        <InfoBarListItem>
          <HigoModeLogo mode={higoMode} />
        </InfoBarListItem>
        <InfoBarListItem>
          <InfoBarLabeledValue
            id="waiting-time"
            label={
              <FormattedMessage id="examinationInfoBar.label.waitingTime" />
            }
          >
            {waiting}
          </InfoBarLabeledValue>
        </InfoBarListItem>
        <InfoBarListItem>
          <InfoBarLabeledValue
            id="exam-date"
            label={<FormattedMessage id="common.examData" />}
          >
            {formattedExamDate};{' '}
            {partnerName && partnerName?.length > 13
              ? `${partnerName?.slice(0, 13)}...`
              : partnerName}
          </InfoBarLabeledValue>
          {shareModalSlot}
        </InfoBarListItem>
        {hasOperatorData && (
          <InfoBarListItem>
            <InfoBarLabeledValue
              id="medical-operator"
              label={
                <FormattedMessage id="examinationInfoBar.label.medicalOperator" />
              }
            >
              {cuntCuttingMOnameLength(
                executedBy.firstName,
                executedBy.lastName,
              )}
            </InfoBarLabeledValue>
          </InfoBarListItem>
        )}
        {hasOperatorData && (
          <InfoBarListItem>
            (
            <InfoBarLabeledValue
              id="contact"
              label={<FormattedMessage id="examinationInfoBar.label.contact" />}
            >
              {phoneContactDetails &&
                `${phoneContactDetails.prefix} ${formattedPhoneNumber}`}
            </InfoBarLabeledValue>
            ){chatModalSlot}
          </InfoBarListItem>
        )}
      </InfoBarListContainer>
    </div>
  );
};
