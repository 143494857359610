import { stringify } from 'qs';
import {
  OAuth2AccessTokenDTO,
  OAuth2AccessTokenReqDTO,
} from '@higo/api/src/models';
import { flatten } from '@higo/api/src/utils';
import { AxiosInstance } from 'axios';
// import { mapToOAuth2AccessToken } from '@higo/common/lib/utils/axios';

export const fetchAuthToken =
  (axios: AxiosInstance) => (data: OAuth2AccessTokenReqDTO) =>
    axios
      .post<OAuth2AccessTokenDTO>(
        'oauth/token',
        stringify({
          ...data,
          grant_type: 'password',
        }),
      )
      .then(flatten);
// .catch(throwErrorResponse);
// .then(flatten)

export const refreshAuthToken =
  (axios: AxiosInstance) => (refreshToken: string) => {
    const formData = new FormData();
    formData.append('grant_type', 'refresh_token');
    formData.append('refresh_token', refreshToken);

    return axios
      .post<OAuth2AccessTokenDTO>('oauth/token', formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then(flatten);
    // .then(mapToOAuth2AccessToken);
    // .catch(throwErrorResponse);
  };

//
// export const logout = async (queryClient: QueryClient, router: NextRouter) => {
//   await router.push(HomepageRoute);
//   // Sentry.setUser(null);
//   deleteFromStorage('auth');
//   // has to be reset separately in order for other queries not using stale data (previous Auth data) during reset
//   await queryClient.resetQueries(QueryKey.Auth, undefined);
//   await queryClient.resetQueries();
// };
