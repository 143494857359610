import { ContactDetailsDTO, pickPhoneContactDetails } from '@higo/api';
import { useDictionaryMap } from '@higo/common/src/hooks/useDictionaryMap';
import { useMemo } from 'react';
import { isNil } from 'rambda';
import { DictionaryItem } from '@summer/jst-dictionaries';
import {
  PhoneNumberPrefixDictionaryItemValue,
  selectPhoneNumberPrefixDictionary,
} from '@higo/common/src/features/Dictionary';

export const usePhoneContactDetails = (contactDetails: ContactDetailsDTO[]) => {
  const dictionary = useDictionaryMap(selectPhoneNumberPrefixDictionary);

  return useMemo(() => {
    const phoneContactDetails = pickPhoneContactDetails(contactDetails);

    if (isNil(phoneContactDetails)) {
      return undefined;
    }

    return {
      prefix: (
        dictionary[
          phoneContactDetails.prefixCode
        ] as DictionaryItem<PhoneNumberPrefixDictionaryItemValue>
      )?.value.prefix,
      value: phoneContactDetails.value,
    };
  }, [contactDetails, dictionary]);
};
