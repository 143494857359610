import { useCallback, useState } from 'react';
import {
  ColumnSortState,
  SortByValue,
  TableSorting,
  TRowEntity,
} from 'react-table';
import { omit } from 'rambda';

// todo: fix typing
const toggleSortState = <T extends TRowEntity>(
  state: ColumnSortState,
  sortBy: SortByValue<T>,
): ColumnSortState =>
  !sortBy.direction
    ? omit(sortBy.sortField as string, state)
    : { ...state, [sortBy.sortField]: sortBy.direction };

export const useColumnSortState = <T extends TRowEntity>(
  initColumnSortState: ColumnSortState = {},
): TableSorting<T> => {
  const [state, setState] = useState<ColumnSortState>(initColumnSortState);

  const toggleSortBy = useCallback((value: SortByValue<T>) => {
    setState((oldState) => toggleSortState(oldState, value));
  }, []);

  return { toggleSortBy, columnSortState: state };
};
