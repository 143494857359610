import styled, { css } from 'styled-components';
import { QuestionAnswerRenderer } from '@higo/common/src/features/FilledSurvey/ComponentRenderers/QuestionAnswerRenderer';
import { QuestionType } from '@higo/api';
import { FC, memo } from 'react';
import { AnsweredQuestionRendererProps } from '@higo/common/src/features/FilledSurvey/ComponentRenderers/AnsweredQuestionRendererProps';
import { useRenderableSelectedAnswers } from '@higo/common/src/features/FilledSurvey/ComponentRenderers/useRenderableAnswers';

const Container = styled('span')(() => css``);

const QuestionLabel = styled('span')(
  () => css`
    padding-right: 0.25rem;
  `,
);

/**
 * Open questions doesn't have child questions
 */
export const AnsweredQuestionDashRenderer: FC<AnsweredQuestionRendererProps> =
  memo(({ answeredQuestion, ...rest }) => {
    const renderableSelectedAnswers = useRenderableSelectedAnswers(
      answeredQuestion.selectedAnswers,
      rest?.options?.isQuestionAnswerOptionRenderable,
    );
    return (
      <Container>
        -
        <QuestionLabel>
          {answeredQuestion.questionUserFriendlyLabel ??
            `${answeredQuestion.question}`}
        </QuestionLabel>
        <span>
          {QuestionType.OPEN === answeredQuestion.questionType ? (
            <span>
              <QuestionAnswerRenderer
                answered={answeredQuestion.answered}
                answer={answeredQuestion.answer}
                type={answeredQuestion.openAnswerType}
                questionType={answeredQuestion.questionType}
              />
            </span>
          ) : (
            renderableSelectedAnswers.map((answer, i, arr) => (
              <span key={answer.code}>
                <QuestionAnswerRenderer
                  answer={answer}
                  questionType={
                    answeredQuestion.questionType as
                      | QuestionType.SINGLE_CLOSED
                      | QuestionType.MULTIPLE_CLOSED
                  } // don't want to add another check
                />
                {i < arr.length - 1 && `; `}
              </span>
            ))
          )}
        </span>
      </Container>
    );
  });
