import styled, { css } from 'styled-components';

export const TD = styled('div')(
  ({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0.5rem 0.25rem 0.5rem 0.75rem;
    z-index: 2;

    background: ${theme.palette.white};
    transition: ${theme.transitions.create('background', {
      duration: theme.transitions.duration.shortest,
    })};

    white-space: nowrap;
    ${theme.typography.body};
    font-weight: bold;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-top: 1px solid ${theme.palette.neutral['500']};
    }
    &:first-child {
      border-radius: 0.5rem 0 0 0.5rem;
      &:before {
        left: 0.5rem;
      }
    }
    &:last-child {
      border-radius: 0 0.5rem 0.5rem 0;
      &:before {
        right: 0.5rem;
      }
    }
  `,
);

export const SkeletonTD = styled(TD)<{ $width: string | number }>`
  ${({ $width }) => css`
    width: ${$width}px;
    flex: ${$width} 0 auto;
    min-width: 0;
    box-sizing: border-box;
    .skeleton {
      width: 60%;
      height: 1.35rem;
    }
  `}
`;
