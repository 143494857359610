import { useQueryClient } from 'react-query';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { OAuth2AccessTokenReqDTO } from '@higo/api/lib/models';
import { useQueryStorage } from '@higo/common/lib/QueryStorage';
import { setAPIOAuth2AccessToken } from 'services/apiOAuth2AccessTokenService';
import { useAuthTokenMutation } from './useAuthTokenMutation';
import { toast } from 'react-toastify';

export const useLoginProcess = () => {
  const queryStorage = useQueryStorage();
  const queryClient = useQueryClient();
  const tokenMutation = useAuthTokenMutation();

  const { isLoading } = useIsLoggedIn();

  const login = (dto: OAuth2AccessTokenReqDTO) =>
    tokenMutation.mutateAsync(dto).then(async (apiOAuthTokens) => {
      ['D', 'MO', 'SD', 'DEMO'].includes(apiOAuthTokens.accessTokenData.role) &&
      apiOAuthTokens.accessTokenData.accountStatus !== 'NEED_PASSWORD_CHANGE'
        ? setAPIOAuth2AccessToken(queryClient, queryStorage, apiOAuthTokens)
        : toast.error('Invalid credentials');
    });

  return { login, isLoading };
};
