import styled from 'styled-components';
import VideoJS from '@higo/common/src/ext/VideoJs/VideoJS';
import { FC, useMemo } from 'react';
import { VideoJsPlayerOptions } from 'video.js';

const StyledVideoPlayer = styled(VideoJS)`
  border-radius: 1rem;
  overflow: hidden;
`;

export type VideoPlayerProps = {
  videoUrl: string;
  options?: VideoJsPlayerOptions;
  fluid: boolean;
};

export const VideoPlayer: FC<VideoPlayerProps> = ({
  videoUrl,
  options,
  fluid,
}) => {
  const mergedOptions = useMemo(
    () => ({
      controls: true,
      fluid: fluid,
      fill: true,
      controlBar: {
        children: [
          'playToggle',
          'progressControl',
          'remainingTimeDisplay',
          'fullscreenToggle',
        ],
      },
      ...options,
      sources: [
        {
          src: videoUrl,
          type: 'video/mp4',
        },
      ],
    }),
    [options, videoUrl],
  );

  return <StyledVideoPlayer options={mergedOptions} />;
};
