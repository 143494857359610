import { IconBox, IconProps } from '../IconBox';

const RoundQuickCheckIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12ZM5.714 12c0-3.456 2.83-6.286 6.286-6.286 3.456 0 6.286 2.83 6.286 6.286 0 3.456-2.816 6.286-6.286 6.286-3.47 0-6.286-2.83-6.286-6.286ZM12 9.306A2.688 2.688 0 0 0 9.306 12 2.688 2.688 0 0 0 12 14.694 2.688 2.688 0 0 0 14.694 12 2.688 2.688 0 0 0 12 9.306Z"
    />
  </IconBox>
);

export default RoundQuickCheckIcon;
