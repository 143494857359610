import { MouseEvent, useCallback, useMemo, useState } from 'react';

export const useMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isOpened = useMemo(() => !!anchorEl, [anchorEl]);

  const toggle = useCallback((e: MouseEvent<HTMLElement>) => {
    setAnchorEl((anchorEl) => (!anchorEl ? e.currentTarget : null));
  }, []);

  const close = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return { isOpened, anchorEl, toggle, close };
};
