import { useQuery } from 'react-query';
import { ApiQueryKey } from '@higo/api/lib/apiQueryKey';
import { fetchAzureResourceLink } from '@higo/api/lib/azureResource/azureStorageService';
import { useExaminationApiContext } from '@higo/common/src/features/ExaminationPage/providers';

export const useExaminationAzureResourceQuery = (
  resourceId: number = NaN,
  enabled = !isNaN(resourceId),
) => {
  const axios = useExaminationApiContext();

  return useQuery(
    [ApiQueryKey.AzureResource, resourceId],
    () => fetchAzureResourceLink(axios)(resourceId),
    {
      enabled,
      refetchInterval: 840000,
    },
  );
};
