import { Measurement } from '@higo/api/lib/models/measurement';
import { formatMeasurement } from '@higo/common/src/components/MeasurementRenderer/utils';

export type MeasurementRendererProps = {
  data?: Measurement;
};

export const MeasurementRenderer = ({ data }: MeasurementRendererProps) => (
  <>{formatMeasurement(data)}</>
);
