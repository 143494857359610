import { CellProps } from 'react-table';
import styled from 'styled-components';

const MessageExcerpt = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 2rem;
`;

export const MessagesListMessageExcerptCellRenderer = <
  T extends Record<string, unknown>,
>({
  cell,
}: CellProps<T, string>) => <MessageExcerpt>{cell.value}</MessageExcerpt>;
