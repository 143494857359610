import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { clearAPIOAuth2AccessToken } from 'services/apiOAuth2AccessTokenService';
import { useQueryStorage } from '@higo/common/lib/QueryStorage';

export const useLogout = () => {
  const queryClient = useQueryClient();
  const queryStorage = useQueryStorage();
  const navigate = useNavigate(); //todo: Causing unnecessary re-renders https://github.com/remix-run/react-router/issues/7634

  return useCallback(() => {
    clearAPIOAuth2AccessToken(queryClient, queryStorage);
    queryClient.clear();

    navigate('/');
  }, [queryClient, queryStorage, navigate]);
};
