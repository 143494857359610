import { extractValue } from '@higo/common/src/utils/unitValue';
import { FC, memo } from 'react';
import { OverviewInfoList } from './OverviewInfoList';
import { PatientOverview } from '@higo/common/src/features/ExaminationPage/models';
import { useIntl } from 'react-intl';
import { SingleRowTooltipList } from '@higo/ui';

export interface OverviewImportantPatientConditionProps {
  patientOverview: PatientOverview;
}

export const OverviewImportantPatientCondition: FC<OverviewImportantPatientConditionProps> =
  memo(({ patientOverview }) => {
    const intl = useIntl();

    const items = [
      {
        translationKey: 'common.diseases',
        value: (
          <SingleRowTooltipList
            items={extractValue(patientOverview.diseases)}
          />
        ),
      },
      {
        translationKey: 'common.allergies',
        value: (
          <SingleRowTooltipList
            items={extractValue(patientOverview.allergies)}
          />
        ),
      },
      {
        translationKey: 'common.medications',
        value: (
          <SingleRowTooltipList
            items={extractValue(patientOverview.medications)}
          />
        ),
      },
      {
        translationKey: 'common.other',
        value: (
          <SingleRowTooltipList
            items={patientOverview.other.map((tag) => `${tag},`)}
          />
        ),
      },
    ];

    return <OverviewInfoList items={items} />;
  });
