import { IconBox, IconProps } from '../IconBox';

const RoundEarsIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Zm-8-2.006c0-2.36-1.922-4.28-4.286-4.28a4.287 4.287 0 0 0-4.285 4.28c0 .934.296 1.821.856 2.567l3.618 4.814A2.278 2.278 0 0 0 16 16.012V9.994Zm-6.447-2.16a3.04 3.04 0 0 1 2.161-.895 3.04 3.04 0 0 1 2.162.894 3.026 3.026 0 0 1 .896 2.16c0 .764-.621 1.384-1.384 1.384a.625.625 0 0 0-.625.623c0 .343.28.623.625.623.763 0 1.384.62 1.384 1.383a1.384 1.384 0 0 1-2.361.978.38.38 0 0 1 0-.538.378.378 0 0 1 .534 0 .625.625 0 0 0 1.069-.44.625.625 0 0 0-.626-.623c-.762 0-1.383-.62-1.383-1.383s.621-1.383 1.383-1.383c.346 0 .626-.28.626-.623 0-.065-.003-.132-.01-.204a2.287 2.287 0 0 0-2.242.588A2.277 2.277 0 0 0 11.088 12a.38.38 0 1 1-.757 0c0-.816.319-1.584.896-2.16a3.04 3.04 0 0 1 2.546-.87A2.288 2.288 0 0 0 11.715 7.7c-.615 0-1.193.239-1.628.673a2.277 2.277 0 0 0-.673 1.622.38.38 0 1 1-.757 0c0-.816.318-1.584.896-2.16Z"
    />
  </IconBox>
);

export default RoundEarsIcon;
