import { useQuery } from 'react-query';
import { fetchTemperaturePhysicalExamination } from '@higo/api/lib/services';
import { useExaminationApiContext } from '@higo/common/src/features/ExaminationPage/providers';
import { ExaminationQueryKey } from '@higo/common/src/features/ExaminationPage/ExaminationQueryKey';

export const useTemperaturePhysicalExaminationQuery = (
  examinationId: number,
  enabled = !isNaN(examinationId),
) => {
  const axios = useExaminationApiContext();

  return useQuery(
    [ExaminationQueryKey.TemperaturePhysicalExamination, examinationId],
    async () => fetchTemperaturePhysicalExamination(axios)(examinationId),
    {
      enabled,
    },
  );
};
