import styled from 'styled-components';
import { Skeleton } from '@higo/ui';
import { FormattedMessage } from 'react-intl';

export const DiagnosticPhotosGallerySkeleton = styled(Skeleton)`
  width: 100%;
  height: 100%;
  align-tems: center;
  justify-content" center;
`;

export const InfoContainer = styled('div')`
  margin: auto;
  text-align: center;
`;

export const DiagnosticVideoLoadingScreen = () => {
  return (
    <DiagnosticPhotosGallerySkeleton>
      <InfoContainer>
        <FormattedMessage id="examinationPage.processVideo" />
        ...
      </InfoContainer>
    </DiagnosticPhotosGallerySkeleton>
  );
};
