import React, { FC } from 'react';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import styled, { css } from 'styled-components';
import clsx from 'clsx';
import { DesktopCloseButton, TabletCloseButton } from './CloseButton';
import { StyleProps } from '@summer/jst-react';

// eslint-disable-next-line react/display-name
const BackdropUnstyled = React.forwardRef<
  HTMLDivElement,
  { open?: boolean; className: string }
>((props, ref) => {
  const { open, className, ...other } = props;

  return (
    <div
      className={clsx({ 'MuiBackdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-tap-highlight-color: transparent;
`;

const BaseModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalLayout = styled('div')(
  () => css`
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    max-height: 100%;
    padding: 1rem 4rem;
    column-gap: 1rem;
    width: min-content;
    justify-content: center;
  `,
);

const ModalContent = styled('div')<{ $fullWidth: boolean; $bigModal: boolean }>(
  ({ theme, $fullWidth, $bigModal }) => css`
    background-color: ${theme.palette.white};
    border-radius: 1rem;
    padding: 1rem;

    ${$bigModal &&
    css`
      width: 75rem;
      padding: 1rem 4rem;
    `}

    ${$fullWidth &&
    css`
      flex-grow: 1;
    `}
  `,
);

const TabletBaseModal = styled(BaseModal)`
  width: 100%;
  height: 100%;
`;

const TabletModalContainer = styled('div')(
  ({ theme }) => css`
    position: relative;
    height: 100%;
    width: 100%;
    background-color: ${theme.palette.white};
  `,
);

const TabletModalContent = styled.div`
  overflow: auto;
  padding: 3rem 0 3rem 2rem;
`;

export type ModalProps = {
  visible: boolean;
  onClose: () => void;
  fullscreen?: boolean;
  fullWidth?: boolean;
  bigModal?: boolean;
} & StyleProps;

export const Modal: FC<ModalProps> = ({
  className,
  style,
  visible,
  onClose,
  fullWidth = false,
  fullscreen = false,
  bigModal = false,
  children,
  ...props
}) => {
  return fullscreen ? (
    <TabletBaseModal
      aria-labelledby="Styled modal"
      open={visible}
      onClose={onClose}
      id="modal"
    >
      <TabletModalContainer>
        <TabletModalContent>
          <TabletCloseButton onClick={onClose} />
          {children}
        </TabletModalContent>
      </TabletModalContainer>
    </TabletBaseModal>
  ) : (
    <BaseModal
      aria-labelledby="Styled modal"
      open={visible}
      id="modal"
      onClose={(reason) => (reason === 'backdropClick' ? null : onClose)}
      components={{
        Backdrop,
      }}
      {...props}
    >
      <ModalLayout id="modal-layout">
        <ModalContent
          $fullWidth={fullWidth}
          $bigModal={bigModal}
          id="modal-content"
        >
          {children}
        </ModalContent>
        <DesktopCloseButton onClick={onClose} />
      </ModalLayout>
    </BaseModal>
  );
};
