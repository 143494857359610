import { ReactElement } from 'react';
import { isNil } from 'rambda';
import { SelectOption, TSelectValue } from '@mui/base/SelectUnstyled';
import MultiSelectUnstyled, {
  MultiSelectUnstyledProps,
} from '@mui/base/MultiSelectUnstyled';
import { StyleProps } from '@summer/jst-react';
import { SelectListbox } from '@higo/ui/src/components/Select/components/SelectListbox';
import { SelectPopper } from '@higo/ui/src/components/Select/components/SelectPopper';
import { MultiSelectRoot } from '@higo/ui/src/components/Select/components/SelectRoot';
import {
  MultiSelectOption,
  OptionProps,
} from '@higo/ui/src/components/Select/components/SelectOption';

export type MultiSelectProps<T> = Omit<
  MultiSelectUnstyledProps<T>,
  'onChange'
> & {
  onChange?: (value: T[] | null) => void; // quick and dirty workaround for breaking change@mui/base@5.0.0-alpha.98
} & (
    | {
        options?: never;
        children: Array<ReactElement<OptionProps>>;
      }
    | {
        options: SelectOption<T>[];
        children?: never;
      }
  );

export const MultiSelect = <T extends TSelectValue>({
  placeholder = '',
  options = [],
  disabled = false,
  error = false,
  clearValue,
  children,
  onChange,
  ...props
}: MultiSelectProps<T> & StyleProps) => (
  <MultiSelectUnstyled
    {...props}
    onChange={(event, newValue) => onChange?.(newValue)}
    disabled={disabled || (options.length === 0 && isNil(children))}
    components={{
      Root: MultiSelectRoot,
      Listbox: SelectListbox,
      Popper: SelectPopper,
      ...props.components,
    }}
    componentsProps={{
      ...props.componentsProps,
      root: {
        placeholder,
        error,
        clearValue,
        ...props.componentsProps?.root,
      },
      listbox: {
        ...props.componentsProps?.listbox,
      },
    }}
  >
    {children ??
      options.map((option) => (
        <MultiSelectOption
          key={option.value as string | number}
          value={option.value ?? option}
          disabled={option.disabled}
        >
          {option.label}
        </MultiSelectOption>
      ))}
  </MultiSelectUnstyled>
);
