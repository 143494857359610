import { PartialRecord } from '@higo/common/src/types/partialRecord';
import {
  OpenAnswerType,
  QuestionType,
  SurveyAnswerTag,
  SurveyQuestionTag,
} from '@higo/api';
import { format, parseISO, parseJSON } from 'date-fns';
import {
  AnsweredQuestion,
  QuestionAnswerOption,
} from '@higo/common/src/features/FilledSurvey/model';
import { isNil } from 'rambda';

const openQuestionTypeFormatters: PartialRecord<
  OpenAnswerType,
  (value: string) => string
> = {
  [OpenAnswerType.DATE]: (value) => format(parseISO(value), 'dd/MM/yyyy'),
  [OpenAnswerType.DATE_TIME]: (value) =>
    format(parseJSON(value), 'HH:mm, dd/MM/yyyy'),
  [OpenAnswerType.TEXT]: (value) => `"${value}"`,
};

export const getOpenQuestionAnswer = (
  answered: boolean,
  type: OpenAnswerType,
  defaultEmptyValue: string,
  answer?: string,
) =>
  answered && answer
    ? (
        openQuestionTypeFormatters[type] ??
        openQuestionTypeFormatters[OpenAnswerType.TEXT]
      )?.(answer)
    : defaultEmptyValue;

export const getClosedQuestionSingleAnswer = ({
  value,
  userFriendlyLabel,
}: QuestionAnswerOption) => userFriendlyLabel ?? value;

export const createAnsweredQuestionNonRenderableTagPredicate =
  (nonRenderableTags: Set<SurveyQuestionTag>) =>
  ({ surveyQuestionTag }: AnsweredQuestion) =>
    !surveyQuestionTag.some((x) => nonRenderableTags.has(x));

export const createQuestionAnswerOptionNonRenderableTagPredicate =
  (nonRenderableTags: Set<SurveyAnswerTag>) =>
  ({ tags }: QuestionAnswerOption) =>
    !tags.some((x) => nonRenderableTags.has(x));

export const extractAnsweredQuestionValue = (aq?: AnsweredQuestion) => {
  if (isNil(aq)) {
    return {
      raw: undefined,
      formatted: undefined,
    };
  }

  if (QuestionType.OPEN === aq.questionType) {
    return {
      raw: aq.answer,
      formatted: `"${aq.answer}"${
        aq.numericQuestionUnit && ' ' + aq.numericQuestionUnit
      }`,
    };
  }

  return {
    raw: undefined,
    formatted: undefined,
  };
};
