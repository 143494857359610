import styled, { css } from 'styled-components';
import { IconButton, IconButtonProps } from './IconButton';
import { ReactElement } from 'react';
import { IconProps } from '@higo/ui/src/components/IconBox';

// todo: type it better
export type RoundIconButtonProps = Omit<IconButtonProps, 'size' | 'icon'> & {
  icon?: ReactElement<IconProps>;
};

export const RoundIconButton = styled(IconButton).attrs({
  size: 'small',
})(
  () =>
    css`
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    `,
);
