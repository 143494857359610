/**
 * These hooks re-implement the now removed useBlocker and usePrompt hooks in 'react-router-dom'.
 * Thanks for the idea @piecyk https://github.com/remix-run/react-router/issues/8139#issuecomment-953816315
 * Source: https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874#diff-b60f1a2d4276b2a605c05e19816634111de2e8a4186fe9dd7de8e344b65ed4d3L344-L381
 * https://reactrouter.com/en/main/upgrading/v5#prompt-is-not-currently-supported
 */
import { useCallback, useContext, useEffect } from 'react';
import {
  UNSAFE_NavigationContext as NavigationContext,
  useLocation,
} from 'react-router-dom';
import type { Blocker, Transition } from 'history';

/**
 * Blocks all navigation attempts. This is useful for preventing the page from
 * changing until some condition is met, like saving form data.
 */
export function useBlocker(blocker: Blocker, when = true) {
  const { navigator } = useContext(NavigationContext);
  const location = useLocation();

  useEffect(() => {
    if (!when) {
      return;
    }

    const unblock = (navigator as any).block((tx: Transition) => {
      // do not execute blocker function if location pathname didn't change
      if (location.pathname === tx?.location?.pathname) {
        return;
      }

      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when, location]);
}

/**
 * Prompts the user with an Alert before they leave the current screen.
 */
export function usePrompt(message: string, when = true) {
  const blocker = useCallback(
    (tx: Transition) => {
      // eslint-disable-next-line no-alert
      // workaround for not prompting when we change #param

      if (window?.confirm(message)) {
        tx.retry();
      }
    },
    [message],
  );

  useBlocker(blocker, when);
}
