import React, { FC, ReactNode, useMemo } from 'react';
import { isNil } from 'rambda';
import styled, { css } from 'styled-components';
import { Heading, Text, VerticalDivider } from '@higo/ui';
import { OverviewContainer } from './OverviewContainer';
import { OverviewDiseasesColumnWrapper } from './OverviewDiseasesColumnWrapper';
import { OverviewDiseasesColumnButtons } from './OverviewDiseasesColumnButtons';
import { Gender, HigoMode } from '@higo/api';
import {
  defaultToNA,
  formatDate,
  mapToOrDefaultNA,
} from '@higo/common/src/utils';
import { OverviewGeneralPatientInformation } from './OverviewGeneralPatientInformation';
import { OverviewImportantPatientCondition } from './OverviewImportantPatientCondition';
import { FormattedMessage } from 'react-intl';
import { PatientOverview } from '@higo/common/src/features/ExaminationPage/models';

const StyledHeading = styled(Heading)(
  ({ theme }) => css`
    margin-bottom: 3.9375rem;
    overflow: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;

    ${theme.media.desktop.min} {
      margin-bottom: 1rem;
    }
  `,
);

const GeneralInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HorizontalContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
`;

export const GeneralInformationText = styled('span')(
  ({ theme }) => css`
    ${theme.typography.body2}
    color: ${theme.palette.secondary['500']};

    & b {
      ${theme.typography.subtitle1}
    }
  `,
);

const GeneralSurveyNotConductedLabel = styled(Text)(
  ({ theme }) => css`
    color: ${theme.palette.secondary[500]};
  `,
);

export interface ExaminationPageOverviewProps {
  mode: HigoMode;
  patientOverview: PatientOverview;
  examinationId: number;
  patient: {
    id: number;
    idNumber?: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    dateOfBirth: Date;
  }; // change later (ExaminationPatientDTO)
  generalSurveySlot?: ReactNode;
  patientExaminationsModalSlot?: ReactNode;
}

export const ExaminationPageOverview: FC<ExaminationPageOverviewProps> = ({
  mode,
  patientOverview,
  patient,
  generalSurveySlot,
  patientExaminationsModalSlot,
}) => {
  const data = useMemo(
    () => ({
      fullName: `${patient.firstName} ${patient.lastName}`,
      idNumber:
        isNil(patient.idNumber) || !patient.idNumber
          ? '...'
          : defaultToNA(patient.idNumber),
      dob: mapToOrDefaultNA<Date>((dob) => formatDate(dob, 'dd/MM/yyyy'))(
        patient.dateOfBirth,
      ),
    }),
    [patient],
  );

  const isHigoFamily = 'FAMILY' === mode;

  return (
    <OverviewContainer>
      <div id="overview-container">
        <div id="user-name">
          <StyledHeading level={2}>{data.fullName}</StyledHeading>
        </div>
        <GeneralInfoContainer>
          <HorizontalContainer>
            <GeneralInformationText id="user-idnumber">
              ID:{' '}
              <b id="idnumber-value">{data.idNumber ? data.idNumber : '...'}</b>
            </GeneralInformationText>
            <GeneralInformationText id="birthdate">
              <FormattedMessage id="examinationPage.header.overview.birthday" />
              : <b id="birthdate-value">{data.dob}</b>
            </GeneralInformationText>
          </HorizontalContainer>
          {patientExaminationsModalSlot}
        </GeneralInfoContainer>
      </div>

      <VerticalDivider />

      {isHigoFamily && (
        <>
          <OverviewGeneralPatientInformation
            patientOverview={patientOverview}
          />

          <VerticalDivider />
        </>
      )}

      <OverviewDiseasesColumnWrapper>
        {isHigoFamily && (
          <OverviewImportantPatientCondition
            patientOverview={patientOverview}
          />
        )}
        <OverviewDiseasesColumnButtons>
          {generalSurveySlot}
        </OverviewDiseasesColumnButtons>
      </OverviewDiseasesColumnWrapper>
    </OverviewContainer>
  );
};
