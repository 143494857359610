import {
  InputUnstyled,
  inputUnstyledClasses,
  InputUnstyledProps,
} from '@mui/base';
import styled, {
  css,
  DefaultTheme,
  InterpolationFunction,
  ThemeProps,
} from 'styled-components';
import { FC } from 'react';

export type InputSize = 'large' | 'small';

export type InputProps = {
  focused?: boolean;
  size?: InputSize; // todo: requires fixing ( some sort unification)
} & InputUnstyledProps;

const sizeStyleMap: Record<
  InputSize,
  InterpolationFunction<ThemeProps<DefaultTheme>>
> = {
  small: ({ theme }) =>
    css`
      padding: 0.5rem;
      ${theme.typography.body2}
    `,
  large: ({ theme }) => css`
    padding: 0.9375rem 1rem;
    ${theme.typography.body}
  `,
};

// export const Input = styled<FC<InputProps>>(InputUnstyled).attrs(
//   ({ focused }) => ({ className: focused && 'Mui-focused' }),
// )(
// todo: focused state support, remove root
export const Input = styled<FC<InputProps>>(InputUnstyled)(
  ({ theme, size }) => css`
    .${inputUnstyledClasses.input} {
      outline: none;
      width: 100%;

      cursor: text;
      border-radius: 0.5rem;
      font-family: ${theme.typography.fontFamily};

      border: 1px solid ${theme.input.default.border};
      background-color: ${theme.input.default.background};

      ${sizeStyleMap[size ?? 'large']({ theme })}

      &.${inputUnstyledClasses.input}:read-only {
        cursor: default;
      }

      &:not(.${inputUnstyledClasses.disabled}) {
        box-shadow: ${theme.input.default.background} 0rem 0rem 0rem 1.5625rem
          inset !important;

        &:hover {
          border-color: ${theme.input.disabled.border};
        }

        &.${inputUnstyledClasses.focused} {
          border-color: ${theme.input.focused.border};
        }

        &.${inputUnstyledClasses.error} {
          border-color: ${theme.input.error.border};
        }
      }

      &.${inputUnstyledClasses.disabled} {
        cursor: not-allowed;
        background-color: ${theme.input.disabled.background};
        border-color: ${theme.input.disabled.border};
        color: ${theme.input.disabled.primary};

        .${inputUnstyledClasses.input} {
          background-color: ${theme.input.disabled.background};

          &::placeholder {
            color: ${theme.input.disabled.placeholder};
          }
        }
      }

      &::placeholder {
        color: ${theme.input.default.placeholder};
      }

      &.${inputUnstyledClasses.multiline} {
        resize: vertical;
        //height: available !important; // todo: fix
      }
    }
  `,
);
