import { cloneElement, FC, ReactElement, useState } from 'react';
import { Modal, ModalProps } from './Modal';
import { StyleProps } from '@summer/jst-react';

export type ManagedModalProps = {
  trigger?: ReactElement<{ onClick: () => void }>;
  initialVisible?: boolean;
  onClose?: () => void;
};
export const ManagedModal: FC<
  ManagedModalProps & Pick<ModalProps, 'fullWidth' | 'fullscreen' | 'bigModal'> & StyleProps
> = ({
  style,
  className,
  initialVisible = false,
  trigger,
  children,
  fullWidth,
  fullscreen,
  bigModal,
  onClose,
}) => {
  const [visible, setVisible] = useState(initialVisible);

  return (
    <>
      {trigger &&
        cloneElement(trigger, {
          ...trigger.props,
          onClick: () => {
            setVisible(true);
            trigger.props.onClick?.();
          },
        })}
      <Modal
        style={style}
        className={className}
        visible={visible}
        onClose={() => {
          setVisible(false);
          onClose?.();
        }}
        fullWidth={fullWidth}
        fullscreen={fullscreen}
        bigModal={bigModal}
      >
        {children}
      </Modal>
    </>
  );
};
