import {
  createContext,
  FC,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { UseAudioReactPlayer } from '@higo/ui/src/components/AudioPlayer/useAudioReactPlayer';

export interface AudioTrackPlaylistContext {
  activeTrack: string | null;
  setActiveTrack: (id: string | null) => void;
  onTrackEnd: (id: string) => void;
  register: (id: string, ref: UseAudioReactPlayer) => void;
  playPause: (id: string) => void;
}

const Context = createContext<AudioTrackPlaylistContext | null>(null);

export const useAudioTrackGroup = () => {
  return useContext(Context);
};

export interface AudioTrackGroupProviderProps {
  trackOrder: string[];
}
export const AudioTrackGroupProvider: FC<AudioTrackGroupProviderProps> = memo(
  ({ trackOrder, children }) => {
    const [activeTrack, setActiveTrack] = useState<string | null>(null);

    const refMap = useRef<Record<string, UseAudioReactPlayer>>({});

    const onTrackEnd = useCallback(() => {
      activeTrack && refMap.current[activeTrack].pause();
    }, [activeTrack, trackOrder]);

    useEffect(() => {
      let oldActiveTrack = activeTrack;
      activeTrack && refMap.current[activeTrack].play();

      return () => {
        // refMap holds tracks references and most likely won't change
        // eslint-disable-next-line react-hooks/exhaustive-deps
        oldActiveTrack && refMap.current[oldActiveTrack].pause();
      };
    }, [activeTrack]);

    const playPause = useCallback(
      (id: string | null) => {
        setActiveTrack(activeTrack === id ? null : id);
      },
      [activeTrack, setActiveTrack],
    );

    const register = useRef<AudioTrackPlaylistContext['register']>((id, x) => {
      refMap.current[id] = x;
    });

    const value = useMemo(
      () => ({
        activeTrack,
        setActiveTrack,
        onTrackEnd,
        register: register.current,
        playPause,
      }),
      [activeTrack, onTrackEnd, playPause],
    );

    return <Context.Provider value={value}>{children}</Context.Provider>;
  },
);
