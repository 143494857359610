import { flatten } from '@higo/api/src/utils';
import {
  AbdominalAuscultationPhysicalExaminationDTO,
  CoughAuscultationPhysicalExaminationDTO,
  HeartAuscultationPhysicalExaminationDTO,
  HeartRateResultDTO,
  HeartRateWavResultDTO,
  LungsAuscultationPhysicalExaminationDTO,
  TemperatureResultDTO,
} from '@higo/api/src/models/physicalExaminations';
import { AxiosInstance } from 'axios';
import {
  EarResultDTO,
  SkinExaminationDTO,
  ThroatExaminationDTO,
} from '@higo/api/src/models/examinationResultsDTO';
import {
  roundPulseMeasurementValue,
  roundTemperatureMeasurementValue,
} from '@higo/api/src/utils/roundMeasurementValue';

export const fetchLungsAuscultationPhysicalExamination =
  (axios: AxiosInstance) => (id: number) =>
    axios
      .get<LungsAuscultationPhysicalExaminationDTO>(
        `api/examinations/${id}/lungs`,
      )
      .then(flatten);

export const fetchCoughAuscultationPhysicalExamination =
  (axios: AxiosInstance) => (id: number) =>
    axios
      .get<CoughAuscultationPhysicalExaminationDTO>(
        `api/examinations/${id}/cough`,
      )
      .then(flatten);

export const fetchHeartAuscultationPhysicalExamination =
  (axios: AxiosInstance) => (id: number) =>
    axios
      .get<HeartAuscultationPhysicalExaminationDTO>(
        `api/examinations/${id}/heartAuscultation`,
      )
      .then(flatten);

export const fetchHeartRatePhysicalExamination =
  (axios: AxiosInstance) => (id: number) =>
    axios
      .get<HeartRateResultDTO>(`api/examinations/${id}/heartRate`)
      .then(flatten)
      .then(roundPulseMeasurementValue);

export const fetchHeartRateWavPhysicalExamination =
  (axios: AxiosInstance) => (id: number) =>
    axios
      .get<HeartRateWavResultDTO>(`api/examinations/${id}/heartRateWav`)
      .then(flatten);

export const fetchTemperaturePhysicalExamination =
  (axios: AxiosInstance) => (id: number) =>
    axios
      .get<TemperatureResultDTO>(`api/examinations/${id}/temperature`)
      .then(flatten)
      .then(roundTemperatureMeasurementValue);

export const fetchAbdominalAuscultationPhysicalExamination =
  (axios: AxiosInstance) => (id: number) =>
    axios
      .get<AbdominalAuscultationPhysicalExaminationDTO>(
        `api/examinations/${id}/abdominalAuscultation`,
      )
      .then(flatten);

export const fetchSkinExaminationResults =
  (axios: AxiosInstance) => (id: number) =>
    axios.get<SkinExaminationDTO>(`api/examinations/${id}/skin`).then(flatten);

export const fetchThroatExaminationResults =
  (axios: AxiosInstance) => (id: number) =>
    axios
      .get<ThroatExaminationDTO>(`api/examinations/${id}/throat`)
      .then(flatten);

export const fetchRightEarExaminationResults =
  (axios: AxiosInstance) => (id: number) =>
    axios.get<EarResultDTO>(`api/examinations/${id}/rightEar`).then(flatten);

export const fetchLeftEarExaminationResults =
  (axios: AxiosInstance) => (id: number) =>
    axios.get<EarResultDTO>(`api/examinations/${id}/leftEar`).then(flatten);
