import styled, { css } from 'styled-components';
import { CellProps, SortDirection, TRowEntity } from 'react-table';
import { format, isValid } from 'date-fns';
import { groupBy } from 'lodash';
import { TableSortAsc } from '@higo/ui/src/components/Table/utils';

type TimeMarker = {
  date: Date;
  format: 'EEEE dd/MM' | 'HH:mm';
} | null;

export type TimeMarkerCellRendererValue = TimeMarker;

const roundToNearestHour = (date: Date, ascending?: boolean) => {
  const roundedDate = new Date(date);

  const fullHours = date.getHours() + Math.ceil(date.getMinutes() / 60);
  if (fullHours !== 24) {
    roundedDate.setHours(fullHours);
  }
  if (ascending) {
    roundedDate.setHours(fullHours - 1);
  }
  roundedDate.setMinutes(0, 0, 0);

  return roundedDate;
};

export const getTimeMarkers = ({
  timestamps,
  sortDirection,
}: {
  timestamps: Date[];
  sortDirection: SortDirection;
}): TimeMarker[] => {
  const pageTimeStamps = timestamps.filter(isValid);

  const groupedDaysWithHours = groupBy(pageTimeStamps, (timestamp) =>
    format(roundToNearestHour(timestamp), 'dd/MM/yyyy HH'),
  );

  const isSortedAscending = sortDirection === TableSortAsc;

  const markers: TimeMarker[] = [];

  Object.keys(groupedDaysWithHours).forEach((group, groupIndex, groupArray) => {
    const daysArray = groupArray.map(
      (dayWithHour) => dayWithHour.split(' ')[0],
    );

    const isPreviousGroupSameDay =
      daysArray[groupIndex - 1] === daysArray[groupIndex];

    groupedDaysWithHours[group].forEach((timestamp, index) => {
      let marker: TimeMarker = null;

      if (index === 0) {
        marker = {
          date: roundToNearestHour(timestamp, isSortedAscending),
          format: 'EEEE dd/MM',
        };

        if (isPreviousGroupSameDay) {
          marker.format = 'HH:mm';
        }
      }

      markers.push(marker);
    });
  });

  return markers;
};

const Marker = styled('span')(
  ({ theme }) => css`
    position: absolute;
    top: -${theme.fontSize.xs};
    left: 0;

    font-family: ${theme.typography.fontFamily};
    font-weight: 600;
    font-size: ${theme.fontSize.xs};
    line-height: 1;
    color: ${theme.palette.primary['600']};

    // break every word to a new line
    display: inline-block;
    max-width: min-content;
    white-space: initial;
  `,
);

export const TimeMarkerCellRenderer = <T extends TRowEntity>({
  cell,
}: CellProps<T, TimeMarkerCellRendererValue>) => {
  const dateIdName =
    cell &&
    cell.value &&
    cell.value.date.toString().slice(0, 24).toLowerCase().replace(/\s+/g, '-');
  if (cell.value?.date && cell.value?.format) {
    return (
      <Marker id={`date-${dateIdName}`}>
        {format(cell.value.date, cell.value.format)}
      </Marker>
    );
  }

  return null;
};
