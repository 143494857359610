import { createContext, FC, useContext } from 'react';
import { AxiosInstance } from 'axios';

export interface DoctorPortalApiContext {
  baseAxios: AxiosInstance;
  authorizedAxios: AxiosInstance;
}

const Context = createContext<DoctorPortalApiContext | undefined>(undefined);

export const DoctorPortalApiContextProvider: FC<{
  value: DoctorPortalApiContext;
}> = ({ value, children }) => (
  <Context.Provider value={value}>{children}</Context.Provider>
);

export const useDoctorPortalApiContext = () => {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('ApiContext not provided');
  }

  return ctx;
};
