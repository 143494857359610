import { ExaminationType } from './examinationDetailsDTO';
import { evolve } from 'rambda';
import { parseJSON } from 'date-fns';

export enum ExaminationDTOStatus {
  STA = 'STA', // STARTED
  PDA = 'PDA', // PATIENT_DRAFT
  INQ = 'INQ', // IN_QUEUE
  PLA = 'PLA', // PLANNED
  TAK = 'TAK', // TAKEN_BY
  DIA = 'DIA', // DIAGNOSED
  DRA = 'DRA', // DIAGNOSE_DRAFT
  ACN = 'ACN', // ACTION_NEED
  CLO = 'CLO', // CLOSED
  CAN = 'CAN', // CANCELED
  ARC = 'ARC', // ARCHIVED
  INT = 'INT', // INTERVENTION
  DIM = 'DIM', // DIAGNOSIS_IMPOSSIBLE
}

export interface ExaminationDTO {
  anamnesisPatientSurveyId: number;
  contextPhotoFileId: number;
  contextVideoFileId: number;
  createDate: string;
  diagnosisId: number;
  doctorId: number;
  generalPatientSurveyId: number;
  id: number;
  patientId: number;
  physicalExaminationId: number;
  sessionEndTime?: string;
  status: ExaminationDTOStatus;
  takenDate?: Date;
  type: ExaminationType;
}

export interface Examination
  extends Omit<ExaminationDTO, 'createDate' | 'sessionEndTime' | 'takenDate'> {
  createDate: Date;
  sessionEndTime?: Date;
  takenDate?: Date;
}

export const mapToExamination: (dto: ExaminationDTO) => Examination = evolve({
  createDate: parseJSON,
  sessionEndTime: parseJSON,
  takenDate: parseJSON,
});
