import styled, { css } from 'styled-components';
import BadgeUnstyled, {
  badgeUnstyledClasses,
  BadgeUnstyledProps,
} from '@mui/base/BadgeUnstyled';
import { FC, forwardRef } from 'react';
import { StyleProps } from '@summer/jst-react';

export interface BadgeProps extends BadgeUnstyledProps, StyleProps {
  count: number;
}

const StyledBadge = styled(BadgeUnstyled)(
  ({ theme }) => css`
    position: relative;
    display: inline-flex;
    margin: 0 0.5rem;

    & .${badgeUnstyledClasses.badge} {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      transform-origin: 100% 0;
      min-width: 1rem;
      height: 1rem;

      padding: 0 0.25rem;
      font-size: ${theme.fontSize.xs};
      font-weight: 700;
      line-height: 1rem;
      white-space: nowrap;
      text-align: center;

      color: ${theme.palette.white};
      background: ${theme.palette.error};
      border-radius: 0.625rem;
      opacity: 1;
      transition: ${theme.transitions.create('opacity')};
    }

    & .${badgeUnstyledClasses.invisible} {
      opacity: 0;
      pointer-events: none;
    }
  `,
);

export const Badge: FC<BadgeProps> = forwardRef<HTMLElement, BadgeProps>(
  ({ count, children, ...props }, ref) => {
    return (
      <StyledBadge badgeContent={count} {...props} ref={ref}>
        {children}
      </StyledBadge>
    );
  },
);

Badge.displayName = 'Badge';
