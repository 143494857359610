import { useQuery } from 'react-query';
import { fetchThroatExaminationResults } from '@higo/api/lib/services/physicalExaminationService';
import { useExaminationApiContext } from '@higo/common/src/features/ExaminationPage/providers';
import { ExaminationQueryKey } from '@higo/common/src/features/ExaminationPage/ExaminationQueryKey';
import { ThroatExaminationDTO } from '@higo/api/lib/models/examinationResultsDTO';

export const useThroatExaminationResultsQuery = <TData = ThroatExaminationDTO>(
  id: number,
  select?: (data: ThroatExaminationDTO) => TData,
) => {
  const axios = useExaminationApiContext();

  return useQuery(
    [ExaminationQueryKey.ThroatExaminationResults, id],
    async () => {
      const data = await await fetchThroatExaminationResults(axios)(id);
      return data;
    },
    {
      enabled: !isNaN(id),
      select,
    },
  );
};
