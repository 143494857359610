import { FC, memo, useMemo } from 'react';
import { Badge, Menu, MenuButton, PatientsIcon, useMenu } from '@higo/ui';
import { useNewExaminations } from 'store/examinationsStore';
import { Link } from 'react-router-dom';
import { getDashboardChildRoute, routes } from 'routes';
import { FormattedMessage } from 'react-intl';
import { HeaderNewExaminationsNotificationsMenuItem } from './HeaderNewExaminationsNotificationsMenuItem';
import { HeaderNotificationMenuFooterMenuItem } from './HeaderNotificationMenuFooterMenuItem';
import { HeaderNotificationMenuHeaderMenuItem } from './HeaderNotificationMenuHeaderMenuItem';
import { HeaderNotificationMenuItem } from 'components/Header/HeaderNotificationMenu/HeaderNotificationMenuItem';
import { isEmpty } from 'rambda';
import { useQueryClient } from 'react-query';
import { DoctorQueryKey } from 'config/doctorQueryKey';

export const HeaderExaminationsNotificationsMenu: FC = memo(() => {
  const { isOpened, anchorEl, toggle, close } = useMenu();
  const newExaminations = useNewExaminations();
  const newExaminationsSlice = useMemo(
    () => newExaminations.slice(0, 2),
    [newExaminations],
  );
  const queryClient = useQueryClient();

  const handleClick = () =>
    window.location.pathname.includes('dashboard/unassigned')
      ? window.location.reload()
      : queryClient.invalidateQueries(
          DoctorQueryKey.UnassignedExaminationsList,
        );

  return (
    <>
      <MenuButton
        id="exams-button"
        menuId="exams-menu"
        isMenuOpened={isOpened}
        onClick={toggle}
      >
        <Badge count={newExaminations.length}>
          <PatientsIcon size={18} />
        </Badge>
      </MenuButton>

      <Menu id="exams-menu" anchorEl={anchorEl} open={isOpened} onClose={close}>
        <HeaderNotificationMenuHeaderMenuItem>
          <div id="exams-notification-title">
            <FormattedMessage id="header.notifications.examinations.title" />
          </div>
        </HeaderNotificationMenuHeaderMenuItem>

        {isEmpty(newExaminationsSlice) && (
          <HeaderNotificationMenuItem disabled id="exams-empty-info">
            <FormattedMessage id="header.notifications.examinations.empty" />
          </HeaderNotificationMenuItem>
        )}

        {newExaminationsSlice.map((examinationId) => (
          <HeaderNewExaminationsNotificationsMenuItem
            key={examinationId}
            examinationId={examinationId}
            id={`exam-${examinationId}-title`}
          />
        ))}

        <HeaderNotificationMenuFooterMenuItem>
          <Link
            to={getDashboardChildRoute(routes.dashboard.unassigned)}
            state={{ prevPath: 'seeAll' }}
            id="see-all-exam-link"
            onClick={handleClick}
          >
            <FormattedMessage id="header.notifications.seeAll" />
          </Link>
        </HeaderNotificationMenuFooterMenuItem>
      </Menu>
    </>
  );
});
