import {
  SwitchUnstyled,
  switchUnstyledClasses,
  SwitchUnstyledOwnerState,
  SwitchUnstyledProps,
} from '@mui/base';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { SwitchUnstyledComponentsPropsOverrides } from '@mui/base/SwitchUnstyled/SwitchUnstyled';
import clsx from 'clsx';

export interface CheckboxProps extends SwitchUnstyledProps {
  indeterminate?: boolean;
}

export const checkboxClasses = {
  ...switchUnstyledClasses,
  indeterminate: 'x-indeterminate',
};

const Root = styled('span')(
  ({ theme }) => css`
    position: relative;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    margin: ${theme.spacing};
    font-size: 0;
    cursor: pointer;
    border-radius: 0.25rem;
    padding: 0.0625rem;
    background-color: ${theme.palette.white};

    border: 1px solid ${theme.palette.secondary['200']};
    color: transparent;

    &.${checkboxClasses.disabled} {
      cursor: not-allowed;
      border-color: ${theme.palette.secondary['100']};
    }

    &:hover {
      border-color: ${theme.palette.primary['500']};
    }

    &.${checkboxClasses.focusVisible} {
      border-color: ${theme.palette.primary['600']};
    }

    &:active {
      border-color: ${theme.palette.primary['600']};
      color: ${theme.palette.primary['600']};
    }

    &.${checkboxClasses.checked}, &.${checkboxClasses.indeterminate} {
      color: ${theme.palette.primary['500']};
      border-color: ${theme.palette.primary['500']};

      &.${checkboxClasses.disabled} {
        border-color: ${theme.palette.primary['200']};
        color: ${theme.palette.primary['200']};
      }

      // &:hover {
      //    hover not provided in designs
      // }

      &.${checkboxClasses.focusVisible} {
        border-color: ${theme.palette.primary['600']};
      }

      &:active {
        border-color: ${theme.palette.primary['600']};
        color: ${theme.palette.primary['600']};
      }
    }
  `,
);

const Input = styled('input')`
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
`;

const IconWrapper = styled.svg`
  fill: currentColor;
  display: inline-block;
`;

const Thumb: FC<
  SwitchUnstyledComponentsPropsOverrides & {
    ownerState: SwitchUnstyledOwnerState;
  }
> = ({ indeterminate, ownerState: { checked } }) => (
  <IconWrapper viewBox="0 0 12 12">
    {checked && (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Zm1.53 6.834a.75.75 0 0 1 1.061-1.061l1.121 1.121 2.713-2.712a.75.75 0 0 1 1.06 1.06L6.303 8.426a.748.748 0 0 1-.59.217.748.748 0 0 1-.592-.217l-1.59-1.591Z"
      />
    )}
    {!checked && indeterminate && (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2Zm2 5a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2H4Z"
      />
    )}
  </IconWrapper>
);

export const Checkbox: FC<CheckboxProps> = ({
  indeterminate = false,
  className,
  ...props
}) => {
  return (
    <SwitchUnstyled
      components={{ Root, Thumb, Input }}
      // todo: fix typing
      // @ts-ignore
      componentsProps={{ thumb: { indeterminate } }}
      className={clsx(
        className,
        indeterminate && checkboxClasses.indeterminate,
      )}
      {...props}
    />
  );
};
