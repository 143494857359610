import { FC, memo, ReactNode, useState } from 'react';
import {
  ExaminationPageSectionId,
  IsPhysicalExaminationCompletedFn,
} from '../../models';
import { ExaminationPageSection } from '../ExaminationPageSection';
import { FormattedMessage } from 'react-intl';

import styled, { css } from 'styled-components';
import {
  useLeftEarExaminationResultsQuery,
  useRightEarExaminationResultsQuery,
} from '@higo/common/src/features/ExaminationPage/hooks/queries';
import {
  useLeftEarExaminationGalleryResultsLinks,
  useLeftEarExaminationVideoFramesIds,
  useLeftEarExaminationVideoResultsLink,
  useRightEarExaminationGalleryResultsLinks,
  useRightEarExaminationVideoFramesIds,
  useRightEarExaminationVideoResultsLink,
} from '@higo/common/src/features/ExaminationPage/sections/EarsExaminationSection/hooks';
import { EarExaminationOmitted } from '@higo/common/src/features/ExaminationPage/sections/EarsExaminationSection/EarExaminationOmitted';
import { PhysicalExaminationType } from '@higo/api';
import { VisualExaminationDiagnosticViewerModel } from '@higo/common/src/features/ExaminationPage/components/VisualExaminationDiagnosticViewer/VisualExaminationDiagnosticViewerProps';
import { VisualExaminationDiagnosticViewerForPublic } from '@higo/common/src/features/ExaminationPage/components/VisualExaminationDiagnosticViewer';
import { WithTogglingViewer } from '@higo/common/src/features/ExaminationPage/components/VisualExaminationDiagnosticViewer/WithTogglingViewer';

const Container = styled('div')(
  ({ theme }) => css`
    display: grid;
    grid-gap: 2rem;
    grid-auto-flow: row;
    grid-template-columns: minmax(21.875rem, 1fr);

    ${theme.media.tablet.min} {
      grid-template-columns: minmax(12.5rem, 1fr) minmax(12.5rem, 1fr);
      aspect-ratio: 2/1;
    }

    @media print {
      grid-template-columns: 1fr 1fr;
    }
  `,
);

const EarExaminationHeader = styled('h5')(
  ({ theme }) => css`
    margin: 0 0 1rem 2rem;
    ${theme.typography.subtitle1};
    color: ${theme.palette.secondary[500]};
  `,
);

export interface EarsExaminationSectionProps {
  examinationId: number;
  isPhysicalExaminationCompletedFn: IsPhysicalExaminationCompletedFn;
  requestGenerateLeftEarExaminationVideoButtonSlot?: ReactNode;
  requestGenerateRightEarExaminationVideoButtonSlot?: ReactNode;
  hasTogglingGalleryVideo?: boolean;
  handleRightEarImageForPDFChange?: (index: string) => void;
  handleIsRightEarImageDiagnosticChange?: (index: boolean) => void;
  handleLeftEarImageForPDFChange?: (index: string) => void;
  handleIsLeftEarImageDiagnosticChange?: (index: boolean) => void;
  handleIsRightEarDataLoadingChange?: (index: boolean) => void;
  handleIsLeftEarDataLoadingChange?: (index: boolean) => void;
}

const leftEarDiagnosticModel: VisualExaminationDiagnosticViewerModel = {
  usePhysicalExaminationResultsQuery: useLeftEarExaminationResultsQuery,
  usePhysicalExaminationGalleryResultsLinks:
    useLeftEarExaminationGalleryResultsLinks,
  usePhysicalExaminationVideoFramesIds: useLeftEarExaminationVideoFramesIds,
  usePhysicalExaminationVideoResultsLink: useLeftEarExaminationVideoResultsLink,
};

const rightEarDiagnosticModel: VisualExaminationDiagnosticViewerModel = {
  usePhysicalExaminationResultsQuery: useRightEarExaminationResultsQuery,
  usePhysicalExaminationGalleryResultsLinks:
    useRightEarExaminationGalleryResultsLinks,
  usePhysicalExaminationVideoFramesIds: useRightEarExaminationVideoFramesIds,
  usePhysicalExaminationVideoResultsLink:
    useRightEarExaminationVideoResultsLink,
};

export const EarsExaminationSection: FC<EarsExaminationSectionProps> = memo(
  ({
    examinationId,
    isPhysicalExaminationCompletedFn,
    requestGenerateLeftEarExaminationVideoButtonSlot,
    requestGenerateRightEarExaminationVideoButtonSlot,
    hasTogglingGalleryVideo,
    handleRightEarImageForPDFChange,
    handleIsRightEarImageDiagnosticChange,
    handleLeftEarImageForPDFChange,
    handleIsLeftEarImageDiagnosticChange,
    handleIsRightEarDataLoadingChange,
    handleIsLeftEarDataLoadingChange,
  }) => {
    const hasLeftEarExamination = isPhysicalExaminationCompletedFn(
      PhysicalExaminationType.LeftEar,
    );
    const hasRightEarExamination = isPhysicalExaminationCompletedFn(
      PhysicalExaminationType.RightEar,
    );

    const { data: RightEarData, isLoading: isRightDataLoading } =
      useRightEarExaminationResultsQuery(examinationId);
    const rowRightEarDataData = RightEarData?.earResults;
    const { data: rightEarGalleryResults, isLoading: isRightgalleryLoading } =
      rightEarDiagnosticModel.usePhysicalExaminationGalleryResultsLinks(
        examinationId,
      );

    const [rightEarGalleryActiveLink, setRightEarGalleryActiveLink] =
      useState('');

    const handleRightEarGalleryActiveLinkChange = (index: string) => {
      setRightEarGalleryActiveLink(index);
    };

    const diagnosticIndex = RightEarData && RightEarData.diagnosticImageIndex;

    const rightEarDiagnosticImageLink =
      RightEarData?.diagnosticImageIndex &&
      rightEarGalleryResults &&
      rightEarGalleryResults.find(
        (item) =>
          diagnosticIndex &&
          item.fileName === RightEarData.earResults[diagnosticIndex].filename,
      )?.link;

    const rightEarMiddleImageLink =
      RightEarData?.earResults &&
      rightEarGalleryResults &&
      rightEarGalleryResults[Math.floor(RightEarData?.earResults.length / 2)]
        ?.link;

    const rightEarImageForPDF =
      rightEarDiagnosticImageLink ||
      rightEarGalleryActiveLink ||
      rightEarMiddleImageLink;
    RightEarData &&
      rightEarImageForPDF &&
      handleRightEarImageForPDFChange &&
      handleRightEarImageForPDFChange(rightEarImageForPDF);
    RightEarData &&
      rightEarImageForPDF &&
      handleIsRightEarImageDiagnosticChange &&
      handleIsRightEarImageDiagnosticChange(RightEarData.hasDiagnosticImage);
    handleIsRightEarDataLoadingChange &&
      handleIsRightEarDataLoadingChange(
        isRightDataLoading || isRightgalleryLoading,
      );

    const { data: LeftEarData, isLoading: isLeftDataLoading } =
      useLeftEarExaminationResultsQuery(examinationId);
    const rowLeftEarDataData = LeftEarData?.earResults;
    const { data: leftEarGalleryResults, isLoading: isLeftGalleryLoading } =
      leftEarDiagnosticModel.usePhysicalExaminationGalleryResultsLinks(
        examinationId,
      );

    const [leftEarGalleryActiveLink, setLeftEarGalleryActiveLink] =
      useState('');

    const handleLeftEarGalleryActiveLinkChange = (index: string) => {
      setLeftEarGalleryActiveLink(index);
    };

    const diagnosticIndexLeft = LeftEarData && LeftEarData.diagnosticImageIndex;

    const leftEarDiagnosticImageLink =
      LeftEarData?.diagnosticImageIndex &&
      leftEarGalleryResults &&
      leftEarGalleryResults.find(
        (item) =>
          diagnosticIndexLeft &&
          item.fileName ===
            LeftEarData.earResults[diagnosticIndexLeft].filename,
      )?.link;

    const leftEarMiddleImageLink =
      LeftEarData?.earResults &&
      leftEarGalleryResults &&
      leftEarGalleryResults[Math.floor(LeftEarData?.earResults.length / 2)]
        ?.link;

    const leftEarImageForPDF =
      leftEarDiagnosticImageLink ||
      leftEarGalleryActiveLink ||
      leftEarMiddleImageLink;
    LeftEarData &&
      leftEarImageForPDF &&
      handleLeftEarImageForPDFChange &&
      handleLeftEarImageForPDFChange(leftEarImageForPDF);
    LeftEarData &&
      leftEarImageForPDF &&
      handleIsLeftEarImageDiagnosticChange &&
      handleIsLeftEarImageDiagnosticChange(LeftEarData.hasDiagnosticImage);
    handleIsLeftEarDataLoadingChange &&
      handleIsLeftEarDataLoadingChange(
        isLeftDataLoading || isLeftGalleryLoading,
      );

    return (
      <ExaminationPageSection
        title={
          <FormattedMessage id="examinationPage.section.earsExamination.title" />
        }
        sectionId={ExaminationPageSectionId.Ears}
      >
        <Container>
          <div id="ears-section-container">
            <EarExaminationHeader id="left-ear-header">
              <FormattedMessage id="common.left" />
            </EarExaminationHeader>
            {hasLeftEarExamination && hasTogglingGalleryVideo && (
              <WithTogglingViewer
                examinationId={examinationId}
                model={leftEarDiagnosticModel}
                requestGenerateVideoButtonSlot={
                  requestGenerateLeftEarExaminationVideoButtonSlot
                }
                rowData={rowLeftEarDataData}
                setActiveGalleryLink={handleLeftEarGalleryActiveLinkChange}
              />
            )}
            {hasLeftEarExamination && !hasTogglingGalleryVideo && (
              <VisualExaminationDiagnosticViewerForPublic
                examinationId={examinationId}
                model={leftEarDiagnosticModel}
                rowData={rowLeftEarDataData}
              />
            )}
            {!hasLeftEarExamination && <EarExaminationOmitted />}
          </div>

          <div>
            <EarExaminationHeader id="right-ear-header">
              <FormattedMessage id="common.right" />
            </EarExaminationHeader>
            {hasRightEarExamination && hasTogglingGalleryVideo && (
              <WithTogglingViewer
                examinationId={examinationId}
                model={rightEarDiagnosticModel}
                requestGenerateVideoButtonSlot={
                  requestGenerateRightEarExaminationVideoButtonSlot
                }
                rowData={rowRightEarDataData}
                setActiveGalleryLink={handleRightEarGalleryActiveLinkChange}
              />
            )}

            {hasRightEarExamination && !hasTogglingGalleryVideo && (
              <VisualExaminationDiagnosticViewerForPublic
                examinationId={examinationId}
                model={rightEarDiagnosticModel}
                rowData={rowRightEarDataData}
              />
            )}
            {!hasRightEarExamination && <EarExaminationOmitted />}
          </div>
        </Container>
      </ExaminationPageSection>
    );
  },
);
