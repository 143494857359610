import { CellProps } from 'react-table';
import { ChatModal } from 'components/ChatModal';
import { MessagesListRow } from 'pages/MessagesListPage/config';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
`;

export type MessagesListActionsCellRendererProps = {
  chatModal: {
    examinationId: number;
    patient: {
      id: number;
      firstName: string;
      lastName: string;
    };
  };
};

export const MessagesListActionsCellRenderer = ({
  cell,
}: CellProps<MessagesListRow, MessagesListActionsCellRendererProps>) => (
  <Container>
    <ChatModal
      examinationId={cell.value.chatModal.examinationId}
      patient={cell.value.chatModal.patient}
    />
  </Container>
);
