import { IconBox, IconProps } from '../IconBox';

const AssignIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5999 10.8001C11.5881 10.8001 13.1999 9.18832 13.1999 7.2001C13.1999 5.21188 11.5881 3.6001 9.5999 3.6001C7.61168 3.6001 5.9999 5.21188 5.9999 7.2001C5.9999 9.18832 7.61168 10.8001 9.5999 10.8001ZM9.5999 13.2001C13.5763 13.2001 16.7999 16.4237 16.7999 20.4001H2.3999C2.3999 16.4237 5.62345 13.2001 9.5999 13.2001ZM19.1999 8.4001C19.1999 7.73736 18.6627 7.2001 17.9999 7.2001C17.3371 7.2001 16.7999 7.73736 16.7999 8.4001V9.6001H15.5999C14.9371 9.6001 14.3999 10.1374 14.3999 10.8001C14.3999 11.4628 14.9371 12.0001 15.5999 12.0001H16.7999V13.2001C16.7999 13.8629 17.3371 14.4001 17.9999 14.4001C18.6627 14.4001 19.1999 13.8629 19.1999 13.2001V12.0001H20.3999C21.0627 12.0001 21.5999 11.4628 21.5999 10.8001C21.5999 10.1374 21.0627 9.6001 20.3999 9.6001H19.1999V8.4001Z"
    />
  </IconBox>
);

export default AssignIcon;
