import styled, { css } from 'styled-components';
import { cloneElement, forwardRef, ReactElement } from 'react';
import ChevronIcon from '../icons/ChevronIcon';
import { IconProps } from '../IconBox';
import { BaseButton, BaseButtonProps } from './BaseButton';
import { buttonUnstyledClasses } from '@mui/base';
import LoaderIcon from '../icons/LoaderIcon';

export const buttonClasses = {
  ...buttonUnstyledClasses,
};
const StyledButton = styled(BaseButton)<
  ButtonProps & { $autoWidth: boolean; $fullWidth: boolean }
>(
  ({ theme, $autoWidth, $fullWidth }) =>
    css`
      justify-content: space-between;
      gap: 1.5rem;

      transition: ${theme.transitions.create(['padding'], {
        duration: theme.transitions.duration.standard,
      })};

      ${$fullWidth ? 'width: 100%' : $autoWidth ? 'auto' : 'width: 14.5625rem'};

      ${$autoWidth && 'white-space: nowrap'};
    `,
);

const Content = styled('div')(
  ({ theme }) => css`
    transition: ${theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,
    })};

    ${StyledButton}:not(.${buttonUnstyledClasses.disabled}):hover & {
      transform: translateX(0.5rem);
    }
  `,
);

const IconContainer = styled('div')(
  ({ theme }) => css`
    transition: ${theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.standard,
    })};

    ${StyledButton}:not(.${buttonUnstyledClasses.disabled}):hover & {
      transform: translateX(-0.5rem);
    }
  `,
);

export type ButtonProps = {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  as?: any; //todo fix this
  endIcon?: ReactElement<IconProps>;
  isLoading?: boolean;
  fullWidth?: boolean;
  autoWidth?: boolean;
} & BaseButtonProps;

export const Button = forwardRef<HTMLButtonElement | null, ButtonProps>(
  (
    {
      children,
      isLoading = false,
      endIcon = <ChevronIcon direction="right" />,
      fullWidth = false,
      autoWidth = false,
      ...props
    },
    ref,
  ) => (
    <StyledButton
      {...props}
      ref={ref}
      $fullWidth={fullWidth}
      $autoWidth={autoWidth}
    >
      <Content>{children}</Content>
      <IconContainer>
        {!isLoading &&
          endIcon &&
          cloneElement(endIcon, { ...endIcon.props, size: 16 })}
        {isLoading && <LoaderIcon size={16} />}
      </IconContainer>
    </StyledButton>
  ),
);

Button.displayName = 'Button';
