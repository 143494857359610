import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
// import higoLogoFull from '@higo/ui/src/assets/icon/full-logo.svg';
import { LogoIcon } from '@higo/ui';

const LogoLink = styled(Link)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-right: 1rem;
    color: ${theme.palette.primary['500']};
    ${theme.media.desktop.min} {
      margin-right: 1.5rem;
    }
  `,
);

// const LogoImage = styled('img')(
//   ({ theme }) => css`
//     ${theme.media.desktop.max} {
//       display: none;
//     }
//   `,
// );

const StyledLogoIcon = styled(LogoIcon)(() => css``);

export const HeaderLogo = () => {
  return (
    <LogoLink id="higo-logo" to="/dashboard">
      {/*todo: fix logo*/}
      {/*<LogoImage src={higoLogoFull} />*/}
      <StyledLogoIcon size={38} />
    </LogoLink>
  );
};
