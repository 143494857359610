import { useEffect } from 'react';
import { useExaminationsStoreActions } from 'store/examinationsStore';

export const useRemoveNewExaminationOnRender = (examinationId: number) => {
  const { removeNewExamination } = useExaminationsStoreActions();

  useEffect(() => {
    removeNewExamination(examinationId);
  }, [removeNewExamination, examinationId]);
};
