import {
  FabButton,
  FabButtonProps,
} from '@higo/ui/src/components/buttons/FabButton';
import { forwardRef } from 'react';

//todo: clean up
export const SelectTriggerButton = FabButton;

// todo: add error support
export type RHFSelectTriggerButtonProps = FabButtonProps & {
  error: boolean;
  clearValue?: () => void;
  ownerState: unknown; // workaround
};

export const RHFSelectTriggerButton = forwardRef<
  HTMLButtonElement,
  RHFSelectTriggerButtonProps
>(({ error, clearValue, ownerState, ...props }, ref) => (
  <FabButton ref={ref} {...props} />
));
