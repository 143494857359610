import styled, { css } from 'styled-components';
import { useFormControlUnstyledContext } from '@mui/base/FormControlUnstyled';
import { FC } from 'react';
import composeClasses from '@mui/base/composeClasses';
import clsx from 'clsx';
import { StyleProps } from '@summer/jst-react';
import {
  formLabelClasses,
  getFormLabelUtilityClasses,
} from '@higo/ui/src/components/FormLabel/formLabelClasses';
import { useFormControlState } from '@higo/ui/src/components/FormControl';

/**
 * Might require some refactoring related to MUI state / styling system
 * @param ownerState
 */
const useUtilityClasses = (ownerState: Record<string, string | boolean>) => {
  const { size, disabled } = ownerState;
  const slots = {
    root: [
      'root',
      disabled && 'disabled',
      size && 'small' === size && 'sizeSmall',
    ],
  };

  return composeClasses(slots, getFormLabelUtilityClasses, undefined);
};

const FormLabelRoot = styled('label')(
  ({ theme }) => css`
    font-family: ${theme.typography.fontFamily};
    font-size: 0.8125rem;
    font-weight: 700;
    line-height: 1.25rem;

    display: block;
    margin-bottom: 0.5rem;

    color: ${theme.formLabel.default.primary};
    text-transform: capitalize;

    &.${formLabelClasses.sizeSmall} {
      font-weight: 400;
    }

    &.${formLabelClasses.disabled} {
      color: ${theme.formLabel.disabled.primary};
    }
  `,
);

const Asterisk = styled.span`
  vertical-align: super;
`;

export type FormLabelProps = {
  size?: 'small' | 'large'; // todo: requires fixing ( some sort unification)
  id?: string;
} & StyleProps;

export const FormLabel: FC<FormLabelProps> = ({
  children,
  className,
  id,
  ...props
}) => {
  const formControlContext = useFormControlUnstyledContext();

  const fcs = useFormControlState({
    props,
    formControlContext,
    states: ['size', 'disabled'],
  });

  const ownerState = {
    disabled: fcs.disabled,
    size: fcs.size,
  };

  const classes = useUtilityClasses(ownerState);

  return (
    <FormLabelRoot className={clsx(classes.root, className)} id={id} {...props}>
      {children}
      {formControlContext?.required ? <Asterisk>*</Asterisk> : ''}
    </FormLabelRoot>
  );
};
