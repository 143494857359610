import { FC, useMemo } from 'react';
import { AuscultationGroup } from '@higo/common/src/components';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ExaminationPageSection } from '@higo/common/src/features/ExaminationPage/sections/ExaminationPageSection';
import { ExaminationPageSectionId } from '@higo/common/src/features/ExaminationPage/models/examinationPageSectionId';
import {
  useCoughAuscultationPhysicalExaminationQuery,
  useCoughAuscultationPhysicalExaminationResourceFiles,
} from '@higo/common/src/features/ExaminationPage/hooks/queries';
import { buildAuscultationPoints } from '@higo/common/src/features/ExaminationPage/utils/buildAuscultationPoints';
import { auscultationPointsCount } from '@higo/common/src/config/auscultationPointsCount';
import { PhysicalExaminationType } from '@higo/api';

const AuscultationGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export interface CoughAuscultationSectionProps {
  examinationId: number;
}

export const CoughAuscultationSection: FC<CoughAuscultationSectionProps> = ({
  examinationId,
}) => {
  const { data } = useCoughAuscultationPhysicalExaminationQuery(examinationId);
  const { data: fileResourceMap } =
    useCoughAuscultationPhysicalExaminationResourceFiles(examinationId);

  const auscultationPoints = useMemo(
    () =>
      buildAuscultationPoints(
        data?.coughResults ?? [],
        fileResourceMap ?? {},
        auscultationPointsCount[PhysicalExaminationType.Cough],
      ),
    [data?.coughResults, fileResourceMap],
  );

  const filesIds = useMemo(
    () => auscultationPoints.filter((x) => x.file).map((x) => x.file?.fileId),
    [auscultationPoints],
  );

  return (
    <ExaminationPageSection
      title={
        <FormattedMessage id="examinationPage.section.coughExamination.title" />
      }
      sectionId={ExaminationPageSectionId.Cough}
      audioDownload="single"
      filesIds={filesIds}
    >
      <AuscultationGroupContainer>
        <AuscultationGroup auscultationPoints={auscultationPoints} />
      </AuscultationGroupContainer>
    </ExaminationPageSection>
  );
};
