import {
  SwitchUnstyled,
  switchUnstyledClasses,
  SwitchUnstyledProps,
} from '@mui/base';
import { FC } from 'react';
import styled, { css } from 'styled-components';

const Root = styled('span')(
  ({ theme }) => css`
    position: relative;
    display: inline-block;
    width: 1.6875rem;
    height: 1rem;
    margin: ${theme.spacing};
    border-radius: 0.625rem;
    font-size: 0;
    cursor: pointer;
    background-color: ${theme.palette.secondary['200']};

    &.${switchUnstyledClasses.disabled} {
      cursor: not-allowed;
      background-color: ${theme.palette.secondary['100']};
    }

    // &:hover {
    //    hover not provided in designs
    // }

    &.${switchUnstyledClasses.focusVisible} {
      outline: 1px solid ${theme.palette.primary['600']};
    }

    &:active {
      background-color: ${theme.palette.secondary['300']};
    }

    &.${switchUnstyledClasses.checked} {
      background-color: ${theme.palette.primary['500']};

      &.${switchUnstyledClasses.disabled} {
        background-color: ${theme.palette.primary['200']};
      }

      // &:hover {
      //    hover not provided in designs
      // }

      &.${switchUnstyledClasses.focusVisible} {
        outline: 1px solid ${theme.palette.primary['600']};
      }

      &:active {
        background-color: ${theme.palette.primary['600']};
      }
    }
  `,
);

const Thumb = styled('span')(
  ({ theme }) => css`
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    position: relative;
    transition: ${theme.transitions.create(['left'], {
      duration: theme.transitions.duration.shortest,
    })};
    background-color: ${theme.palette.white};

    ${Root}.${switchUnstyledClasses.checked} & {
      background-color: ${theme.palette.white};
      left: 13px;
    }
  `,
);

const Input = styled('input')`
  cursor: inherit;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
`;

export const Switch: FC<SwitchUnstyledProps> = (props) => {
  return <SwitchUnstyled components={{ Root, Thumb, Input }} {...props} />;
};
