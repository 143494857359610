import React from 'react';
import { Skeleton } from '@higo/ui';

import styled from 'styled-components';
import { InfoBarListContainer } from './InfoBarListContainer';
import { InfoBarListItem } from './InfoBarListItem';

const SkeletonListItem = styled(InfoBarListItem)`
  height: 1.5rem;
`;

export const ExaminationPageInfoBarSkeleton = () => {
  return (
    <InfoBarListContainer>
      <SkeletonListItem>
        <Skeleton style={{ width: '3.375rem' }} />
      </SkeletonListItem>
      <SkeletonListItem>
        <Skeleton style={{ width: '8.125rem' }} />
      </SkeletonListItem>
      <SkeletonListItem>
        <Skeleton style={{ width: '13.65rem' }} />
      </SkeletonListItem>
      <SkeletonListItem>
        <Skeleton style={{ width: '6rem' }} />
      </SkeletonListItem>
      <SkeletonListItem>
        <Skeleton style={{ width: '12rem' }} />
      </SkeletonListItem>
    </InfoBarListContainer>
  );
};
