import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import {
  MultiSelectFieldProps,
  RHFMultiSelectField,
} from '@higo/ui/src/components/Select';
import { SelectOption, TSelectValue } from '@mui/base/SelectUnstyled';
import { useMemo } from 'react';
import { isEmpty } from 'rambda';

interface ExhaustiveSelectOption<T> extends SelectOption<T> {
  exhaustive: boolean;
}

export type RHFExhaustiveSelectFieldProps<T, K> = UseControllerProps<T> &
  Omit<MultiSelectFieldProps<K>, 'options' | 'children'> & {
    options: ExhaustiveSelectOption<K>[];
  };

export const RHFExhaustiveMultiSelectField = <
  TFieldValues extends FieldValues,
  K extends TSelectValue,
>({
  name,
  control,
  options,
  ...props
}: RHFExhaustiveSelectFieldProps<TFieldValues, K>) => {
  const {
    field: { value },
  } = useController({
    name,
    control,
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    defaultValue: [] as any, // todo: fixme
  });

  const exhaustiveOptionsSet = useMemo(() => {
    return new Set(
      options
        .filter(({ exhaustive }) => exhaustive ?? false)
        .map(({ value }) => value),
    );
  }, [options]);

  const availableOptions = useMemo(() => {
    const selectedExhaustive = value
      .filter((x: K) => exhaustiveOptionsSet.has(x))
      .at(0);

    const anyNonExhaustiveSelected = !selectedExhaustive && !isEmpty(value);

    return options.map(({ value, label }) => ({
      value,
      label,
      disabled:
        (selectedExhaustive && value !== selectedExhaustive) ||
        (anyNonExhaustiveSelected && exhaustiveOptionsSet.has(value)),
    }));
  }, [value, options, exhaustiveOptionsSet]);

  return (
    <RHFMultiSelectField
      name={name}
      control={control}
      options={availableOptions}
      {...props}
    />
  );
};
