import { IconBox, IconProps } from '../IconBox';

const LogoIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.23 6.823A1.835 1.835 0 0 0 7.386 5c-1.02 0-1.847.816-1.847 1.823v3.647H1.846C.826 10.47 0 11.286 0 12.293c0 1.008.827 1.824 1.846 1.824h3.692v3.646c0 1.007.827 1.824 1.847 1.824s1.846-.817 1.846-1.823v-3.647H12c1.02 0 1.846-.817 1.846-1.824A1.835 1.835 0 0 0 12 10.47H9.23V6.823Zm9.232 0A1.835 1.835 0 0 0 16.614 5c-1.02 0-1.846.816-1.846 1.823v10.94c0 1.007.827 1.824 1.846 1.824 1.02 0 1.847-.817 1.847-1.823v-3.647h3.692c1.02 0 1.846-.817 1.846-1.823a1.835 1.835 0 0 0-1.846-1.824h-3.692V6.823Z"
    />
  </IconBox>
);

export default LogoIcon;
