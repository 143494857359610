import { useMutation, useQueryClient } from 'react-query';
import { addExaminationNote } from '@higo/api/lib/services/examinationService';
import { useExaminationApiContext } from '@higo/common/src/features/ExaminationPage/providers';
import { ExaminationQueryKey } from '@higo/common/src/features/ExaminationPage/ExaminationQueryKey';

export const useAddExaminationNoteMutation = (id: number) => {
  const axiosInstance = useExaminationApiContext();

  const queryClient = useQueryClient();
  return useMutation(
    (noteText: string) => addExaminationNote(axiosInstance)(id, noteText),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([
          ExaminationQueryKey.ExaminationNotes,
          id,
        ]),
    },
  );
};
