import { createShareExaminationLink } from '@higo/api/lib/services/examinationService';
import { useMutation } from 'react-query';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';
import { DoctorMutationKey } from 'config/doctorMutationKey';

export const useCreateShareExaminationLinkMutation = () => {
  const { authorizedAxios } = useDoctorPortalApiContext();

  return useMutation(
    [DoctorMutationKey.ExaminationShareLink],
    (examinationId: number) =>
      createShareExaminationLink(authorizedAxios)(examinationId),
  );
};
