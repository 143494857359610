import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import {
  Heading,
  HorizontalDivider,
  Pagination,
  Table,
  usePagination,
} from '@higo/ui';
import { ConstraintContainer } from 'components/ConstraintContainer';
import { useMessagesQuery } from './useMessagesQuery';
import {
  mapToMessagesList,
  MessagesListRow,
  useMessagesListConfig,
} from 'pages/MessagesListPage/config';
import { FormattedMessage } from 'react-intl';
import { TableNoDataAvailable } from 'components/TableDataInfo';
import { useOnEventEmitterMessage } from 'contexts/EventEmitterProvider';
import { AppEvent } from 'types/appEvent';

const TotalElements = styled('sup')(
  ({ theme }) => css`
    color: ${theme.palette.secondary['500']};
    ${theme.typography.subtitle2}
  `,
);

export const MessagesListPage = () => {
  const config = useMessagesListConfig();
  const pagination = usePagination();

  const { data, isFetching, isError, refetch } = useMessagesQuery({
    page: pagination.page,
    pageSize: pagination.pageSize,
  });
  const tableData: MessagesListRow[] = useMemo(
    () => mapToMessagesList(data?.content ?? []),
    [data?.content],
  );

  useOnEventEmitterMessage(
    AppEvent.FcmNewComment,
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  return (
    <ConstraintContainer>
      <div id="messages-title">
        <Heading level={2}>
          Messages{' '}
          {data?.totalElements !== 0 && (
            <TotalElements id="total-elements">
              ({data?.totalElements})
            </TotalElements>
          )}
        </Heading>
      </div>
      <HorizontalDivider />
      {data?.totalElements === 0 ? (
        <TableNoDataAvailable title="No open chats" />
      ) : (
        <>
          <Table
            {...config}
            data={tableData}
            pageSize={pagination.pageSize}
            isLoading={isFetching || isError}
          />
          <Pagination
            {...pagination}
            totalPages={data?.totalPages ?? 0}
            currentPageLabelSlot={
              <FormattedMessage
                id="pagination.pageCurrentOfTotal"
                values={{
                  current: pagination.page,
                  total: data?.totalPages ?? 0,
                }}
              />
            }
            perPageLabel={<FormattedMessage id="pagination.messagesPerPage" />}
          />
        </>
      )}
    </ConstraintContainer>
  );
};
