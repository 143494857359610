import { useCallback, useState } from 'react';

export interface UsePaginationConfig {
  initialPage?: number;
  initialPageSize?: number;
}

export const usePagination = (config?: UsePaginationConfig) => {
  const [page, setPage] = useState(config?.initialPage ?? 1);
  const [pageSize, setPageSize] = useState(config?.initialPageSize ?? 10);

  const resetPage = useCallback(() => {
    setPage(1);
  }, []);

  return {
    page,
    pageSize,
    resetPage,
    goToPage: setPage,
    setPageSize,
  };
};
