import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useLogoutAutomatically } from 'hooks/useLogoutAutomatically';
import { useLocation } from 'react-router-dom';

export const useIdleDetector = (idleTime = 1200) => {
  const idleTimeout = 1000 * idleTime;
  const [isIdle, setIdle] = useState(false);
  const logout = useLogoutAutomatically();
  const location = useLocation();

  const handleIdle = () => {
    if (location.pathname !== '/') {
      setIdle(true);
      logout();
    }
  };
  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout: idleTimeout / 2,
    onIdle: handleIdle,
    debounce: 500,
  });
  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};
export default useIdleDetector;
