import { ApiQueryKey } from '@higo/api/lib/apiQueryKey';
import {
  DoctorPatientSearchResultDTO,
  PaginatedListReqDTO,
  PaginatedListResultDTO,
} from '@higo/api/lib/models';
import { useQuery, UseQueryOptions } from 'react-query';
import { fetchPatientsList } from '@higo/api/lib/services';
import { isNil } from 'rambda';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';

export const usePatientsListQuery = (
  searchText: string,
  data?: PaginatedListReqDTO,
  options?: Omit<
    UseQueryOptions<
      PaginatedListResultDTO<DoctorPatientSearchResultDTO>,
      unknown,
      PaginatedListResultDTO<DoctorPatientSearchResultDTO>,
      (PaginatedListReqDTO | ApiQueryKey | undefined)[]
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  const { authorizedAxios } = useDoctorPortalApiContext();

  return useQuery(
    // @ts-ignore
    [ApiQueryKey.PatientsListData, searchText, data],
    () => fetchPatientsList(authorizedAxios)(searchText, data),
    { enabled: !isNil(searchText), ...options },
  );
};
