import { MutableRefObject, useEffect, useMemo, useState } from 'react';
import { useLiveRef } from '@higo/ui/src/utils';

export const useObserveVisibleElements = (
  containerRef: MutableRefObject<HTMLElement | null>,
) => {
  const [items, setItems] = useState<Element[]>([]);
  const [visibleItems, setVisibleItems] = useState(new Set());
  const visibleItemsRef = useLiveRef(visibleItems);

  useEffect(() => {
    setItems(Array.from(containerRef.current?.children ?? []));

    const observer = new IntersectionObserver(
      (e) => {
        const visible = e
          .filter(({ isIntersecting }) => isIntersecting)
          .map((x) => x.target);

        const invisible = e
          .filter(({ isIntersecting }) => !isIntersecting)
          .map((x) => x.target);

        const newState = new Set([
          ...Array.from(visibleItemsRef.current),
          ...visible,
        ]);

        invisible.forEach((x) => newState.delete(x));
        setVisibleItems(newState);
      },
      {
        root: containerRef.current,
        threshold: 1,
      },
    );

    Array.from(containerRef.current?.children ?? []).forEach((item) => {
      observer.observe(item);
    });

    return () => {
      observer.disconnect();
      setVisibleItems(new Set());
    };
  }, [containerRef, visibleItemsRef]);

  return useMemo(() => {
    return {
      visibleItemsCount: visibleItems.size,
      invisibleItemsCount: items?.filter((x) => !visibleItems.has(x)).length,
    };
  }, [items, visibleItems]);
};
