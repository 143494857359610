//
// na wiek mamy coś takiego, i to dla pytań i dla odpowiedzi:
//   fun checkIfAgeMatchesConditions(age: Int): Boolean {
//   val matchesAgeConditionsList = ageConditionOptions.map {
//     when (it.operator) {
//       AgeConditionOptions.AgeConditionsOperator.LT.toString() -> age < it.age
//       AgeConditionOptions.AgeConditionsOperator.LE.toString() -> age <= it.age
//       AgeConditionOptions.AgeConditionsOperator.GT.toString() -> age > it.age
//       AgeConditionOptions.AgeConditionsOperator.GE.toString() -> age >= it.age
//     else -> false
//     }
//   }
//   return when(conjunction) {
//     Conjunction.AND.toString() -> matchesAgeConditionsList.reduce { acc, next -> acc && next }
//     Conjunction.OR.toString() -> matchesAgeConditionsList.reduce { acc, next -> acc || next }
//   else -> false
//   }
// }
import { isNil } from 'rambda';
import {
  SurveyAgeConditionOperator,
  SurveyAgeConditionsConjunction,
  SurveyAgeConditionsDTO,
} from '@higo/api/src/models';

const conditionOperatorFn: Record<
  SurveyAgeConditionOperator,
  (a: number, b: number) => boolean
> = {
  EQ: (a, b) => a === b,
  GE: (a, b) => a >= b,
  GT: (a, b) => a > b,
  LE: (a, b) => a <= b,
  LT: (a, b) => a < b,
  NE: (a, b) => a !== b,
};

export const createSurveyAgeConditionPredicate =
  (patientAge: number) => (ageConditions?: SurveyAgeConditionsDTO) => {
    if (isNil(ageConditions)) {
      return true;
    } else if (
      SurveyAgeConditionsConjunction.And === ageConditions.conjunction
    ) {
      return ageConditions.ageConditionOptions.every(({ age, operator }) =>
        conditionOperatorFn[operator](patientAge, age),
      );
    } else if (
      SurveyAgeConditionsConjunction.Or === ageConditions.conjunction
    ) {
      return ageConditions.ageConditionOptions.some(({ age, operator }) =>
        conditionOperatorFn[operator](patientAge, age),
      );
    }

    return false;
  };
