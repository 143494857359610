import {
  ChatIcon,
  IconButton,
  ManagedModal,
  ManagedModalProps,
} from '@higo/ui';
import React, { FC, memo, useCallback, useEffect } from 'react';
import { ChatWindow, ChatWindowProps } from './ChatWindow';
import styled from 'styled-components';
import { useDispatchDoctorTrackedType } from 'hooks/useDispatchDoctorTrackedEvent';
import {
  DoctorTrackedType,
  TrackedEventButtonClickAction,
} from '@higo/common/lib/features/TrackedEvent';

const StyledManagedModal = styled(ManagedModal)``;

export type ChatModalProps = {
  initialVisible?: boolean;
  triggerLess?: boolean;
  onClose?: ManagedModalProps['onClose'];
} & ChatWindowProps;

export const ChatModal: FC<ChatModalProps> = memo(
  ({ initialVisible = false, triggerLess = false, onClose, ...props }) => {
    const sendTrackedEvent = useDispatchDoctorTrackedType();
    const sendModalOpenTrackedEvent = useCallback(
      () =>
        sendTrackedEvent({
          event: DoctorTrackedType.ButtonClick,
          eventAction: TrackedEventButtonClickAction.OpenPatientChatModal,
          patientId: props.patient.id,
          examinationId: props.examinationId,
        }),
      [sendTrackedEvent, props.patient.id, props.examinationId],
    );

    useEffect(() => {
      if (initialVisible) {
        sendModalOpenTrackedEvent();
      }
    }, [sendModalOpenTrackedEvent, initialVisible]);

    return (
      <StyledManagedModal
        initialVisible={initialVisible}
        trigger={
          triggerLess ? undefined : (
            <IconButton
              color="secondary"
              size="small"
              icon={<ChatIcon />}
              onClick={sendModalOpenTrackedEvent}
              id="chat-button"
            />
          )
        }
        onClose={onClose}
      >
        <ChatWindow {...props} />
      </StyledManagedModal>
    );
  },
);
