import { useEffect } from 'react';
import {
  eachSecondEmitter,
  TimeObserver,
} from '@higo/common/src/services/tickEmitter';

export const useEachSecond = (callback: TimeObserver) => {
  useEffect(() => {
    eachSecondEmitter.attach(callback);

    return () => eachSecondEmitter.detach(callback);
  }, [callback]);
};
