import { UserSettingsDTO } from '@higo/api';
import { indexBy, mapObjIndexed, prop } from 'rambda';

export interface UserSettings {
  LANGUAGE: string;
}

export const mapToUserSettings = (dto: UserSettingsDTO[]): UserSettings => {
  return mapObjIndexed(
    (x: UserSettingsDTO) => x.value,
    indexBy(prop('code'), dto),
  ) as unknown as UserSettings; // todo: rewrite to transducer / fix typing?
};
