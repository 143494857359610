const blue = {
  100: '#D7F2FF',
  200: '#B0E6FF',
  300: '#88D9FE',
  400: '#61CDFE',
  500: '#39C0FE',
  600: '#2E9ACB',
  700: '#227398',
  800: '#174D66',
  900: '#0B2633',
};

const grey = {
  100: '#E3E4EB',
  200: '#C7C9D7',
  300: '#AAAFC4',
  400: '#8E94B0',
  500: '#72799C',
  600: '#5B617D',
  700: '#44495E',
  800: '#2E303E',
  900: '#17181F',
};

const neutral = {
  300: '#F8F9FB',
  400: '#EEF0F4',
  500: '#E4E7ED',
  600: '#DADDE4',
  700: '#BBBECC',
};

const pro = {
  100: '#0977FF',
};

export const colors = {
  blue,
  grey,
  neutral,
  pro,
};
