import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { TSelectValue } from '@mui/base/SelectUnstyled';
import OptionUnstyled, {
  optionUnstyledClasses,
  OptionUnstyledProps,
} from '@mui/base/OptionUnstyled';
import { Checkbox } from '@higo/ui/src/components/Checkbox';

export type OptionProps = OptionUnstyledProps<TSelectValue>;

const StyledCheckbox = styled(Checkbox)`
  margin: 0 0.625rem 0 0;
  pointer-events: none;
`;

const Option = styled(OptionUnstyled)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding: 0.5rem;

    transition: ${theme.transitions.create('all')};

    border-radius: 0.5rem;
    background: ${theme.palette.white};
    cursor: pointer;

    .${optionUnstyledClasses.selected} {
      background: ${theme.palette.neutral['400']};
    }

    &.${optionUnstyledClasses.highlighted} {
      background: ${theme.palette.neutral['400']};
    }

    &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
      background: ${theme.palette.neutral['400']};
    }

    &.${optionUnstyledClasses.disabled} {
      opacity: 0.45;
      cursor: not-allowed;
      & > * {
        pointer-events: none;
      }
    }

    &:not(.${optionUnstyledClasses.selected}) {
      ${StyledCheckbox} {
        border-color: ${theme.palette.secondary['200']};
        path {
          display: none;
        }
      }
    }

    &:hover:not(.${optionUnstyledClasses.disabled}) {
      background: ${theme.palette.neutral['400']};
    }
  `,
);

const OptionValue = styled('span')(
  ({ theme }) => css`
    ${theme.typography.lineClamp(1)};
    ${theme.typography.body};
    line-height: 1rem;
    font-weight: 700;
    color: ${theme.palette.secondary['500']};
  `,
);

export const SelectOption = forwardRef<HTMLLIElement, OptionProps>(
  ({ children, ...props }, ref) => (
    <Option {...props} ref={ref}>
      <OptionValue>{children}</OptionValue>
    </Option>
  ),
);

SelectOption.displayName = 'SelectOption';

export const MultiSelectOption = forwardRef<HTMLLIElement, OptionProps>(
  ({ children, ...props }, ref) => {
    return (
      <Option {...props} ref={ref}>
        <StyledCheckbox checked />
        <OptionValue>{children}</OptionValue>
      </Option>
    );
  },
);

MultiSelectOption.displayName = 'MultiSelectOption';
