import { memo, ReactElement, useMemo } from 'react';
import { formatDate } from '@higo/common/lib/utils/formatDate';

export const FormatDate = memo(function FormatDate(props: {
  format: string;
  children: string | Date;
}): ReactElement {
  const dateTime = useMemo(
    () =>
      (typeof props.children === 'string'
        ? new Date(props.children)
        : props.children
      ).toISOString(),
    [props.children],
  );

  return <time dateTime={dateTime}>{formatDate(dateTime, props.format)}</time>;
});

export const FormatHour = (props: { children: string | Date }) => (
  <FormatDate format="hh:mm aaaaa'm'">{props.children}</FormatDate>
);
