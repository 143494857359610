import { useMutation } from 'react-query';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';
import { setNewPassword, SetUserPasswordDTO } from '@higo/api';

export const useSetNewPasswordMutation = () => {
  const { baseAxios } = useDoctorPortalApiContext();
  const intl = useIntl();

  return useMutation(
    (dto: SetUserPasswordDTO) => setNewPassword(baseAxios)(dto),
    {
      onSuccess: () => {
        toast.success(
          intl.formatMessage({
            id: 'authentication.setNewPassword.successMessage',
          }),
        );
      },
    },
  );
};
