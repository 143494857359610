import { FC, useCallback, useMemo, useState } from 'react';
import { StyleProps } from '@summer/jst-react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import {
  Button,
  ChevronIcon,
  CollapsibleItemsState,
  CollapsibleList,
  RoundCloseIcon,
} from '@higo/ui';
import { isEmpty, values } from 'rambda';
import { QuestionAnswerOption } from '@higo/common/src/features/FilledSurvey/model';
import { getClosedQuestionSingleAnswer } from '@higo/common/src/features/FilledSurvey';

const Container = styled('div')(
  ({ theme }) => css`
    ${theme.typography.subtitle1};
    color: ${theme.palette.secondary['500']};
  `,
);

const Row = styled.div`
  display: grid;
  grid-template-columns: auto 1fr min-content;
  gap: 2rem;
`;
const Label = styled.span`
  height: 2.5rem;
  display: inline-flex;
  align-items: center;
`;

const AilmentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledRoundCloseIcon = styled(RoundCloseIcon)(
  () => css`
    margin-right: 0.5rem;
  `,
);

const ExpansionButton = styled(Button)`
  width: auto;
`;

interface NotReportedAilmentsListProps {
  notReportedAilments?: QuestionAnswerOption[];
}

export const NotReportedAilmentsList: FC<
  NotReportedAilmentsListProps & StyleProps
> = ({ className, notReportedAilments }) => {
  const [isExpandable, setIsExpandable] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const evaluateIsExpandable = useCallback((state: CollapsibleItemsState) => {
    setIsExpandable(values(state).some((x) => !x)); // set expandable if at least one element is not visible
  }, []);

  const items = useMemo(() => {
    if (!notReportedAilments || isEmpty(notReportedAilments)) {
      return undefined;
    }

    return notReportedAilments?.map((ailment) => ({
      id: ailment.code,
      content: (
        <AilmentWrapper>
          <StyledRoundCloseIcon />
          {getClosedQuestionSingleAnswer(ailment)}
        </AilmentWrapper>
      ),
    }));
  }, [notReportedAilments]);

  return (
    <Container className={className} id="selected-ailments">
      {!items && <FormattedMessage id="anamnesisSurvey.allAilmentsSelected" />}

      {items && (
        <Row>
          <Label id="not-reported-ailments-label">
            <FormattedMessage id="anamnesisSurvey.notReportedAilments" />:
          </Label>

          <CollapsibleList
            isCollapsed={isCollapsed}
            items={items}
            onChanged={evaluateIsExpandable}
          />

          {(isExpandable || !isCollapsed) && (
            <ExpansionButton
              id="collapse-button"
              size="small"
              autoWidth
              endIcon={<ChevronIcon direction={isCollapsed ? 'down' : 'up'} />}
              onClick={() => setIsCollapsed((x) => !x)}
            >
              <FormattedMessage
                id={isCollapsed ? 'common.more' : 'common.less'}
              />
            </ExpansionButton>
          )}
        </Row>
      )}
    </Container>
  );
};
