import { DictionaryType } from './dictionaryType';
import { collect, select, toCodes } from '@summer/jst-dictionaries';

export const selectLanguageDictionary = select(DictionaryType.Languages);
export const collectLanguageDictionaryItems = collect(selectLanguageDictionary);
export const collectLanguageDictionaryItemsCodes = toCodes(
  collectLanguageDictionaryItems,
);

export type PhoneNumberPrefixDictionaryItemValue = {
  country: string;
  prefix: string;
};

export const selectPhoneNumberPrefixDictionary =
  select<PhoneNumberPrefixDictionaryItemValue>(
    DictionaryType.PhoneNumberPrefix,
  );
export const collectPhoneNumberPrefixDictionaryItems =
  collect<PhoneNumberPrefixDictionaryItemValue>(
    selectPhoneNumberPrefixDictionary,
  );
export const collectPhoneNumberPrefixDictionaryItemsCodes =
  toCodes<PhoneNumberPrefixDictionaryItemValue>(
    collectPhoneNumberPrefixDictionaryItems,
  );

export type AuscultationTagDictionaryItemMetadata = { blocking: false };

export const selectLungsAuscultationDictionary = select<
  string,
  AuscultationTagDictionaryItemMetadata
>(DictionaryType.LungsAuscultation);
export const collectLungsAuscultationDictionaryItems = collect(
  selectLungsAuscultationDictionary,
);
export const collectLungsAuscultationDictionaryItemsCodes = toCodes(
  collectLungsAuscultationDictionaryItems,
);

export const selectHeartAuscultationDictionary = select<
  string,
  AuscultationTagDictionaryItemMetadata
>(DictionaryType.HeartAuscultation);
export const collectHeartAuscultationDictionaryItems = collect(
  selectHeartAuscultationDictionary,
);
export const collectHeartAuscultationDictionaryItemsCodes = toCodes(
  collectHeartAuscultationDictionaryItems,
);
