import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { createAspectRatio } from '@higo/ui';

const Container = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.neutral['500']};
    width: calc(100% - 4rem);
    margin: 3.5rem auto 0;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    ${theme.typography.subtitle1};
    color: ${theme.palette.secondary[500]};
    ${createAspectRatio(4, 3)};
  `,
);

export const EarExaminationOmitted = () => (
  <Container>
    <FormattedMessage id="examinationPage.omittedByTheUser" />
  </Container>
);
