import styled, { css } from 'styled-components';

export const InfoBarListItem = styled('li')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    white-space: nowrap;
    ${theme.typography.body2}
    color: ${theme.palette.secondary['500']};

    & b {
      ${theme.typography.subtitle1}
    }

    & button {
      margin-left: 0.5rem;
    }

    &:after {
      content: '\\025CF';
      color: ${theme.palette.neutral['700']};
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }

    &:last-of-type:after {
      content: none;
    }
  `,
);
