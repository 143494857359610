import styled, { css, ThemeProvider } from 'styled-components';
import { darkTheme } from '@higo/ui';
import { ConstraintContainer } from 'components/ConstraintContainer';
import { HeaderLogo } from 'components/Header/HeaderLogo';
import { HeaderSearchInput } from 'components/Header/HeaderSearchInput';
import { HeaderDateTimer } from 'components/Header/HeaderDateTimer';
import { HeaderProfileMenu } from 'components/Header/HeaderProfileMenu';
import {
  HeaderExaminationsNotificationsMenu,
  HeaderMessagesNotificationsMenu,
} from 'components/Header/HeaderNotificationMenu';
import { useEffect, useRef } from 'react';
import { useIntersection } from 'react-use';
import { useLayoutContext } from '@higo/ui/lib/contexts';

const Wrapper = styled('nav')(
  ({ theme }) => css`
    width: 100%;
    min-height: 3.5rem;
    background: ${theme.palette.background};
    color: ${theme.palette.text};
    position: relative;
    z-index: ${theme.layers.appHeader};
    display: flex;
    align-items: center;
  `,
);

const Container = styled(ConstraintContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const AppHeader = () => {
  const { update: updateLayoutState } = useLayoutContext();
  const intersectionRef = useRef(null);

  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  });

  useEffect(
    () =>
      updateLayoutState({
        isNavHeaderHidden: !intersection?.isIntersecting ?? false,
      }),
    [intersection, updateLayoutState],
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <Wrapper ref={intersectionRef}>
        <Container id="header-container">
          <Row>
            <HeaderLogo />
            <HeaderSearchInput />
          </Row>
          <Row>
            <HeaderDateTimer />
            <HeaderExaminationsNotificationsMenu />
            <HeaderMessagesNotificationsMenu />
            <HeaderProfileMenu />
          </Row>
        </Container>
      </Wrapper>
    </ThemeProvider>
  );
};
