import { QueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { ToastMultipleMessagesTemplate } from '@higo/ui';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  handleAxiosError,
  TranslatedError,
} from '@higo/common/lib/ext/ApiError';
import { useCallback, useRef, useState } from 'react';

const showError = (error: TranslatedError) => {
  if (!Array.isArray(error.message)) {
    toast.error(error.message);
  } else {
    toast.error(
      <ToastMultipleMessagesTemplate
        title={<FormattedMessage id="toast.multipleErrorMessages.title" />}
        messages={error.message}
      />,
    );
  }
};

export const useDoctorQueryClient = () => {
  const intl = useIntl();

  const handleError = useCallback(
    (error: unknown) => {
      const translatedErrors = handleAxiosError(error as Error, intl);
      showError(translatedErrors);
    },
    [intl],
  );

  const errorHandlerRef = useRef(handleError);
  errorHandlerRef.current = handleError;

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retry: false,
            onError: errorHandlerRef.current,
          },
          mutations: {
            onError: errorHandlerRef.current,
          },
        },
      }),
  );

  return queryClient;
};
