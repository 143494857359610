import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  Heading,
  HorizontalDivider,
  Pagination,
  Table,
  usePagination,
} from '@higo/ui';
import {
  mapToPatientList,
  PatientsListItem,
  usePatientListConfig,
} from 'pages/PatientsListPage/config';
import { ConstraintContainer } from 'components/ConstraintContainer';
import { usePatientsListQuery } from './usePatientsListQuery';
import { FormattedMessage } from 'react-intl';
import { TableNoDataAvailable } from 'components/TableDataInfo';

const TotalElements = styled('sup')(
  ({ theme }) => css`
    color: ${theme.palette.secondary['500']};
    ${theme.typography.subtitle2}
  `,
);

export const PatientsListPage = () => {
  const [searchParams] = useSearchParams();
  const searchText = searchParams?.get('search');

  const config = usePatientListConfig();
  const pagination = usePagination();

  const { data, isFetching, isError } = usePatientsListQuery(searchText ?? '', {
    page: pagination.page,
    pageSize: pagination.pageSize,
  });

  const tableData: PatientsListItem[] = useMemo(
    () => mapToPatientList(data?.content ?? []),
    [data?.content],
  );

  return (
    <ConstraintContainer>
      <Heading level={2}>
        <div id="result-title">
          <FormattedMessage id="searchResultsPage.title" />
          {data?.totalElements !== 0 && (
            <TotalElements id="total-elements">
              ({data?.totalElements})
            </TotalElements>
          )}
        </div>
      </Heading>
      <HorizontalDivider />
      {data?.totalElements === 0 ? (
        <TableNoDataAvailable title={`No results for ${searchText}`} />
      ) : (
        <>
          <Table
            {...config}
            data={tableData}
            pageSize={pagination.pageSize}
            isLoading={isFetching || isError}
          />
          <Pagination
            {...pagination}
            totalPages={data?.totalPages ?? 0}
            currentPageLabelSlot={
              <FormattedMessage
                id="pagination.pageCurrentOfTotal"
                values={{
                  current: pagination.page,
                  total: data?.totalPages ?? 0,
                }}
              />
            }
            perPageLabel={<FormattedMessage id="pagination.patientsPerPage" />}
          />
        </>
      )}
    </ConstraintContainer>
  );
};
