import { IconBox, IconProps } from '../IconBox';

const EmailIcon = (props: IconProps) => (
  <IconBox {...props}>
    <g fillRule="evenodd" clipRule="evenodd">
      <path d="M2.404 7.06 12 11.858l9.596-4.798A2.4 2.4 0 0 0 19.2 4.8H4.8a2.4 2.4 0 0 0-2.396 2.26Z" />
      <path d="m21.6 9.741-9.6 4.8-9.6-4.8V16.8a2.4 2.4 0 0 0 2.4 2.4h14.4a2.4 2.4 0 0 0 2.4-2.4V9.741Z" />
    </g>
  </IconBox>
);

export default EmailIcon;
