//https://github.com/tim-kos/node-retry/blob/master/lib/retry.js
export type ExponentialBackoffOptions = {
  randomize?: boolean;
  factor: number;
  minTimeout: number;
  maxTimeout: number;
};
export const calculateExponentialBackoff = (
  attempt: number,
  opts: ExponentialBackoffOptions,
) => {
  const random = opts.randomize ? Math.random() + 1 : 1;

  const timeout = Math.round(
    random * Math.max(opts.minTimeout, 1) * Math.pow(opts.factor, attempt),
  );

  return Math.min(timeout, opts.maxTimeout);
};
