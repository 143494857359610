import { VisualExaminationDiagnosticViewerProps } from './VisualExaminationDiagnosticViewerProps';
import { VisualExaminationDiagnosticGalleryViewer } from './VisualExaminationDiagnosticGalleryViewer';

export const VisualExaminationDiagnosticViewerForPublic = (
  props: VisualExaminationDiagnosticViewerProps,
) => {
  const { data } = props.model.usePhysicalExaminationResultsQuery(
    props.examinationId,
  );

  const diagnosticItemIndex = data?.diagnosticImageIndex;
  const diagnosticItemName = props.rowData
    ? props.rowData[diagnosticItemIndex]?.filename
    : undefined;

  return (
    <VisualExaminationDiagnosticGalleryViewer
      examinationId={props.examinationId}
      diagnosticImageIndex={data?.diagnosticImageIndex}
      diagnosticItemName={diagnosticItemName}
      model={props.model}
      galleryViewerProps={props.galleryViewerProps}
      id="visual-gallery-viewer"
    />
  );
};
