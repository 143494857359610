import { evolve } from 'rambda';
import { parseJSON } from 'date-fns';

export interface ExaminationDiagnosisDTO {
  id: number;
  createDate: string;
}

export type ExaminationDiagnosis = Omit<
  ExaminationDiagnosisDTO,
  'createDate'
> & {
  createDate: Date;
};

export const mapToExaminationDiagnosis: (
  a: ExaminationDiagnosisDTO,
) => ExaminationDiagnosis = evolve({
  createDate: parseJSON,
});
