import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { HeaderProps, TRowEntity } from 'react-table';
import { Checkbox } from '@higo/ui/src/components/Checkbox';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';

const StyledCheckbox = styled(Checkbox)`
  display: block;
  margin: 0 0 0 8px;
`;

export const SelectorHeaderRenderer = <T extends TRowEntity>({
  selection,
  rows,
}: HeaderProps<T>) => {
  const selectRowValues = useMemo(
    () => rows.map((row) => row.original.id),
    [rows],
  );

  const isChecked = useMemo(
    () =>
      selectRowValues.length > 0 &&
      selectRowValues.every((value) => selection?.selectedIds.includes(value)),
    [selection?.selectedIds, selectRowValues],
  );

  const isIndeterminate = useMemo(
    () =>
      !isChecked &&
      selectRowValues.some((value) => selection?.selectedIds.includes(value)),
    [selection?.selectedIds, selectRowValues, isChecked],
  );

  const togglePageRows = useCallback(
    () => selection?.togglePageRowsSelection(selectRowValues),
    [selection, selectRowValues],
  );

  return rows?.length > 0 ? (
    <>
      <Tooltip
        placement="right"
        title={<FormattedMessage id="table.header.select" />}
        color="#72799C"
      >
        <StyledCheckbox
          disabled={selectRowValues.length === 0}
          checked={isChecked}
          indeterminate={isIndeterminate}
          onChange={togglePageRows}
        />
      </Tooltip>
    </>
  ) : (
    <></>
  );
};
