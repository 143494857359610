import { Button, UnassignIcon } from '@higo/ui';
import { FormattedMessage } from 'react-intl';
import { MouseEvent, useCallback } from 'react';
import { useUnassignDoctorExaminationMutation } from '@higo/doctor/src/pages/Dashboard/DashboardAssigned/useUnassignDoctorExaminationMutation';
import styled from 'styled-components';
import { ExaminationQueryKey } from '@higo/common/lib/features/ExaminationPage/ExaminationQueryKey';
import { useQueryClient } from 'react-query';

export type UnassignFromExamPageButtonProps = {
  examinationId: number;
  patientId: number;
};

export const UnassignFromExamPageButton = ({
  examinationId,
  patientId,
}: UnassignFromExamPageButtonProps) => {
  const queryClient = useQueryClient();

  const StyledButtonWrapper = styled('div')`
    display: inline;
    align-self: end;
    margin-bottom: 0.4rem;
    margin-left: auto;
  `;

  const StyledButton = styled(Button)`
    height: 1.5rem;
    width: 14.5rem;
    padding: 1rem;
  `;

  const { mutateAsync: unassignExamination } =
    useUnassignDoctorExaminationMutation({
      patientId: patientId,
    });

  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      unassignExamination(examinationId).then(() =>
        queryClient.invalidateQueries([ExaminationQueryKey.ExaminationDetails]),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [examinationId, unassignExamination],
  );

  return (
    <StyledButtonWrapper>
      <StyledButton
        size="small"
        color="secondary"
        endIcon={<UnassignIcon />}
        onClick={handleClick}
        id="unassign-from-exam-button"
      >
        <FormattedMessage id="common.unassign" />
      </StyledButton>
    </StyledButtonWrapper>
  );
};
