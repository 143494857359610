import { AnamnesisSurveyGroup } from '@higo/common/src/features/ExaminationPage/sections/AnamnesisSection/AnamnesisSurveyGroup';
import { FormattedMessage } from 'react-intl';
import { SurveyRenderer } from '@higo/common/src/features/FilledSurvey';
import { TaggedAnsweredQuestionMap } from '@higo/common/src/features/FilledSurvey/model';
import { FC } from 'react';

export interface AnamnesisMedicamentsSegmentProps {
  anamnesisTaggedQuestions?: TaggedAnsweredQuestionMap;
  generalTaggedQuestions?: TaggedAnsweredQuestionMap;
}
export const AnamnesisMedicamentsSegment: FC<AnamnesisMedicamentsSegmentProps> =
  ({ anamnesisTaggedQuestions, generalTaggedQuestions }) => {
    return (
      <>
        {(anamnesisTaggedQuestions?.A_MEDICAMENTS ||
          generalTaggedQuestions?.G_MEDICAMENTS) && (
          <AnamnesisSurveyGroup
            title={<FormattedMessage id="anamnesisSurvey.patientMedicaments" />}
          >
            {anamnesisTaggedQuestions?.A_MEDICAMENTS && (
              <SurveyRenderer
                answeredQuestionsList={anamnesisTaggedQuestions.A_MEDICAMENTS}
              />
            )}

            {generalTaggedQuestions?.G_MEDICAMENTS && (
              <SurveyRenderer
                answeredQuestionsList={generalTaggedQuestions.G_MEDICAMENTS}
              />
            )}
          </AnamnesisSurveyGroup>
        )}
      </>
    );
  };
