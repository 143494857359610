import {
  AnsweredQuestion,
  QuestionAnswerOption,
  SurveyQuestionLevel,
} from '@higo/common/src/features/FilledSurvey/model';
import { FC, Fragment, memo } from 'react';
import { AnsweredQuestionRootRowRenderer } from '@higo/common/src/features/FilledSurvey/ComponentRenderers/AnsweredQuestionRootRowRenderer';
import { AnsweredQuestionParenthesisRenderer } from '@higo/common/src/features/FilledSurvey/ComponentRenderers/AnsweredQuestionParenthesisRenderer';
import { AnsweredQuestionDashRenderer } from '@higo/common/src/features/FilledSurvey/ComponentRenderers/AnsweredQuestionDashRenderer';
import { Predicate } from 'rambda';
import { useRenderableQuestions } from '@higo/common/src/features/FilledSurvey/ComponentRenderers/useRenderableQuestions';
import { AnsweredQuestionRendererProps } from '@higo/common/src/features/FilledSurvey/ComponentRenderers/AnsweredQuestionRendererProps';

const defaultRenderers: FC<AnsweredQuestionRendererProps>[] = [
  AnsweredQuestionRootRowRenderer,
  AnsweredQuestionParenthesisRenderer,
  AnsweredQuestionDashRenderer,
];

export interface SurveyRendererProps {
  answeredQuestionsList: AnsweredQuestion[];
  level?: SurveyQuestionLevel;
  renderers?: FC<AnsweredQuestionRendererProps>[];
  options?: {
    isAnsweredQuestionRenderable?: Predicate<AnsweredQuestion>;
    isQuestionAnswerOptionRenderable?: Predicate<QuestionAnswerOption>;
  };
}

//Todo: rename to answered question renderer
export const SurveyRenderer: FC<SurveyRendererProps> = memo(
  ({
    level = 0,
    renderers = defaultRenderers,
    answeredQuestionsList,
    options,
  }) => {
    const questions = useRenderableQuestions(
      answeredQuestionsList,
      options?.isAnsweredQuestionRenderable,
    );

    if (level > renderers?.length - 1) {
      console.error(`No template specified for questions depth${level}`);
      return null;
    }

    const Renderer = renderers[level];

    return (
      <>
        {questions.map((value) => (
          <Fragment key={value.questionCode}>
            <Renderer
              answeredQuestion={value}
              renderers={renderers}
              level={level}
              options={options}
            />
          </Fragment>
        ))}
      </>
    );
  },
);
