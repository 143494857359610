import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';
import {
  ButtonUnstyled,
  buttonUnstyledClasses,
  ButtonUnstyledProps,
} from '@mui/base';
import { BaseButtonSize } from './BaseButton';

export type LinkButtonSize = 'default' | 'small';

const sizeStyleMap: Record<
  BaseButtonSize,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  default: css`
    height: 2.125rem;
    font-size: 0.8125rem;
  `,
  small: css`
    height: 2rem;
    font-size: 0.75rem;
  `,
};

export const LinkButton = styled(ButtonUnstyled)<LinkButtonProps>(
  ({ theme, size }) => css`
    color: ${theme.palette.primary['600']};
    border: none;
    outline: none;

    font-family: ${theme.fontFamily.primary};
    font-weight: 700;

    background-color: transparent;
    border-radius: 0.5rem;

    &.${buttonUnstyledClasses.disabled} {
      color: ${theme.palette.neutral['700']};
      box-shadow: none;
    }

    &:not(.${buttonUnstyledClasses.disabled}) {
      &:hover {
        border-color: ${theme.palette.primary['600']};
        text-decoration: underline;
      }

      &.${buttonUnstyledClasses.focusVisible} {
        border: 1px solid ${theme.palette.primary['600']};
      }

      &:active {
        color: ${theme.palette.primary['700']};
        text-decoration: underline;
      }
    }

    ${sizeStyleMap[size ?? 'default']}
  `,
);

export type LinkButtonProps = {
  size?: LinkButtonSize;
} & ButtonUnstyledProps;
