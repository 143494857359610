import axios from 'axios';
import { TranslatedError } from '@higo/common/src/ext/ApiError/types';
import { IntlShape } from 'react-intl/src/types';
import { getTranslatedApiErrorResponse } from '@higo/common/src/ext/ApiError/getTranslatedApiErrorResponse';

// https://github.com/axios/axios#handling-errors
export const handleAxiosError = (
  originalError: Error,
  intl: IntlShape,
): TranslatedError => {
  if (axios.isAxiosError(originalError)) {
    if (originalError.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return (
        getTranslatedApiErrorResponse(
          originalError.response.data,
          originalError,
          intl,
        ) ?? {
          message: intl.formatMessage({ id: 'error.axios.unexpected' }),
          originalError,
        }
      );
    } else if (originalError.request) {
      // The request was made but no response was received
      // `originalError.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return {
        message: intl.formatMessage({ id: 'error.axios.request' }),
        originalError,
      };
    } else {
      // Something happened in setting up the request that triggered an Error
      return {
        message: intl.formatMessage({ id: 'error.axios.internal' }),
        originalError,
      };
    }
  }
  return { originalError };
};
