import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import MenuItemUnstyled, {
  menuItemUnstyledClasses,
  MenuItemUnstyledProps,
} from '@mui/base/MenuItemUnstyled';

export type MenuItemProps = MenuItemUnstyledProps;

export const StyledMenuItem = styled(MenuItemUnstyled)(
  ({ theme }) => css`
    display: block;
    min-height: 2rem;

    list-style: none;
    user-select: none;
    border-radius: 0.5rem;
    color: ${theme.palette.secondary['500']};
    padding: 0.5rem;

    ${theme.typography.body};
    font-weight: 700;

    &.${menuItemUnstyledClasses.focusVisible} {
      background: ${theme.palette.neutral['400']};
    }

    &:not(.${menuItemUnstyledClasses.disabled}) {
      cursor: pointer;
      &:hover {
        background: ${theme.palette.neutral['400']};
      }
    }
  `,
);

export const MenuItem = forwardRef<HTMLLIElement, MenuItemProps>(
  ({ children, ...props }, ref) => (
    <StyledMenuItem {...props} ref={ref}>
      {children}
    </StyledMenuItem>
  ),
);

MenuItem.displayName = 'MenuItem';
