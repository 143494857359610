import {
  DiagnosticPhotosGallery,
  DiagnosticPhotosGalleryActionsContainer,
  DiagnosticPhotosGallerySkeleton,
} from '@higo/common/src/components';
import styled from 'styled-components';
import { createAspectRatio } from '@higo/ui';
import { VisualExaminationDiagnosticViewerProps } from './VisualExaminationDiagnosticViewerProps';

const VisualExaminationPhotosGalleryImgContainer = styled.div`
  width: 100%;
  background: black;
  position: relative;
  ${createAspectRatio(4, 3)};

  & > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const VisualExaminationPhotosGalleryImgSlide = (
  props: JSX.IntrinsicElements['img'],
) => (
  <VisualExaminationPhotosGalleryImgContainer>
    <img src={props.src} alt={props.alt} />
  </VisualExaminationPhotosGalleryImgContainer>
);

export type VisualExaminationPhotosGalleryProps =
  VisualExaminationDiagnosticViewerProps & {
    diagnosticImageIndex?: number;
    diagnosticItemName?: string;
    id?: string;
    setActiveGalleryLink?: (index: string) => void;
  };

export const VisualExaminationDiagnosticGalleryViewer = ({
  model,
  examinationId,
  requestGenerateVideoButtonSlot,
  diagnosticItemName,
  galleryViewerProps,
  id,
  setActiveGalleryLink,
}: VisualExaminationPhotosGalleryProps) => {
  const { data, isLoading } =
    model.usePhysicalExaminationGalleryResultsLinks(examinationId);

  const diagnosticIndexAfterPreparedData = data?.findIndex(
    (x) => x.fileName === diagnosticItemName,
  );

  if (isLoading) {
    return <DiagnosticPhotosGallerySkeleton />;
  }

  return (
    <div id="gallery-viewer-container">
      <DiagnosticPhotosGallery
        resources={data ?? []}
        initialSlide={
          diagnosticItemName ? diagnosticIndexAfterPreparedData : undefined
        }
        thumbnailsSlidesPerView={5}
        Slide={VisualExaminationPhotosGalleryImgSlide}
        {...galleryViewerProps}
        id="diagnostic-photo-gallery"
        setActiveGalleryLink={setActiveGalleryLink}
      />
      <DiagnosticPhotosGalleryActionsContainer>
        {requestGenerateVideoButtonSlot}
      </DiagnosticPhotosGalleryActionsContainer>
    </div>
  );
};
