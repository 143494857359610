import { find, propEq } from 'rambda';

export enum ContactType {
  Phone = 'phone',
}
export interface ContactDetailsDTO {
  id: number;
  contactType: ContactType;
  prefixCode: string;
  value: string;
}

export interface UserBaseDTO {
  contactDetails: ContactDetailsDTO[];
  email: string;
  firstName: string;
  id: number;
  lastName: string;
}

export type ContactDetails = ContactDetailsDTO;

export const pickContactDetailsByType = (type: ContactType) =>
  find<ContactDetails>(propEq('contactType', type));

export const pickPhoneContactDetails = pickContactDetailsByType(
  ContactType.Phone,
);
