import { useMemo } from 'react';
import {
  getTimeMarkers,
  TimeMarkerCellRenderer,
  TimeMarkerCellRendererValue,
} from '@higo/ui';
import { TableOptions } from 'react-table';
import {
  DiagnosedByCellRenderer,
  DiagnosedByCellValue,
  HigoModeCellRenderer,
  HigoModeCellRendererValue,
  StatusCellRenderer,
} from 'components/TableRenderers';
import { ExaminationPatientDTOPatientType } from '@higo/api/lib/models';
import {
  ExaminationDTOStatus,
  ExaminationType,
  PatientExaminations,
} from '@higo/api';
import { DateCellRenderer } from 'components/PatientExaminationsTable/renderers/DateCellRenderer';
import { ExamTypeCellRenderer } from 'components/PatientExaminationsTable/renderers/ExamTypeCellRenderer';
import { TableSortDesc } from '@higo/ui/lib/components/Table';
import { useIntl } from 'react-intl';

export type PatientExamsListItem = {
  id: number;
  timeMarker: TimeMarkerCellRendererValue;
  examination: {
    createDate: Date;
    type: ExaminationType;
    doctorId: number;
    status: ExaminationDTOStatus;
  };
  patient: {
    type: ExaminationPatientDTOPatientType;
  };
  diagnosedBy: DiagnosedByCellValue;
  modeWithSurveyInfo: HigoModeCellRendererValue;
};

export const usePatientExamsConfig = () => {
  const intl = useIntl();

  return useMemo(
    (): Omit<TableOptions<PatientExamsListItem>, 'data'> => ({
      columns: [
        {
          accessor: 'timeMarker',
          width: 70,
          isMarker: true,
          Cell: TimeMarkerCellRenderer,
        },
        {
          Header: intl.formatMessage({ id: 'table.header.examDate' }),

          accessor: 'examination.createDate',
          width: 120,
          Cell: DateCellRenderer,
        },
        {
          Header: intl.formatMessage({ id: 'table.header.examType' }),

          accessor: 'examination.type',
          width: 180,
          Cell: ExamTypeCellRenderer,
        },
        {
          Header: intl.formatMessage({ id: 'table.header.higoMode' }),
          accessor: 'modeWithSurveyInfo',
          width: 130,
          Cell: HigoModeCellRenderer,
        },
        {
          Header: intl.formatMessage({ id: 'table.header.diagnosedBy' }),
          accessor: 'diagnosedBy',
          width: 150,
          Cell: DiagnosedByCellRenderer,
        },
        {
          Header: intl.formatMessage({ id: 'table.header.status' }),
          accessor: 'examination.status',
          Cell: StatusCellRenderer,
        },
      ],
    }),
    [intl],
  );
};

export const mapToPatientExamsList = (
  exams: PatientExaminations[],
): PatientExamsListItem[] => {
  const timeMarkers = getTimeMarkers({
    timestamps: exams.map((el) => el.createDate),
    sortDirection: TableSortDesc, // backend should return ordered by create/session end date in descending order
  });

  return exams.map((exam, index) => ({
    id: exam.examinationId,
    timeMarker: timeMarkers[index],
    examination: {
      createDate: exam.createDate,
      type: exam.type,
      doctorId: exam.doctor?.id,
      status: exam.status,
    },
    patient: {
      type: exam.patientType,
    },
    diagnosedBy: exam.doctor,
    modeWithSurveyInfo: {
      mode: exam.patientType,
      anamnesisSurveyFilled: exam.anamnesisSurveyFilled,
    },
  }));
};
