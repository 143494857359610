import React, { FC } from 'react';
import { Heading, ManagedModal, VirusIcon } from '@higo/ui';
import { AnsweredSurvey } from '@higo/common/src/features/FilledSurvey/model';
import { isFilled } from '@higo/common/src/utils';
import { FormattedMessage } from 'react-intl';
import { SurveyRenderer } from '@higo/common/src/features/FilledSurvey';
import styled, { css } from 'styled-components';
import SimpleBar from 'simplebar-react';

export interface ExaminationPageCovidProps {
  answeredGeneralSurvey: AnsweredSurvey;
}

const Content = styled(SimpleBar)`
  width: 37rem;
  padding-right: 0.5rem;
  margin: 1rem;
`;

const SurveyContainer = styled.div`
  margin-top: 1.4rem;
  padding-left: 1.4rem;
`;

const StyledVirusIcon = styled(VirusIcon)(
  ({ theme }) => css`
    color: ${theme.palette.secondary[500]};
    margin-right: 1rem;
    width: 2.8rem;
    height: 2.8rem;
  `,
);

export const ExaminationPageCovidModal: FC<ExaminationPageCovidProps> = ({
  answeredGeneralSurvey,
}) => {
  const hasCovidQuestions = isFilled(
    answeredGeneralSurvey.taggedQuestions.COVID,
  );

  const isCovidPopupVisible = false;

  return hasCovidQuestions && isCovidPopupVisible ? (
    <ManagedModal initialVisible={true}>
      <Content>
        <Heading level={3}>
          <StyledVirusIcon />
          <FormattedMessage id="covidModal.title" />:
        </Heading>
        <SurveyContainer>
          <SurveyRenderer
            answeredQuestionsList={answeredGeneralSurvey.taggedQuestions.COVID!}
          />
        </SurveyContainer>
      </Content>
    </ManagedModal>
  ) : null;
};
