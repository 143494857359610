import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { Button, RHFTextField } from '@higo/ui';
import { FormattedMessage } from 'react-intl';
import { StyleProps } from '@summer/jst-react';
import { setYupDefaultMessages } from '../../config/yupLocale';

setYupDefaultMessages();

const schema = object({
  login: string().required(),
  password: string().required(),
});

export interface LoginFormModel {
  login: string;
  password: string;
}

const defaultValues: LoginFormModel = {
  login: '',
  password: '',
};

export type LoginFormProps = {
  isLoading: boolean;
  onSubmit: (value: LoginFormModel) => Promise<unknown>;
} & StyleProps;

export const LoginForm: FC<LoginFormProps> = ({
  className,
  isLoading,
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<LoginFormModel>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  return (
    <form className={className} onSubmit={handleSubmit(onSubmit)}>
      <RHFTextField
        control={control}
        label="common.email"
        name="login"
        autoComplete="email"
        id="email-login-form"
      />
      <RHFTextField
        control={control}
        type="password"
        label="common.password"
        name="password"
        autoComplete="current-password"
        id="password-login-form"
      />
      <Button
        type="submit"
        disabled={isLoading || isSubmitting}
        isLoading={isLoading || isSubmitting}
        fullWidth
        id="login-button"
      >
        <FormattedMessage id="common.login" />
      </Button>
    </form>
  );
};
