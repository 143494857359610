import { useCallback, useMemo, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { OnProgressProps } from 'react-player/base';

export interface AudioPlayerState {
  ready: boolean;
  playing: boolean;
  playedSeconds: number;
  loaded: number;
  duration: number;
  seeking: boolean;
  played: boolean;
}

const defaultState: AudioPlayerState = {
  ready: false,
  playing: false,
  playedSeconds: 0,
  loaded: 0,
  duration: 0,
  seeking: false,
  played: false,
};

export interface UseAudioReactPlayerOptions {
  onEnded?: () => void;
  id: string;
}

export type UseAudioReactPlayer = {
  getState: () => AudioPlayerState;
  play: () => void;
  pause: () => void;
  playPause: () => void;
  seekStart: () => void;
  seekChange: (value: number) => void;
  seekStop: (value: number) => void;
};

export type UseAudioReactPlayerPlayerProps = {
  onProgress: (state: OnProgressProps) => void;
  onDuration: (duration: number) => void;
  onReady: () => void;
};

export const useAudioReactPlayer = (options?: UseAudioReactPlayerOptions) => {
  const playerRef = useRef<ReactPlayer | null>(null);
  const [state, setState] = useState<AudioPlayerState>(defaultState);
  const stateRef = useRef(state);
  stateRef.current = state;

  const updateState = useCallback(
    (partial: Partial<AudioPlayerState>) =>
      setState((old) => ({ ...old, ...partial })),
    [],
  );

  const api: UseAudioReactPlayer = useMemo(
    () => ({
      getState: () => stateRef.current,
      play: () => updateState({ playing: true, played: true }),
      pause: () => updateState({ playing: false }),
      playPause: () => setState((old) => ({ ...old, playing: !old.playing })),
      seekStart: () => updateState({ seeking: true }),
      seekChange: (value: number) => updateState({ playedSeconds: value }),
      seekStop: (value: number) => {
        updateState({ seeking: false });
        playerRef?.current?.seekTo(value);

        if (stateRef.current.duration === stateRef.current.playedSeconds) {
          api.pause(); // replaying current from beginning when seeked to the end
        }
      },
    }),
    [updateState, stateRef],
  );

  const playerProps: UseAudioReactPlayerPlayerProps = useMemo(
    () => ({
      onProgress: (state: OnProgressProps) => {
        // We only want to update time slider if we are not currently seeking
        setState((old) =>
          old.seeking ? old : { ...old, playedSeconds: state.playedSeconds },
        );
      },
      onDuration: (duration: number) => updateState({ duration, ready: true }),
      onReady: () => updateState({ ready: true }),
    }),
    [updateState],
  );

  const handleEnded = () => {
    updateState({ playing: false });
    options?.onEnded?.();
  };

  return {
    playerRef,
    state,
    api,
    playerProps,
    handleEnded,
  };
};
