import { Navigate, Route, Routes } from 'react-router-dom';
import { LoginPage } from 'pages/LoginPage';
import { lazy, memo, Suspense } from 'react';
import { RequireAuth } from 'components/RequireAuth';
import { PrivateLayout } from 'PrivateLayout';
import { ExaminationPage } from 'pages/ExaminationPage';
import { ResetPasswordPage } from 'pages/ResetPasswordPage';
import { PublicLayout } from 'components/PublicLayout';
import { SetNewPasswordPage } from 'pages/SetNewPasswordPage';
import { PatientsListPage } from 'pages/PatientsListPage';
import { routes } from 'routes';
import { MessagesListPage } from 'pages/MessagesListPage';
import { ExaminationApiContextProvider } from '@higo/common/lib/features/ExaminationPage/providers';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';

const DashboardModuleRouter = lazy(() => import('pages/Dashboard'));

export const AppRouter = memo(() => {
  const { authorizedAxios } = useDoctorPortalApiContext();

  return (
    <>
      <Routes>
        <Route element={<PublicLayout />}>
          <Route path={routes.root} element={<LoginPage />} />
          <Route
            path={routes.resetPasswordRoute}
            element={<ResetPasswordPage />}
          />
          <Route
            path={routes.setNewPassword}
            element={<SetNewPasswordPage />}
          />
        </Route>
        <Route
          element={
            <RequireAuth>
              <PrivateLayout />
            </RequireAuth>
          }
        >
          <Route
            path={routes.dashboard.root}
            element={
              <Suspense fallback={<>...</>}>
                <DashboardModuleRouter />
              </Suspense>
            }
          />

          <Route
            path={routes.examination}
            element={
              <ExaminationApiContextProvider value={authorizedAxios}>
                <ExaminationPage />
              </ExaminationApiContextProvider>
            }
          />

          {/* todo: fix nesting*/}
          <Route path={routes.patients.root} element={<PatientsListPage />} />
          <Route path={routes.messages} element={<MessagesListPage />} />
        </Route>

        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
});
