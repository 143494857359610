import {
  AnsweredQuestion,
  QuestionAnswerOption,
  TaggedAnsweredQuestionMap,
} from '@higo/common/src/features/FilledSurvey/model';
import { useMemo } from 'react';
import { indexBy, isNil } from 'rambda';
import { SurveyQuestionTag } from '@higo/api';

export const useAnamnesisSurveyGroups = (
  taggedAnsweredSurveyQuestionMap?: TaggedAnsweredQuestionMap,
) => {
  return useMemo(() => {
    if (isNil(taggedAnsweredSurveyQuestionMap)) {
      return {
        patientConditionGroup: [] as AnsweredQuestion[],
        ailmentsGroup: [] as QuestionAnswerOption[],
        otherGroup: [] as AnsweredQuestion[],
        notReportedAilments: [],
      };
    }

    //todo add code based sort
    const patientConditionGroup =
      taggedAnsweredSurveyQuestionMap[SurveyQuestionTag.PatientCondition];

    const otherGroup = taggedAnsweredSurveyQuestionMap[SurveyQuestionTag.Other];

    const ailments =
      taggedAnsweredSurveyQuestionMap[SurveyQuestionTag.Ailments]?.[0];

    const ailmentsGroup =
      taggedAnsweredSurveyQuestionMap[SurveyQuestionTag.Ailments]?.[0]
        ?.selectedAnswers ?? [];

    const selectedAilmentsCodes = indexBy('code')(ailmentsGroup);

    const notReportedAilments = ailments?.selectableAnswers?.filter(
      ({ code }) => !selectedAilmentsCodes.hasOwnProperty(code),
    );

    return {
      patientConditionGroup,
      ailmentsGroup,
      otherGroup,
      notReportedAilments,
    };
  }, [taggedAnsweredSurveyQuestionMap]);
};
