import { IconBox, IconProps } from '../IconBox';

const RoundCoughIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12ZM9.876 9.486c0 .377.252.628.63.628h3.718c1.198 0 2.206-1.006 2.206-2.2 0-1.194-1.008-2.2-2.206-2.2h-.34c-.378 0-.63.252-.63.629s.252.629.63.629h.34c.505 0 .945.44.945.943 0 .502-.44.942-.945.942h-3.718c-.378 0-.63.251-.63.629Zm2.067 8.171c0 .377.252.629.63.629h1.248c1.197 0 2.206-1.006 2.206-2.201 0-1.194-1.008-2.2-2.206-2.2H8.086c-.378 0-.63.252-.63.629s.252.629.63.629h5.735c.504 0 .946.44.946.942a.968.968 0 0 1-.946.943h-1.248c-.378 0-.63.252-.63.629Zm5.37-5.028H6.572c-.378 0-.63-.252-.63-.629s.252-.629.63-.629h10.74c.378 0 .63.252.63.629s-.252.629-.63.629Z"
    />
  </IconBox>
);

export default RoundCoughIcon;
