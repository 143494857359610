import { useCallback } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { SearchInput } from '@higo/ui';
import { routes } from 'routes';
import { useIntl } from 'react-intl';

export const HeaderSearchInput = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const handleSubmit = useCallback(
    (value: string) => {
      navigate({
        pathname: routes.patients.root,
        search: `?${createSearchParams({
          search: value,
        })}`,
      });
    },
    [navigate],
  );

  return (
    <SearchInput
      onSubmit={handleSubmit}
      id="header-search-input"
      placeholder={intl.formatMessage({
        id: 'header.searchPatient.placeholder',
      })}
    />
  );
};
