import { FileResultDto } from '@higo/api/lib/models/fileResultDTO';
import { indexAuscultationResultsByPointer } from '@higo/common/src/features/ExaminationPage/utils/auscultationUtils';
import { range } from 'rambda';
import { FileResource } from '@higo/common/src/types';
import { AuscultationGroupPoint } from '@higo/common/src/components';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';

export const getAuscultationPointTagFieldName = (point: number) =>
  `taggedPoint-${point}`;

export const buildAuscultationPoints = (
  fileResults: FileResultDto[] = [],
  fileResourceMap: Record<string, FileResource> = {},
  pointsCount: number,
  pointMetaData: (
    point: number,
  ) => Omit<Partial<AuscultationGroupPoint>, 'file'> = () => ({}),
) => {
  const pointerLookup = indexAuscultationResultsByPointer(fileResults);

  return range(1, pointsCount + 1).map((point) => {
    const pointerFileName = pointerLookup[point]?.filename;
    const intl = useIntl();

    return {
      id: `point-${point}`,
      label: `${intl.formatMessage({
        id: 'common.point',
      })} -${point}`,

      tagFieldName: getAuscultationPointTagFieldName(point),
      file: pointerFileName ? fileResourceMap?.[pointerFileName] : undefined,
      ...pointMetaData(point),
    };
  });
};
