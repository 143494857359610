import styled, { css } from 'styled-components';
import { TD } from '@higo/ui/src/components/Table/components/TD';
import ChevronIcon from '@higo/ui/src/components/icons/ChevronIcon';
import { FC, useCallback, useMemo } from 'react';
import { HeaderGroup, TableSorting } from 'react-table';
import clsx from 'clsx';
import { isNil } from 'rambda';
import {
  TableSortAsc,
  TableSortDesc,
} from '@higo/ui/src/components/Table/utils';

export const TH = styled(TD)(
  ({ theme }) => css`
    height: 2.5rem;
    font-weight: 400;
    color: ${theme.palette.secondary['500']};
    &:before {
      content: none;
    }
  `,
);

const SortButton = styled('button')(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.125rem;
    border: none;
    background: transparent;
    min-width: 1.5rem;

    &.sorted {
      color: ${theme.palette.primary['500']};
    }
  `,
);

type THSortButtonProps = {
  sorting: TableSorting<any>;
  sortField: string;
  alwaysSorted: boolean;
  id?: string;
};

const THSortButton: FC<THSortButtonProps> = ({
  sortField,
  sorting,
  alwaysSorted,
  id,
}) => {
  const isSorted = useMemo(
    () => !isNil(sorting.columnSortState[sortField]),
    [sorting, sortField],
  );

  const isSortedDesc = sorting.columnSortState[sortField] === TableSortDesc;

  const handleSortBy = useCallback(
    () =>
      sorting.toggleSortBy({
        sortField,
        direction: !sorting.columnSortState[sortField]
          ? TableSortDesc
          : isSortedDesc
          ? TableSortAsc
          : alwaysSorted
          ? TableSortDesc
          : false,
      }),
    [sortField, alwaysSorted, sorting, isSortedDesc],
  );

  return (
    <SortButton
      className={clsx({ sorted: isSorted })}
      onClick={handleSortBy}
      id={id}
    >
      <ChevronIcon
        size={16}
        direction={!isSorted || isSortedDesc ? 'down' : 'up'}
      />
    </SortButton>
  );
};

const StyledTHead = styled(TH)`
  white-space: pre-line;
  word-wrap: normal;
`;

export const THRenderer = <T extends Record<string, unknown>>({
  sorting,
  selection,
  sortField,
  alwaysSorted = false,
  render,
  getHeaderProps,
  id,
}: HeaderGroup<T>) => {
  const renderedHeader = render('Header', { sorting, selection });

  return (
    <StyledTHead {...getHeaderProps()} id={id}>
      {typeof renderedHeader === 'string'
        ? renderedHeader // TODO: wrap with translate function
        : renderedHeader}
      {sortField && sorting && (
        <THSortButton
          sortField={sortField}
          sorting={sorting}
          alwaysSorted={alwaysSorted}
          id={`sort-button-${id}`}
        />
      )}
    </StyledTHead>
  );
};
