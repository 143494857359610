import videojs, { VideoJsPlayer } from 'video.js';
import { mergeLeft } from 'rambda';
import { VideoJsPluginOptions } from '@higo/common/src/ext/VideoJs/controls/videoJsPluginOptions';
import registerComponent = videojs.registerComponent;

export interface SeekForwardOptions extends VideoJsPluginOptions {
  seekBy?: number;
}

const Button = videojs.getComponent('Button');

class SeekForward extends Button {
  seekBy = 1;

  constructor(player: VideoJsPlayer, options: SeekForwardOptions) {
    super(player, options);

    this.seekBy = options.seekBy ?? 1;
  }

  buildCSSClass() {
    return `vjs-seek-forward-control ${super.buildCSSClass()}`;
  }

  createEl(tag: string, props = {}, attributes = {}) {
    tag = 'button';

    props = mergeLeft(
      {
        className: this.buildCSSClass(),
      },
      props,
    );

    attributes = mergeLeft(
      {
        type: 'button',
      },
      attributes,
    );

    const el = videojs.dom.createEl(
      tag,
      props,
      attributes,
    ) as HTMLButtonElement;

    this.createControlTextEl(el);

    return el;
  }

  handleClick() {
    const player = this.player();
    const postSeekTime = player.currentTime() + this.seekBy;
    player.currentTime(
      postSeekTime > player.duration() ? player.duration() : postSeekTime,
    );
  }
}

registerComponent('SeekForward', SeekForward);
