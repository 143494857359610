import { useMutation, useQueryClient } from 'react-query';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';
import {
  PhysicalExaminationType,
  requestGenerateRightEarExaminationVideo,
} from '@higo/api';
import { DoctorTrackedType } from '@higo/common/lib/features/TrackedEvent';
import { useDispatchDoctorTrackedType } from 'hooks/useDispatchDoctorTrackedEvent';

export const useRequestGenerateRightEarExaminationVideoMutation = () => {
  const { authorizedAxios } = useDoctorPortalApiContext();
  const queryClient = useQueryClient();
  const sendTrackedEvent = useDispatchDoctorTrackedType();

  return useMutation(requestGenerateRightEarExaminationVideo(authorizedAxios), {
    onSuccess: (_, variables) => {
      sendTrackedEvent({
        event: DoctorTrackedType.RequestVisualExaminationVideo,
        eventResult: 'success',
        physicalExaminationType: PhysicalExaminationType.RightEar,
        examinationId: variables,
      });
    },

    onError: (error, variables, context) => {
      sendTrackedEvent({
        event: DoctorTrackedType.RequestVisualExaminationVideo,
        eventResult: 'error',
        physicalExaminationType: PhysicalExaminationType.RightEar,
        examinationId: variables,
      });

      // call global error handler
      return queryClient
        .getDefaultOptions()
        ?.mutations?.onError?.(error, variables, context);
    },
  });
};
