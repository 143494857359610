import { AxiosRequestConfig } from 'axios';
import { OAuth2AccessToken } from '../oAuth2AccessToken';

export const setAuthHeader = (
  token: string,
  config: AxiosRequestConfig,
): AxiosRequestConfig => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${token}`,
  },
});

export interface AccessTokenInterceptorProps {
  getAccessToken: () => OAuth2AccessToken | undefined;
}

export const createAccessTokenInterceptor =
  ({ getAccessToken }: AccessTokenInterceptorProps) =>
  async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const auth = getAccessToken();

    if (auth == null) {
      return config;
    } else {
      return setAuthHeader(auth.accessToken, config);
    }
  };
