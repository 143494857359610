import {
  DoctorPatientSearchResultDTO,
  PaginatedListReqDTO,
  PaginatedListResultDTO,
  PatientExaminationsDTO,
} from '@higo/api/src/models';
import { flatten } from '@higo/api/src/utils';
import { stringify } from 'qs';
import { SetPinDTO } from '@higo/api/src/models/setPinDTO';
import { AxiosInstance } from 'axios';

export const fetchPatient = (axios: AxiosInstance) => (id: number) =>
  axios.get<any>(`api/patients/${id}`).then(flatten);

export const fetchPatientsList =
  (axios: AxiosInstance) => (searchText: string, data?: PaginatedListReqDTO) =>
    axios
      .get<PaginatedListResultDTO<DoctorPatientSearchResultDTO>>(
        `api/v2/doctor/patients?searchText=${searchText}&${stringify(data, {
          allowDots: true,
        })}`,
      )
      .then(flatten);

export const fetchPatientExaminations =
  (axios: AxiosInstance) =>
  (patientId: number, pageData?: PaginatedListReqDTO) =>
    axios
      .get<PaginatedListResultDTO<PatientExaminationsDTO>>(
        `api/v2/patients/${patientId}/examinations?${stringify(pageData, {
          allowDots: true,
        })}`,
      )
      .then(flatten);

export const setPatientPin = (axios: AxiosInstance) => (dto: SetPinDTO) =>
  axios.post(`api/patients/pin/set`, dto).then(flatten);
