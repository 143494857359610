import { useQuery } from 'react-query';
import { fetchCoughAuscultationPhysicalExamination } from '@higo/api/lib/services';
import { useExaminationApiContext } from '@higo/common/src/features/ExaminationPage/providers';
import { ExaminationQueryKey } from '@higo/common/src/features/ExaminationPage/ExaminationQueryKey';
import { CoughAuscultationPhysicalExaminationDTO } from '@higo/api/lib/models/physicalExaminations';
import { pipe, prop } from 'rambda';
import { useExaminationAzureResourcesQuery } from '@higo/common/src/features/ExaminationPage/hooks/queries/useExaminationAzureResourcesQuery';
import {
  indexByFileName,
  remapToFileIdArray,
} from '@higo/common/src/utils/fileResourceUtils';

export const useCoughAuscultationPhysicalExaminationQuery = <
  TData = CoughAuscultationPhysicalExaminationDTO,
>(
  examinationId: number,
  select?: (dto: CoughAuscultationPhysicalExaminationDTO) => TData,
) => {
  const axios = useExaminationApiContext();

  return useQuery(
    [ExaminationQueryKey.CoughAuscultationPhysicalExamination, examinationId],
    async () => fetchCoughAuscultationPhysicalExamination(axios)(examinationId),
    {
      enabled: !isNaN(examinationId),
      select,
    },
  );
};

// todo: whole pipe should be extracted but there were typing issues

const selectFileResultIds: (
  data: CoughAuscultationPhysicalExaminationDTO,
) => number[] = pipe(prop('coughResults'), remapToFileIdArray);

export const useCoughAuscultationPhysicalExaminationResourceFiles = (
  id: number,
) => {
  const { data: ids } = useCoughAuscultationPhysicalExaminationQuery(
    id,
    selectFileResultIds,
  );

  return useExaminationAzureResourcesQuery(ids ?? [], indexByFileName);
};
