import styled, { css } from 'styled-components';
import { FC, ReactNode } from 'react';

const LegendHeader = styled('h5')(
  ({ theme }) => css`
    margin: 0 0 0.5rem;
    padding: 0;
    ${theme.typography.subtitle1};
    color: ${theme.palette.secondary[500]};
  `,
);

export const Container = styled.div`
  width: 9.75rem;
  height: 11.0625rem;
`;

export type LungsAuscultationLegendProps = {
  heading?: ReactNode;
};

export const LungsAuscultationLegendContainer: FC<LungsAuscultationLegendProps> =
  ({ heading, children }) => (
    <Container id="lungs-asculation-legend">
      {heading && (
        <LegendHeader id="lungs-legend-header">{heading}</LegendHeader>
      )}
      {children}
    </Container>
  );
