import styled, { css } from 'styled-components';
import { Select } from '@higo/ui/src/components/Select';
import { isNil } from 'rambda';
import { PaginationRange } from '@higo/ui/src/components/Pagination/PaginationRange';
import { ReactNode, useCallback, useMemo } from 'react';
import { SelectOption } from '@mui/base';

export interface PaginationProps {
  perPageLabel?: ReactNode;
  currentPageLabelSlot?: ReactNode;

  page: number;
  totalPages: number | undefined;
  pageSize: number;
  pageSizeOptions?: number[];

  goToPage: (v: number) => void;
  setPageSize: (v: number) => void;
}

const Wrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    border-top: 3px solid ${theme.palette.neutral['500']};
    padding: 0.5rem 0 1rem;
    margin-top: 0.5rem;
  `,
);

const Label = styled('span')(
  ({ theme }) => css`
    ${theme.typography.body};
    color: ${theme.palette.secondary['500']};
  `,
);

const PageSizeSelectWrapper = styled.div`
  --select-width: 4.6875rem;
`;

export const Pagination = ({
  page,
  pageSize,
  pageSizeOptions = [5, 10, 15, 20],
  totalPages = 0,
  currentPageLabelSlot,
  perPageLabel = 'Rows per page',
  goToPage,
  setPageSize,
}: PaginationProps) => {
  const pageSizeSelectOptions: SelectOption<number>[] = useMemo(
    () =>
      pageSizeOptions.map((perPage) => ({
        label: perPage,
        value: perPage,
      })),
    [pageSizeOptions],
  );

  const handleSetPageSize = useCallback(
    (page: number | null) => {
      if (!isNil(page)) {
        setPageSize(page);
        goToPage(1);
      }
    },
    [goToPage, setPageSize],
  );

  return (
    <Wrapper>
      <Label id="current-page-label-slot">{currentPageLabelSlot}</Label>

      <PaginationRange
        page={page}
        totalPages={totalPages}
        onChange={goToPage}
        id="pagination-range"
      />

      <Label id="row-per-page-label">{perPageLabel}</Label>
      <PageSizeSelectWrapper>
        <Select
          value={pageSize}
          onChange={handleSetPageSize}
          disabled={!totalPages}
          options={pageSizeSelectOptions}
          id="page-size-select-button"
        />
      </PageSizeSelectWrapper>
    </Wrapper>
  );
};
