export interface ListWrapperDTO<T> {
  items: T[];
}

export function wrap<T>(items: T[]): ListWrapperDTO<T> {
  return { items };
}

export function unwrap<T>({ items }: ListWrapperDTO<T>): T[] {
  return items;
}
