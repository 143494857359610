import { useMemo } from 'react';
import { differenceInMonths, isValid, parseISO } from 'date-fns';

export const useAgeLabel = (age: string): string => {
  return useMemo(() => {
    const date = parseISO(age);

    if (!isValid(date)) return '-';

    const months = Math.abs(differenceInMonths(date, new Date()));
    const years = Math.floor(months / 12);

    if (years < 2) {
      return `${months}m`;
    }

    if (years < 3) {
      return `${years}y ${months % 12}m`;
    }

    return `${years}y`;
  }, [age]);
};
