import { useProfileDataQuery } from 'hooks/queries/useProfileDataQuery';
import { isNil } from 'rambda';
import { useUserSettings } from 'hooks/useUserSettings';
import { useEffect, useState } from 'react';

/**
 * We consider user logged in only when he/she has both
 * profile data ( required for name, etc)
 * settings ( required for proper translations)
 */
export const useIsLoggedIn = () => {
  const {
    data: profileData,
    isLoading: isProfileDataLoading,
    isIdle: isProfileIdle,
  } = useProfileDataQuery();

  const { data: userSettings, isLoading: isUserSettingsLoading } =
    useUserSettings();

  const isLoading = isProfileDataLoading || isUserSettingsLoading;

  const [isAutoLogin, setIsAutoLogin] = useState(!isProfileIdle); //Initial loading will only happen if access token has been rehydrated and profile will try to fetch data

  useEffect(() => {
    if (isAutoLogin && !isLoading) {
      setIsAutoLogin(false);
    }
  }, [isAutoLogin, isLoading]);

  return {
    isAutoLogin,
    isLoggedIn: !isNil(profileData) && !isNil(userSettings),
    isLoading,
  };
};
