import styled, { css } from 'styled-components';

export const OverviewDiseasesColumnWrapper = styled('div')(
  ({ theme }) => css`
    display: grid;
    grid-row-gap: 1rem;
    grid-template-columns: minmax(18rem, 1fr);

    ${theme.media.desktop.min} {
      display: grid;
      grid-template-columns: minmax(18rem, 1fr) min-content;
      grid-gap: 2rem;
      grid-row-gap: 1rem;
    }
  `,
);
