import React, { FC, ReactNode } from 'react';
import { Heading, AudioDownloadIcon } from '@higo/ui';
import styled from 'styled-components';
import { StyleProps } from '@summer/jst-react';
import { useAudioAzureResourcesQuery } from '@higo/common/src/features/ExaminationPage/hooks/queries/physicalExamination/useAudioAzureResourcesQuery';
import { prepareFilesForSingleDownload } from '@higo/common/src/features/ExaminationPage/utils/prepareFilesForSingleDownload';

const Container = styled.div`
  display: flex;
`;

const DownloadIcons = styled.div`
  margin-top: 0.8rem;
  margin-left: 1rem;
  width: 8rem;
  font-size: 0.8rem;
  color: grey;
  display: flex;
  justify-content: space-between;
`;

const AdditionalActions = styled.div`
  display: flex;
  margin-left: auto;
`;

const StyledHeading = styled(Heading)`
  margin-top: 0.5rem;
`;

export type SectionHeaderProps = {
  title: ReactNode;
  audioDownload?: 'single' | 'double';
  filesIds?: any;
  audioLinksToDownload?: (string | undefined)[];
  id?: string;
} & StyleProps;

const isDownloadingAvailable = process.env.FILES_DOWNLOADING === 'yes';

export const SectionHeader: FC<SectionHeaderProps> = ({
  className,
  style,
  title,
  audioDownload,
  filesIds,
  children,
  id,
}) => {
  const showROWandDSPIcons =
    isDownloadingAvailable && audioDownload && audioDownload === 'double';
  const showOnlyROWIcon =
    isDownloadingAvailable && audioDownload && audioDownload === 'single';

  const { data, refetch } = useAudioAzureResourcesQuery(filesIds, true);
  const { data: dspData, refetch: refetchDsp } = useAudioAzureResourcesQuery(
    filesIds,
    false,
  );

  const handleClickRawDownloadZip = async () => {
    const result: any = await refetch();

    const linksToDownload = result.isSuccess
      ? result.data?.map((x: any) => x.link)
      : [];

    // prepareFilesForZIPDownload('higo_examination', linksToDownload);

    prepareFilesForSingleDownload(linksToDownload);
  };

  const handleClickDspDownload = async () => {
    const result = await refetchDsp();
    const linksToDownload = result.isSuccess
      ? result.data?.map((x) => x.link)
      : [];

    prepareFilesForSingleDownload(linksToDownload);
  };

  return (
    <Container className={className} style={style} id={id}>
      <StyledHeading level={3}>{title}</StyledHeading>
      <DownloadIcons>
        {showROWandDSPIcons && (
          <>
            <a onClick={handleClickRawDownloadZip}>
              <AudioDownloadIcon />
              RAW
            </a>
            <a onClick={handleClickDspDownload}>
              <AudioDownloadIcon />
              DSP
            </a>
          </>
        )}

        {showOnlyROWIcon && (
          <a onClick={handleClickRawDownloadZip}>
            <AudioDownloadIcon />
          </a>
        )}
      </DownloadIcons>
      <AdditionalActions>{children}</AdditionalActions>
    </Container>
  );
};
