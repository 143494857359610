import { FC, memo } from 'react';
import { StyleProps } from '@summer/jst-react';

export type ResponsiveImageProps = {
  alt: string;
  srcSet: { src: string; media: string }[];
};

export const ResponsiveImage: FC<ResponsiveImageProps & StyleProps> = memo(
  ({ srcSet, alt, ...rest }) => (
    <img
      src={srcSet[0].src}
      srcSet={srcSet.reduce(
        (acc, { src, media }) => `${acc}${src} ${media}, `,
        '',
      )}
      alt={alt}
      {...rest}
    />
  ),
);
