import { isNil } from 'rambda';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  isValid,
  parseJSON,
} from 'date-fns';

export const coerceDate = (date: Date | string) =>
  typeof date === 'string' ? parseJSON(date) : date;

export const differenceToHHMMSS = (segments: (number | undefined)[]) =>
  segments
    .map((num) => (!isNil(num) ? num.toString().padStart(2, '0') : '00'))
    .join(':');

export const calculateWaitingTime = (date: string | Date): string => {
  const coercedDate = coerceDate(date);

  if (!isValid(coercedDate)) return '';

  coercedDate.setSeconds(0);

  const days = Math.abs(differenceInDays(coercedDate, new Date()));
  const hours = Math.abs(differenceInHours(coercedDate, new Date()));
  const minutes = Math.abs(differenceInMinutes(coercedDate, new Date())) % 60;

  if (hours < 24) {
    return differenceToHHMMSS([hours, minutes]);
  }

  if (days < 3) {
    return `${days} ${differenceToHHMMSS([hours])}`;
  }

  if (days < 7) {
    return `${days} days`;
  }

  return `7+ days`;
};
