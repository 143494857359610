import { FC, memo, useCallback, useState } from 'react';
import styled from 'styled-components';
import { Badge, Menu, MenuButton, NotificationIcon, useMenu } from '@higo/ui';
import { HeaderNotificationMenuItem } from './HeaderNotificationMenuItem';
import { useUnreadMessagesQuery } from 'components/Header/HeaderNotificationMenu/hooks/useUnreadMessagesQuery';
import { PatientUnreadMessage } from '@higo/api/lib/models/patientUnreadMessageDTO';
import { FormattedMessage } from 'react-intl';
import { ChatModal, ChatModalProps } from 'components/ChatModal';
import { ChatWindowProps } from 'components/ChatModal/ChatWindow';
import { Link } from 'react-router-dom';
import { routes } from 'routes';
import { HeaderNotificationMenuHeaderMenuItem } from './HeaderNotificationMenuHeaderMenuItem';
import { HeaderNotificationMenuFooterMenuItem } from './HeaderNotificationMenuFooterMenuItem';
import { isEmpty } from 'rambda';

const StyledHeaderNotificationMenuItem = styled(HeaderNotificationMenuItem)`
  width: 13rem;
`;

type HeaderMessagesNotificationsMenuItem = {
  patient: ChatWindowProps['patient'];
  examinationId: number;
};

const mapToHeaderMessagesNotificationsMenuItem = (
  x: PatientUnreadMessage,
): HeaderMessagesNotificationsMenuItem => ({
  patient: {
    id: x.patient.id,
    firstName: x.patient.firstName,
    lastName: x.patient.lastName,
  },
  examinationId: x.examinationId,
});

export const HeaderMessagesNotificationsMenu: FC = memo(() => {
  const { isOpened, anchorEl, toggle, close } = useMenu();
  const { data } = useUnreadMessagesQuery((x) => ({
    list: x.content.map(mapToHeaderMessagesNotificationsMenuItem),
    totalUnreadMessages: x.totalElements,
  }));

  const [chatModalProps, setChatModalProps] =
    useState<ChatModalProps | undefined>(undefined);

  const handleNotificationMenuItemClick = useCallback(
    (item: HeaderMessagesNotificationsMenuItem) => {
      setChatModalProps({
        initialVisible: true,
        examinationId: item.examinationId,
        patient: item.patient,
      });
    },
    [],
  );

  return (
    <>
      {chatModalProps && (
        <ChatModal
          {...chatModalProps}
          triggerLess={true}
          onClose={() => setChatModalProps(undefined)}
        />
      )}
      <MenuButton
        id="messages-button"
        menuId="messages-menu"
        isMenuOpened={isOpened}
        onClick={toggle}
      >
        <Badge count={data?.totalUnreadMessages ?? 0}>
          <NotificationIcon size={18} />
        </Badge>
      </MenuButton>

      <Menu
        id="messages-menu"
        anchorEl={anchorEl}
        open={isOpened}
        onClose={close}
      >
        <HeaderNotificationMenuHeaderMenuItem>
          <div id="messages-notification-title">
            <FormattedMessage id="header.notifications.messages.title" />
          </div>
        </HeaderNotificationMenuHeaderMenuItem>

        {data?.list && isEmpty(data.list) && (
          <HeaderNotificationMenuItem disabled id="messages-empty">
            <FormattedMessage id="header.notifications.messages.empty" />
          </HeaderNotificationMenuItem>
        )}

        {data?.list.map((item) => (
          <StyledHeaderNotificationMenuItem
            key={item.examinationId}
            onClick={() => handleNotificationMenuItemClick(item)}
            id="message-item-title"
          >
            <FormattedMessage
              id="header.notifications.messages.patientWroteAMessage"
              values={{
                strong: (v) => <strong>{v}</strong>,
                fullName: `${item.patient.firstName} ${item.patient.lastName}`,
              }}
            />
          </StyledHeaderNotificationMenuItem>
        ))}

        <HeaderNotificationMenuFooterMenuItem>
          <Link to={routes.messages} id="see-all-messages-link">
            <FormattedMessage id="header.notifications.seeAll" />
          </Link>
        </HeaderNotificationMenuFooterMenuItem>
      </Menu>
    </>
  );
});
