import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { IncomingMessage, OutgoingMessage } from './MessageTemplate';
import { Avatar, combineString } from '@higo/ui';
import { format } from 'date-fns';

type MessageWrapperProps = {
  $isIncomingMessage: boolean;
};

const MessageWrapper = styled('div')<MessageWrapperProps>(
  ({ $isIncomingMessage }) =>
    $isIncomingMessage
      ? css`
          & > * {
            float: left;
            clear: both;
          }
        `
      : css`
          & > * {
            float: right;
            clear: both;
          }
        `,
);

const AuthorContainer = styled.div`
  display: flex;
  column-gap: 0.25rem;
`;

const AuthorText = styled('span')(
  ({ theme }) => css`
    ${theme.typography.subtitle2};
    color: ${theme.palette.secondary['900']};
  `,
);

const DateText = styled('span')(
  ({ theme }) => css`
    ${theme.typography.body2};
    color: ${theme.palette.secondary['500']};
    margin-bottom: 0.5rem;
  `,
);

export interface MessageProps {
  commentText: string;
  authorUserId: number;
  firstName?: string; // todo: fix typing
  lastName?: string; // todo: fix typing
  createDate: Date;
  read?: boolean;
  isIncomingMessage: boolean;
  continuedThread?: boolean;
}

export const ChatMessage: FC<MessageProps> = ({
  commentText,
  createDate,
  firstName,
  lastName,
  isIncomingMessage,
  continuedThread,
}) => {
  const messageAuthorFullName = combineString([firstName, lastName]);
  const Message = isIncomingMessage ? IncomingMessage : OutgoingMessage;
  const formattedDate = format(createDate, 'HH:mm | dd/MM/yyyy');

  return (
    <MessageWrapper $isIncomingMessage={isIncomingMessage}>
      {continuedThread ? null : (
        <AuthorContainer id="author-container">
          <AuthorText id="author-text">
            {(isIncomingMessage ? `User: ` : `Dr `) +
              `${messageAuthorFullName}`}
          </AuthorText>
          <Avatar firstName={firstName} lastName={lastName} size="small" />
        </AuthorContainer>
      )}
      <DateText id="date-text">{formattedDate}</DateText>
      <Message id="message-text">{commentText}</Message>
    </MessageWrapper>
  );
};
