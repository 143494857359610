import { registerUserFcmToken } from '@higo/api';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';
import { useMutation } from 'react-query';
import { DoctorMutationKey } from 'config/doctorMutationKey';

export const useRegisterUserFcmTokenMutation = () => {
  const { authorizedAxios } = useDoctorPortalApiContext();

  return useMutation(
    DoctorMutationKey.AuthToken,
    registerUserFcmToken(authorizedAxios),
  );
};
