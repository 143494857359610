import { FC, useMemo } from 'react';
import { combineAcronym } from '../utils';
import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';
import { StyleProps } from '@summer/jst-react';

type AvatarColor = 'primary' | 'secondary';
type AvatarSize = 'small' | 'medium' | 'large';

export interface AvatarProps {
  firstName?: string;
  lastName?: string;
  src?: string;
  color?: AvatarColor;
  size?: AvatarSize;
}

const colorStyleMap: Record<
  AvatarColor,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  primary: css(
    ({ theme }) => css`
      background-color: ${theme.palette.primary[500]};
      color: ${theme.palette.white};
    `,
  ),
  secondary: css(
    ({ theme }) => css`
      background-color: ${theme.palette.secondary[300]};
      color: ${theme.palette.text};
    `,
  ),
};

const sizeStyleMap: Record<
  AvatarSize,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  small: css(
    () => css`
      width: 1rem;
      height: 1rem;
      font-size: 0.5rem;
    `,
  ),
  medium: css(
    () => css`
      width: 1.5rem;
      height: 1.5rem;
      font-size: 0.8125rem;
    `,
  ),
  large: css(
    () => css`
      width: 2.75rem;
      height: 2.75rem;
      font-size: 1.125rem;
    `,
  ),
};

const Container = styled('div')<{
  $color: AvatarColor;
  $size: AvatarSize;
}>(
  ({ theme, $color, $size }) => css`
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    object-position: 50% 50%;
    max-height: 100%;
    max-width: 100%;
    font-weight: bold;
    border-radius: 50%;
    font-family: ${theme.fontFamily} ${colorStyleMap[$color]}
      ${sizeStyleMap[$size]};
  `,
);

export const Avatar: FC<AvatarProps & StyleProps> = ({
  firstName,
  lastName,
  color = 'primary',
  size = 'medium',
  ...props
}) => {
  const fullNameAcronym = useMemo(
    () => combineAcronym([firstName, lastName]),
    [firstName, lastName],
  );

  return props.src ? (
    <Container
      as="img"
      alt={fullNameAcronym}
      $color={color}
      $size={size}
      {...props}
    />
  ) : (
    <Container $color={color} $size={size} {...props}>
      {fullNameAcronym}
    </Container>
  );
};
