import { FC, ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FullFrameDiagnosticPhotosGalleryContainer } from '@higo/common/src/components';
import { useSkinExaminationResultsQuery } from '@higo/common/src/features/ExaminationPage/hooks/queries';
import { ExaminationPageSection } from '@higo/common/src/features/ExaminationPage/sections/ExaminationPageSection';
import { ExaminationPageSectionId } from '@higo/common/src/features/ExaminationPage/models';
import { VisualExaminationDiagnosticViewerModel } from '@higo/common/src/features/ExaminationPage/components/VisualExaminationDiagnosticViewer/VisualExaminationDiagnosticViewerProps';
import {
  useSkinExaminationResultsLinks,
  useSkinExaminationVideoFramesIds,
  useSkinExaminationVideoResultsLink,
} from './hooks';
import { VisualExaminationDiagnosticViewerForPublic } from '@higo/common/src/features/ExaminationPage/components/VisualExaminationDiagnosticViewer';
import { WithTogglingViewer } from '@higo/common/src/features/ExaminationPage/components/VisualExaminationDiagnosticViewer/WithTogglingViewer';

export interface SkinExaminationSectionProps {
  examinationId: number;
  requestGenerateSkinExaminationVideoButtonSlot?: ReactNode;
  hasTogglingGalleryVideo?: boolean;
  handleSkinImageForPDFChange?: (index: string) => void;
  handleIsSkinImageDiagnosticChange?: (index: boolean) => void;
  handleIsSkinDataLoadingChange?: (index: boolean) => void;
}

const model: VisualExaminationDiagnosticViewerModel = {
  usePhysicalExaminationResultsQuery: useSkinExaminationResultsQuery,
  usePhysicalExaminationGalleryResultsLinks: useSkinExaminationResultsLinks,
  usePhysicalExaminationVideoFramesIds: useSkinExaminationVideoFramesIds,
  usePhysicalExaminationVideoResultsLink: useSkinExaminationVideoResultsLink,
};

const galleryViewerProps = {
  thumbnailsSlidesPerView: 8,
};

export const SkinExaminationSection: FC<SkinExaminationSectionProps> = ({
  examinationId,
  requestGenerateSkinExaminationVideoButtonSlot,
  hasTogglingGalleryVideo,
  handleSkinImageForPDFChange,
  handleIsSkinImageDiagnosticChange,
  handleIsSkinDataLoadingChange,
}) => {
  const { data, isFetched: isDataLoading } =
    useSkinExaminationResultsQuery(examinationId);
  const { data: galleryResults, isFetched: isGalleryLoading } =
    model.usePhysicalExaminationGalleryResultsLinks(examinationId);
  const rowData = data?.skinResults;

  const [skinGalleryActiveLink, setskinGalleryActiveLink] = useState('');

  const handleskinGalleryActiveLinkChange = (index: string) => {
    setskinGalleryActiveLink(index);
  };

  const diagnosticIndex = data && data.diagnosticImageIndex;

  const skinDiagnosticImageLink =
    data?.diagnosticImageIndex &&
    galleryResults &&
    galleryResults.find(
      (item) =>
        diagnosticIndex &&
        item.fileName === data.skinResults[diagnosticIndex].filename,
    )?.link;

  const skinMiddleImageLink =
    data?.skinResults &&
    galleryResults &&
    galleryResults[Math.floor(data?.skinResults.length / 2)]?.link;

  const skinImageForPDF =
    skinDiagnosticImageLink || skinGalleryActiveLink || skinMiddleImageLink;
  data &&
    skinImageForPDF &&
    handleSkinImageForPDFChange &&
    handleSkinImageForPDFChange(skinImageForPDF);
  data &&
    skinImageForPDF &&
    handleIsSkinImageDiagnosticChange &&
    handleIsSkinImageDiagnosticChange(data.hasDiagnosticImage);
  handleIsSkinDataLoadingChange &&
    handleIsSkinDataLoadingChange(!isDataLoading || !isGalleryLoading);

  return (
    <ExaminationPageSection
      title={
        <FormattedMessage id="examinationPage.section.skinExamination.title" />
      }
      sectionId={ExaminationPageSectionId.Skin}
    >
      <FullFrameDiagnosticPhotosGalleryContainer>
        {hasTogglingGalleryVideo ? (
          <WithTogglingViewer
            examinationId={examinationId}
            model={model}
            requestGenerateVideoButtonSlot={
              requestGenerateSkinExaminationVideoButtonSlot
            }
            galleryViewerProps={galleryViewerProps}
            rowData={rowData}
            setActiveGalleryLink={handleskinGalleryActiveLinkChange}
          />
        ) : (
          <VisualExaminationDiagnosticViewerForPublic
            examinationId={examinationId}
            model={model}
            galleryViewerProps={galleryViewerProps}
            rowData={rowData}
          />
        )}
      </FullFrameDiagnosticPhotosGalleryContainer>
    </ExaminationPageSection>
  );
};
