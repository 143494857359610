import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { Button, RHFTextField } from '@higo/ui';
import { FormattedMessage } from 'react-intl';
import { object, ref, string } from 'yup';
import { passwordValidationRegex } from 'config/validationSchemaParams';
import { setYupDefaultMessages } from 'config/yupLocale';

setYupDefaultMessages();
const schema = object({
  password: string()
    .required()
    .matches(passwordValidationRegex, 'validation.passwordRegex'),

  passwordConfirmation: string()
    .required()
    .oneOf([ref('password')], 'validation.passwordMatch'),
});

export interface SetNewPasswordFormModel {
  password: string;
  passwordConfirmation: string;
}

const defaultValues: SetNewPasswordFormModel = {
  password: '',
  passwordConfirmation: '',
};

export type SetNewPasswordFormProps = {
  onSubmit: (value: SetNewPasswordFormModel) => Promise<unknown>;
};

export const SetNewPasswordForm: FC<SetNewPasswordFormProps> = ({
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<SetNewPasswordFormModel>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RHFTextField
        control={control}
        type="password"
        label="common.password"
        name="password"
        autoComplete="new-password"
      />
      <RHFTextField
        control={control}
        type="password"
        label="common.passwordConfirmation"
        name="passwordConfirmation"
        autoComplete="new-password"
      />
      <Button
        type="submit"
        disabled={isSubmitting}
        isLoading={isSubmitting}
        fullWidth
      >
        <FormattedMessage id="save" />
      </Button>
    </form>
  );
};
