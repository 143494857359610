import React, { FC, memo, useRef } from 'react';
import {
  Button,
  Heading,
  HorizontalDivider,
  ManagedModal,
  ManagedModalProps,
  SurveyIcon,
} from '@higo/ui';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import 'simplebar/dist/simplebar.min.css';
import { SurveyQuestionTag } from '@higo/api';
import SimpleBar from 'simplebar-react';
import {
  createAnsweredQuestionNonRenderableTagPredicate,
  SurveyRenderer,
} from '@higo/common/src/features/FilledSurvey';
import { isFilled } from '@higo/common/src/utils';
import { AnsweredSurvey } from '@higo/common/src/features/FilledSurvey/model';
import { CopiedTextOnly } from '@higo/common/src/components/CopiedTextOnly';
import { CopyTextContentButton } from './CopyTextContentButton';

const Container = styled('div')`
  padding: 1rem 1rem 3.75rem;
  display: grid;
  grid-template-columns: 1fr 2rem;
  grid-template-rows: 100%;
  grid-column-gap: 2rem;
`;

const StyledSimpleBar = styled(SimpleBar)`
  height: 100%;
  width: 100%;
`;

const SurveyGroup = styled.div`
  display: grid;
  grid-template-columns: 16rem 1fr;
  padding-bottom: 2rem;
  align-items: baseline;
`;

const StyledCopyTextContentButton = styled(CopyTextContentButton)`
  position: sticky;
  top: 1rem;
  right: 1rem;
`;

export interface ExaminationPageGeneralSurveyModalProps {
  answeredGeneralSurvey: AnsweredSurvey;
  trigger?: ManagedModalProps['trigger'];
  hasCopyFeature: boolean;
  hasToastWhenCopy: boolean;
}

const surveyRendererOptions = {
  isAnsweredQuestionRenderable: createAnsweredQuestionNonRenderableTagPredicate(
    new Set([SurveyQuestionTag.GeneralMedicaments, SurveyQuestionTag.Covid]),
  ),
};

export const ExaminationPageGeneralSurveyModal: FC<ExaminationPageGeneralSurveyModalProps> =
  memo(
    ({
      answeredGeneralSurvey,
      hasCopyFeature,
      hasToastWhenCopy,
      trigger = (
        <Button color="secondary" size="small" endIcon={<SurveyIcon />}>
          <FormattedMessage id="generalSurvey.modalTrigger.title" />
        </Button>
      ),
    }) => {
      const surveyContainerRef = useRef<HTMLDivElement>(null);

      const hasCovidQuestions = isFilled(
        answeredGeneralSurvey.taggedQuestions.COVID,
      );

      return (
        <ManagedModal fullWidth initialVisible={false} trigger={trigger}>
          <StyledSimpleBar>
            <Container ref={surveyContainerRef}>
              <div id="survey-container">
                <SurveyGroup id="survey-group">
                  <div id="survey-header">
                    <Heading level={3}>
                      <FormattedMessage id="generalSurvey" />:
                      <CopiedTextOnly>{'\r\n'}</CopiedTextOnly>
                    </Heading>
                  </div>
                  <div>
                    <SurveyRenderer
                      answeredQuestionsList={
                        answeredGeneralSurvey.surveyTreeList
                      }
                      options={surveyRendererOptions}
                    />
                  </div>
                </SurveyGroup>

                {hasCovidQuestions && (
                  <>
                    <HorizontalDivider />
                    <CopiedTextOnly>{'\r\n\r\n'}</CopiedTextOnly>

                    <SurveyGroup>
                      <Heading level={3}>
                        <FormattedMessage id="generalSurvey.covid19" />:
                        <CopiedTextOnly>{'\r\n'}</CopiedTextOnly>
                      </Heading>
                      <div>
                        <SurveyRenderer
                          answeredQuestionsList={
                            answeredGeneralSurvey.taggedQuestions.COVID!
                          }
                        />
                      </div>
                    </SurveyGroup>
                  </>
                )}
              </div>
              {hasCopyFeature && (
                <StyledCopyTextContentButton
                  contentRef={surveyContainerRef}
                  isGeneralSurveyButton
                  hasToastWhenCopy={hasToastWhenCopy}
                />
              )}
            </Container>
          </StyledSimpleBar>
        </ManagedModal>
      );
    },
  );
