import React, { FC, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { CounterChip } from '@higo/ui/src/components/CounterChip';
import { useObserveVisibleElements } from '@higo/ui/src/utils';
import { Tooltip } from './Tooltip';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ListContainer = styled.span`
  list-style: none;
  margin: 0 0.25rem 0 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ListItem = styled.span`
  &:after {
    content: ', ';
    padding-right: 0.25rem;
  }

  &:last-of-type:after {
    content: none;
  }
`;

const TooltipContainer = styled.div`
  flex-shrink: 0;
`;

interface CollapsibleListProps {
  items: Array<string | number>;
}

// todo: add storybook
export const SingleRowTooltipList: FC<CollapsibleListProps> = ({ items }) => {
  const ref = useRef<HTMLUListElement | null>(null);
  const { visibleItemsCount, invisibleItemsCount } =
    useObserveVisibleElements(ref);

  const invisibleItems = useMemo(
    () => items.slice(visibleItemsCount),
    [items, visibleItemsCount],
  );

  return (
    <Container>
      <ListContainer ref={ref}>
        {items?.map((item, index) => (
          <ListItem key={index}>{item}</ListItem>
        ))}
      </ListContainer>

      {invisibleItems?.length > 0 && (
        <TooltipContainer>
          <Tooltip
            title={
              <div>
                {invisibleItems?.map((item, index) => (
                  <div key={index}>{item}</div>
                ))}
              </div>
            }
          >
            <CounterChip>{invisibleItemsCount}</CounterChip>
          </Tooltip>
        </TooltipContainer>
      )}
    </Container>
  );
};
