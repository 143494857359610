import styled from 'styled-components';
import { FC, forwardRef, HTMLAttributes, MouseEvent } from 'react';
import { StyleProps } from '@summer/jst-react';

export interface MenuButtonProps {
  id: string;
  menuId: string;
  isMenuOpened: boolean;
  onClick: (e: MouseEvent<HTMLElement>) => void;
}

const CleanButton = styled.button`
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
`;

export const MenuButton: FC<MenuButtonProps & StyleProps> = forwardRef<
  HTMLButtonElement,
  HTMLAttributes<HTMLButtonElement> & MenuButtonProps
>(({ menuId, isMenuOpened, children, onClick, ...props }, ref) => (
  <CleanButton
    onClick={onClick}
    aria-controls={isMenuOpened ? menuId : undefined}
    aria-expanded={isMenuOpened ? 'true' : undefined}
    aria-haspopup="menu"
    ref={ref}
    {...props}
  >
    {children}
  </CleanButton>
));

MenuButton.displayName = 'MenuButton';
