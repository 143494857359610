import { IconBox, IconProps } from '../IconBox';

const AddIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 20.485a1.5 1.5 0 1 0 3 0V13.5h6.985a1.5 1.5 0 1 0 0-3H13.5V3.515a1.5 1.5 0 0 0-3 0V10.5H3.515a1.5 1.5 0 0 0 0 3H10.5v6.985Z"
    />
  </IconBox>
);

export default AddIcon;
