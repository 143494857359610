/* eslint-disable */
// @ts-ignore
export const useFormControlState = ({ props, formControlContext, states }) => {
  // @ts-ignore
  return states.reduce((acc, state) => {
    acc[state] = props[state];

    if (formControlContext) {
      if (typeof props[state] === 'undefined') {
        acc[state] = formControlContext[state];
      }
    }

    return acc;
  }, {});
};
