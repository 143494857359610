import { useMemo } from 'react';
import {
  combineString,
  DoubleFieldCellRenderer,
  DoubleFieldRendererValue,
} from '@higo/ui';
import { TableOptions } from 'react-table';
import { AgeCellRenderer } from 'components/TableRenderers/AgeCellRenderer';
import { DoctorPatientSearchResultDTO } from '@higo/api';
import { ActionButtonsCellRenderer } from 'pages/PatientsListPage/renderers/ActionButtonsCellRenderer';
import { formatDate } from '@higo/common/lib/utils/formatDate';
import { useIntl } from 'react-intl';

export type PatientsListItem = {
  id: number;
  patient: {
    fullName: DoubleFieldRendererValue;
    gender: string;
    age: string;
  };
  previousDiagnosis: DoubleFieldRendererValue;
  managePatient: {
    patientId: number;
  };
};

export const usePatientListConfig = () => {
  const intl = useIntl();

  return useMemo(
    (): Omit<TableOptions<PatientsListItem>, 'data'> => ({
      columns: [
        {
          Header: intl.formatMessage({ id: 'table.header.patientId' }),
          accessor: 'patient.fullName',
          width: 100,
          Cell: DoubleFieldCellRenderer,
        },
        {
          Header: intl.formatMessage({ id: 'table.header.gender' }),
          accessor: 'patient.gender',
          width: 90,
        },
        {
          Header: intl.formatMessage({ id: 'table.header.age' }),
          accessor: 'patient.age',
          width: 80,
          Cell: AgeCellRenderer,
        },
        {
          Header: intl.formatMessage({ id: 'table.header.previousHigoVisit' }),
          accessor: 'previousDiagnosis',
          width: 160,
          Cell: DoubleFieldCellRenderer,
        },
        {
          id: 'managePatient',
          accessor: 'managePatient',
          Cell: ActionButtonsCellRenderer,
        },
      ],
    }),
    [intl],
  );
};

export const mapToPatientList = (
  patientResults: DoctorPatientSearchResultDTO[],
): PatientsListItem[] =>
  patientResults.map((result) => ({
    id: result.patient.id,
    patient: {
      fullName: {
        highlighted: combineString([
          result.patient?.firstName,
          result.patient?.lastName,
        ]),
        text: result.patient?.idNumber,
      },
      gender: result.patient?.gender,
      age: result.patient?.dateOfBirth,
    },
    previousDiagnosis: {
      highlighted: formatDate(
        result.latestExamination?.diagnosis?.date,
        'dd/MM/yyyy',
      ),
      text: combineString([
        result.latestExamination?.diagnosis?.doctor?.firstName,
        result.latestExamination?.diagnosis?.doctor?.lastName,
      ]),
    },
    managePatient: {
      patientId: result.patient?.id,
    },
  }));
