import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';
import { FC } from 'react';
import { StyleProps } from '@summer/jst-react';

export type TagColor = 'primary' | 'secondary';

export type TagProps = {
  disabled?: boolean;
  color?: TagColor;
} & StyleProps;

const colorStyleMap: Record<
  TagColor,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  primary: css(
    ({ theme }) => css`
      color: ${theme.palette.white};
      background: ${theme.palette.secondary['500']};
    `,
  ),
  secondary: css(
    ({ theme }) =>
      css`
        background: ${theme.palette.white};
        color: ${theme.palette.secondary['500']};
      `,
  ),
};

const BasicTag = styled('div')<{ $color: TagColor }>(
  ({ theme, $color }) => css`
    display: inline-flex;
    align-items: center;

    ${theme.typography.body};
    font-weight: 700;

    padding: 0.25rem 0.5rem;
    border: 1px solid ${theme.palette.secondary['500']};
    border-radius: 4.1875rem;

    ${colorStyleMap[$color]}
  `,
);

const Text = styled('span')(
  ({ theme }) => css`
    white-space: initial;
    ${theme.typography.lineClamp(1)}
  `,
);

export const Tag: FC<TagProps> = ({
  children,
  color = 'primary',
  ...props
}) => {
  return (
    <BasicTag $color={color} {...props}>
      <Text>{children}</Text>
    </BasicTag>
  );
};
