import styled, { css } from 'styled-components';
import { useFormControlUnstyledContext } from '@mui/base/FormControlUnstyled';
import { FC } from 'react';
import clsx from 'clsx';

const invalidClass = 'invalid'; // todo: refactor to mui class

const Text = styled('p')(
  ({ theme }) => css`
    margin: 0.25rem 0 0;

    ${theme.typography.body};
    color: ${theme.formHelperText.default.primary};

    &.${invalidClass} {
      color: ${theme.formHelperText.error.primary};
    }
  `,
);

export const HelperText: FC = (props) => {
  const formControlContext = useFormControlUnstyledContext();

  // const [dirty, setDirty] = React.useState(false);
  // React.useEffect(() => {
  //   if (formControlContext?.filled) {
  //     setDirty(true);
  //   }
  // }, [formControlContext]);

  if (formControlContext === undefined) {
    return null;
  }

  const { error } = formControlContext;
  // console.log('error:', error, 'dirty:', dirty);
  return (
    <Text
      id="error-text"
      className={clsx({ [invalidClass]: error })}
      {...props}
    />
  );
};
