import { Button, RHFTextField } from '@higo/ui';
import React, { FC } from 'react';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const schema = object({
  note: string().required(),
});

export interface MedicalOperatorNoteFormModel {
  note: string;
}

const defaultValues: MedicalOperatorNoteFormModel = {
  note: '',
};

const StyledSubmitButton = styled(Button)`
  margin-left: auto;
`;

export interface MedicalOperatorNoteFormProps {
  onSubmit: (value: MedicalOperatorNoteFormModel) => Promise<unknown>;
}

export const MedicalOperatorNoteForm: FC<MedicalOperatorNoteFormProps> = ({
  onSubmit,
}) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<MedicalOperatorNoteFormModel>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <RHFTextField
        control={control}
        hiddenLabel
        name="note"
        multiline
        rows={6}
        disabled={isSubmitting}
      />
      <StyledSubmitButton
        color="secondary"
        type="submit"
        isLoading={isSubmitting}
        disabled={isSubmitting}
      >
        <FormattedMessage id="common.save" />
      </StyledSubmitButton>
    </form>
  );
};
