import {
  AnsweredQuestion,
  AnsweredSurvey,
} from '@higo/common/src/features/FilledSurvey/model';
import { fold, Option } from 'fp-ts/es6/Option';
import { QuestionType, SurveyAnswerTag } from '@higo/api';
import { UnitValue } from '@higo/common/src/types';
import { ShareExaminationPatient } from '@higo/api/lib/models/shareExaminationPatientDTO';
import { getAge } from '@higo/common/src/utils';
import { isFilled } from '@higo/common/src/utils/isFilled';
import { PatientOverview } from '../models';
import { has } from 'rambda';

const calculateBMI = (weight: number, height: number) =>
  (weight / (height / 100) ** 2).toFixed(1);

export const extractAnsweredQuestionValue = (
  aq?: AnsweredQuestion,
): UnitValue[] => {
  if (QuestionType.OPEN === aq?.questionType && aq?.answer) {
    return [
      {
        value: aq.answer,
        unit: aq.numericQuestionUnit,
      },
    ];
  }

  if (
    aq &&
    [QuestionType.SINGLE_CLOSED, QuestionType.MULTIPLE_CLOSED].includes(
      aq.questionType,
    ) &&
    isFilled(aq.selectedAnswers)
  ) {
    return aq.selectedAnswers.map(({ value, userFriendlyLabel }) => ({
      value: userFriendlyLabel ?? value,
      unit: 'string',
    }));
  }

  return [];
};

const patientOverviewOtherConditions = [
  SurveyAnswerTag.Menopause,
  SurveyAnswerTag.Pregnancy,
  SurveyAnswerTag.Cigaretes,
];

const extractGeneralSurveyAnswers = fold<
  AnsweredSurvey,
  Omit<PatientOverview, 'age' | 'gender'>
>(
  () => ({
    height: undefined,
    weight: undefined,
    bmi: undefined,
    allergies: [],
    diseases: [],
    medications: [],
    operations: [],
    other: [],
  }),
  (survey) => {
    const height = extractAnsweredQuestionValue(
      survey.taggedQuestions.HEIGHT?.[0],
    )?.[0];

    const weight = extractAnsweredQuestionValue(
      survey.taggedQuestions.WEIGHT?.[0],
    )?.[0];

    const allergies = extractAnsweredQuestionValue(
      survey.taggedQuestions.ALLERGIES?.[0],
    );

    const diseases = extractAnsweredQuestionValue(
      survey.taggedQuestions.CHRONIC_DISEASES?.[0],
    );

    const medications = extractAnsweredQuestionValue(
      survey.taggedQuestions.G_MEDICAMENTS?.[0],
    );

    const other = patientOverviewOtherConditions.filter((x) =>
      has(x, survey.taggedAnswers),
    );

    return {
      height,
      weight,
      bmi:
        height &&
        weight &&
        calculateBMI(weight.value as number, height.value as number), // todo: fix typing
      allergies,
      diseases,
      medications,
      other,
    };
  },
);

export const extractPatientOverview = (
  patient: ShareExaminationPatient,
  answeredGeneralSurvey: Option<AnsweredSurvey>,
): PatientOverview => ({
  age: getAge(patient.dateOfBirth),
  gender: patient.gender,
  ...extractGeneralSurveyAnswers(answeredGeneralSurvey),
});
