import { evolve, isNil, unless } from 'rambda';
import { Measurement } from '@higo/api/src/models/measurement';

const roundMeasurementValue = (precision: number) =>
  unless<Measurement>(
    isNil,
    evolve({
      value: (value) => value.toFixed(precision),
    }),
  );

export const roundPulseMeasurementValue = roundMeasurementValue(0);
export const roundTemperatureMeasurementValue = roundMeasurementValue(1);
