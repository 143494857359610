/* eslint-disable react/jsx-key */
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useMeasure } from 'react-use';
import clsx from 'clsx';
import {
  IdType,
  TableOptions,
  TRowEntity,
  useFlexLayout,
  useTable,
} from 'react-table';
import {
  InfoTR,
  TableScrollableContent,
  TableWrapper,
  TBody,
  TD,
  THead,
  THRenderer,
  TR,
  TRDisabledLabel,
  TBodySkeleton,
} from '@higo/ui/src/components/Table/components';
import { StyleProps } from '@summer/jst-react';
import { isEmpty, sum } from 'rambda';
import { SimpleBar } from 'simplebar-react';

interface CustomTableOptions {
  fullHeight?: boolean;
  noDataSlot?: ReactNode;
  error?: ReactNode;
  canBeHighlited?: boolean;
}

export const Table = <T extends TRowEntity>({
  columns,
  data,
  sorting,
  selection,
  pageSize = 0,
  isLoading,
  // disabled, // TODO: disable whole table
  disabledIds,
  disabledEntityLabel,
  className,
  style,
  onRowClick,
  fullHeight = false, // fullHeight == 100% of the table's parent container
  noDataSlot = null,
  error = null,
  canBeHighlited = false,
  ...props
}: TableOptions<T> & CustomTableOptions & StyleProps) => {
  const { headerGroups, rows, prepareRow, getTableProps, getTableBodyProps } =
    useTable(
      {
        columns,
        data,
        ...props,
      },
      useFlexLayout,
    );

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [activeRow, setActiveRow] = useState('0');

  const scrollRef = useRef<SimpleBar>(null);
  const [ref, { width }] = useMeasure();

  useEffect(() => {
    setIsOverflowing(!!scrollRef.current?.axis?.x?.isOverflowing);
  }, [width]);

  const hasMarkerColumn = useMemo(() => !!columns[0]?.isMarker, [columns]);
  const stickyColumns = useMemo(
    () =>
      columns
        .filter((column) => column.isSticky)
        .map((column) => Number(column.width) || 0)
        .reverse(),
    [columns],
  );

  const toggleClass = (rowId: string) => {
    setActiveRow(rowId);
  };

  return (
    <TableWrapper
      ref={(instance) => instance && ref(instance)}
      $stickyColumns={stickyColumns}
      {...getTableProps()}
      style={style}
      className={clsx(className, {
        'has-overflow': isOverflowing,
        'has-marker-column': hasMarkerColumn,
        'has-full-height': fullHeight,
      })}
    >
      <TableScrollableContent
        ref={scrollRef}
        autoHide={false}
        $scrollbarOffset={sum(stickyColumns)}
        className={clsx(className, {
          'has-full-height': fullHeight,
        })}
      >
        {headerGroups.map((headerGroup) => (
          <THead {...headerGroup.getHeaderGroupProps()} id="table-header">
            {headerGroup.headers.map((header) => (
              <THRenderer
                {...header}
                {...header.getHeaderProps()}
                sorting={sorting}
                selection={selection}
                id={header.getHeaderProps().key as IdType<T>}
              />
            ))}
          </THead>
        ))}
        <TBody {...getTableBodyProps()}>
          {isLoading && <TBodySkeleton columns={columns} pageSize={pageSize} />}
          {!isLoading && !error && isEmpty(data) && (
            <InfoTR>{noDataSlot}</InfoTR>
          )}
          {!isLoading && error && <InfoTR>{error}</InfoTR>}
          {!isLoading &&
            !isEmpty(data) &&
            rows.map((row) => {
              prepareRow(row);
              const rowProps = row.getRowProps();
              const isDisabled = disabledIds?.includes(row.original.id);
              const isSelected = selection?.selectedIds.includes(
                row.original.id,
              );
              const isActive = row.id === activeRow;
              return (
                <TR
                  {...rowProps}
                  onClick={() => {
                    !isDisabled && onRowClick?.(row);
                    toggleClass(row.id);
                  }}
                  id={rowProps.key as string}
                  className={clsx(rowProps.className, {
                    selected: isSelected,
                    clickable: !!onRowClick,
                    disabled: isDisabled,
                    highlighted: canBeHighlited && isActive,
                  })}
                >
                  {row.cells.map((cell) => (
                    <TD
                      {...cell.getCellProps()}
                      id={cell.getCellProps().key as string}
                    >
                      {cell.render('Cell', { sorting, selection })}
                    </TD>
                  ))}
                  {isDisabled && disabledEntityLabel && (
                    <TRDisabledLabel id="disabled-label">
                      {disabledEntityLabel}
                    </TRDisabledLabel>
                  )}
                </TR>
              );
            })}
        </TBody>
      </TableScrollableContent>
    </TableWrapper>
  );
};
