//Prepare data for slider -> always 15 items with diagnostic item inside

export const slidingWindowSlice = <T>(
  data: T[],
  diagnosticImageIndex?: number,
) => {
  if (data.length < 15) {
    return [...data];
  }

  if (!diagnosticImageIndex) {
    return [...data];
  }

  if (diagnosticImageIndex <= 7) {
    return data.slice(0, 15);
  }

  if (diagnosticImageIndex + 6 >= data.length) {
    return data.slice(-15);
  }

  return data.slice(diagnosticImageIndex - 8, diagnosticImageIndex + 6);
};
