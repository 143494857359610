import {
  Tooltip as BaseTooltip,
  tooltipClasses as baseTooltipClasses,
  TooltipProps as BaseTooltipProps,
} from '@mui/material';
import clsx from 'clsx';
import styled, { css } from 'styled-components';

export const tooltipClasses = {
  ...baseTooltipClasses,
  light: 'light',
};

export type TooltipProps = {
  color?: 'default' | 'light';
} & BaseTooltipProps;

export const Tooltip = styled(
  ({ className, children, color, ...props }: TooltipProps) => (
    <BaseTooltip
      {...props}
      classes={{
        popper: clsx(className, { [tooltipClasses.light]: 'light' === color }),
      }}
    >
      {children}
    </BaseTooltip>
  ),
)(
  ({ theme }) =>
    css`
      & .${tooltipClasses.tooltip} {
        ${theme.typography.subtitle2}
      }

      &.${tooltipClasses.light} .${tooltipClasses.tooltip} {
        background-color: ${theme.palette.white};
        color: ${theme.palette.secondary[900]};
        border-radius: 0.5rem;
        padding: 0.5rem;
        box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.1);
        background-color: ${theme.palette.white};
      }
    `,
);
