import { useQuery } from 'react-query';
import { fetchExaminationComments } from '@higo/api/lib/services/messagesService';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';
import { messagesQueryKeys } from 'config/doctorQueryKey';

export const useExaminationCommentsQuery = (examinationId: number) => {
  const { authorizedAxios } = useDoctorPortalApiContext();

  return useQuery(
    messagesQueryKeys.examinationMessages(examinationId),
    () => fetchExaminationComments(authorizedAxios)(examinationId),
    {
      enabled: !isNaN(examinationId),
      refetchOnMount: true,
      refetchOnReconnect: true,
    },
  );
};
