import styled, { css } from 'styled-components';
import { FC, memo } from 'react';
import clsx from 'clsx';
import { StyleProps } from '@summer/jst-react';
import { useLayoutContext } from '@higo/ui/lib/contexts';

const examinationPageHeaderClasses = {
  shadow: 'shadow',
};

const Container = styled('header')(
  ({ theme }) => css`
    position: sticky;
    @media print {
      position: relative;
    }

    top: 0;

    background-color: ${theme.palette.neutral['400']};
    z-index: ${theme.layers.examinationPageHeader};

    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0);
    transition: ${theme.transitions.create('box-shadow', {
      duration: theme.transitions.duration.short,
    })};

    &.${[examinationPageHeaderClasses.shadow]} {
      box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.12);
    }
  `,
);

export type ExaminationPageHeaderProps = {
  shadowless?: boolean;
} & StyleProps;

export const ExaminationPageHeader: FC<ExaminationPageHeaderProps> = memo(
  ({ children, className, style, shadowless = false }) => {
    const { state } = useLayoutContext();

    return (
      <Container
        id="exam-page-header"
        style={style}
        className={clsx(className, {
          [examinationPageHeaderClasses.shadow]:
            !shadowless && (state.isNavHeaderHidden ?? false),
        })}
      >
        {children}
      </Container>
    );
  },
);
