import { useMutation, useQueryClient } from 'react-query';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';
import {
  PhysicalExaminationType,
  requestGenerateLeftEarExaminationVideo,
} from '@higo/api';
import { useDispatchDoctorTrackedType } from 'hooks/useDispatchDoctorTrackedEvent';
import { DoctorTrackedType } from '@higo/common/lib/features/TrackedEvent';

export const useRequestGenerateLeftEarExaminationVideoMutation = () => {
  const { authorizedAxios } = useDoctorPortalApiContext();
  const queryClient = useQueryClient();
  const sendTrackedEvent = useDispatchDoctorTrackedType();

  return useMutation(requestGenerateLeftEarExaminationVideo(authorizedAxios), {
    onSuccess: (_, variables) => {
      sendTrackedEvent({
        event: DoctorTrackedType.RequestVisualExaminationVideo,
        eventResult: 'success',
        physicalExaminationType: PhysicalExaminationType.LeftEar,
        examinationId: variables,
      });
    },

    onError: (error, variables, context) => {
      sendTrackedEvent({
        event: DoctorTrackedType.RequestVisualExaminationVideo,
        eventResult: 'error',
        physicalExaminationType: PhysicalExaminationType.LeftEar,
        examinationId: variables,
      });

      // call global error handler
      return queryClient
        .getDefaultOptions()
        ?.mutations?.onError?.(error, variables, context);
    },
  });
};
