import { useMemo } from 'react';
import { AnsweredQuestion } from '@higo/common/src/features/FilledSurvey/model';
import { Predicate } from 'rambda';

export const useRenderableQuestions = (
  answeredQuestionList: AnsweredQuestion[],
  predicate?: Predicate<AnsweredQuestion>,
) =>
  useMemo(
    () =>
      predicate ? answeredQuestionList.filter(predicate) : answeredQuestionList,
    [answeredQuestionList, predicate],
  );
