import Axios from 'axios';
import { appConfig } from '@higo/doctor/src/config/appConfig';

export const createBaseAxios = () =>
  Axios.create({
    baseURL: appConfig.apiUrl,
    headers: {
      Authorization: `Basic ${appConfig.bearerToken}`,
    },
  });

export const createAuthorizedAxios = () =>
  Axios.create({
    baseURL: appConfig.apiUrl,
  });
