import styled, { css } from 'styled-components';
import { ButtonUnstyled, buttonUnstyledClasses } from '@mui/base';
import CloseIcon from './icons/CloseIcon';
import { IconProps } from './IconBox';
import { FC } from 'react';
import { StyleProps } from '@summer/jst-react';

const BasicChip = styled('div')(
  ({ theme }) => css`
    font-family: ${theme.typography.fontFamily};
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1;
    height: 1.875rem; // (32-2px border)
    display: inline-flex;
    align-items: center;

    user-select: none;
    transition: ${theme.transitions.create('all')};
    padding: 0 0.75rem;

    outline: none;
    border: 1px solid ${theme.palette.primary['500']}; // outline in this case just dont look good
    border-radius: 1rem;

    color: ${theme.palette.white};
    background-color: ${theme.palette.primary['500']};
  `,
);

const ChipRoot = styled(BasicChip).attrs({ as: 'button' })(
  ({ theme }) => css`
    cursor: pointer;
    &.${buttonUnstyledClasses.disabled} {
      cursor: not-allowed;
      background-color: ${theme.palette.primary['200']};
      border-color: ${theme.palette.primary['200']};
    }

    // &:hover {
    //   background-color: ${theme.palette.primary['500']};
    // }

    &.${buttonUnstyledClasses.focusVisible} {
      border: 1px solid ${theme.palette.primary['600']};
    }

    &:active {
      background-color: ${theme.palette.primary['600']};
      border-color: ${theme.palette.primary['600']};
    }
  `,
);

const StyledCloseIcon = styled(CloseIcon)`
  margin-left: 0.25rem;
`;

export type ChipProps = {
  onDelete?: () => void;
  icon?: FC<IconProps>;
  disabled?: boolean;
  id?: string;
} & StyleProps;

export const Chip: FC<ChipProps> = ({
  children,
  onDelete,
  icon,
  id,
  ...props
}) => {
  const Component: FC = onDelete ? ButtonUnstyled : BasicChip;
  const moreProps = onDelete
    ? { component: ChipRoot, onClick: onDelete, ...props }
    : {};
  const tagId = id?.toLowerCase();
  return (
    <Component {...props} {...moreProps}>
      <span id={`tag-${tagId}`}>{children}</span>
      {onDelete && <StyledCloseIcon as={icon} size={16} />}
    </Component>
  );
};
