import jwtDecode from 'jwt-decode';
import { APIOAuthAccessTokenData, OAuth2AccessTokenDTO } from '@higo/api';
import { OAuth2AccessToken } from '@higo/common/src/ext/axios';

export const mapToOAuth2AccessToken = (dto: OAuth2AccessTokenDTO) =>
  createAPIOAuth2AccessToken(dto.access_token, dto.refresh_token);

export const createAPIOAuth2AccessToken = (
  accessToken: string,
  refreshToken: string,
): OAuth2AccessToken => {
  const accessTokenData = jwtDecode<APIOAuthAccessTokenData>(accessToken);

  return {
    accessToken,
    accessTokenData,
    accessTokenExpiresAt: new Date(accessTokenData.exp * 1000),
    refreshToken: refreshToken,
  };
};
