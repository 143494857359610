import { IconBox, IconProps } from '../IconBox';

const RoundCloseIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm-4.236-7.764a.9.9 0 0 1 0-1.272L10.727 12 7.764 9.036a.9.9 0 0 1 1.272-1.272L12 10.727l2.964-2.963a.9.9 0 0 1 1.272 1.272L13.273 12l2.963 2.964a.9.9 0 1 1-1.272 1.272L12 13.273l-2.964 2.963a.9.9 0 0 1-1.272 0Z"
    />
  </IconBox>
);

export default RoundCloseIcon;
