import { useQuery, UseQueryOptions } from 'react-query';
import { ApiQueryKey } from '@higo/api/lib/apiQueryKey';
import { fetchPatientExaminations } from '@higo/api/lib/services/patientService';
import {
  mapToPatientExaminations,
  PaginatedListReqDTO,
  PaginatedListResultDTO,
  PatientExaminations,
} from '@higo/api/lib/models';
import { isNil } from 'rambda';
import { mapPaginatedListResult } from '@higo/api/lib/utils/mapPaginatedListResult';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';

export const usePatientExaminationListQuery = (
  patientId: number,
  pageData?: PaginatedListReqDTO,
  options?: Omit<
    UseQueryOptions<
      PaginatedListResultDTO<PatientExaminations>,
      unknown,
      PaginatedListResultDTO<PatientExaminations>,
      (PaginatedListReqDTO | ApiQueryKey | undefined)[]
    >,
    'queryKey' | 'queryFn'
  >,
) => {
  const { authorizedAxios } = useDoctorPortalApiContext();

  return useQuery(
    //@ts-ignore
    [ApiQueryKey.PatientExaminations, patientId, pageData],
    () =>
      fetchPatientExaminations(authorizedAxios)(patientId, pageData).then(
        mapResults,
      ),
    { enabled: !isNil(patientId), keepPreviousData: true, ...options },
  );
};

const mapResults = mapPaginatedListResult(mapToPatientExaminations);
