import { useDoctorPortalApiContext } from 'contexts/ApiContext';
import { useQuery } from 'react-query';
import { messagesQueryKeys } from 'config/doctorQueryKey';
import { fetchUnreadPatientsMessages, PaginatedListResultDTO } from '@higo/api';
import { PaginatedListReqDTO } from '@higo/api/lib/models';
import { PatientUnreadMessage } from '@higo/api/lib/models/patientUnreadMessageDTO';

// we always fetch 3 unread messages (aggregated by examination)
const data: PaginatedListReqDTO = {
  page: 1,
  pageSize: 3,
};

export const useUnreadMessagesQuery = <
  TData = PaginatedListResultDTO<PatientUnreadMessage>,
>(
  select?: (pageData: PaginatedListResultDTO<PatientUnreadMessage>) => TData,
) => {
  const { authorizedAxios } = useDoctorPortalApiContext();

  return useQuery(
    messagesQueryKeys.unreadList(),
    () => fetchUnreadPatientsMessages(authorizedAxios)(data),
    {
      select,
      refetchOnMount: true,
      refetchOnReconnect: true,
    },
  );
};
