import { DiagnosticVideoLoadingScreen } from '@higo/common/src/components';
import { VisualExaminationDiagnosticVideo } from './VisualExaminationDiagnosticVideo';
import { DiagnosticPhotosGallerySkeleton } from '@higo/common/src/components';
import { VisualExaminationDiagnosticViewerProps } from './VisualExaminationDiagnosticViewerProps';
import { DiagnosticPhotosGalleryNoDataInfo } from '@higo/common/src/components/DiagnosticPhotosGallery/DiagnosticPhotosGalleryNoDataInfo';

export const VisualExaminationDiagnosticVideoViewerWithToggling = ({
  examinationId,
  model,
}: VisualExaminationDiagnosticViewerProps) => {
  const { data, isLoading, isError } =
    model.usePhysicalExaminationVideoResultsLink(examinationId);

  const { data: frameFileIds } =
    model.usePhysicalExaminationVideoFramesIds(examinationId);

  if (isLoading || !data) {
    return <DiagnosticVideoLoadingScreen />;
  }

  if (data?.link)
    return (
      <VisualExaminationDiagnosticVideo
        videoUrl={data.link}
        frameFileIds={frameFileIds ?? []}
      />
    );

  if (isError) {
    return <DiagnosticPhotosGalleryNoDataInfo />;
  }

  return <></>;
};

export const VisualExaminationDiagnosticVideoViewer = ({
  examinationId,
  model,
}: VisualExaminationDiagnosticViewerProps) => {
  const { data, isLoading } =
    model.usePhysicalExaminationVideoResultsLink(examinationId);

  const { data: frameFileIds } =
    model.usePhysicalExaminationVideoFramesIds(examinationId);

  if (isLoading) {
    return <DiagnosticPhotosGallerySkeleton />;
  }

  if (data?.link)
    return (
      <VisualExaminationDiagnosticVideo
        videoUrl={data.link}
        frameFileIds={frameFileIds ?? []}
      />
    );

  return <></>;
};
