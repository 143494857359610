import {
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form';
import { TextField, TextFieldProps } from '@higo/ui/src/components/TextField';
import { ErrorMessage } from '@higo/ui/src/components/ErrorMessage';

export type RHFTextFieldProps<T extends FieldValues> = UseControllerProps<T> &
  TextFieldProps;
export const RHFTextField = <TFieldValues extends FieldValues>({
  name,
  rules,
  control,
  shouldUnregister,
  ...props
}: RHFTextFieldProps<TFieldValues>) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    // rules: { required: true },
  });

  // console.log(value, error);
  return (
    <TextField
      name={name}
      id={`text-field-${name}`}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={!!error}
      helperText={<ErrorMessage error={error} label={props.label} />}
      {...props}
    />
  );
};
