import { useCallback, useEffect, useRef, useState } from 'react';
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging';
import { toast } from 'react-toastify';
import { FirebaseApp } from '@firebase/app';
import firebase from 'firebase/compat';
import { useIntl } from 'react-intl';
import { isNil } from 'rambda';

const requestNotificationsPermission = () => {
  console.log('Requesting permission...');

  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    }
  });
};

export const useFirebaseMessaging = (
  app: FirebaseApp,
  vapidKey: string | undefined,
  nextFn: firebase.NextFn<firebase.messaging.MessagePayload>,
  enabled: boolean,
) => {
  const unsubscribeRef = useRef<void | (() => void)>(() => {});
  const [token, setToken] = useState<string | undefined>();
  const intl = useIntl();

  const initFCM = useCallback(
    () =>
      isSupported()
        .then(requestNotificationsPermission)
        .then(() => {
          const messaging = getMessaging(app);

          getToken(messaging, { vapidKey })
            .then((currentToken) => {
              if (currentToken) {
                setToken(currentToken);
              } else {
                toast.info(
                  intl.formatMessage({ id: 'webAPI.notification.permission' }),
                );
              }
            })
            .catch((err) => {
              console.log(
                'An error occurred while retrieving the FCM registration token: ',
                err,
              );
            });

          return onMessage(messaging, nextFn);
        })
        .catch(() => {
          toast.warning(
            intl.formatMessage({ id: 'webAPI.notification.unsupported' }),
          );
        }),
    [app, vapidKey, intl, nextFn],
  );

  useEffect(() => {
    if (enabled && isNil(token)) {
      initFCM().then((x) => {
        unsubscribeRef.current = x;
      });
    } else if (!enabled && token) {
      unsubscribeRef.current?.();
      setToken(undefined);
    }
  }, [enabled, token, initFCM]);

  return token;
};
