import ReactDOM from 'react-dom';
import './index.css';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppWrapper } from 'AppWrapper';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { sessionStorageQueryStorage } from 'services/sessionStorageQueryStorage';
import { QueryStorageProvider } from '@higo/common/lib/QueryStorage';
import { AppLocaleProvider } from '@higo/common/lib/features/Locale';
import { appConfig } from 'config/appConfig';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { isNilEmpty } from '@higo/common/lib/utils/isNilEmpty';
import { useDoctorQueryClient } from 'hooks/useDoctorQueryClient';
import { EventEmitterProvider } from 'contexts/EventEmitterProvider';

const gtmParams = {
  id: appConfig.gtmContainerId ?? '',
  injectScript: !isNilEmpty(appConfig.gtmContainerId),
};

const RootProviders = () => {
  const queryClient = useDoctorQueryClient();

  return (
    <QueryStorageProvider queryStorage={sessionStorageQueryStorage}>
      <QueryClientProvider client={queryClient}>
        <GTMProvider state={gtmParams}>
          <EventEmitterProvider>
            <AppWrapper />
            <ReactQueryDevtools initialIsOpen={false} />
          </EventEmitterProvider>
        </GTMProvider>
      </QueryClientProvider>
    </QueryStorageProvider>
  );
};

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <AppLocaleProvider>
        <RootProviders />
      </AppLocaleProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
