import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

export const useWarnToastDetector = (idleTime = 1140) => {
  const idleTimeout = 1000 * idleTime;
  const [isIdle, setIdle] = useState(false);
  const intl = useIntl();
  const location = useLocation();

  const handleIdle = () => {
    location.pathname !== '/' && setIdle(true);

    if (location.pathname !== '/')
      toast.warning(
        intl.formatMessage({
          id: 'notification.idleAutoLogoutIn',
        }),
        {
          toastId: 'success1',
          autoClose: 59000,
        },
      );
  };
  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    onIdle: handleIdle,
    debounce: 500,
  });
  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};
