import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import { useCallback } from 'react';
import { useProfileUserId } from 'hooks/queries';
import { useUserRole } from 'hooks/useUserRole';

import {
  useTrackedEventDataContext,
  TrackedEvent,
} from '@higo/common/lib/features/TrackedEvent';

export const useDispatchDoctorTrackedType = () => {
  const { data: profileUserId } = useProfileUserId();
  const sendDataToGTM = useGTMDispatch();
  const trackedCustomEventContextData = useTrackedEventDataContext();
  const { role } = useUserRole();

  return useCallback(
    (event: TrackedEvent) => {
      sendDataToGTM({
        doctorId: profileUserId,
        userRoleType: role,
        ...trackedCustomEventContextData,
        ...event,
      });
    },
    [sendDataToGTM, trackedCustomEventContextData, profileUserId, role],
  );
};
