import { IconBox, IconProps } from '../IconBox';

const LinkIcon = (props: IconProps) => (
  <IconBox {...props}>
    <g>
      <svg fill="none">
        <path
          d="M13.3477 17.0164L11.4697 18.8944C10.6258 19.7383 9.48117 20.2124 8.2877 20.2124C7.09422 20.2124 5.94963 19.7383 5.10572 18.8944C4.2618 18.0505 3.7877 16.9059 3.7877 15.7124C3.7877 14.5189 4.2618 13.3743 5.10572 12.5304L6.94232 10.6938"
          stroke="#72799C"
          strokeWidth="2.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.6937 6.94245L12.5303 5.10586C13.3742 4.26194 14.5188 3.78784 15.7123 3.78784C16.9058 3.78784 18.0504 4.26194 18.8943 5.10586C19.7382 5.94977 20.2123 7.09436 20.2123 8.28784C20.2123 9.48131 19.7382 10.6259 18.8943 11.4698L17.0162 13.3478"
          stroke="#72799C"
          strokeWidth="2.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.11865 14.8813L14.9476 9.05236"
          stroke="#72799C"
          strokeWidth="2.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </g>
  </IconBox>
);

export default LinkIcon;
