import { CellProps } from 'react-table';
import { Tag } from '@higo/ui';
import { ExaminationDTOStatus } from '@higo/api/lib/models';
import { ExaminationStatusLabel } from '@higo/common/lib/components';

export const StatusCellRenderer = <T extends Record<string, unknown>>({
  cell,
}: CellProps<T, ExaminationDTOStatus>) => {
  return (
    <div id={`status-tag-${cell.row.id}`}>
      <Tag
        color={
          cell.value === ExaminationDTOStatus.DRA ? 'secondary' : 'primary'
        }
      >
        <ExaminationStatusLabel status={cell.value} />
      </Tag>
    </div>
  );
};
