import { create } from 'zustand';
import produce from 'immer';
import { notEquals } from '@higo/common/lib/utils';

export interface ExaminationsState {
  newExaminations: number[];
  takenExaminations: number[];

  actions: {
    addNewExamination: (examinationId: number) => void;
    removeNewExamination: (examinationId: number) => void;
    clearNewExaminations: () => void;

    addTakenExamination: (examinationId: number) => void;
    clearTakenExaminations: () => void;
  };
}

const examinationsStore = create<ExaminationsState>((set) => ({
  newExaminations: [],
  takenExaminations: [],

  actions: {
    addNewExamination: (examinationId) =>
      set(
        produce((state) => {
          !state.newExaminations.includes(examinationId) &&
            state.newExaminations.unshift(examinationId);
        }),
      ),
    removeNewExamination: (examinationId) =>
      set((state) => ({
        ...state,
        newExaminations: state.newExaminations.filter(notEquals(examinationId)),
      })),
    clearNewExaminations: () =>
      set(
        produce((state) => {
          state.newExaminations = [];
        }),
      ),

    addTakenExamination: (examinationId) =>
      set(
        produce((state) => {
          !state.takenExaminations.includes(examinationId) &&
            state.takenExaminations.unshift(examinationId);
        }),
      ),
    clearTakenExaminations: () =>
      set(
        produce((state) => {
          state.takenExaminations = [];
        }),
      ),
  },
}));

export const useNewExaminations = () =>
  examinationsStore((state) => state.newExaminations);

export const useExaminationsStoreActions = () =>
  examinationsStore((state) => state.actions);

export const useTakenExaminations = () =>
  examinationsStore((state) => state.takenExaminations);
