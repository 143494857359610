import { FC } from 'react';
import {
  MultiSelect,
  MultiSelectProps,
  Select,
  SelectProps,
} from '@higo/ui/src/components/Select';
import {
  FormControl,
  FormControlProps,
} from '@higo/ui/src/components/FormControl';
import { TSelectValue } from '@mui/base/SelectUnstyled';

export type SelectFieldCommonProps = {
  label?: string;
  name: string;
  type?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
} & Omit<FormControlProps, 'onChange'>;

export type SelectFieldProps<T> = SelectProps<T> & SelectFieldCommonProps;

export type MultiSelectFieldProps<T> = MultiSelectProps<T> &
  SelectFieldCommonProps;

const SelectFieldFactory: FC<SelectFieldCommonProps> = ({
  label,
  hiddenLabel,
  required,
  size,
  disabled,
  error,
  helperText,
  horizontal = false,
  children,
}) => {
  return (
    <FormControl
      required={required}
      label={label}
      hiddenLabel={hiddenLabel}
      disabled={disabled}
      helperText={helperText}
      horizontal={horizontal}
      size={size}
      error={error}
    >
      {children}
    </FormControl>
  );
};

export const SelectField = <T extends TSelectValue>({
  ...props
}: SelectFieldProps<T>) => {
  return (
    <SelectFieldFactory {...props}>
      <Select {...props} />
    </SelectFieldFactory>
  );
};

export const MultiSelectField = <T extends TSelectValue>({
  hiddenLabel,
  helperText,
  ...props
}: MultiSelectFieldProps<T>) => (
  <SelectFieldFactory
    hiddenLabel={hiddenLabel}
    helperText={helperText}
    {...props}
  >
    <MultiSelect {...props} />
  </SelectFieldFactory>
);
