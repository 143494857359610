import { IconBox, IconProps } from '../IconBox';

const AudioDownloadIcon = ({
  fill = 'none',
  stroke = '#8E94B0',
  strokeWidth = '1.5',
  enableBackground = 'new',
  strokeLinecap = 'round',
  width = '45px',
  ...props
}: IconProps) => (
  <IconBox {...props}>
    <svg id="Warstwa_1" version="1.1">
      <g id="_x5B_Platforma_x5D_-stan-rzeczywisty">
        <g
          transform="translate(-673.000000, -3434.000000)"
          id="doctor-portal-kb-pl"
        >
          <g transform="translate(674.000000, 3435.000000)" id="cloud-download">
            <path
              stroke-width="1.5"
              fill="none"
              stroke="#8E94B0"
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M7,7v5.7 M7,12.7l2.2-2.2 M7,12.7l-2.2-2.2 M12.1,9.9C13,9.6,14,8.7,14,7c0-2.5-2.1-3.2-3.2-3.2
				c0-1.3,0-3.8-3.8-3.8S3.2,2.5,3.2,3.8C2.1,3.8,0,4.5,0,7c0,1.7,1,2.6,1.9,2.9"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
      /{' '}
    </svg>
  </IconBox>
);

export default AudioDownloadIcon;
