import { MouseEvent, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { CellProps, TRowEntity } from 'react-table';
import { Checkbox } from '@higo/ui/src/components/Checkbox';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';

const StyledCheckbox = styled(Checkbox)`
  display: block;
  margin: 0;
`;

const CheckboxContainer = styled('div')`
  margin: 0 20%;
`;

export const SelectorCellRenderer = <T extends TRowEntity>({
  row,
  selection,
}: CellProps<T>) => {
  const isChecked = useMemo(
    () => selection?.selectedIds.includes(row.original.id),
    [selection?.selectedIds, row.original.id],
  );

  const handleClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);

  const toggleSelection = useCallback(() => {
    selection?.toggleRowSelection(row.original.id);
  }, [row.original.id, selection]);

  return (
    <Tooltip
      placement="right"
      title={<FormattedMessage id="table.header.select" />}
      color="#72799C"
    >
      <CheckboxContainer onClick={handleClick}>
        <StyledCheckbox
          checked={isChecked}
          onChange={toggleSelection}
          id={`checkbox-${row.original.id}`}
        />
      </CheckboxContainer>
    </Tooltip>
  );
};
