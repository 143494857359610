import {
  DoctorUserDTO,
  UserSettingsDTO,
  DemoAccountDTO,
} from '@higo/api/src/models';
import { ListWrapperDTO } from '@higo/api/src/ext/listWrapper';
import { SetUserPasswordDTO } from '@higo/api/src/models/user/setUserPasswordDTO';
import { flatten } from '@higo/api/src/utils';
import { SetPinDTO } from '@higo/api/src/models/setPinDTO';
import { AxiosInstance } from 'axios';
import { RegisterFcmTokenDTO } from '@higo/api/src/models/registerFcmTokenDTO';

export const fetchDoctorData = (axios: AxiosInstance) =>
  axios.get<DoctorUserDTO>(`/api/users/doctorData`).then(flatten);

export const fetchMedicalOperatorData = (axios: AxiosInstance) =>
  axios.get<DoctorUserDTO>(`/api/users/data`).then(flatten);

export const fetchUserSettings = (axios: AxiosInstance) => {
  return axios.get<ListWrapperDTO<UserSettingsDTO>>('/api/users/settings');
};

export const setNewPassword =
  (axios: AxiosInstance) => (dto: SetUserPasswordDTO) =>
    axios.post('/api/users/password/set', dto).then(flatten);

export const setUserPin = (axios: AxiosInstance) => (dto: SetPinDTO) =>
  axios.post('/api/users/pin/set', dto).then(flatten);

export const registerUserFcmToken =
  (axios: AxiosInstance) => (dto: RegisterFcmTokenDTO) =>
    axios.post('/api/v2/users/fcm/token', dto).then(flatten);
