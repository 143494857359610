import { IconBox, IconProps } from '../IconBox';

const RoundVitalSignsIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm.031-15.551a1.6 1.6 0 1 0 0-3.199 1.6 1.6 0 0 0 0 3.199Zm5.173.456H6.891a.75.75 0 0 0-.751.702c-.016.408.31.75.718.75h3.362l.098 3.143-.85 4.698a.809.809 0 0 0 .67.914.809.809 0 0 0 .914-.67l.848-4.648c.017-.066.066-.114.131-.114s.114.049.13.114l.85 4.649a.82.82 0 0 0 .799.685c.032 0 .081 0 .114-.016a.797.797 0 0 0 .67-.914l-.85-4.698.098-3.142h3.346a.75.75 0 0 0 .75-.702.745.745 0 0 0-.734-.75Z"
    />
  </IconBox>
);

export default RoundVitalSignsIcon;
