import styled, { css } from 'styled-components';
import { FC, memo } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { getOpenQuestionAnswer } from '@higo/common/src/features/FilledSurvey/utils';
import { OpenAnswerType, QuestionType } from '@higo/api';

export type OpenQuestionAnswerProps = {
  questionType: QuestionType.OPEN;
  answered: boolean;
  answer?: string;
  type: OpenAnswerType;
};

export const OpenAnswerRendererClasses = {
  empty: 'empty',
};

const Container = styled('span')(
  ({ theme }) => css`
    ${theme.typography.subtitle2};
    &.${OpenAnswerRendererClasses.empty} {
      color: ${theme.palette.neutral[700]};
    }
  `,
);

export const OpenQuestionAnswerRenderer: FC<OpenQuestionAnswerProps> = memo(
  ({ answered, answer, type }) => {
    const intl = useIntl();
    const formattedAnswer = getOpenQuestionAnswer(
      answered,
      type,
      intl.formatMessage({ id: 'examination.survey.emptyOpenAnswer' }),
      answer,
    );

    return (
      <Container
        className={clsx({
          [OpenAnswerRendererClasses.empty]: !answered,
        })}
      >
        {formattedAnswer}
      </Container>
    );
  },
);
