import { StyleProps } from '@summer/jst-react';
import { FC } from 'react';
import { useProfileDataQuery } from 'hooks/queries/useProfileDataQuery';
import { RoundIndicator } from 'components/RoundIndicator';
import styled from 'styled-components';

const StyledRoundIndicator = styled(RoundIndicator)`
  margin-right: 0.5rem;
`;

export type SelfDiagnosedIndicatorProps = {
  doctorId: number;
} & StyleProps;

export const SelfDiagnosedIndicator: FC<SelfDiagnosedIndicatorProps> = ({
  doctorId,
}) => {
  const { data } = useProfileDataQuery();
  return doctorId === data?.userId ? <StyledRoundIndicator /> : null;
};
