import { EventsType, useIdleTimer } from 'react-idle-timer';
import { FC, useEffect, useState } from 'react';
import { appConfig } from 'config/appConfig';
import { toast } from 'react-toastify';
import { useLogout } from 'hooks/useLogout';
import { FormattedMessage } from 'react-intl';
import { useEachSecond } from '@higo/common/lib/hooks';
import { ToastId } from 'config/toastId';

const blurredEvents: EventsType[] = [
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
];
const focusedEvents: EventsType[] = ['mousemove', ...blurredEvents];

const promptTimeLeft = 30e3; // 30 sec;

const IdleAutoLogoutToast: FC<{ timeout: number }> = ({ timeout }) => {
  const [timeLeft, setTimeLeft] = useState(timeout);
  useEachSecond(() => setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0)));

  return (
    <span>
      <FormattedMessage
        id="notification.idleAutoLogoutIn"
        values={{ timeout: timeLeft }}
      />
    </span>
  );
};

export const IdleAutoLogout = () => {
  const [events, setEvents] = useState<EventsType[]>(focusedEvents);
  const timeout = appConfig.sessionTimeout ? appConfig.sessionTimeout : NaN;
  const logout = useLogout();

  useIdleTimer({
    timeout,
    promptTimeout: timeout - promptTimeLeft,
    events,
    onIdle: () => {
      toast.dismiss(ToastId.IdleAutoLogout);
      logout();
    },
    onPrompt: () => {
      const timeoutSeconds = promptTimeLeft / 1000;
      toast.warning(<IdleAutoLogoutToast timeout={timeoutSeconds} />, {
        toastId: ToastId.IdleAutoLogout,
      });
    },
    onAction: () => {
      toast.dismiss(ToastId.IdleAutoLogout);
    },
    startManually: isNaN(timeout), // workaround for not starting if timeout not provided
  });

  useEffect(() => {
    const onBlur = () => setEvents(blurredEvents);
    const onFocus = () => setEvents(focusedEvents);

    window?.addEventListener('blur', onBlur);
    window?.addEventListener('focus', onFocus);

    return () => {
      window?.removeEventListener('blur', onBlur);
      window?.removeEventListener('focus', onFocus);
    };
  }, []);

  return null;
};
