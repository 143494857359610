import { Gender } from '@higo/api/lib/models/gender';
import { createElement, FC } from 'react';
import { ResponsiveImage } from '@higo/ui';
import { Maturity } from '@higo/common/src/types';
import manBack from './man_back.png';
import manBack2 from './man_back@2x.png';
import manBack4 from './man_back@4x.png';
import womanBack from './woman_back.png';
import womanBack2 from './woman_back@2x.png';
import womanBack4 from './woman_back@4x.png';
import boyBack from './boy_back.png';
import boyBack2 from './boy_back@2x.png';
import boyBack4 from './boy_back@4x.png';
import girlBack from './girl_back.png';
import girlBack2 from './girl_back@2x.png';
import girlBack4 from './girl_back@4x.png';
import babyBack from './baby_back.png';
import babyBack2 from './baby_back@2x.png';
import babyBack4 from './baby_back@4x.png';
import { BodyImageProps } from '@higo/common/src/components/Auscultation/bodyImageProps';

const manBackBody = {
  srcSet: [
    {
      src: manBack,
      media: '1x',
    },
    {
      src: manBack2,
      media: '2x',
    },
    {
      src: manBack4,
      media: '4x',
    },
  ],
};
const boyBackBody = {
  srcSet: [
    {
      src: boyBack,
      media: '1x',
    },
    {
      src: boyBack2,
      media: '2x',
    },
    {
      src: boyBack4,
      media: '4x',
    },
  ],
};
const babyBackBody = {
  srcSet: [
    {
      src: babyBack,
      media: '1x',
    },
    {
      src: babyBack2,
      media: '2x',
    },
    {
      src: babyBack4,
      media: '4x',
    },
  ],
};

const bodies = {
  [Gender.Male]: {
    [Maturity.Adult]: manBackBody,
    [Maturity.Teen]: boyBackBody,
    [Maturity.Baby]: babyBackBody,
  },
  [Gender.Female]: {
    [Maturity.Adult]: {
      srcSet: [
        {
          src: womanBack,
          media: '1x',
        },
        {
          src: womanBack2,
          media: '2x',
        },
        {
          src: womanBack4,
          media: '4x',
        },
      ],
    },
    [Maturity.Teen]: {
      srcSet: [
        {
          src: girlBack,
          media: '1x',
        },
        {
          src: girlBack2,
          media: '2x',
        },
        {
          src: girlBack4,
          media: '4x',
        },
      ],
    },
    [Maturity.Baby]: babyBackBody,
  },
  [Gender.Other]: {
    [Maturity.Adult]: manBackBody,
    [Maturity.Teen]: boyBackBody,
    [Maturity.Baby]: babyBackBody,
  },
  [Gender.Unknown]: {
    [Maturity.Adult]: manBackBody,
    [Maturity.Teen]: boyBackBody,
    [Maturity.Baby]: babyBackBody,
  },
};

export const BackBodyImage: FC<BodyImageProps> = ({
  gender,
  maturity,
  ...params
}) =>
  createElement(ResponsiveImage, {
    ...params,
    ...bodies[gender][maturity],
    alt: 'Lungs auscultation back points image',
  });
