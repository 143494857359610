import { hu } from 'date-fns/locale';
import { LocaleDefinition } from '../localeDefinition';
import messages from './hu.json';

const definition: LocaleDefinition = {
  code: 'hu-HU',
  messages,
  dateFnsLocale: hu,
};

export default definition;
