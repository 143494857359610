import React, { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Heading } from '@higo/ui';
import { FormattedMessage } from 'react-intl';

const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 6.5rem 1fr;
  grid-column-gap: 1.5rem;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled('span')(
  ({ theme }) => css`
    ${theme.typography.subtitle1}
    color: ${theme.palette.secondary['500']}
  `,
);

const MeasurementText = styled(Heading)(
  ({ theme }) => css`
    color: ${theme.palette.secondary['900']};
    white-space: nowrap;
    text-transform: uppercase;
  `,
);

const BottomDivider = styled('div')(
  ({ theme }) => css`
    height: 1px;
    width: 100%;
    background-color: ${theme.palette.neutral['500']};
    margin-bottom: 1.5rem;
  `,
);

interface MeasurementEntryProps {
  title: string;
  measurement: ReactNode;
}

export const MeasurementEntry: FC<MeasurementEntryProps> = ({
  title,
  measurement,
  children,
}) => {
  const fieldName = title.replace(/\s/g, '');

  return (
    <>
      <ContentContainer>
        <TextContainer>
          <Title id={`label-${fieldName}`}>
            <FormattedMessage id={title} />
          </Title>
          <div id={`value-${fieldName}`}>
            <MeasurementText level={2}>{measurement}</MeasurementText>
          </div>
        </TextContainer>
        <div>{children}</div>
      </ContentContainer>
      <BottomDivider />
    </>
  );
};
