import { FC, ReactNode } from 'react';
import {
  SectionHeader,
  SectionHeaderProps,
} from '@higo/common/src/features/ExaminationPage/sections/ExaminationPageSection/SectionHeader';
import { StyleProps } from '@summer/jst-react';
import styled from 'styled-components';
import { ExaminationPageSectionId } from '../../models';
import {
  HorizontalDivider,
  useTocAnchor,
  AudioDownloadIcon,
  DSPAudioDownloadIcon,
} from '@higo/ui';

export type ExaminationPageSectionProps = {
  sectionId?: ExaminationPageSectionId;
  headerExtra?: ReactNode;
  divider?: ReactNode;
  audioDownload?: 'single' | 'double';
  filesIds?: (number | undefined)[];
} & SectionHeaderProps &
  StyleProps;

const Container = styled.section`
  position: relative;
`;

const Content = styled.div`
  margin-bottom: 2rem;
`;

const SectionAnchor = styled.div`
  position: absolute;
  top: -250px;
`;

export const ExaminationPageSection: FC<ExaminationPageSectionProps> = ({
  sectionId,
  className,
  style,
  children,
  title,
  audioDownload,
  audioLinksToDownload,
  filesIds,
  headerExtra,
  divider = <HorizontalDivider />,
}) => {
  const tocAnchorProps = useTocAnchor(sectionId);

  return (
    <Container
      id={sectionId}
      className={className}
      style={style}
      {...tocAnchorProps}
    >
      {sectionId && <SectionAnchor />}
      <SectionHeader
        title={title}
        audioDownload={audioDownload}
        filesIds={filesIds}
        audioLinksToDownload={audioLinksToDownload}
        id={sectionId ? `${sectionId}-header` : 'section-header'}
      >
        {headerExtra}
      </SectionHeader>
      <Content>{children}</Content>
      {divider}
    </Container>
  );
};
