export enum DoctorQueryKey {
  APIOAuth2AccessToken = 'doctor/authToken',
  UserSettings = 'doctor/userSettings',
  UserPersonalData = 'doctor/userPersonalData',

  ProfileData = 'doctor/doctorData',
  PatientsListData = 'doctor/patients',

  Messages = 'doctor/messages/patients',
  UnreadMessages = 'doctor/messages/unread/patients',

  UnassignedExaminationsList = 'doctor/unassignedExaminationsList',
  AssignedExaminationsList = 'doctor/assignedExaminationsList',
  HistoricalExaminationsList = 'doctor/historicalExaminationsList',

  PatientExaminations = 'doctor/patientExaminations',
  ExaminationsSummary = 'doctor/examinationsSummary',

  ExaminationComments = 'doctor/examinationComments',

  NewExaminationNotifiaction = 'doctor/notifications/newExamination',
}

export const examinationNotifiactionQueryKeys = {
  details: (examinationId: number) =>
    [DoctorQueryKey.NewExaminationNotifiaction, examinationId] as const,
};

export const messagesQueryKeys = {
  unreadList: () => [DoctorQueryKey.UnreadMessages] as const,
  examinationMessages: (examinationId: number) =>
    [DoctorQueryKey.ExaminationComments, examinationId] as const,
};
