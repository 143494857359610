import { CellProps } from 'react-table';
import { ExaminationType } from '@higo/api';
import { ExaminationTypeLabeledIcon, RoundQuickCheckIcon } from '@higo/ui';

export const ExamTypeCellRenderer = <T extends Record<string, unknown>>({
  cell,
}: CellProps<T, ExaminationType>) => {
  return (
    <ExaminationTypeLabeledIcon
      examinationType={cell.value}
      icon={<RoundQuickCheckIcon />}
    />
  );
};
