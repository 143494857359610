import { LoaderIcon, PauseIcon, PlayIcon } from '@higo/ui/src/components/icons';
import {
  RoundIconButton,
  RoundIconButtonProps,
} from '@higo/ui/src/components/buttons';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import clsx from 'clsx';

export const playPauseButtonClasses = {
  played: 'x-played',
};

const StyledRoundIconButton = styled(RoundIconButton)(
  ({ theme }) =>
    css`
      &.${playPauseButtonClasses.played} {
        background-color: ${theme.palette.primary[700]};
      }
    `,
);

export type PlayPauseButtonProps = {
  isLoading?: boolean;
  isPlaying: boolean;
  played?: boolean;
} & RoundIconButtonProps;

export const PlayPauseButton: FC<PlayPauseButtonProps> = ({
  isLoading = false,
  isPlaying,
  played = false,
  className,
  ...rest
}) => (
  <StyledRoundIconButton
    className={clsx(className, {
      [playPauseButtonClasses.played]: !isPlaying && played,
    })}
    icon={isLoading ? <LoaderIcon /> : isPlaying ? <PauseIcon /> : <PlayIcon />}
    {...rest}
  />
);
