import { pl } from 'date-fns/locale';
import { LocaleDefinition } from '../localeDefinition';
import messages from './pl.json';

const definition: LocaleDefinition = {
  code: 'pl-PL',
  messages,
  dateFnsLocale: pl,
};

export default definition;
