import { evolve, map } from 'rambda';

export type PaginatedListDirection = 'ASC' | 'DESC';

export interface PaginatedListRowBoundsDTO {
  limit: number;
  offset: number;
}

export interface PaginatedListOrderByDTO {
  fieldName: string;
}

export type SortWithDirection = {
  order: string;
  direction: PaginatedListDirection;
};

export interface PaginatedListResultDTO<T = Record<string, unknown>> {
  content: T[];
  direction: PaginatedListDirection;
  numberOfElement: number;
  orderBy: PaginatedListOrderByDTO;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
  sortWithDirection?: SortWithDirection[];
}

export interface PaginatedListReqDTO {
  page?: number;
  pageSize?: number;
  rowBounds?: Partial<PaginatedListRowBoundsDTO>;
  sortData?: string[]; // ['column;direction', 'column;direction'];
}

export const mapPaginatedListContent = <T, R>(
  fn: (x: T) => R,
): ((data: PaginatedListResultDTO<T>) => PaginatedListResultDTO<R>) =>
  evolve({
    content: map(fn),
  });
