import React, {
  FC,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { ExaminationPageLayout } from 'pages/ExaminationPage/ExaminationPageLayout';
import { useAPIOAuth2AccessTokenQuery } from 'services/apiOAuth2AccessTokenService';
import { Heading } from '@higo/ui';
import {
  Button,
  HorizontalDivider,
  media,
  SaveIcon,
  SurveyIcon,
} from '@higo/ui';
import { FormProvider, useForm } from 'react-hook-form';
import {
  AbdominalAuscultationSection,
  AnamnesisSection,
  CoughAuscultationSection,
  EarsExaminationSection,
  ExaminationPageContextSection,
  ExaminationPageSummarySection,
  HeartAuscultationSection,
  LungsAuscultationSection,
  SkinExaminationSection,
  ThroatExaminationSection,
} from '@higo/common/lib/features/ExaminationPage/sections';
import { useUserRole } from 'hooks/useUserRole';
import {
  ExaminationPageCovidModal,
  ExaminationPageFloatingDiagnosisWindow,
  ExaminationPageGeneralSurveyModal,
  ExaminationPageHeader,
  ExaminationPageInfoBar,
  ExaminationPageInfoBarOuterContainer,
  ExaminationPageInfoBarSkeleton,
  ExaminationPageOverview,
  ExaminationPageOverviewSkeleton,
} from '@higo/common/lib/features/ExaminationPage/components';
import {
  ExaminationPageDiagnosisFormValue,
  ExaminationPageModel,
  ExaminationPageSectionId,
  mapToExaminationDoctorDiagnosisDTO,
  mapToExaminationPageDiagnosisFormValue,
} from '@higo/common/lib/features/ExaminationPage/models';
import {
  ExaminationDTOStatus,
  ExaminationPatientDTOPatientType,
} from '@higo/api';
import { ChatModal } from 'components/ChatModal';
import { VitalSignsSection } from '@higo/common/lib/features/ExaminationPage/sections/VitalSignsSection';
import { PatientExaminationsModal } from 'components/PatientExaminationsModal';
import { ShareExaminationModal } from 'components/ShareExaminationModal';
import {
  ExaminationPageDiagnosisAutoSave,
  ExaminationPageDiagnosisAutoSaveRef,
} from 'pages/ExaminationPage/ExaminationPageDiagnosisAutoSave';
import { FormattedMessage } from 'react-intl';
import { ReactQueryAutoSaveSaveStatus } from '@higo/common/lib/hooks/useReactQueryAutoSave';
import { ExaminationDoctorDiagnosisDTO } from '@higo/api/lib/models/examinationDoctorDiagnosisDTO';
import { isNil } from 'rambda';
import { useExaminationPageModel } from '@higo/common/lib/features/ExaminationPage/hooks';
import { useProfileUserId } from 'hooks/queries/useProfileDataQuery';
import { coerceNumber } from '@higo/common/lib/utils';
import {
  useExaminationDetailsQuery,
  useExaminationDiagnosisQuery,
} from '@higo/common/lib/features/ExaminationPage/hooks/queries';
import { useSaveFinalExaminationDiagnosisMutation } from 'pages/ExaminationPage/hooks/useSaveFinalExaminationDiagnosisMutation';
import {
  RequestGenerateLeftEarExaminationVideoButton,
  RequestGenerateRightEarExaminationVideoButton,
  RequestGenerateSkinExaminationVideoButton,
  RequestGenerateThroatExaminationVideoButton,
} from './components/RequestGenerateVisualExaminationVideoButton';
import { useDispatchDoctorTrackedType } from 'hooks/useDispatchDoctorTrackedEvent';
import {
  DoctorTrackedType,
  TrackedEventButtonClickAction,
  TrackedEventDataContextProvider,
} from '@higo/common/lib/features/TrackedEvent';
import { useRemoveNewExaminationOnRender } from 'pages/ExaminationPage/hooks/useRemoveNewExaminationOnLoad';
import { useVideoProcessQuery } from '@higo/doctor/src/hooks/queries/useVideoProcessQuery';
import { useIntl } from 'react-intl';
import { AssignFromExamPageButton } from './components/buttons/AssignFromExamPageButton';
import { UnassignFromExamPageButton } from '@higo/doctor/src/pages/ExaminationPage/components/buttons/UnassignFromExamPageButton';
import { useProfileDataQuery } from 'hooks/queries/useProfileDataQuery';

const ContentContainer = styled('main')`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(1rem, 2rem) 3rem;

  section {
    scroll-margin-top: 220px;

    ${media.desktop.min} {
      scroll-margin-top: 193px;
    }
  }

  ${media.desktop.min} {
    grid-template-columns: minmax(0, 1fr) minmax(2rem, 6.25rem) 20.5rem;
  }
  padding-bottom: 14.425rem; // allows for proper floating window position
`;

const ContextSectionWrapper = styled.div<{ bothFiles: boolean }>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.bothFiles === true
      ? '2fr minmax(1rem, 6.25rem) 1fr'
      : '2fr minmax(1rem, 3rem) 3.5fr'};
`;

const UnassignButtonContainer = styled('div')`
  margin: 0 auto;
  max-width: 85rem;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
`;

export type ExaminationPageParams = {
  id: string;
};

const ExaminationPageContent: FC<{
  model: ExaminationPageModel;
  diagnosis?: ExaminationDoctorDiagnosisDTO;
  othersDrDiagnosisShouldBeHidden: boolean;
}> = memo(({ model, diagnosis, othersDrDiagnosisShouldBeHidden }) => {
  const { isMedicalOperator } = useUserRole();
  const sendTrackedEvent = useDispatchDoctorTrackedType();
  const examStatus = model.details.examination.status;
  const isNotAssignedExam = examStatus === ExaminationDTOStatus.INQ;
  const canBeUnassignedExam =
    examStatus === ExaminationDTOStatus.TAK ||
    examStatus === ExaminationDTOStatus.DRA;

  useRemoveNewExaminationOnRender(model.details.examination.id);

  const methods = useForm({
    defaultValues: mapToExaminationPageDiagnosisFormValue(diagnosis),
  });

  // had to lift autoSave function and state to mitigate RHF rendering / freezes issues
  const autoSaveRef = useRef<ExaminationPageDiagnosisAutoSaveRef>(null);
  const [autoSaveState, setAutoSaveState] =
    useState<ReactQueryAutoSaveSaveStatus>();
  const [skinImageForPDF, setSkinImageForPDF] = useState('');
  const [isSkinImageDiagnostic, setIsSkinImageDiagnostic] = useState(false);
  const [isSkinDataLoading, setIsSkinDataLoading] = useState(false);
  const handleSkinImageForPDFChange = (index: string) => {
    setSkinImageForPDF(index);
  };
  const handleIsSkinImageDiagnosticChange = (value: boolean) => {
    setIsSkinImageDiagnostic(value);
  };
  const handleIsSkinDataLoadingChange = (value: boolean) => {
    setIsSkinDataLoading(value);
  };

  const [throatImageForPDF, setThroatImageForPDF] = useState('');
  const [isThroatImageDiagnostic, setIsThroatImageDiagnostic] = useState(false);
  const [isThroatDataLoading, setIsThroatDataLoading] = useState(false);

  const handleThroatImageForPDFChange = (index: string) => {
    setThroatImageForPDF(index);
  };
  const handleIsThroatImageDiagnosticChange = (value: boolean) => {
    setIsThroatImageDiagnostic(value);
  };
  const handleIsThroatDataLoadingChange = (value: boolean) => {
    setIsThroatDataLoading(value);
  };

  const [rightEarImageForPDF, setRightEarImageForPDF] = useState('');
  const [isRightEarImageDiagnostic, setIsRightEarImageDiagnostic] =
    useState(false);
  const [isRightEarDataLoading, setIsRightEarDataLoading] = useState(false);

  const handleRightEarImageForPDFChange = (index: string) => {
    setRightEarImageForPDF(index);
  };
  const handleIsRightEarImageDiagnosticChange = (value: boolean) => {
    setIsRightEarImageDiagnostic(value);
  };
  const handleIsRightEarDataLoadingChange = (value: boolean) => {
    setIsRightEarDataLoading(value);
  };

  const [leftEarImageForPDF, setLeftEarImageForPDF] = useState('');
  const [isLeftEarImageDiagnostic, setIsLeftEarImageDiagnostic] =
    useState(false);
  const [isLeftEarDataLoading, setIsLeftEarDataLoading] = useState(false);

  const handleLeftEarImageForPDFChange = (index: string) => {
    setLeftEarImageForPDF(index);
  };
  const handleIsLeftEarImageDiagnosticChange = (value: boolean) => {
    setIsLeftEarImageDiagnostic(value);
  };
  const handleIsLeftEarDataLoadingChange = (value: boolean) => {
    setIsLeftEarDataLoading(value);
  };

  const {
    mutateAsync: saveFinalDiagnosis,
    isLoading: isSaveFinalDiagnosisLoading,
  } = useSaveFinalExaminationDiagnosisMutation(model.details.examination.id);
  const handleDiagnosisSubmit = (value: ExaminationPageDiagnosisFormValue) => {
    const dto = mapToExaminationDoctorDiagnosisDTO(value);
    dto && saveFinalDiagnosis(dto);
  };
  const intl = useIntl();

  //todo: fix deps issue
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isSectionAvailable = useCallback(
    (id: ExaminationPageSectionId) =>
      model.availableExaminationPageSections.has(id),
    [model],
  );

  const hasContextFile =
    !isNil(model.details.examination.contextPhotoFileId) ||
    !isNil(model.details.examination.contextVideoFileId);

  const hasBothContextFiles =
    !isNil(model.details.examination.contextPhotoFileId) &&
    !isNil(model.details.examination.contextVideoFileId);

  const onSaveDraftButtonClick = () => {
    autoSaveRef?.current?.saveDraft();
    sendTrackedEvent({
      event: DoctorTrackedType.ButtonClick,
      eventAction: TrackedEventButtonClickAction.SaveExaminationDiagnosisDraft,
    });
    autoSaveState === 'saved' &&
      toast.success(
        intl.formatMessage({
          id: 'toast.diagnosis.savedAsDraft',
        }),
      );
  };

  const { refetch: refetchExam } = useExaminationDetailsQuery(
    model.details.examination.id,
  );

  useEffect(() => {
    refetchExam();
  }, [examStatus, refetchExam]);

  useVideoProcessQuery(model.details.examination.id);

  return (
    <>
      {model.answeredGeneralSurvey && (
        <ExaminationPageCovidModal
          answeredGeneralSurvey={model.answeredGeneralSurvey}
        />
      )}
      <ExaminationPageHeader style={{ height: '180px' }}>
        <ExaminationPageLayout>
          <ExaminationPageOverview
            mode={model.mode}
            patientOverview={model.patientOverview}
            examinationId={model.details.examination.id}
            patient={model.details.patient}
            generalSurveySlot={
              model.answeredGeneralSurvey && (
                <ExaminationPageGeneralSurveyModal
                  answeredGeneralSurvey={model.answeredGeneralSurvey}
                  hasCopyFeature
                  hasToastWhenCopy
                  trigger={
                    model.mode === 'FAMILY' ? (
                      <Button
                        color="secondary"
                        size="small"
                        endIcon={<SurveyIcon />}
                        id="general-survey-button"
                        onClick={() =>
                          sendTrackedEvent({
                            event: DoctorTrackedType.ButtonClick,
                            eventAction:
                              TrackedEventButtonClickAction.OpenGeneralSurveyModal,
                            patientId: model.details.patient.id,
                            examinationId: model.details.examination.id,
                          })
                        }
                      >
                        <FormattedMessage id="generalSurvey.modalTrigger.title" />
                      </Button>
                    ) : (
                      <></>
                    )
                  }
                />
              )
            }
            patientExaminationsModalSlot={
              <PatientExaminationsModal patientId={model.details.patient.id} />
            }
          />
        </ExaminationPageLayout>
        <ExaminationPageInfoBarOuterContainer>
          <UnassignButtonContainer>
            <ExaminationPageInfoBar
              examinationCreateDate={
                model.details.examination.sessionEndTime ??
                model.details.examination.createDate
              }
              partnerName={model.details.patient.partnerDetails?.name}
              higoMode={model.details.patient.type} // todo: mode shouldn't be stored on user but  ¯\_(ツ)_/¯
              executedBy={model.details.executedBy}
              chatModalSlot={
                model.details.patient.type ===
                  ExaminationPatientDTOPatientType.FAMILY && (
                  <ChatModal
                    examinationId={model.details.examination.id}
                    patient={model.details.patient}
                  />
                )
              }
              shareModalSlot={
                <ShareExaminationModal
                  examinationId={model.details.examination.id}
                  examData={model}
                  diagnosisAutoSaveState={autoSaveState}
                  diagnosisData={diagnosis}
                  skinImageForPDF={skinImageForPDF}
                  isSkinImageDiagnostic={
                    skinImageForPDF && isSkinImageDiagnostic
                  }
                  isThroatImageDiagnostic={
                    throatImageForPDF && isThroatImageDiagnostic
                  }
                  throatImageForPDF={throatImageForPDF}
                  rightEarImageForPDF={rightEarImageForPDF}
                  isRightEarImageDiagnostic={isRightEarImageDiagnostic}
                  leftEarImageForPDF={leftEarImageForPDF}
                  isLeftEarImageDiagnostic={isLeftEarImageDiagnostic}
                  isThroatDataLoading={isThroatDataLoading}
                  isSkinDataLoading={isSkinDataLoading}
                  isRightEarDataLoading={isRightEarDataLoading}
                  isLeftEarDataLoading={isLeftEarDataLoading}
                />
              }
              hasOperatorData
            />
            {canBeUnassignedExam && (
              <UnassignFromExamPageButton
                examinationId={model.details.examination.id}
                patientId={model.details.patient.id}
              />
            )}
          </UnassignButtonContainer>
        </ExaminationPageInfoBarOuterContainer>
        <ExaminationPageLayout></ExaminationPageLayout>
      </ExaminationPageHeader>

      <FormProvider {...methods}>
        {model.diagnosis.isEditable && (
          <ExaminationPageDiagnosisAutoSave
            ref={autoSaveRef}
            examinationId={model.details.examination.id}
            onStatusChange={setAutoSaveState}
          />
        )}
        <ExaminationPageLayout>
          <ContentContainer>
            <div>
              {model.mode === 'PRO' && (
                <VitalSignsSection
                  examinationId={model.details.examination.id}
                  isPhysicalExaminationCompletedFn={
                    model.isPhysicalExaminationCompletedFn
                  }
                />
              )}
              {model.mode === 'FAMILY' && (
                <>
                  <HorizontalDivider />
                  {hasContextFile ? (
                    <ContextSectionWrapper bothFiles={hasBothContextFiles}>
                      <ExaminationPageContextSection
                        contextPhotoFileId={
                          model.details.examination.contextPhotoFileId
                        }
                        contextVideoFileId={
                          model.details.examination.contextVideoFileId
                        }
                        hasBothContextFiles={hasBothContextFiles}
                      />
                      <div />
                      <VitalSignsSection
                        examinationId={model.details.examination.id}
                        isPhysicalExaminationCompletedFn={
                          model.isPhysicalExaminationCompletedFn
                        }
                      />
                    </ContextSectionWrapper>
                  ) : (
                    <VitalSignsSection
                      examinationId={model.details.examination.id}
                      isPhysicalExaminationCompletedFn={
                        model.isPhysicalExaminationCompletedFn
                      }
                    />
                  )}
                </>
              )}

              {isSectionAvailable(ExaminationPageSectionId.AnamnesisSurvey) && (
                <AnamnesisSection
                  examinationId={model.details.examination.id}
                  createDate={model.details.examination.createDate}
                  mode={model.mode}
                  isMedicalOperatorExaminer={isMedicalOperator}
                  anamnesisTaggedQuestions={
                    model.answeredAnamnesisSurvey?.taggedQuestions
                  }
                  generalTaggedQuestions={
                    model.answeredGeneralSurvey?.taggedQuestions
                  }
                  hasCopyFeature
                  hasToastWhenCopy={true}
                />
              )}

              <form onSubmit={methods.handleSubmit(handleDiagnosisSubmit)}>
                {isSectionAvailable(ExaminationPageSectionId.Skin) && (
                  <SkinExaminationSection
                    hasTogglingGalleryVideo
                    examinationId={model.details.examination.id}
                    handleSkinImageForPDFChange={handleSkinImageForPDFChange}
                    handleIsSkinImageDiagnosticChange={
                      handleIsSkinImageDiagnosticChange
                    }
                    handleIsSkinDataLoadingChange={
                      handleIsSkinDataLoadingChange
                    }
                    requestGenerateSkinExaminationVideoButtonSlot={
                      <RequestGenerateSkinExaminationVideoButton
                        examinationId={model.details.examination.id}
                      />
                    }
                  />
                )}

                {isSectionAvailable(ExaminationPageSectionId.Heart) && (
                  <HeartAuscultationSection
                    examinationId={model.details.examination.id}
                    patientModel={model.patient}
                    diagnosisModel={model.diagnosis}
                  />
                )}

                {isSectionAvailable(ExaminationPageSectionId.Lungs) && (
                  <LungsAuscultationSection
                    examinationId={model.details.examination.id}
                    patientModel={model.patient}
                    diagnosisModel={model.diagnosis}
                  />
                )}

                {isSectionAvailable(ExaminationPageSectionId.Cough) && (
                  <CoughAuscultationSection
                    examinationId={model.details.examination.id}
                  />
                )}

                {isSectionAvailable(ExaminationPageSectionId.Ears) && (
                  <EarsExaminationSection
                    hasTogglingGalleryVideo
                    examinationId={model.details.examination.id}
                    isPhysicalExaminationCompletedFn={
                      model.isPhysicalExaminationCompletedFn
                    }
                    handleRightEarImageForPDFChange={
                      handleRightEarImageForPDFChange
                    }
                    handleIsRightEarImageDiagnosticChange={
                      handleIsRightEarImageDiagnosticChange
                    }
                    handleLeftEarImageForPDFChange={
                      handleLeftEarImageForPDFChange
                    }
                    handleIsLeftEarImageDiagnosticChange={
                      handleIsLeftEarImageDiagnosticChange
                    }
                    handleIsRightEarDataLoadingChange={
                      handleIsRightEarDataLoadingChange
                    }
                    handleIsLeftEarDataLoadingChange={
                      handleIsLeftEarDataLoadingChange
                    }
                    requestGenerateLeftEarExaminationVideoButtonSlot={
                      <RequestGenerateLeftEarExaminationVideoButton
                        examinationId={model.details.examination.id}
                      />
                    }
                    requestGenerateRightEarExaminationVideoButtonSlot={
                      <RequestGenerateRightEarExaminationVideoButton
                        examinationId={model.details.examination.id}
                      />
                    }
                  />
                )}

                {isSectionAvailable(ExaminationPageSectionId.Throat) && (
                  <ThroatExaminationSection
                    examinationId={model.details.examination.id}
                    hasTogglingGalleryVideo
                    handleThroatImageForPDFChange={
                      handleThroatImageForPDFChange
                    }
                    handleIsThroatImageDiagnosticChange={
                      handleIsThroatImageDiagnosticChange
                    }
                    handleIsThroatDataLoadingChange={
                      handleIsThroatDataLoadingChange
                    }
                    requestGenerateThroatExaminationVideoButtonSlot={
                      <RequestGenerateThroatExaminationVideoButton
                        examinationId={model.details.examination.id}
                      />
                    }
                  />
                )}

                {isSectionAvailable(ExaminationPageSectionId.Abdominal) && (
                  <AbdominalAuscultationSection
                    examinationId={model.details.examination.id}
                  />
                )}

                {isNotAssignedExam ? (
                  <>
                    <div id="summary-header">
                      <Heading level={4}>
                        <FormattedMessage id="examinationPage.section.summary.title" />
                      </Heading>
                    </div>
                    <AssignFromExamPageButton
                      examinationId={model.details.examination.id}
                      patientId={model?.details.patient.id}
                    />
                  </>
                ) : (
                  <>
                    {!othersDrDiagnosisShouldBeHidden && (
                      <ExaminationPageSummarySection
                        patientOverview={model.patientOverview}
                        isReadOnly={model.diagnosis.isReadOnly}
                      >
                        {model.diagnosis.isEditable && (
                          <>
                            <Button
                              color="secondary"
                              autoWidth
                              endIcon={<SaveIcon />}
                              onClick={onSaveDraftButtonClick}
                              isLoading={'saving' === autoSaveState}
                            >
                              <FormattedMessage id="examinationPage.button.saveAsDraft" />
                            </Button>
                            <Button
                              type="submit"
                              fullWidth
                              isLoading={isSaveFinalDiagnosisLoading}
                            >
                              <FormattedMessage id="common.send" />
                            </Button>
                          </>
                        )}
                      </ExaminationPageSummarySection>
                    )}
                  </>
                )}
              </form>
            </div>
            <div>{/*Hacky variable gap*/}</div>
            <div>
              {isNotAssignedExam ? (
                <AssignFromExamPageButton
                  examinationId={model.details.examination.id}
                  patientId={model?.details.patient.id}
                />
              ) : (
                <>
                  {!othersDrDiagnosisShouldBeHidden && (
                    <ExaminationPageFloatingDiagnosisWindow
                      diagnosisModel={model.diagnosis}
                      availableExaminationPageSections={
                        model.availableExaminationPageSections
                      }
                    />
                  )}
                </>
              )}
            </div>
          </ContentContainer>
        </ExaminationPageLayout>
      </FormProvider>
    </>
  );
});

const ExaminationPageSkeleton = () => (
  <ExaminationPageHeader shadowless>
    <ExaminationPageLayout>
      <ExaminationPageOverviewSkeleton />
    </ExaminationPageLayout>
    <ExaminationPageInfoBarOuterContainer>
      <ExaminationPageLayout>
        <ExaminationPageInfoBarSkeleton />
      </ExaminationPageLayout>
    </ExaminationPageInfoBarOuterContainer>
  </ExaminationPageHeader>
);

/**
 * Isolate re-renders cause by url hash param
 */
export const ExaminationPage = () => {
  const { id } = useParams();
  const examId = coerceNumber(id);

  const { data: profileUserId } = useProfileUserId();
  const model = useExaminationPageModel(examId, profileUserId);
  const examStatus = model?.details.examination.status;

  const { data: tokenData, isSuccess: hasTokenData } =
    useAPIOAuth2AccessTokenQuery();
  const { data: doctorData } = useProfileDataQuery();
  const loggedDoctorId = doctorData?.userId;
  const userPermissions =
    hasTokenData && tokenData?.accessTokenData.permissions;
  const otherDrsDiagnosisPersmissionDisabled =
    userPermissions &&
    userPermissions[0]['DIAGNOSIS'] &&
    userPermissions[0]['DIAGNOSIS'].scope === 'OWN';
  const isOthersDrExamination =
    model?.details.examination.doctorId !== loggedDoctorId;
  const othersDrDiagnosisShouldBeHidden =
    examStatus !== 'INQ' &&
    otherDrsDiagnosisPersmissionDisabled &&
    isOthersDrExamination;

  const { data: diagnosisData, isLoading: isDiagnosisLoading } =
    useExaminationDiagnosisQuery(
      examId,
      model?.isDiagnosisAvailable && !othersDrDiagnosisShouldBeHidden
        ? true
        : false,
    );

  const trackedCustomEventData = useMemo(
    () => ({
      patientId: model?.details.patient.id,
      examinationId: model?.details.examination.id,
    }),
    [model],
  );

  return isNil(model) || isDiagnosisLoading ? (
    <ExaminationPageSkeleton />
  ) : (
    <TrackedEventDataContextProvider value={trackedCustomEventData}>
      <ExaminationPageContent
        model={model}
        diagnosis={diagnosisData}
        othersDrDiagnosisShouldBeHidden={othersDrDiagnosisShouldBeHidden}
      />
    </TrackedEventDataContextProvider>
  );
};
