import styled, { css } from 'styled-components';

const MessageTemplate = styled('div')(
  ({ theme }) => css`
    display: inline-block;
    max-width: 16.6875rem;
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: 0.5rem;

    ${theme.typography.body2};

    & + & {
      margin-top: 0.5rem;
    }
  `,
);

export const IncomingMessage = styled(MessageTemplate)(
  ({ theme }) => css`
    background-color: ${theme.palette.secondary['500']};
    color: ${theme.palette.white};
  `,
);

export const OutgoingMessage = styled(MessageTemplate)(
  ({ theme }) => css`
    background-color: ${theme.palette.neutral['400']};
    color: ${theme.palette.secondary['500']};
  `,
);
