export enum FcmMessageCode {
  NewExam = 'NEW_EXAM',
  NewComment = 'NEW_COMMENT',
  ExamTakenByDoctor = 'EXAM_TAKEN_BY_OTHER_DOCTOR', // todo: clarify with backend
  VideoCreated = 'EXAMINATION_VIDEO_SUCCESSFULLY_CREATED',
  VideoFailed = 'EXAMINATION_VIDEO_CREATION_FAILED',
  SurveyFilled = 'EXAMINATION_SURVEY_FILLED',
}
export const supportedFcmMessages = Object.values(FcmMessageCode);

export const isFcmMessageSupported = (code: string) =>
  Object.values(FcmMessageCode).includes(code as FcmMessageCode);

export type FcmNewExamMessagePayloadData = {
  code: 'NEW_EXAM';
  examId: string;
};

export type FcmNewCommentMessagePayloadData = {
  code: 'NEW_COMMENT';
  examId: string;
};

export type FcmExamTakenByDoctorPayloadData = {
  code: 'EXAM_TAKEN_BY_OTHER_DOCTOR';
  examId: string;
};

export type FcmVideoCreatedPayloadData = {
  code: 'EXAMINATION_VIDEO_SUCCESSFULLY_CREATED';
  examId: string;
  type: string;
};

export type FcmVideoFailedPayloadData = {
  code: 'EXAMINATION_VIDEO_CREATION_FAILED';
  examId: string;
  type: string;
};

export type FcmSurveyFilledPayloadData = {
  code: 'EXAMINATION_SURVEY_FILLED';
  examId: string;
};

export type FcmMessagePayloadData =
  | FcmNewExamMessagePayloadData
  | FcmNewCommentMessagePayloadData
  | FcmExamTakenByDoctorPayloadData
  | FcmVideoCreatedPayloadData
  | FcmVideoFailedPayloadData
  | FcmSurveyFilledPayloadData;

export interface FcmMessagePayload {
  [FcmMessageCode.NewExam]: FcmNewExamMessagePayloadData;
  [FcmMessageCode.NewComment]: FcmNewCommentMessagePayloadData;
  [FcmMessageCode.ExamTakenByDoctor]: FcmExamTakenByDoctorPayloadData;
  [FcmMessageCode.VideoCreated]: FcmVideoCreatedPayloadData;
  [FcmMessageCode.VideoFailed]: FcmVideoFailedPayloadData;
  [FcmMessageCode.SurveyFilled]: FcmSurveyFilledPayloadData;
}

export const isFcmMessagePayload = (
  data: unknown,
): data is FcmMessagePayloadData =>
  !!(data && typeof data === 'object' && data.hasOwnProperty('code'));
