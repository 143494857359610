export enum ExaminationQueryKey {
  ExaminationDetails = 'examination/examinationDetails',
  ExaminationDiagnosis = 'examination/examinations/diagnosis',
  ExaminationNotes = 'examination/notes/examinations',

  SkinExaminationResults = 'examination/examinationResults/skin',
  ThroatExaminationResults = 'examination/examinationResults/throat',
  RightEarExaminationResults = 'examination/examinationResults/rightEar',
  LeftEarExaminationResults = 'examination/examinationResults/leftEar',
  SecuredFileResource = 'examination/securedFileResource',

  HeartRatePhysicalExamination = 'examination/physicalExamination/heartRate',
  HeartRateWavPhysicalExamination = 'examination/physicalExamination/heartRateWav',
  TemperaturePhysicalExamination = 'examination/physicalExamination/temperature',
  HeartAuscultationPhysicalExamination = 'examination/physicalExamination/heart',
  LungsAuscultationPhysicalExamination = 'examination/physicalExamination/lungs',
  CoughAuscultationPhysicalExamination = 'examination/physicalExamination/cough',
  AbdominalAuscultationPhysicalExamination = 'examination/physicalExamination/abdominal',

  UserAnsweredGeneralSurvey = 'examination/userAnsweredGeneralSurvey',
  UserAnsweredAnamnesisSurvey = 'examination/userAnsweredAnamnesisSurvey',
}
