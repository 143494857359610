import {
  createContext,
  FC,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { IntlProvider } from 'react-intl';
import { useQueryClient } from 'react-query';
import { LocaleDefinition } from './localeDefinition';
import { availableLocaleDefinitions } from './config';
import { CommonQueryKey } from '@higo/common/src/config/commonQueryKey';
import en from './assets/en';
import pl from './assets/pl';
import bg from './assets/bg';
import nl from './assets/nl';
import ro from './assets/ro';
import it from './assets/it';
import hu from './assets/hu';
import { saveLocalLanguage } from './languageLocalPersistor';
import { stripRegionDesignator } from './utils';
import { availableLocaleCodes } from './config';

interface AppLocaleContext {
  current: LocaleDefinition;
  use: (code: LocaleDefinition) => void;
  isFetching: boolean;
  setIsFetching: (x: boolean) => void;
}

const Context = createContext<AppLocaleContext>({
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  current: undefined!, // workaround for default context value and typescript
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  use: () => {},
  isFetching: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsFetching: () => {},
});

export const useAppLocale = () => {
  const { current, use, isFetching, setIsFetching } = useContext(Context);

  const queryClient = useQueryClient();

  const changeLocale = useCallback(
    async (code: string) => {
      const regionlessCode = stripRegionDesignator(code);

      if (stripRegionDesignator(current.code) === regionlessCode) {
        return;
      }
      setIsFetching(true);

      const definition = await queryClient.fetchQuery(
        [CommonQueryKey.AppLocaleCache, regionlessCode],
        availableLocaleDefinitions[regionlessCode],
      );

      use(definition);
      saveLocalLanguage(definition.code); // todo: decouple this
      setIsFetching(false);
    },
    // todo: verify use implementation (might require rewrite to useRef)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [current, queryClient, setIsFetching],
  );

  return {
    isFetching,
    current,
    changeLocale,
  };
};

export interface AppIntlProviderProps {
  onLoading?: (isLoading: boolean) => void;
}

export const AppLocaleProvider: FC<AppIntlProviderProps> = memo(
  ({ children }) => {
    const [locale, setLocale] = useState(en);
    const [isFetching, setIsFetching] = useState(false);

    const browserLanguage = navigator.languages
      ? navigator.languages[0]
      : navigator.language;

    const languagesList = ['pl', 'it', 'nl', 'bg', 'hu', 'ro'];
    useEffect(() => {
      if (browserLanguage && browserLanguage !== 'en') {
        const browserLanguageCode = browserLanguage
          .split('')
          .slice(0, 2)
          .join('');

        browserLanguageCode === 'pl' && setLocale(pl);
        browserLanguageCode === 'it' && setLocale(it);
        browserLanguageCode === 'nl' && setLocale(nl);
        browserLanguageCode === 'bg' && setLocale(bg);
        browserLanguageCode === 'hu' && setLocale(hu);
        browserLanguageCode === 'ro' && setLocale(ro);

        !languagesList.includes(browserLanguageCode) && setLocale(en);
      }
    }, [browserLanguage]);

    const value = useMemo(
      () => ({
        current: locale,
        use: setLocale,
        isFetching,
        setIsFetching,
      }),
      [locale, setLocale, isFetching, setIsFetching],
    );

    return (
      <Context.Provider value={value}>
        <IntlProvider locale={locale.code} messages={locale.messages}>
          {children}
        </IntlProvider>
      </Context.Provider>
    );
  },
);

AppLocaleProvider.displayName = 'AppIntlProvider';
