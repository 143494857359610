import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export enum ExaminationPageSummaryFormField {
  Notes = 'note',
  Diagnosis = 'diagnosis',
  Medicines = 'medicines',
  Recommendations = 'recommendations',
}

export interface ExaminationPageSummaryFormValue {
  note?: string;
  diagnosis?: string;
  medicines?: string;
  recommendations?: string;
}

export const examinationPageSummaryFormDefaultValues: ExaminationPageSummaryFormValue =
  {
    [ExaminationPageSummaryFormField.Notes]: '',
    [ExaminationPageSummaryFormField.Diagnosis]: '',
    [ExaminationPageSummaryFormField.Medicines]: '',
    [ExaminationPageSummaryFormField.Recommendations]: '',
  };

export const useExaminationPageSummaryFormTranslations = () => {
  const intl = useIntl();
  return useMemo(
    () => ({
      [ExaminationPageSummaryFormField.Notes]: {
        label: intl.formatMessage({
          id: 'examinationPage.summaryForm.notes.label',
        }),
        placeholder: intl.formatMessage({
          id: 'examinationPage.summaryForm.notes.placeholder',
        }),
      },
      [ExaminationPageSummaryFormField.Diagnosis]: {
        label: intl.formatMessage({
          id: 'examinationPage.summaryForm.diagnosis.label',
        }),
        placeholder: intl.formatMessage({
          id: 'examinationPage.summaryForm.diagnosis.placeholder',
        }),
      },
      [ExaminationPageSummaryFormField.Medicines]: {
        label: intl.formatMessage({
          id: 'examinationPage.summaryForm.medicines.label',
        }),
        placeholder: intl.formatMessage({
          id: 'examinationPage.summaryForm.medicines.placeholder',
        }),
      },
      [ExaminationPageSummaryFormField.Recommendations]: {
        label: intl.formatMessage({
          id: 'examinationPage.summaryForm.recommendations.label',
        }),
        placeholder: intl.formatMessage({
          id: 'examinationPage.summaryForm.recommendations.placeholder',
        }),
      },
    }),
    [intl],
  );
};
