import { IconBox, IconProps } from '../IconBox';

const RoundLungsIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12ZM12.311 6.596a.309.309 0 1 0-.616 0v4.881l-.632.635V9.494c0-.908-.734-1.647-1.637-1.647l-.143.004a2.595 2.595 0 0 0-2.378 1.975l-1.12 4.587a2.684 2.684 0 0 0 .713 2.514 2.65 2.65 0 0 0 1.891.787 2.65 2.65 0 0 0 1.892-.787 2.679 2.679 0 0 0 .782-1.902v-2.037l.956-.96.923.929v2.023c0 .72.279 1.394.784 1.901a2.648 2.648 0 0 0 1.89.788 2.65 2.65 0 0 0 1.892-.788c.658-.662.924-1.6.7-2.554l-1.334-4.784a2.381 2.381 0 0 0-2.294-1.741l-.117.004a1.645 1.645 0 0 0-1.52 1.642l-.001 2.632-.631-.634v-4.85Zm-4.8 5.832.072-.002.073.011.069.022a.45.45 0 0 1 .28.453l-.01.072-.31 1.343-.02.109a.973.973 0 0 0 .272.791l.082.075.47.38c.192.16.244.258.198.453a.457.457 0 0 1-.473.354l-.073-.01-.042-.017-.459-.27-.112-.07a1.832 1.832 0 0 1-.771-1.793l.025-.13.328-1.418.022-.071a.457.457 0 0 1 .378-.282Zm8.714.01.074-.011a.457.457 0 0 1 .473.354l.327 1.417.025.13c.116.74-.23 1.482-.883 1.865l-.459.269-.042.017-.073.01a.457.457 0 0 1-.473-.354c-.051-.22.02-.315.278-.517l.39-.316.082-.075a.973.973 0 0 0 .253-.9l-.31-1.343-.011-.072a.456.456 0 0 1 .35-.475Z"
    />
  </IconBox>
);

export default RoundLungsIcon;
