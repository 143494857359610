export enum DictionaryType {
  // Gender = 'GENDER',
  Languages = 'LANGUAGES',
  PhoneNumberPrefix = 'PHONE_NUMBER_PREFIX',
  LungsAuscultation = 'LUNGS_AUSCULTATION',
  HeartAuscultation = 'HEART_AUSCULTATION',
  // ProfileColors = 'PROFILE_COLORS',
  // Countries = 'COUNTRIES',
  // MedicalInstitutions = 'MEDICAL_INSTITUTION',
}
