import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import clsx from 'clsx';
import {
  selectUnstyledClasses,
  SelectUnstyledComponentsPropsOverrides,
  SelectUnstyledOwnerState,
  TSelectValue,
} from '@mui/base/SelectUnstyled';
import {
  MultiSelectUnstyledComponentsPropsOverrides,
  MultiSelectUnstyledOwnerState,
} from '@mui/base/MultiSelectUnstyled';
import { StyleProps } from '@summer/jst-react';
import ChevronIcon from '@higo/ui/src/components/icons/ChevronIcon';
import { CounterChip } from '@higo/ui/src/components/CounterChip';
import { isNil } from 'rambda';

export interface SelectRootProps
  extends StyleProps,
    SelectUnstyledComponentsPropsOverrides {
  ownerState: SelectUnstyledOwnerState<TSelectValue>;
}

export interface MultiSelectRootProps
  extends StyleProps,
    MultiSelectUnstyledComponentsPropsOverrides {
  clearValue?: () => void;
  ownerState: MultiSelectUnstyledOwnerState<TSelectValue>;
}

export const selectClasses = {
  ...selectUnstyledClasses,
  error: 'x-error',
};

export const SelectButton = styled('button')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    transition: ${theme.transitions.create('all')};

    width: 100%;
    padding: 0.5rem 0.625rem;
    border-radius: 0.5rem;

    font-family: ${theme.fontFamily.primary};
    color: ${theme.palette.secondary['500']};
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.secondary['200']};

    &.${selectClasses.disabled} {
      cursor: not-allowed;
      background: ${theme.palette.neutral['300']};
      color: ${theme.palette.secondary['400']};
      & > * {
        pointer-events: none;
      }
    }

    &:hover:not(.${selectClasses.disabled}) {
      border-color: ${theme.palette.secondary['500']};
    }
    && {
      &:focus,
      &.${selectClasses.focusVisible} {
        border-color: ${theme.palette.primary['500']};
      }
    }
    &.${selectClasses.error} {
      border-color: ${theme.palette.error} !important;
    }
  `,
);

const SelectValue = styled('span')(
  ({ theme }) => css`
    ${theme.typography.lineClamp(1)};
    ${theme.typography.body};
    padding-right: 0.25rem;

    line-height: 1rem;
    &.${selectClasses.active} {
      color: ${theme.palette.secondary['900']};
    }
  `,
);

const StyledCounterChip = styled(CounterChip)`
  margin-right: 0.5rem;
`;

const StyledChevronIcon = styled(ChevronIcon)`
  min-width: 1rem;
`;

export const SelectRoot = forwardRef<HTMLButtonElement, SelectRootProps>(
  ({ children, ownerState, placeholder, error, className, ...props }, ref) => (
    <SelectButton
      {...props}
      className={clsx(className, { [selectClasses.error]: error })}
      ref={ref}
    >
      <SelectValue
        className={clsx({
          [selectClasses.active]:
            !isNil(ownerState.value) && !ownerState.disabled,
        })}
      >
        {isNil(ownerState.value) ? placeholder : children}
      </SelectValue>
      <ChevronIcon size={16} direction={ownerState.open ? 'up' : 'down'} />
    </SelectButton>
  ),
);

SelectRoot.displayName = 'SelectRoot';

export const MultiSelectRoot = forwardRef<
  HTMLButtonElement,
  MultiSelectRootProps
>(
  (
    {
      children,
      ownerState,
      placeholder,
      error,
      className,
      clearValue,
      ...props
    },
    ref,
  ) => (
    <SelectButton
      {...props}
      className={clsx(className, { [selectClasses.error]: error })}
      ref={ref}
    >
      <SelectValue
        className={clsx({
          [selectClasses.active]:
            ownerState.value?.length !== 0 && !ownerState.disabled,
        })}
      >
        {ownerState?.value?.length === 0 ? (
          placeholder
        ) : (
          <>
            {!!clearValue && (
              <StyledCounterChip
                disabled={ownerState.disabled}
                onDelete={(e) => {
                  e.stopPropagation();
                  clearValue();
                }}
              >
                {ownerState?.value?.length}
              </StyledCounterChip>
            )}
            {children}
          </>
        )}
      </SelectValue>
      <StyledChevronIcon
        size={16}
        direction={ownerState.open ? 'up' : 'down'}
      />
    </SelectButton>
  ),
);

MultiSelectRoot.displayName = 'MultiSelectRoot';
