import { Heading, Text } from '@higo/ui';
import { ResetPasswordForm, ResetPasswordFormModel } from './ResetPasswordForm';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { useRequestResetPasswordMutation } from 'hooks/auth/useRequestResetPasswordMutation';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';

const StyledText = styled(Text)`
  margin-bottom: 1.5rem;
`;

export const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const mutation = useRequestResetPasswordMutation();
  const intl = useIntl();

  const requestResetPassword = ({ email }: ResetPasswordFormModel) =>
    mutation.mutateAsync(email).then(() => {
      toast.success(
        intl.formatMessage({
          id: 'authentication.requestResetPasswordSuccessMessage',
        }),
      );
      navigate(routes.root);
    });

  return (
    <>
      <Heading level={1}>
        <div id="reset-header">
          <FormattedMessage id="authentication.resetPasswordHeader" />
        </div>
      </Heading>
      <StyledText variant="body2" id="reset-descriptopn">
        <FormattedMessage id="authentication.resetPasswordDescription" />
      </StyledText>
      <ResetPasswordForm onSubmit={requestResetPassword} />
    </>
  );
};
