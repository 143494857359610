import { UserRole } from '@higo/api';
import { useMemo } from 'react';
import { useAPIOAuth2AccessTokenQuery } from 'services/apiOAuth2AccessTokenService';

export const useUserRole = () => {
  const { data } = useAPIOAuth2AccessTokenQuery();
  const role = data?.accessTokenData?.role;

  return useMemo(
    () =>
      role
        ? {
            role: role as UserRole,
            isDoctor: [UserRole.Doctor, UserRole.SuperDoctor].includes(role),
            isSuperDoctor: UserRole.SuperDoctor === role,
            isMedicalOperator: UserRole.MedicalOperator === role,
            isDemo: UserRole.DemoAccount === role,
          }
        : {
            role: undefined,
            isDoctor: false,
            isSuperDoctor: false,
            isMedicalOperator: false,
            isDemo: false,
          },
    [role],
  );
};
