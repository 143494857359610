import { Gender, Maturity } from '@higo/api/lib/models';
import manHeart from '@higo/doctor/src/components/ShareExaminationModal/asculationsImagesForPDF/heart/man.png';
import womanHeart from '@higo/doctor/src/components/ShareExaminationModal/asculationsImagesForPDF/heart/woman.png';
import boyHeart from '@higo/doctor/src/components/ShareExaminationModal/asculationsImagesForPDF/heart/boy.png';
import girlHeart from '@higo/doctor/src/components/ShareExaminationModal/asculationsImagesForPDF/heart/girl.png';
import babyHeart from '@higo/doctor/src/components/ShareExaminationModal/asculationsImagesForPDF/heart/baby.png';
import manLungsBack from '@higo/doctor/src/components/ShareExaminationModal/asculationsImagesForPDF/lungsBack/man_back.png';
import womanLungsBack from '@higo/doctor/src/components/ShareExaminationModal/asculationsImagesForPDF/lungsBack/woman_back.png';
import boyLungsBack from '@higo/doctor/src/components/ShareExaminationModal/asculationsImagesForPDF/lungsBack/boy_back.png';
import girlLungsBack from '@higo/doctor/src/components/ShareExaminationModal/asculationsImagesForPDF/lungsBack/girl_back.png';
import babyLungsBack from '@higo/doctor/src/components/ShareExaminationModal/asculationsImagesForPDF/lungsBack/baby_back.png';
import manLungsFront from '@higo/doctor/src/components/ShareExaminationModal/asculationsImagesForPDF/lungsFront/man_front.png';
import womanLungsFront from '@higo/doctor/src/components/ShareExaminationModal/asculationsImagesForPDF/lungsFront/woman_front.png';
import boyLungsFront from '@higo/doctor/src/components/ShareExaminationModal/asculationsImagesForPDF/lungsFront/boy_front.png';
import girlLungsFront from '@higo/doctor/src/components/ShareExaminationModal/asculationsImagesForPDF/lungsFront/girl_front.png';
import babyLungsFront from '@higo/doctor/src/components/ShareExaminationModal/asculationsImagesForPDF/lungsFront/baby_front.png';

export const heartAsculationImages = {
  [Gender.Male]: {
    [Maturity.Adult]: manHeart,
    [Maturity.Teen]: boyHeart,
    [Maturity.Baby]: babyHeart,
  },
  [Gender.Female]: {
    [Maturity.Adult]: womanHeart,
    [Maturity.Teen]: girlHeart,
    [Maturity.Baby]: babyHeart,
  },

  [Gender.Other]: {
    [Maturity.Adult]: manHeart,
    [Maturity.Teen]: boyHeart,
    [Maturity.Baby]: babyHeart,
  },
  [Gender.Unknown]: {
    [Maturity.Adult]: manHeart,
    [Maturity.Teen]: boyHeart,
    [Maturity.Baby]: babyHeart,
  },
};

export const lungsBackAsculationImages = {
  [Gender.Male]: {
    [Maturity.Adult]: manLungsBack,
    [Maturity.Teen]: boyLungsBack,
    [Maturity.Baby]: babyLungsBack,
  },
  [Gender.Female]: {
    [Maturity.Adult]: womanLungsBack,
    [Maturity.Teen]: girlLungsBack,
    [Maturity.Baby]: babyLungsBack,
  },

  [Gender.Other]: {
    [Maturity.Adult]: manLungsBack,
    [Maturity.Teen]: boyLungsBack,
    [Maturity.Baby]: babyLungsBack,
  },
  [Gender.Unknown]: {
    [Maturity.Adult]: manLungsBack,
    [Maturity.Teen]: boyLungsBack,
    [Maturity.Baby]: babyLungsBack,
  },
};

export const lungsFrontAsculationImages = {
  [Gender.Male]: {
    [Maturity.Adult]: manLungsFront,
    [Maturity.Teen]: boyLungsFront,
    [Maturity.Baby]: babyLungsFront,
  },
  [Gender.Female]: {
    [Maturity.Adult]: womanLungsFront,
    [Maturity.Teen]: girlLungsFront,
    [Maturity.Baby]: babyLungsFront,
  },

  [Gender.Other]: {
    [Maturity.Adult]: manLungsFront,
    [Maturity.Teen]: boyLungsFront,
    [Maturity.Baby]: babyLungsFront,
  },
  [Gender.Unknown]: {
    [Maturity.Adult]: manLungsFront,
    [Maturity.Teen]: boyLungsFront,
    [Maturity.Baby]: babyLungsFront,
  },
};
