import { flatten, qsArrayParamsSerializer } from '@higo/api/src/utils/axios';
import { SecuredFileResourceDTO } from '@higo/api/src/models/fileResourceDTO';
import { AxiosInstance } from 'axios';
import { FileAccessLinkExtendedDTO } from '@higo/api/src/models/fileAccessLinkExtendedDTO';
import { ListWrapperDTO, unwrap } from '@higo/api/src/ext/listWrapper';

export const fetchFile = (axios: AxiosInstance) => (fileId?: number) =>
  axios.get<SecuredFileResourceDTO>(`api/files/${fileId}/link`).then(flatten);

export const fetchFiles =
  (axios: AxiosInstance) => (fileIds: number[], rawAudio?: boolean) =>
    axios
      .get<ListWrapperDTO<FileAccessLinkExtendedDTO>>(`api/v2/files/links`, {
        params: { fileIds: fileIds, rawAudio: [rawAudio] },
        paramsSerializer: qsArrayParamsSerializer,
      })
      .then(flatten)
      .then(unwrap);
