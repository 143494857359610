import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { BaseButton, IconButton } from '@higo/ui/src/components/buttons';
import ChevronIcon from '@higo/ui/src/components/icons/ChevronIcon';
import clsx from 'clsx';

export interface PaginationRangeProps {
  page: number;
  totalPages: number;
  siblingCount?: number;
  onChange: (v: number) => void;
  id?: string;
}

const PageButtons = styled.div`
  display: flex;
  gap: 2px;
  margin: 0 2rem 0 0.5rem;
`;

const PageButton = styled(BaseButton)`
  &&& {
    display: flex;
    justify-content: center;
    width: 32px;
    border: none;
    font-weight: 400;
    padding: 0;
    color: ${({ theme }) => theme.palette.secondary['500']};
    &[disabled] {
      pointer-events: none;
    }
    &:hover {
      padding: inherit;
      background: ${({ theme }) => theme.palette.neutral['400']};
    }
    &.active {
      font-weight: 700;
      color: ${({ theme }) => theme.palette.secondary['900']};
    }
  }
`;

const DOTS = '...';

const range = (start: number, end: number) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const PaginationRange = ({
  page,
  totalPages,
  siblingCount = 1,
  onChange,
  id,
}: PaginationRangeProps) => {
  const canNextPage = useMemo(
    () => !!(totalPages && page < totalPages),
    [page, totalPages],
  );

  const canPreviousPage = useMemo(() => !!(page > 1), [page]);

  const previousPage = useCallback(() => {
    if (canPreviousPage) {
      onChange(page - 1);
    }
  }, [page, canPreviousPage, onChange]);

  const nextPage = useCallback(() => {
    if (canNextPage) {
      onChange(page + 1);
    }
  }, [page, canNextPage, onChange]);

  const paginationRange = useMemo(() => {
    if (!totalPages) {
      return [DOTS];
    }

    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPages) {
      return range(1, totalPages);
    }

    const leftSiblingIndex = Math.max(page - siblingCount, 1);
    const rightSiblingIndex = Math.min(page + siblingCount, totalPages);

    const shouldShowLeftDots = leftSiblingIndex > 1;
    const shouldShowRightDots = rightSiblingIndex < totalPages;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 1 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 1 + 2 * siblingCount;
      const rightRange = range(totalPages - rightItemCount + 1, totalPages);
      return [1, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [1, DOTS, ...middleRange, DOTS];
    }
  }, [totalPages, siblingCount, page]);

  return (
    <PageButtons>
      <IconButton
        color="secondary"
        size="small"
        icon={<ChevronIcon size={10} direction="left" />}
        disabled={!canPreviousPage}
        onClick={previousPage}
        id="pagination-left-button"
      />

      {paginationRange?.map((pageNum, index) => (
        <PageButton
          key={`${pageNum}-${index}`}
          color="secondary"
          size="small"
          disabled={pageNum === DOTS}
          onClick={() => typeof pageNum === 'number' && onChange(pageNum)}
          className={clsx({ active: pageNum === page })}
          id={`page-nr-${pageNum}-button`}
        >
          {pageNum}
        </PageButton>
      ))}

      <IconButton
        color="secondary"
        size="small"
        icon={<ChevronIcon size={10} direction="right" />}
        disabled={!canNextPage}
        onClick={nextPage}
        id="pagination-right-button"
      />
    </PageButtons>
  );
};
