import {
  allPass,
  defaultTo,
  filter,
  lensPath,
  over,
  path,
  pipe,
  prop,
} from 'rambda';
import { createSurveyAgeConditionPredicate } from '@higo/api/src/utils/purgeQuestionWithUserAnswerDTO/surveyAgeConditionPredicate';
import { createSurveyGenderTagPredicate } from '@higo/api/src/utils/purgeQuestionWithUserAnswerDTO/surveyGenderTagPredicate';
import {
  QuestionAnswerDTO,
  QuestionWithUserAnswerDTO,
  SurveyAgeConditionsDTO,
  SurveyAnswerTag,
  SurveyQuestionTag,
} from '@higo/api/src/models';
import { Gender } from '@higo/api/src/models/gender';

/**
 * utility function used to remove questions and closed answers that are not applicable to the current patient.
 * Required for proper handling various new surveys functionalities, such as:
 * - displaying not selected ailments
 * - displaying questions using special tags
 * which forced us to do so
 */

// quick and dirty "type guard" ensuring that proper field names are provided (as we're using lenses)
type PurgeableQuestionWithUserAnswer = QuestionWithUserAnswerDTO & {
  surveyQuestionDTO: {
    ageConditions: SurveyAgeConditionsDTO;
    questionAnswers: {
      ageConditions: SurveyAgeConditionsDTO;
      answerTags: SurveyAnswerTag[];
    }[];
  };
};

export const purgeQuestionWithUserAnswerDTO =
  (gender: Gender, patientAge: number) =>
  (
    questionWithUserAnswer: PurgeableQuestionWithUserAnswer[],
  ): QuestionWithUserAnswerDTO[] => {
    const genderTagPredicate = createSurveyGenderTagPredicate(gender);
    const ageConditionPredicate = createSurveyAgeConditionPredicate(patientAge);

    // filter non-applicable questions (these shouldn't even have answers)
    const surveyQuestionApplicabilityPredicate =
      allPass<QuestionWithUserAnswerDTO>([
        pipe(
          path<SurveyQuestionTag[]>('surveyQuestionDTO.surveyQuestionTags'),
          defaultTo([] as SurveyQuestionTag[]),
          genderTagPredicate,
        ),
        pipe(
          path<SurveyAgeConditionsDTO>('surveyQuestionDTO.ageConditions'),
          ageConditionPredicate,
        ),
      ]);

    const filteredQuestionWithUserAnswer = questionWithUserAnswer.filter(
      surveyQuestionApplicabilityPredicate,
    );

    // remove non-applicable answer(selectable answers)
    const questionAnswerApplicabilityPredicate = allPass<QuestionAnswerDTO>([
      pipe(prop('answerTags'), genderTagPredicate),
      pipe(prop('ageConditions'), ageConditionPredicate),
    ]);

    const purgeNonApplicableAnswersFromSurveyQuestion = over(
      lensPath('surveyQuestionDTO.questionAnswers'),
      filter(questionAnswerApplicabilityPredicate),
    );

    return filteredQuestionWithUserAnswer.map(
      purgeNonApplicableAnswersFromSurveyQuestion,
    );
  };
