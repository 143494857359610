import { useQueryClient } from 'react-query';
import { useOnEventEmitterMessage } from 'contexts/EventEmitterProvider';
import { AppEvent } from 'types/appEvent';
import { useCallback } from 'react';
import { messagesQueryKeys } from 'config/doctorQueryKey';

export const useRefreshExaminationCommentsQuery = (examinationId: number) => {
  const queryClient = useQueryClient();

  useOnEventEmitterMessage(
    AppEvent.FcmNewComment,
    useCallback(
      ({ examId }) => {
        if (examinationId === examId) {
          queryClient.invalidateQueries(
            messagesQueryKeys.examinationMessages(examinationId),
          );
        }
      },
      [queryClient, examinationId],
    ),
  );
};
