import { IconBox, IconProps } from '../IconBox';

const RoundHeartIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Zm-.912-6.484.912.77.912-.77.802-.685c2.783-2.373 4.572-3.898 4.572-6.148 0-1.54-1.509-3.254-3.458-3.254-1.4 0-2.137.732-2.828 1.501-.691-.769-1.429-1.501-2.829-1.501-1.948 0-3.457 1.714-3.457 3.254 0 2.25 1.79 3.775 4.573 6.148.259.22.526.448.801.685Z"
    />
  </IconBox>
);

export default RoundHeartIcon;
