import {
  ExaminationDiagnosisMode,
  ExaminationPageModel,
  ExaminationPageSectionId,
} from '../models';
import { extractFinishedPhysicalExaminations } from '../utils';
import {
  ExaminationDetailsWithPatient,
  ExaminationDTOStatus,
  PhysicalExaminationType,
} from '@higo/api';
import { isFilled } from '@higo/common/src/utils/isFilled';
import { PartialRecord } from '@higo/common/src/types/partialRecord';
import { fromNullable } from 'fp-ts/es6/Option';
import {
  useUserAnsweredAnamnesisSurvey,
  useUserAnsweredGeneralSurvey,
} from '@higo/common/src/features/FilledSurvey/hooks/useUserAnsweredSurveyQuery';
import { getAgeInYears } from '@higo/common/src/utils';
import { extractPatientOverview } from '../utils/extractPatientOverview';
import { isNilEmpty } from '@higo/common/src/utils/isNilEmpty';
import { useExaminationDetailsQuery } from '@higo/common/src/features/ExaminationPage/hooks/queries/useExaminationDetailsQuery';
import { patientConfig } from '@higo/common/src/config/patientConfig';
import { Maturity } from '@higo/common/src/types';
import { useMemo } from 'react';

const physicalExaminationSectionIdMap: PartialRecord<
  PhysicalExaminationType,
  ExaminationPageSectionId
> = {
  [PhysicalExaminationType.Skin]: ExaminationPageSectionId.Skin,
  [PhysicalExaminationType.HeartAuscultation]: ExaminationPageSectionId.Heart,
  [PhysicalExaminationType.LungsAuscultation]: ExaminationPageSectionId.Lungs,
  [PhysicalExaminationType.Cough]: ExaminationPageSectionId.Cough,

  // todo: clarify which are used
  [PhysicalExaminationType.Ear]: ExaminationPageSectionId.Ears,
  [PhysicalExaminationType.RightEar]: ExaminationPageSectionId.Ears,
  [PhysicalExaminationType.LeftEar]: ExaminationPageSectionId.Ears,

  [PhysicalExaminationType.Throat]: ExaminationPageSectionId.Throat,
  [PhysicalExaminationType.AbdominalAuscultation]:
    ExaminationPageSectionId.Abdominal,
};

const getAvailableExaminationPageSections = (
  data: ExaminationDetailsWithPatient,
) => {
  const physicalExaminationSections: ExaminationPageSectionId[] =
    extractFinishedPhysicalExaminations(data.physicalExaminationDetails)
      .map((x) => physicalExaminationSectionIdMap[x])
      .filter(isFilled) as ExaminationPageSectionId[]; // todo: FIX CAST

  return new Set([
    ExaminationPageSectionId.ExaminationContext,
    ...(!isNaN(data.examination.anamnesisPatientSurveyId)
      ? [ExaminationPageSectionId.AnamnesisSurvey]
      : []),
    ...physicalExaminationSections,
  ]);
};

const evaluateDiagnosisMode = (
  status: ExaminationDTOStatus,
  profileUserId?: number,
  diagnosedById?: number,
): ExaminationDiagnosisMode =>
  isNilEmpty(profileUserId)
    ? ExaminationDiagnosisMode.NotAvailable
    : [ExaminationDTOStatus.DRA, ExaminationDTOStatus.TAK].includes(status) &&
      diagnosedById === profileUserId
    ? ExaminationDiagnosisMode.Editable
    : ExaminationDiagnosisMode.ReadOnly;

export const useExaminationPageModel = (
  id: number,
  profileUserId?: number,
): ExaminationPageModel | undefined => {
  const { data: examinationDetails, isLoading: isDetailsLoading } =
    useExaminationDetailsQuery(id);

  const patientAgeInYears = examinationDetails?.patient.dateOfBirth
    ? getAgeInYears(examinationDetails?.patient.dateOfBirth)
    : NaN;

  const { data: answeredGeneralSurvey, isLoading: isGeneralSurveyLoading } =
    useUserAnsweredGeneralSurvey(
      examinationDetails?.examination.generalPatientSurveyId ?? NaN,
      patientAgeInYears,
      examinationDetails?.patient.gender,
    );

  const { data: answeredAnamnesisSurvey, isLoading: isAnamnesisSurveyLoading } =
    useUserAnsweredAnamnesisSurvey(
      examinationDetails?.examination?.anamnesisPatientSurveyId ?? NaN,
      patientAgeInYears,
      examinationDetails?.patient.gender,
    );

  return useMemo(() => {
    if (
      isDetailsLoading ||
      isGeneralSurveyLoading ||
      isAnamnesisSurveyLoading
    ) {
      return undefined;
    }

    const diagnosisMode = evaluateDiagnosisMode(
      examinationDetails!.examination.status,
      profileUserId,
      examinationDetails?.examination.doctorId,
    );

    const completedPhysicalExaminations = new Set(
      extractFinishedPhysicalExaminations(
        examinationDetails!.physicalExaminationDetails ?? [],
      ),
    );

    const patient = {
      gender: examinationDetails!.patient.gender,
      maturity:
        patientAgeInYears <= patientConfig.maturityAgeLimits.baby
          ? Maturity.Baby
          : patientAgeInYears <= patientConfig.maturityAgeLimits.teen
          ? Maturity.Teen
          : Maturity.Adult,
    };

    return {
      mode: examinationDetails!.patient.type,
      details: examinationDetails!,
      availableExaminationPageSections: getAvailableExaminationPageSections(
        examinationDetails!,
      ),
      answeredGeneralSurvey,
      answeredAnamnesisSurvey,
      patient,
      patientOverview: extractPatientOverview(
        examinationDetails!.patient,
        fromNullable(answeredGeneralSurvey),
      ),
      isDiagnosisAvailable: !isNilEmpty(
        examinationDetails!.examination.diagnosisId,
      ),
      isPhysicalExaminationCompletedFn: (x) =>
        completedPhysicalExaminations.has(x),
      diagnosis: {
        mode: diagnosisMode,
        isEditable: ExaminationDiagnosisMode.Editable === diagnosisMode,
        isReadOnly: ExaminationDiagnosisMode.ReadOnly === diagnosisMode,
        isNotAvailable: ExaminationDiagnosisMode.NotAvailable === diagnosisMode,
      },
    };
  }, [
    isDetailsLoading,
    isGeneralSurveyLoading,
    isAnamnesisSurveyLoading,
    examinationDetails,
    profileUserId,
    patientAgeInYears,
    answeredGeneralSurvey,
    answeredAnamnesisSurvey,
  ]);
};
