import { IconBox, IconProps } from '../IconBox';

const NotificationIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.286 9.714a7.714 7.714 0 1 1 15.428 0v4.61l.91.91a1.286 1.286 0 0 1-.91 2.194H4.286a1.286 1.286 0 0 1-.91-2.194l.91-.91v-4.61Zm3.857 9a3.857 3.857 0 1 0 7.714 0H8.143Z"
    />
  </IconBox>
);

export default NotificationIcon;
