import { WarningIcon } from '@higo/ui/src/components/icons';
import { StyleProps } from '@summer/jst-react';
import styled, { css } from 'styled-components';
import { createElement, ElementType, FC } from 'react';

export type DialogIconType = 'error';

export type DialogIconProps = {
  type: DialogIconType;
} & StyleProps;

const ErrorIcon = styled(WarningIcon)(
  ({ theme }) => css`
    color: ${theme.palette.error};
  `,
);

const iconTypeMap: Record<DialogIconType, ElementType> = {
  error: ErrorIcon,
};

export const DialogIcon: FC<DialogIconProps> = ({ type, ...props }) =>
  createElement(iconTypeMap[type], props);
