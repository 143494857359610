import { media } from '@higo/ui';
import styled, { css } from 'styled-components';

export const ExaminationPageInfoBarOuterContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.white};
    border-radius: 1rem 1rem 0 0;

    ${media.desktop.min} {
      border-radius: 2rem 2rem 0 0;
    }
  `,
);
