import { flatten, qsArrayParamsSerializer } from '@higo/api/src/utils/axios';
import {
  ExaminationDetailsDTO,
  mapExaminationDetailsData,
} from '@higo/api/src/models/examinationDetailsDTO';
import {
  AssignedExaminationDTO,
  ExaminationBase,
  ExaminationHistoryDTO,
  ExaminationsAssignmentResultDTO,
  ExaminationsToAssignDTO,
  mapToExaminationBase,
  PaginatedListReqDTO,
  PaginatedListResultDTO,
  UnassignedExaminationDTO,
} from '@higo/api/src/models';
import { ListWrapperDTO, unwrap } from '@higo/api/src/ext/listWrapper';
// import { mapExaminationCommentsData } from 'lib/models/examinationCommentDTO';
import { ExaminationShareLinkDTO } from '@higo/api/src/models/examinationShareLinkDTO';
import {
  ExaminationDetailsWithPatientDTO,
  mapToExaminationDetailsWithPatient,
} from '@higo/api/src/models/examinationDetailsWithPatientDTO';
import { NoteDTO } from '@higo/api/src/models/noteDTO';
import { ExaminationDoctorDiagnosisDTO } from '@higo/api/src/models/examinationDoctorDiagnosisDTO';
import { AxiosInstance } from 'axios';
import { map } from 'rambda';

export const fetchUnassignedExaminations =
  (axios: AxiosInstance) => (data?: PaginatedListReqDTO) =>
    axios
      .get<PaginatedListResultDTO<UnassignedExaminationDTO>>(
        `api/v2/examinations/unassigned`,
        {
          params: data,
          paramsSerializer: qsArrayParamsSerializer,
        },
      )
      .then(flatten);

export const fetchAssignedExaminations =
  (axios: AxiosInstance) => (data?: PaginatedListReqDTO) =>
    axios
      .get<PaginatedListResultDTO<AssignedExaminationDTO>>(
        `api/v2/examinations/assigned`,
        {
          params: data,
          paramsSerializer: qsArrayParamsSerializer,
        },
      )
      .then(flatten);

export const fetchHistoricalExaminations =
  (axios: AxiosInstance) => (data?: PaginatedListReqDTO) =>
    axios
      .get<PaginatedListResultDTO<ExaminationHistoryDTO>>(
        `api/v2/examinations/history?`,
        {
          params: data,
          paramsSerializer: qsArrayParamsSerializer,
        },
      )
      .then(flatten);

export const fetchBaseExamination = (axios: AxiosInstance) => (ids: number[]) =>
  axios
    .get<ListWrapperDTO<ExaminationBase>>(`/api/v2/examinations/base`, {
      params: { examinationIds: ids },
      paramsSerializer: qsArrayParamsSerializer,
    })
    .then(flatten)
    .then(unwrap)
    .then(map(mapToExaminationBase));

export const fetchExaminationDetails = (axios: AxiosInstance) => (id: number) =>
  axios
    .get<ExaminationDetailsWithPatientDTO>(`api/v2/examinations/${id}/details`)
    .then(flatten)
    .then(mapToExaminationDetailsWithPatient);

export const deleteDoctorExamination = (axios: AxiosInstance) => (id: number) =>
  axios.delete(`api/v2/examinations/${id}/doctor`);

export const fetchExaminationsSummary =
  (axios: AxiosInstance) => (id: number) =>
    axios
      .get<ExaminationDetailsDTO>(`api/v2/examinations/${id}/summary`)
      .then(flatten)
      .then(mapExaminationDetailsData);

export const assignExamination = (axios: AxiosInstance) => (id: number) =>
  assignExaminations(axios)({ examinationIds: [id] });

export const assignExaminations =
  (axios: AxiosInstance) => (data: ExaminationsToAssignDTO) =>
    axios
      .post<ExaminationsAssignmentResultDTO>(
        `api/v2/examinations/assignment`,
        data,
      )
      .then(flatten);

export const fetchExaminationNotes =
  (axios: AxiosInstance) => (examinationId: number) =>
    axios
      .get<ListWrapperDTO<NoteDTO>>(`/api/notes/examinations/${examinationId}`)
      .then(flatten)
      .then(unwrap);

export const addExaminationNote =
  (axios: AxiosInstance) => (examinationId: number, noteText: string) =>
    axios.post(`/api/notes/examinations/${examinationId}`, {
      noteText,
    });

// todo: delete the tags after the EP update
export const createShareExaminationLink =
  (axios: AxiosInstance) => (examinationId: number) =>
    axios
      .post<ExaminationShareLinkDTO>(
        `/api/examinations/${examinationId}/share`,
        { tags: [], doctorPortalV2: true },
      )
      .then(flatten);

export const saveFinalExaminationDiagnosis =
  (axios: AxiosInstance) =>
  (examinationId: number, dto: ExaminationDoctorDiagnosisDTO) =>
    axios
      .post<ExaminationDoctorDiagnosisDTO>(
        `/api/v2/examinations/${examinationId}/diagnosis`,
        dto,
      )
      .then(flatten);

export const saveDraftExaminationDiagnosis =
  (axios: AxiosInstance) =>
  (examinationId: number, dto: ExaminationDoctorDiagnosisDTO) =>
    axios
      .patch<ExaminationDoctorDiagnosisDTO>(
        `/api/v2/examinations/${examinationId}/diagnosis`,
        dto,
      )
      .then(flatten);

export const fetchExaminationDiagnosis =
  (axios: AxiosInstance) => (examinationId: number) =>
    axios
      .get<ExaminationDoctorDiagnosisDTO>(
        `/api/v2/examinations/${examinationId}/diagnosis`,
      )
      .then(flatten);

export const requestGenerateLeftEarExaminationVideo =
  (axios: AxiosInstance) => (examinationId: number) =>
    axios
      .post<void>(`/api/examinations/${examinationId}/leftEar/video`)
      .then(flatten);

export const requestGenerateRightEarExaminationVideo =
  (axios: AxiosInstance) => (examinationId: number) =>
    axios
      .post<void>(`/api/examinations/${examinationId}/rightEar/video`)
      .then(flatten);

export const requestGenerateSkinExaminationVideo =
  (axios: AxiosInstance) => (examinationId: number) =>
    axios
      .post<void>(`/api/examinations/${examinationId}/skin/video`)
      .then(flatten);

export const requestGenerateThroatExaminationVideo =
  (axios: AxiosInstance) => (examinationId: number) =>
    axios
      .post<void>(`/api/examinations/${examinationId}/throat/video`)
      .then(flatten);
