import { css, DefaultTheme } from 'styled-components';
import { createTransitions } from './createTransitions';
import { colors } from './colors';
import { media } from './media';
import { layers } from './layers';

const white = '#FFFFFF';
const fontFamily = 'Aileron';

const palette = {
  text: colors.grey['900'],
  primary: colors.blue,
  secondary: colors.grey,
  neutral: colors.neutral,
  pro: colors.pro,
  white,
  success: '#00B818',
  warning: '#FFBF00',
  error: '#E83635',
  background: white,
};

const fontSize = {
  xxl: '1.5625rem',
  xl: '1.1875rem',
  l: '1.0625rem',
  m: '0.9375rem',
  s: '0.8125rem',
  xs: '0.6875rem',
};

export const lightTheme: DefaultTheme = {
  media,
  palette,
  layers,
  fontFamily: {
    primary: fontFamily,
  },
  fontSize,
  spacing: '0.5rem',
  transitions: createTransitions({}),
  typography: {
    fontFamily: 'Aileron',
    h1: css`
      font-family: ${fontFamily};
      font-weight: 700;
      font-size: ${fontSize.xxl};
      line-height: 1.875rem;
    `,
    h4: css`
      font-family: ${fontFamily};
      font-weight: 700;
      font-size: ${fontSize.m};
      line-height: 1.25rem;
    `,
    subtitle1: css`
      font-family: ${fontFamily};
      font-weight: 700;
      font-size: ${fontSize.s};
      line-height: 1rem;
    `,
    subtitle2: css`
      font-family: ${fontFamily};
      font-weight: 600;
      font-size: ${fontSize.s};
      line-height: 1rem;
    `,
    body: css`
      font-family: ${fontFamily};
      font-size: 0.8125rem;
      font-weight: 400;
      line-height: 1.125rem;
    `,
    body2: css`
      font-family: ${fontFamily};
      font-size: 0.8125rem;
      font-weight: 400;
      line-height: 1rem;
    `,
    lineClamp: (lines: number) => css`
      display: -webkit-box;
      -webkit-line-clamp: ${lines};
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      word-break: break-all;
    `,
  },
  formLabel: {
    default: {
      primary: palette.secondary['900'],
    },
    disabled: {
      primary: palette.secondary['400'],
    },
  },
  formHelperText: {
    default: {
      primary: palette.secondary['900'],
    },
    error: {
      primary: palette.error,
    },
  },
  input: {
    default: {
      primary: palette.secondary['900'], //text
      background: palette.white,
      placeholder: palette.secondary['500'], // placeholder color
      border: palette.secondary['200'],
    },
    focused: {
      border: palette.primary['500'],
      placeholder: palette.secondary['900'],
    },
    hover: {
      border: palette.secondary['500'],
    },
    disabled: {
      primary: palette.secondary['400'],
      background: palette.neutral['300'],
      placeholder: palette.secondary['400'], // placeholder color
      border: palette.secondary['200'],
    },
    error: {
      border: palette.error,
    },
  },
};
