import { useQuery } from 'react-query';
import { fetchExaminationDetails } from '@higo/api/lib/services/examinationService';
import { useExaminationApiContext } from '@higo/common/src/features/ExaminationPage/providers';
import { ExaminationQueryKey } from '@higo/common/src/features/ExaminationPage/ExaminationQueryKey';

export const useExaminationDetailsQuery = (id: number) => {
  const axios = useExaminationApiContext();

  return useQuery(
    [ExaminationQueryKey.ExaminationDetails, id],
    () => fetchExaminationDetails(axios)(id),
    {
      enabled: !isNaN(id),
    },
  );
};
