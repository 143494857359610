import { FC, memo } from 'react';
import { QuestionType } from '@higo/api';
import { QuestionAnswerOption } from '@higo/common/src/features/FilledSurvey/model';
import { getClosedQuestionSingleAnswer } from '@higo/common/src/features/FilledSurvey/utils';

export type SingleClosedQuestionAnswerProps = {
  questionType: QuestionType.MULTIPLE_CLOSED | QuestionType.SINGLE_CLOSED;
  answer: QuestionAnswerOption;
  userFriendlyLabel?: string;
};

export const SingleClosedQuestionAnswerRenderer: FC<SingleClosedQuestionAnswerProps> =
  memo(({ answer }) => <>{getClosedQuestionSingleAnswer(answer)}</>);
