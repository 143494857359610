import App from 'App';
import { useEffect, useRef } from 'react';

import { DoctorQueryKey } from 'config/doctorQueryKey';

import { QueryClient, useQueryClient } from 'react-query';
import { refreshAuthToken } from '@higo/api';
import { setAPIOAuth2AccessToken } from 'services/apiOAuth2AccessTokenService';
import { useLogout } from 'hooks/useLogout';
import { IntlShape, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { QueryStorage, useQueryStorage } from '@higo/common/lib/QueryStorage';
import { AsyncDictionaryProvider } from '@higo/common/lib/features/Dictionary';
import { useAppLocale } from '@higo/common/lib/features/Locale';
import { LocaleDefinition } from '@higo/common/lib/features/Locale/localeDefinition';
import { createAuthorizedAxios, createBaseAxios } from 'utils/axios';
import {
  createAcceptLanguageInterceptor,
  createRefreshTokenInterceptor,
  OAuth2AccessToken,
} from '@higo/common/lib/ext/axios';
import { createAccessTokenInterceptor } from '@higo/common/lib/ext/axios/interceptors';
import { mapToOAuth2AccessToken } from '@higo/common/lib/utils/axios';
import {
  DoctorPortalApiContext,
  DoctorPortalApiContextProvider,
} from 'contexts/ApiContext';
import { FirebaseEventEmitter } from 'features/FirebaseEvents';

const apiContextSetup = (
  intl: IntlShape,
  queryClient: QueryClient,
  queryStorage: QueryStorage,
  logout: () => void,
  getLanguage: () => string,
): DoctorPortalApiContext => {
  const getAccessToken = () =>
    queryClient.getQueryData<OAuth2AccessToken>(
      DoctorQueryKey.APIOAuth2AccessToken,
    );

  const accessTokenInterceptor = createAccessTokenInterceptor({
    getAccessToken,
  });

  const acceptLanguageInterceptor =
    createAcceptLanguageInterceptor(getLanguage);

  const baseAxios = createBaseAxios();
  const authorizedAxios = createAuthorizedAxios();

  const apiContext = {
    baseAxios,
    authorizedAxios,
  };

  const refreshTokenInterceptor = createRefreshTokenInterceptor({
    refreshTokenFn: (token) =>
      refreshAuthToken(apiContext.baseAxios)(token).then(
        mapToOAuth2AccessToken,
      ),
    getAccessToken,
    onRefreshSuccess: async (oAuth2AccessToken) => {
      setAPIOAuth2AccessToken(queryClient, queryStorage, oAuth2AccessToken);
    },
    onRefreshError: async () => {
      toast.warning(
        intl.formatMessage({ id: 'authentication.sessionExpired' }),
      );
      logout();
    },
  });

  baseAxios.interceptors.request.use(acceptLanguageInterceptor);

  authorizedAxios.interceptors.request.use(acceptLanguageInterceptor);
  authorizedAxios.interceptors.request.use(accessTokenInterceptor);
  authorizedAxios.interceptors.request.use(refreshTokenInterceptor);

  return apiContext;
};

export const AppWrapper = () => {
  const { current: currentLocaleDefinition } = useAppLocale();
  const localeDefinitionRef = useRef<LocaleDefinition>(currentLocaleDefinition);
  const queryClient = useQueryClient();
  const queryStorage = useQueryStorage();
  const intl = useIntl();
  const logout = useLogout();

  const apiContext = useRef(
    apiContextSetup(
      intl,
      queryClient,
      queryStorage,
      logout,
      () => localeDefinitionRef.current.code,
    ),
  );

  useEffect(() => {
    localeDefinitionRef.current = currentLocaleDefinition;
  }, [currentLocaleDefinition]);

  return (
    <DoctorPortalApiContextProvider value={apiContext.current}>
      <AsyncDictionaryProvider
        axios={apiContext.current.baseAxios}
        languageCode={currentLocaleDefinition.code}
      >
        <FirebaseEventEmitter />
        <App />
      </AsyncDictionaryProvider>
    </DoctorPortalApiContextProvider>
  );
};
