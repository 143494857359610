import { IconBox, IconProps } from '../IconBox';

const ShareIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.688 10.125a2.813 2.813 0 1 0-2.792-2.465L9.265 9.976a2.813 2.813 0 1 0 0 4.049l4.631 2.315a2.813 2.813 0 1 0 .839-1.677l-4.631-2.316a2.824 2.824 0 0 0 0-.694l4.631-2.316a2.803 2.803 0 0 0 1.953.788Z"
    />
  </IconBox>
);

export default ShareIcon;
