import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';
import { ButtonUnstyled } from '@mui/base';
import { StyleProps } from '@summer/jst-react';

export type ColorMode = 'default' | 'inverted';

//todo
// export const examinationTOCButtonClasses = {
//   available: 'available'
// }

const physicalExamPaletteMap: Record<
  ColorMode,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  default: css``,
  inverted: css(
    ({ theme }) => css`
      color: ${theme.palette.secondary['500']};
      background-color: ${theme.palette.white};

      &:hover {
        background-color: ${theme.palette.secondary['500']};
        color: ${theme.palette.white};
      }
    `,
  ),
};

type ExaminationTOCButtonProps = {
  // onClick: () => void;
  $colorMode: ColorMode;
  $isChecked?: boolean;
  $isActive?: boolean;
  $isAvailable?: boolean;
} & StyleProps;

export const ExaminationTOCButton = styled(
  ButtonUnstyled,
)<ExaminationTOCButtonProps>(
  ({
    theme,
    $colorMode,
    $isChecked = false,
    $isActive = false,
    $isAvailable = true,
  }) =>
    css`
      all: unset;
      color: inherit;
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;

      & + & {
        margin-top: 0.5rem;
      }

      ${$isAvailable &&
      css`
        &:hover {
          color: ${theme.palette.white};
          background-color: ${theme.palette.primary['500']};
        }
      `}

      ${$isActive &&
      $isAvailable &&
      css`
        position: relative;
        z-index: 2;
        outline: 2px solid ${theme.palette.secondary['500']};

        &:focus {
          outline: 2px solid ${theme.palette.secondary['500']};
          color: ${theme.palette.primary['500']};
          background-color: ${theme.palette.white};
        }

        &:before {
          content: ' ';
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
          border: 4px solid ${theme.palette.primary['500']};
          position: absolute;
          z-index: -1;
          top: -4px;
          left: -4px;
        }
      `}

      ${$isChecked &&
      $isAvailable &&
      css`
        position: relative;
        z-index: 2;

        &:focus {
          color: ${theme.palette.primary['500']};
          background-color: ${theme.palette.white};
        }

        &:before {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='22' width='22' viewBox='0 0 45 43'%3E%3Cpath fill='%23fff' d='M19.06 7.94a1.5 1.5 0 0 0-2.12 0L12 12.878l-4.94-4.94a1.5 1.5 0 0 0-2.12 2.122l6 6a1.5 1.5 0 0 0 2.12 0l6-6a1.5 1.5 0 0 0 0-2.122Z'/%3E%3C/svg%3E");
          width: 0.7rem;
          height: 0.7rem;
          border-radius: 50%;
          border: 2px solid ${theme.palette.secondary['500']};
          position: absolute;
          z-index: 1;
          right: -4px;
          bottom: -4px;
          background-color: ${theme.palette.success};
        }
      `}

      ${physicalExamPaletteMap[$colorMode ?? 'default']}

      ${!$isAvailable &&
      css`
        opacity: 0.6;
        cursor: not-allowed;
      `}
    `,
);

ExaminationTOCButton.displayName = 'ExaminationTOCButton';
