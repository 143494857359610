import { ro } from 'date-fns/locale';
import { LocaleDefinition } from '../localeDefinition';
import messages from './ro.json';

const definition: LocaleDefinition = {
  code: 'ro-RO',
  messages,
  dateFnsLocale: ro,
};

export default definition;
