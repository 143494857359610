import styled from 'styled-components';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { HeartRateMeasurementEntry } from '../VitalSignsSection/HeartRateMeasurementEntry';
import { TemperatureMeasurementEntry } from '../VitalSignsSection/TemperatureMeasurementEntry';
import { ExaminationPageSection } from '../ExaminationPageSection';
import { IsPhysicalExaminationCompletedFn } from '@higo/common/src/features/ExaminationPage/models';
import { PhysicalExaminationType } from '@higo/api';

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledExaminationPageSection = styled(ExaminationPageSection)`
  display: grid;
  grid-template-rows: min-content minmax(0, 1fr);
`;

interface VitalSignsSectionProps {
  examinationId: number;
  isPhysicalExaminationCompletedFn: IsPhysicalExaminationCompletedFn;
}

export const VitalSignsSection: FC<VitalSignsSectionProps> = ({
  examinationId,
  isPhysicalExaminationCompletedFn,
}) => (
  <StyledExaminationPageSection
    title={<FormattedMessage id="examinationPage.section.lifeSigns.title" />}
    divider={null}
  >
    <Content>
      <TemperatureMeasurementEntry
        isAvailable={isPhysicalExaminationCompletedFn(
          PhysicalExaminationType.Temperature,
        )}
        examinationId={examinationId}
      />
      <HeartRateMeasurementEntry
        isAvailable={
          isPhysicalExaminationCompletedFn(PhysicalExaminationType.HeartRate) ||
          isPhysicalExaminationCompletedFn(PhysicalExaminationType.HeartRateWav)
        }
        examinationId={examinationId}
      />
    </Content>
  </StyledExaminationPageSection>
);
