import styled, { css } from 'styled-components';
import { sum } from 'rambda';
import { TD } from '@higo/ui/src/components/Table/components/TD';
import { TH } from '@higo/ui/src/components/Table/components/TH';

interface TableWrapperProps {
  $stickyColumns?: number[] | undefined;
}

export const TableWrapper = styled.div<TableWrapperProps>`
  position: relative;
  padding: 0.5rem 0 1rem;
  &.has-marker-column {
    ${TD} {
      &:first-child {
        background: transparent !important;
        &:before {
          content: none;
        }
      }
      &:nth-child(2) {
        border-radius: 0.5rem 0 0 0.5rem;
        &:before {
          left: 0.5rem;
        }
      }
    }
  }
  &.has-overflow {
    padding-right: 1.25rem;
  }
  &.has-full-height {
    height: 100%;
  }

  ${({ $stickyColumns }) =>
    $stickyColumns &&
    css`
      ${TD}:nth-last-child(-n + 1) {
        position: sticky !important;
        right: 0;
        top: 0;
      }

      .unassign-table ${TD}:nth-last-child(-n + ${$stickyColumns.length}) {
        position: sticky !important;
        right: 0;
        top: 0;

        &:nth-last-child(2) {
          right: 165px;
        }

        &:nth-last-child(3) {
          right: 225px;
        }

        &:nth-last-child(4) {
          right: 285px;
          padding-left: 20px;
        }
      }
    `}
`;
