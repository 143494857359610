import styled, { css } from 'styled-components';
import { IconButton } from './IconButton';
import {
  AddIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  PlayIcon,
  StopIcon,
} from '../icons';
import {
  RoundIconButton,
  RoundIconButtonProps,
} from '@higo/ui/src/components/buttons/RoundIconButton';

// todo: type it better
export type FabButtonProps = RoundIconButtonProps;

export const FabButton = styled(RoundIconButton).attrs({
  icon: <AddIcon />,
})<FabButtonProps>``;

export const PlayButton = styled(IconButton).attrs({
  icon: <PlayIcon />,
  size: 'small',
})(
  () =>
    css`
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    `,
);

export const StopButton = styled(IconButton).attrs({
  icon: <StopIcon />,
  size: 'small',
})(
  () =>
    css`
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    `,
);

export const ArrowLeftButton = styled(IconButton).attrs({
  icon: <ArrowLeftIcon />,
  size: 'small',
  color: 'secondary',
})(
  () =>
    css`
      height: 2rem;
      width: 2rem;
      border-radius: 8px 0 0 8px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    `,
);

export const ArrowRightButton = styled(IconButton).attrs({
  icon: <ArrowRightIcon />,
  size: 'small',
  color: 'secondary',
})(
  () =>
    css`
      height: 2rem;
      width: 2rem;
      border-radius: 0 8px 8px 0;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    `,
);
