import { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';

// todo; think how to push this to public folder and share with other packages
import '@higo/ui/src/styles/fonts/aileron/aileron-400.css';
import '@higo/ui/src/styles/fonts/aileron/aileron-600.css';
import '@higo/ui/src/styles/fonts/aileron/aileron-700.css';
import 'simplebar/dist/simplebar.min.css';

export const GlobalStyle = createGlobalStyle(
  ({ theme }) => css`
    ${normalize}

    body, html {
      cursor: default;
      min-height: 100vh;
      font-family: 'Aileron', serif !important;
      scroll-behavior: smooth;
    }

    * {
      text-rendering: geometricPrecision;
      cursor: inherit;
      box-sizing: border-box;
      min-width: 0; // fixes grid overgrowth, normalizes behaviour across browsers
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      //margin: 0;
      //padding: 0;
      //border: 0;

      &:focus {
        outline: none;
      }
    }

    a,
    button {
      color: inherit;
      text-decoration: inherit;

      &:not([disabled]) {
        cursor: pointer;
      }
    }

    input,
    textarea,
    ::placeholder {
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }

    input,
    textarea {
      max-width: 100%;
      box-sizing: border-box;
      cursor: text;
    }

    ol,
    ul,
    menu {
      list-style: none;
    }
  `,
);
