import { useMutation, useQueryClient } from 'react-query';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';
import { DoctorQueryKey } from 'config/doctorQueryKey';
import { DoctorMutationKey } from 'config/doctorMutationKey';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { assignExamination } from '@higo/api';
import { useDispatchDoctorTrackedType } from 'hooks/useDispatchDoctorTrackedEvent';
import {
  DoctorTrackedType,
  TrackedAsyncEvent,
} from '@higo/common/lib/features/TrackedEvent';

export const useAssignExaminationMutation = (
  examinationId: number,
  TrackedAsyncEventData?: Pick<TrackedAsyncEvent, 'eventTrigger' | 'patientId'>,
) => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const { authorizedAxios } = useDoctorPortalApiContext();
  const sendTrackedEvent = useDispatchDoctorTrackedType();

  return useMutation(
    [DoctorMutationKey.AssignExaminations, examinationId],
    assignExamination(authorizedAxios),
    {
      onSuccess: async (_, variables) => {
        toast.success(
          intl.formatMessage({
            id: 'examinationsAssignment.assignedSuccessfully',
          }),
        );

        await queryClient.invalidateQueries(
          DoctorQueryKey.UnassignedExaminationsList,
        );
        await queryClient.invalidateQueries(
          DoctorQueryKey.AssignedExaminationsList,
        );

        if (TrackedAsyncEventData) {
          sendTrackedEvent({
            event: DoctorTrackedType.AssignExamination,
            eventResult: 'success',
            examinationId: variables,
            ...TrackedAsyncEventData,
          });
        }
      },

      onError: (_, variables) => {
        toast.error(
          intl.formatMessage({
            id: 'examinationsAssignment.notAssignedSuccessfully',
          }),
        );

        if (TrackedAsyncEventData) {
          sendTrackedEvent({
            event: DoctorTrackedType.AssignExamination,
            eventResult: 'error',
            examinationId: variables,
            ...TrackedAsyncEventData,
          });
        }
      },
    },
  );
};
