import { IconBox, IconProps } from '../IconBox';

const SearchIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 4.5a5 5 0 1 0 0 10 5 5 0 0 0 0-10ZM2 9.5a7.5 7.5 0 1 1 13.613 4.346l6.02 6.02a1.25 1.25 0 0 1-1.767 1.768l-6.02-6.02A7.5 7.5 0 0 1 2 9.5Z"
    />
  </IconBox>
);

export default SearchIcon;
