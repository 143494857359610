import { FC, memo, ReactNode, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { isEmpty } from 'rambda';

const Container = styled('ul')<{ $isCollapsed: boolean }>(
  ({ theme, $isCollapsed }) => css`
    list-style: none;
    display: inline-flex;
    row-gap: 1rem;
    column-gap: 2rem;
    flex-wrap: wrap;
    padding: 0.5rem 0;
    margin: 0;
    overflow: hidden;
    transition: ${theme.transitions.create(['max-height'], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    })};

    max-height: ${$isCollapsed ? '2.5rem' : '20rem'};
  `,
);

export interface CollapsibleListItem {
  id?: string;
  content: ReactNode;
}

export type CollapsibleItemsState = Record<string, boolean>;
export interface CollapsibleListProps {
  isCollapsed?: boolean;
  items: CollapsibleListItem[];
  onChanged?: (state: CollapsibleItemsState) => void;
}

export const CollapsibleList: FC<CollapsibleListProps> = memo(
  ({ isCollapsed = true, items, onChanged }) => {
    const navRef = useRef<HTMLUListElement | null>(null);
    const [entriesState, setEntriesState] = useState({});
    const transitionedRef = useRef(true);

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      const updatedEntries: Record<string, boolean> = {};

      entries.forEach((entry) => {
        const targetId = (entry.target as HTMLElement).dataset.id as string;
        updatedEntries[targetId] = entry.isIntersecting;
      });

      transitionedRef.current &&
        setEntriesState((prev) => ({
          ...prev,
          ...updatedEntries,
        }));
    };

    useEffect(() => {
      if (!isEmpty(entriesState)) {
        onChanged?.(entriesState);
      }
    }, [onChanged, entriesState]);

    useEffect(() => {
      if (!isCollapsed) {
        transitionedRef.current = false;
      }
    }, [isCollapsed]);

    useEffect(() => {
      if (!navRef.current) {
        return;
      }

      const observer = new IntersectionObserver(handleIntersection, {
        root: navRef.current,
        threshold: 1,
      });

      Array.from(navRef.current.children).forEach((item) => {
        observer.observe(item);
      });

      return () => observer?.disconnect();
    }, [items]);

    return (
      <Container
        id="ailment-list"
        ref={navRef}
        $isCollapsed={isCollapsed}
        onTransitionEnd={() => (transitionedRef.current = true)}
      >
        {items.map(({ id, content }) => (
          <li id={`ailment-item-${id}`} key={id}>
            {content}
          </li>
        ))}
      </Container>
    );
  },
);
