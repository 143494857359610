import { IconBox, IconProps } from '../IconBox';

const CloseIcon = (props: IconProps) => (
  <IconBox {...props} id="close-icon-svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.94 16.94a1.5 1.5 0 1 0 2.12 2.12L12 14.122l4.94 4.94a1.5 1.5 0 0 0 2.12-2.122L14.122 12l4.94-4.94a1.5 1.5 0 0 0-2.122-2.12L12 9.878l-4.94-4.94a1.5 1.5 0 0 0-2.12 2.122L9.878 12l-4.94 4.94Z"
    />
  </IconBox>
);

export default CloseIcon;
