import { FC } from 'react';
import styled from 'styled-components';
import { Slider } from '../Slider';

const StyledSlider = styled(Slider)(
  ({ theme }) => `
  color: ${theme.palette.primary[500]}
`,
);

export interface TrackProgressControlProps {
  disabled: boolean;
  progress: number;
  duration: number;
  onSeekStart: () => void; // start seeking
  onSeekChange: (x: number) => void; // range value changed (not synced with player)
  onSeekEnd: (x: number) => void; // confirm seek value
}

export const TrackProgressControl: FC<TrackProgressControlProps> = ({
  disabled,
  progress,
  duration,
  onSeekStart,
  onSeekChange,
  onSeekEnd,
}) => (
  <StyledSlider
    value={progress}
    max={duration}
    onMouseDown={() => onSeekStart()}
    onChange={(e, v) => !Array.isArray(v) && onSeekChange(v)}
    onChangeCommitted={(e, v) => !Array.isArray(v) && onSeekEnd(v)}
    disabled={disabled}
  />
);
