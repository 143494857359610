import {
  calculateExponentialBackoff,
  ExponentialBackoffOptions,
} from '@higo/common/src/utils/exponentialBackoff';
import { useCallback, useRef, useState } from 'react';

export const useExponentialBackoff = (
  options: ExponentialBackoffOptions,
  initialAttempt: number = 1,
) => {
  const attemptRef = useRef(initialAttempt);

  const [delay, setDelay] = useState(
    calculateExponentialBackoff(initialAttempt, options),
  );

  const updateAttempt = useCallback(
    (attempt) => {
      attemptRef.current = attempt;
      const newDelay = calculateExponentialBackoff(attemptRef.current, options);
      setDelay(newDelay);

      return newDelay;
    },
    [options],
  );

  const increaseAttempt = useCallback(
    () => updateAttempt(attemptRef.current + 1),
    [updateAttempt],
  );
  const resetAttempt = useCallback(() => updateAttempt(1), [updateAttempt]);

  return {
    increaseAttempt,
    resetAttempt,
    delay,
  };
};
