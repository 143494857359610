import { ExaminationType } from '@higo/api';
import { FormattedMessage } from 'react-intl';

export type ExamTypeLabelProps = {
  type: ExaminationType;
  short?: boolean;
};
export const ExamTypeLabel = ({ type, short = false }: ExamTypeLabelProps) => (
  <FormattedMessage
    id={`examinationType.${short ? 'short' : 'full'}.${type}`}
  />
);
