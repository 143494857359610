import { useQuery } from 'react-query';
import { useExaminationApiContext } from '@higo/common/src/features/ExaminationPage/providers';
import { ExaminationQueryKey } from '@higo/common/src/features/ExaminationPage/ExaminationQueryKey';
import { fetchExaminationNotes } from '@higo/api/lib/services/examinationService';

export const useMedicalOperatorNotesQuery = (id: number) => {
  const axios = useExaminationApiContext();

  return useQuery(
    [ExaminationQueryKey.ExaminationNotes, id],
    () => fetchExaminationNotes(axios)(id),
    {
      enabled: !isNaN(id),
      refetchOnMount: true,
    },
  );
};
