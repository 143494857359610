import { FC, ReactElement } from 'react';
import styled, { css } from 'styled-components';

export interface HeaderTimerProps {
  Time: ReactElement;
  Label: ReactElement | string;
  id?: string;
  idLabel?: string;
}

const Wrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.palette.text};
    &:after {
      content: '';
      min-width: 4px;
      min-height: 4px;
      background: ${theme.palette.secondary['300']};
      border-radius: 50%;
      margin: 0 0.5rem;
    }
  `,
);

const Text = styled('div')(
  ({ theme }) => css`
    ${theme.typography.body};
    & > span {
      margin-left: 0.25rem;
    }
  `,
);

export const HeaderTimer: FC<HeaderTimerProps> = ({
  Time,
  Label,
  id,
  idLabel,
}) => (
  <Wrapper>
    <Text>
      <strong id={id}>{Time}</strong>
      <span id={idLabel}>{Label}</span>
    </Text>
  </Wrapper>
);
