import { cloneElement, FC, memo, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { IconProps } from './IconBox';

const Container = styled('div')(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1.5rem 1fr;
    grid-column-gap: 0.5rem;
    align-items: center;
    color: ${theme.palette.secondary['500']};

    & > span {
      color: ${theme.palette.secondary['900']};
      ${theme.typography.subtitle1}
    }
  `,
);

enum ExaminationType {
  ChosenByUser = 'CHOSEN_BY_USER',
  Custom = 'CUSTOM',
  FollowUp = 'FOLLOW_UP',
  Full = 'FULL_EXAM',
  Quick = 'QUICK_CHECK',
}

type ExaminationTypeLabeledIconProps = {
  icon: ReactElement<IconProps>;
  examinationType: ExaminationType;
};

// icon depends on the examination type
export const ExaminationTypeLabeledIcon: FC<ExaminationTypeLabeledIconProps> =
  memo(({ icon, examinationType }) => {
    const renderExaminationTypeText = (examinationType: ExaminationType) => {
      switch (examinationType) {
        case ExaminationType.Full:
          return 'FME';
        case ExaminationType.Quick:
          return 'Quick Check';
      }
    };

    return (
      <Container>
        {cloneElement(icon, { ...icon.props, size: '1.5rem' })}
        <span>{renderExaminationTypeText(examinationType)}</span>
      </Container>
    );
  });
