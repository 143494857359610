import { FC, useMemo } from 'react';
import {
  AuscultationGroup,
  AuscultationPointTagSelectFn,
} from '@higo/common/src/components';
import { LungsFrontAuscultationLegend } from '@higo/common/src/components/Auscultation/LungsAuscultationLegend/LungsFrontAuscultationLegend';
import { LungsBackAuscultationLegend } from '@higo/common/src/components/Auscultation/LungsAuscultationLegend/LungsBackAuscultationLegend';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { ExaminationPageSection } from '@higo/common/src/features/ExaminationPage/sections/ExaminationPageSection';
import { ExaminationPageSectionId } from '@higo/common/src/features/ExaminationPage/models/examinationPageSectionId';
import { ExaminationDiagnosisMode } from '@higo/common/src/features/ExaminationPage/models/examinationDiagnosisMode';
import { AusultationTagSelect } from '@higo/common/src/features/ExaminationPage/components/AusultationTagSelect';
import {
  collectLungsAuscultationDictionaryItems,
  selectLungsAuscultationDictionary,
} from '@higo/common/src/features/Dictionary';
import {
  ExaminationPageDiagnosisModel,
  ExaminationPagePatientModel,
} from '@higo/common/src/features/ExaminationPage/models';
import {
  useLungsAuscultationPhysicalExaminationQuery,
  useLungsAuscultationPhysicalExaminationResourceFiles,
} from '@higo/common/src/features/ExaminationPage/hooks/queries';
import { buildAuscultationPoints } from '@higo/common/src/features/ExaminationPage/utils/buildAuscultationPoints';
import { auscultationPointsCount } from '@higo/common/src/config/auscultationPointsCount';
import { PhysicalExaminationType } from '@higo/api';

const AuscultationGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export interface LungsAuscultationSectionProps {
  examinationId: number;
  diagnosisModel: ExaminationPageDiagnosisModel;
  patientModel: ExaminationPagePatientModel;
}

const diagnosisModeAuscultationTagStrategy: Record<
  ExaminationDiagnosisMode,
  AuscultationPointTagSelectFn
> = {
  [ExaminationDiagnosisMode.Editable]: (fieldName) => (
    <AusultationTagSelect
      name={`lungAuscultation.tags.${fieldName}.tags`}
      dictionarySelector={selectLungsAuscultationDictionary}
      dictionaryItemsCollector={collectLungsAuscultationDictionaryItems}
    />
  ),
  [ExaminationDiagnosisMode.ReadOnly]: (fieldName) => (
    <AusultationTagSelect
      readonly
      name={`lungAuscultation.tags.${fieldName}.tags`}
      dictionarySelector={selectLungsAuscultationDictionary}
      dictionaryItemsCollector={collectLungsAuscultationDictionaryItems}
    />
  ),
  [ExaminationDiagnosisMode.NotAvailable]: () => null,
};

export const LungsAuscultationSection: FC<LungsAuscultationSectionProps> = ({
  examinationId,
  patientModel,
  diagnosisModel,
}) => {
  const intl = useIntl();
  const { data } = useLungsAuscultationPhysicalExaminationQuery(examinationId);
  const { data: fileResourceMap } =
    useLungsAuscultationPhysicalExaminationResourceFiles(examinationId);

  const auscultationPoints = useMemo(() => {
    const pointsCount =
      auscultationPointsCount[PhysicalExaminationType.LungsAuscultation];

    const right = <FormattedMessage id="common.right" />;

    const aggregatedPoints = buildAuscultationPoints(
      data?.lungsResults,
      fileResourceMap,
      pointsCount,
      (point) => ({
        tagline: intl.formatMessage({
          id: point % 2 ? 'common.right' : 'common.left',
        }),
      }),
    );

    return {
      front: aggregatedPoints.slice(0, pointsCount / 2),
      back: aggregatedPoints.slice(-(pointsCount / 2)),
    };
  }, [data?.lungsResults, fileResourceMap, intl]);

  const filesIdsFront = useMemo(
    () =>
      auscultationPoints.front.filter((x) => x.file).map((x) => x.file?.fileId),
    [auscultationPoints],
  );
  const filesIdsBack = useMemo(
    () =>
      auscultationPoints.back.filter((x) => x.file).map((x) => x.file?.fileId),
    [auscultationPoints],
  );

  const filesIds = filesIdsFront.concat(filesIdsBack);

  return (
    <ExaminationPageSection
      title={
        <FormattedMessage id="examinationPage.section.lungAuscultation.title" />
      }
      sectionId={ExaminationPageSectionId.Lungs}
      audioDownload="double"
      filesIds={filesIds}
    >
      <AuscultationGroupContainer>
        <AuscultationGroup
          legend={
            <LungsFrontAuscultationLegend
              gender={patientModel.gender}
              maturity={patientModel.maturity}
            />
          }
          auscultationPoints={auscultationPoints.front}
          tag={diagnosisModeAuscultationTagStrategy[diagnosisModel.mode]}
        />

        <AuscultationGroup
          legend={
            <LungsBackAuscultationLegend
              gender={patientModel.gender}
              maturity={patientModel.maturity}
            />
          }
          auscultationPoints={auscultationPoints.back}
          tag={diagnosisModeAuscultationTagStrategy[diagnosisModel.mode]}
        />
      </AuscultationGroupContainer>
    </ExaminationPageSection>
  );
};
