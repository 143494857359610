import styled, { css } from 'styled-components';
import { FC, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { SurveyQuestionTag } from '@higo/api';
import { TaggedAnsweredQuestionMap } from '@higo/common/src/features/FilledSurvey/model';
import {
  createAnsweredQuestionNonRenderableTagPredicate,
  SurveyRenderer,
  useAnamnesisSurveyGroups,
} from '@higo/common/src/features/FilledSurvey';
import { NotReportedAilmentsList } from '@higo/common/src/features/ExaminationPage/sections/AnamnesisSection/NotReportedAilmentsList';
import { AnamnesisSurveyGroup } from '@higo/common/src/features/ExaminationPage/sections/AnamnesisSection/AnamnesisSurveyGroup';

const AnamnesisSurveyGroupList = styled('div')`
  column-count: 2;
  column-gap: 6rem;
  margin-bottom: 4.5rem;
`;

const StyledNotReportedAilmentsList = styled(NotReportedAilmentsList)(
  ({ theme }) => css`
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    border-top: 1px solid ${theme.palette.neutral[500]};
  `,
);

const ailmentSurveyRendererOptions = {
  isAnsweredQuestionRenderable: createAnsweredQuestionNonRenderableTagPredicate(
    new Set([
      SurveyQuestionTag.AnamnesisMedicaments,
      SurveyQuestionTag.Other,
      SurveyQuestionTag.PatientCondition,
      SurveyQuestionTag.Covid,
    ]),
  ),
};

export interface AnamnesisSurveySegmentProps {
  anamnesisTaggedQuestions: TaggedAnsweredQuestionMap;
}
export const AnamnesisSurveySegment: FC<AnamnesisSurveySegmentProps> = memo(
  ({ anamnesisTaggedQuestions }) => {
    const {
      patientConditionGroup,
      ailmentsGroup,
      otherGroup,
      notReportedAilments,
    } = useAnamnesisSurveyGroups(anamnesisTaggedQuestions);

    return (
      <>
        <AnamnesisSurveyGroupList id="anamnesis-survey-group-list">
          {patientConditionGroup && (
            <AnamnesisSurveyGroup
              title={
                <FormattedMessage id="anamnesisSurvey.generalPatientCondition" />
              }
            >
              <SurveyRenderer answeredQuestionsList={patientConditionGroup} />
            </AnamnesisSurveyGroup>
          )}

          {ailmentsGroup.map(({ value, code, childQuestions }) => (
            <AnamnesisSurveyGroup key={code} title={value}>
              <SurveyRenderer
                answeredQuestionsList={childQuestions}
                options={ailmentSurveyRendererOptions}
              />
            </AnamnesisSurveyGroup>
          ))}

          {otherGroup && (
            <AnamnesisSurveyGroup
              title={<FormattedMessage id="anamnesisSurvey.additional" />}
            >
              <SurveyRenderer answeredQuestionsList={otherGroup} />
            </AnamnesisSurveyGroup>
          )}
        </AnamnesisSurveyGroupList>

        {anamnesisTaggedQuestions.AILMENTS && (
          <StyledNotReportedAilmentsList
            notReportedAilments={notReportedAilments}
          />
        )}
      </>
    );
  },
);
