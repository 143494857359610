import { createContext, FC, PropsWithChildren, useContext } from 'react';
import { AxiosInstance } from 'axios';

export type ExaminationApiContext = AxiosInstance;

const Context = createContext<ExaminationApiContext | undefined>(undefined);

export const ExaminationApiContextProvider: FC<{
  value: ExaminationApiContext;
}> = ({
  value,
  children,
}: PropsWithChildren<{ value: ExaminationApiContext }>) => (
  <Context.Provider value={value}>{children}</Context.Provider>
);

export const useExaminationApiContext = () => {
  const ctx = useContext(Context);
  if (!ctx) {
    throw new Error('ApiContext not provided');
  }

  return ctx;
};
