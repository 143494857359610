import { FC, useRef } from 'react';
import { ExaminationPageSectionId } from '../../models';
import { ExaminationPageSection } from '../ExaminationPageSection';
import { FormattedMessage } from 'react-intl';
import { Divider, Text } from '@higo/ui';
import styled, { css } from 'styled-components';
import { TaggedAnsweredQuestionMap } from '@higo/common/src/features/FilledSurvey/model';
import { AnamnesisMedicamentsSegment } from './AnamnesisMedicamentsSegment';
import { AnamnesisSurveySegment } from './AnamnesisSurveySegment';
import { MedicalOperatorNotes } from './MedicalOperatorNotes';
import { HigoMode } from '@higo/api';
import { CopyTextContentButton } from '../../components/CopyTextContentButton';

const Content = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.background};

    // Hack: Hides topmost column groups top border (by sliding it underneath)
    // there are no selectors for first column element
    &:before {
      position: relative;
      content: '';
      height: 1px;
      width: 100%;
      display: block;
      margin-bottom: -1px;
      background-color: ${theme.palette.background};
      z-index: 1;
    }
  `,
);

const CopyConetent = styled.div`
  margin-bottom: 0.5rem;
`;

export interface AnamnesisSectionProps {
  examinationId: number;
  createDate?: Date;
  mode: HigoMode;
  isMedicalOperatorExaminer: boolean;
  anamnesisTaggedQuestions?: TaggedAnsweredQuestionMap;
  generalTaggedQuestions?: TaggedAnsweredQuestionMap;
  hasCopyFeature: boolean;
  hasToastWhenCopy?: boolean;
}

export const AnamnesisSection: FC<AnamnesisSectionProps> = ({
  examinationId,
  createDate,
  mode,
  isMedicalOperatorExaminer,
  anamnesisTaggedQuestions,
  generalTaggedQuestions,
  hasCopyFeature,
  hasToastWhenCopy,
}) => {
  const surveyContainerRef = useRef<HTMLDivElement>(null);
  const hasAnamnesisSurvey = !!anamnesisTaggedQuestions?.AILMENTS;
  const isHigoPro = 'PRO' === mode;
  const surveyVersionDate = new Date('2023-03-21');

  const checkIfIsTooOldSurvey = () => {
    return createDate && createDate < surveyVersionDate;
  };

  const isTooOldSurvey = checkIfIsTooOldSurvey();

  return (
    <ExaminationPageSection
      sectionId={ExaminationPageSectionId.AnamnesisSurvey}
      title={<FormattedMessage id="anamnesisSurvey" />}
      headerExtra={
        hasCopyFeature &&
        hasAnamnesisSurvey && (
          <CopyTextContentButton
            contentRef={surveyContainerRef}
            isAnamnesisSurveyButton
            hasToastWhenCopy={hasToastWhenCopy}
          />
        )
      }
    >
      <Content>
        <CopyConetent ref={surveyContainerRef}>
          {!hasAnamnesisSurvey && !isTooOldSurvey && (
            <Text variant="body2" id="anamnesis-not-conducted">
              <FormattedMessage id="examinationPage.section.anamnesis.anamnesisSurveyNotConducted" />
            </Text>
          )}
          {isTooOldSurvey && (
            <Text variant="body2" id="anamnsesis-too-old">
              <FormattedMessage id="examinationPage.section.anamnesis.anamnesisSurveyIsTooOld" />
            </Text>
          )}
          {!isTooOldSurvey && hasAnamnesisSurvey && (
            <AnamnesisSurveySegment
              anamnesisTaggedQuestions={anamnesisTaggedQuestions}
            />
          )}
          {!isTooOldSurvey && (
            <AnamnesisMedicamentsSegment
              anamnesisTaggedQuestions={anamnesisTaggedQuestions}
              generalTaggedQuestions={generalTaggedQuestions}
            />
          )}
        </CopyConetent>

        {isHigoPro && (
          <>
            <Divider />
            <MedicalOperatorNotes
              examinationId={examinationId}
              isMedicalOperatorExaminer={isMedicalOperatorExaminer}
            />
          </>
        )}
      </Content>
    </ExaminationPageSection>
  );
};
