import { IconBox, IconProps } from '../IconBox';

const DownloadFileIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.2 2.4a2.4 2.4 0 0 0-2.4 2.4v14.4a2.4 2.4 0 0 0 2.4 2.4h9.6a2.4 2.4 0 0 0 2.4-2.4V8.897a2.4 2.4 0 0 0-.703-1.697L14.4 3.103a2.4 2.4 0 0 0-1.697-.703H7.2Zm6 7.2a1.2 1.2 0 1 0-2.4 0v4.303L9.249 12.35a1.2 1.2 0 1 0-1.697 1.697l3.6 3.6a1.2 1.2 0 0 0 1.697 0l3.6-3.6a1.2 1.2 0 1 0-1.698-1.697L13.2 13.903V9.6Z"
    />
  </IconBox>
);

export default DownloadFileIcon;
