import { allPass, complement, find, ifElse, pipe } from 'rambda';
import { SurveyGenderTag, SurveyGenderTagType } from '@higo/api/src/models';
import { isNotNil } from '@higo/api/src/utils/isNotNil';
import { Gender } from '@higo/api/src/models/gender';

const genderTagGroupMap: Record<Gender, SurveyGenderTagType[]> = {
  [Gender.Male]: ['FOR_MEN', 'ONLY_MEN'],
  [Gender.Female]: ['FOR_WOMEN', 'ONLY_WOMEN'],
  [Gender.Unknown]: ['FOR_UNKNOWN'],
  [Gender.Other]: ['FOR_OTHER'],
};

const genderExcludingTags: Record<Gender, SurveyGenderTagType[]> = {
  [Gender.Male]: ['ONLY_WOMEN'],
  [Gender.Female]: ['ONLY_MEN'],
  [Gender.Unknown]: ['ONLY_WOMEN', 'ONLY_MEN'],
  [Gender.Other]: ['ONLY_WOMEN', 'ONLY_MEN'],
};

const anyGenderTagList = Object.values(SurveyGenderTag) as string[];

const hasTags = (tags: string[]) =>
  pipe(
    find((x) => tags.includes(x)),
    isNotNil,
  );

const hasAnyGenderTags = hasTags(anyGenderTagList);
const hasCorrespondingGenderTags = (g: Gender) =>
  allPass([
    hasTags(genderTagGroupMap[g]),
    complement(hasTags(genderExcludingTags[g])),
  ]);

export const createSurveyGenderTagPredicate = (g: Gender) =>
  ifElse(hasAnyGenderTags, hasCorrespondingGenderTags(g), () => true);
