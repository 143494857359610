import {
  PhysicalExaminationDetailsDTO,
  PhysicalExaminationStatus,
  PhysicalExaminationType,
} from '@higo/api';
import { concat, filter, includes, map, or, pipe, prop } from 'rambda';
import { when } from 'rambda/immutable';

const isCompleted = (x: PhysicalExaminationDetailsDTO) =>
  PhysicalExaminationStatus.DeviceEnded === x.examStatus;

const hasAnyEarExam = or(
  includes(PhysicalExaminationType.LeftEar),
  includes(PhysicalExaminationType.RightEar),
);

// As ears are now separate examinations, we will push common examination type if either is present
export const extractFinishedPhysicalExaminations = pipe(
  filter(isCompleted),
  map(prop('examType')),
  when(hasAnyEarExam, concat([PhysicalExaminationType.Ear])),
);
