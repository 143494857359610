import { FC } from 'react';
import { MenuItem, MenuItemProps } from '@higo/ui';
import styled, { css } from 'styled-components';

export interface HeaderNotificationMenuItemProps extends MenuItemProps {
  date?: string;
  indicator?: boolean;
  id?: string | undefined;
}

const StyledMenuItem = styled(MenuItem)(
  ({ theme }) => css`
    margin-right: auto;
    padding-left: 1rem;
    max-width: 11rem;

    ${theme.typography.body};
    color: ${theme.palette.secondary['500']};
  `,
);

export const HeaderNotificationMenuItem: FC<HeaderNotificationMenuItemProps> =
  ({ children, ...props }) => {
    return <StyledMenuItem {...props}>{children}</StyledMenuItem>;
  };
