import styled, { css } from 'styled-components';
import { useQueryClient } from 'react-query';
import { DoctorQueryKey } from 'config/doctorQueryKey';
import { Button, combineString, Heading, RHFTextField } from '@higo/ui';
import { FC, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import SimpleBar from 'simplebar-react';
import { useExaminationCommentsQuery } from './hooks/useExaminationCommentsQuery';
import { CreateExamCommentDTO } from '@higo/api/lib/models/examinationComments';
import { useCreateExaminationCommentMutation } from './hooks/useCreateExaminationCommentMutation';
import { useProfileDataQuery } from 'hooks/queries/useProfileDataQuery';
import { ChatMessage } from './ChatMessage';
import { useRefreshExaminationCommentsQuery } from 'components/ChatModal/hooks/useRefreshExaminationCommentsQuery';
import { useMarkExaminationCommentsAsReadMutation } from 'components/ChatModal/hooks/useMarkExaminationCommentsAsReadMutation';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMessagesQuery } from '@higo/doctor/src/pages/MessagesListPage/useMessagesQuery';

const ContentContainer = styled('div')(
  ({ theme }) => css`
    width: 30.125rem;
    height: 31.75rem;
    display: grid;
    grid-template-rows: 4.75rem 1fr;
    background-color: ${theme.palette.neutral['400']};
    border-radius: 16px;
    margin: -1rem;
  `,
);

const HeadlineSection = styled.div`
  padding: 2rem 2rem 1rem;
`;

const StyledHeading = styled(Heading)`
  margin: 0 0 0.5rem;
`;

const ChatSection = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.white};
    border-radius: 16px;
    padding: 2rem;
  `,
);

const ScrollableMessagesContainer = styled(SimpleBar)(
  ({ theme }) => css`
    width: calc(100% + 1.125rem);
    height: 18.8125rem;
    padding-right: 1.125rem;

    .simplebar-scrollbar:before {
      background: ${theme.palette.neutral['500']};
      width: 0.25rem;
      opacity: 1;
      left: 6px;
    }
  `,
);

const StyledForm = styled.form`
  display: grid;
  grid-template-columns: 16.625rem 8.875rem;
  grid-column-gap: 0.5rem;
  height: 3rem;
  margin-top: 1rem;
`;

const StyledBottomRef = styled.div`
  clear: both;
`;

const NoMessagesInfo = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.palette.secondary['500']};
    font-size: 0.8125rem;
    height: 80%;
    width: 100%
    margin: 0 auto;
  `,
);

export type ChatWindowProps = {
  examinationId: number;
  patient: {
    id: number;
    firstName: string;
    lastName: string;
  };
};

export const ChatWindow: FC<ChatWindowProps> = ({ examinationId, patient }) => {
  const { formatMessage } = useIntl();
  const scrollToBottomRef = useRef<null | HTMLDivElement>(null);
  const queryClient = useQueryClient();

  const handleScrollToBottom = () => {
    window.requestAnimationFrame(() => {
      if (scrollToBottomRef.current) {
        scrollToBottomRef!.current!.scrollIntoView(false);
      }
    });
  };

  const { data: doctorProfile } = useProfileDataQuery();
  const { data: commentsData, isLoading: isCommentsLoading } =
    useExaminationCommentsQuery(examinationId);
  useRefreshExaminationCommentsQuery(examinationId);
  const { mutate: mutateMarkExaminationCommentsAsRead } =
    useMarkExaminationCommentsAsReadMutation(examinationId);

  const { mutateAsync } = useCreateExaminationCommentMutation({
    patientId: patient.id,
  });

  const { refetch: refetchMessagesQuery } = useMessagesQuery();

  const patientFullName = combineString([
    patient?.firstName,
    patient?.lastName,
  ]);

  const { handleSubmit, control, resetField } = useForm<CreateExamCommentDTO>({
    defaultValues: { commentText: '' },
  });

  const onSubmit = (data: CreateExamCommentDTO) => {
    // todo: scroll to bottom only when user is not viewing the older messages
    mutateAsync({ examinationId, data }).then(() => {
      refetchMessagesQuery();
      queryClient.invalidateQueries([DoctorQueryKey.Messages]);

      handleScrollToBottom();
    });
    resetField('commentText');
  };

  useEffect(() => {
    if (!isCommentsLoading) {
      mutateMarkExaminationCommentsAsRead();
      handleScrollToBottom();
    }
  }, [mutateMarkExaminationCommentsAsRead, isCommentsLoading, commentsData]);

  return (
    <ContentContainer id="chat-container">
      <HeadlineSection id="chat-header">
        <StyledHeading level={4}>
          <FormattedMessage
            id="chatWindow.chatWithPatient"
            values={{ patientFullName }}
          />
        </StyledHeading>
      </HeadlineSection>
      <ChatSection>
        {!commentsData || commentsData?.length === 0 ? (
          <NoMessagesInfo id="no-messages-info">
            No messages in the chat
          </NoMessagesInfo>
        ) : (
          <ScrollableMessagesContainer id="messages-container">
            {commentsData?.map((msg, index, arr) => {
              const prevMsg = arr[index - 1];
              const isIncomingMessage =
                msg.authorUserId !== doctorProfile?.userId;
              return (
                <ChatMessage
                  key={msg.id}
                  commentText={msg.commentText}
                  authorUserId={msg.authorUserId}
                  createDate={msg.createDate}
                  read={msg.read}
                  firstName={
                    isIncomingMessage
                      ? patient?.firstName
                      : doctorProfile?.firstName
                  }
                  lastName={
                    isIncomingMessage
                      ? patient?.lastName
                      : doctorProfile?.lastName
                  }
                  isIncomingMessage={isIncomingMessage}
                  continuedThread={prevMsg?.authorUserId === msg.authorUserId}
                />
              );
            })}
            <StyledBottomRef ref={scrollToBottomRef} />
          </ScrollableMessagesContainer>
        )}
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <RHFTextField
            hiddenLabel
            control={control}
            name="commentText"
            type="text"
            id="chat-text-field"
            placeholder={formatMessage({
              id: 'chatWindow.messageInput.placeholder',
            })}
          />
          <Button type="submit" id="chat-submit-button" fullWidth>
            <FormattedMessage id="common.send" />
          </Button>
        </StyledForm>
      </ChatSection>
    </ContentContainer>
  );
};
