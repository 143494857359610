import { IconBox, IconProps } from '../IconBox';

const RoundStomachIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.7955 7.79088C16.4135 7.79088 16.1128 7.8452 15.847 7.89321L15.8448 7.89361C15.6206 7.9342 15.4273 7.96877 15.2168 7.96877C14.9667 7.96877 14.7181 7.91666 14.435 7.80492C13.5619 7.46017 13.5488 6.00143 13.5488 5.98694C13.5488 5.87619 13.4579 5.7865 13.3458 5.7865H11.5196C11.4075 5.7865 11.3167 5.87619 11.3167 5.98694V7.59044C11.3167 7.84599 11.373 8.15818 11.4328 8.48839C11.5678 9.23402 11.7357 10.1621 11.34 10.9047C10.9392 11.6563 10.2361 11.8197 9.49092 11.9921C8.53973 12.2125 7.46118 12.4631 7.46118 14.2049V18.0133C7.46118 18.124 7.55199 18.2137 7.6641 18.2137H9.69333C9.80544 18.2137 9.89625 18.124 9.89625 18.0133V15.608C9.89625 14.9225 10.0728 14.9225 10.1392 14.9225C10.3508 14.9225 10.7115 15.1089 11.1285 15.3249C11.8499 15.6982 12.8376 16.2093 13.9547 16.2093C16.6977 16.2093 18.8249 13.2999 18.8249 10.7974C18.8249 8.08354 17.4059 7.79081 16.7956 7.79081L16.7955 7.79088ZM16.1412 11.1357C16.1412 12.2695 15.2379 13.4678 14.2282 13.7314L13.9337 13.8119C13.6251 13.8963 13.4427 14.2143 13.5257 14.5233C13.6089 14.8327 13.922 15.0209 14.2342 14.9492L14.5029 14.8875C16.0355 14.4871 17.2858 12.8357 17.2858 11.1357V10.7215C17.2858 10.4055 17.0295 10.1493 16.7135 10.1493C16.3974 10.1493 16.1412 10.4055 16.1412 10.7215V11.1357Z"
    />
  </IconBox>
);

export default RoundStomachIcon;
