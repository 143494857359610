import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { clearAPIOAuth2AccessToken } from 'services/apiOAuth2AccessTokenService';
import { useQueryStorage } from '@higo/common/lib/QueryStorage';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

export const useLogoutAutomatically = () => {
  const queryClient = useQueryClient();
  const queryStorage = useQueryStorage();
  const intl = useIntl();

  const navigate = useNavigate(); //todo: Causing unnecessary re-renders https://github.com/remix-run/react-router/issues/7634

  return useCallback(() => {
    clearAPIOAuth2AccessToken(queryClient, queryStorage);
    queryClient.clear();

    navigate('/');
    setTimeout(() => {
      toast.warning(
        intl.formatMessage({
          id: 'notification.idleAutoLogoutInDone',
        }),
        {
          toastId: 'success1',
          autoClose: 30000,
        },
      );
    }, 500);
  }, [queryClient, queryStorage, navigate, intl]);
};
