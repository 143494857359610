import { IconBox, IconProps } from '../IconBox';

const PatientsIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.667 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm10.666 0a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm-1.427 10.667c.062-.436.094-.881.094-1.334 0-2.18-.748-4.185-2-5.774a6.667 6.667 0 0 1 10 5.775v1.333h-8.095Zm-2.573-1.334a6.667 6.667 0 0 0-13.333 0v1.334h13.333v-1.334Z"
    />
  </IconBox>
);

export default PatientsIcon;
