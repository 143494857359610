import {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export interface LayoutState {
  isNavHeaderHidden: boolean;
}
export interface LayoutContext {
  state: LayoutState;
  update: (updates: Partial<LayoutState>) => void;
}

const Context = createContext<LayoutContext>({
  state: {
    isNavHeaderHidden: false,
  },
  update: () => {},
});

export const LayoutContextProvider: FC = ({ children }) => {
  const [layoutState, setLayoutState] = useState<LayoutState>({
    isNavHeaderHidden: false,
  });

  const updateLayout = useCallback(
    (updates) => setLayoutState((x) => ({ ...x, ...updates })),
    [setLayoutState],
  );

  const context = useMemo<LayoutContext>(
    () => ({
      state: layoutState,
      update: updateLayout,
    }),
    [layoutState, updateLayout],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const useLayoutContext = () => {
  return useContext(Context);
};
