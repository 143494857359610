import React from 'react';
import { Heading, Skeleton, VerticalDivider } from '@higo/ui';
import styled from 'styled-components';
import { OverviewContainer } from './OverviewContainer';
import { OverviewDiseasesColumnWrapper } from './OverviewDiseasesColumnWrapper';
import { OverviewDiseasesColumnButtons } from './OverviewDiseasesColumnButtons';

const SkeletonItem = styled(Skeleton)`
  height: 1rem;
`;

const SkeletonButton = styled(Skeleton)`
  height: 2rem;
`;

const SkeletonButtonSmall = styled(Skeleton)`
  width: 2rem;
  height: 2rem;
`;

const GeneralInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ColumnContainer = styled.div`
  width: 16rem;
`;

export const ExaminationPageOverviewSkeleton = () => {
  return (
    <OverviewContainer>
      <div>
        <Heading level={2}>
          <SkeletonItem />
        </Heading>
        <GeneralInfoContainer>
          <SkeletonButton />
          <SkeletonButtonSmall />
        </GeneralInfoContainer>
      </div>

      <VerticalDivider />

      <div>
        <SkeletonItem />
        <SkeletonItem />
        <SkeletonItem />
        <SkeletonItem />
        <SkeletonItem />
      </div>

      <VerticalDivider />

      <OverviewDiseasesColumnWrapper>
        <ColumnContainer>
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
        </ColumnContainer>
        <OverviewDiseasesColumnButtons>
          <SkeletonButton />
          <SkeletonButtonSmall />
        </OverviewDiseasesColumnButtons>
      </OverviewDiseasesColumnWrapper>
    </OverviewContainer>
  );
};
