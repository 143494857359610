import {
  useExaminationAzureResourceQuery,
  useThroatExaminationResultsQuery,
} from '@higo/common/src/features/ExaminationPage/hooks/queries';
import { ThroatExaminationDTO } from '@higo/api/lib/models/examinationResultsDTO';
import { map, pick, pipe, prop } from 'rambda';
import { slidingWindowSlice } from '@higo/common/src/utils/slidingWindowSlice';
import { useExaminationAzureResourcesQuery } from '@higo/common/src/features/ExaminationPage/hooks/queries/useExaminationAzureResourcesQuery';

const selectFileResultIds: (data: ThroatExaminationDTO) => number[] = pipe(
  prop('throatResults'),
  map(prop('fileId')),
);

const selectGalleryFileResultIds: (data: ThroatExaminationDTO) => number[] = (
  data,
) =>
  slidingWindowSlice(
    data.throatResults.map(prop('fileId')),
    data.diagnosticImageIndex,
  );

export const useThroatExaminationResultsLinks = (id: number) => {
  const { data: ids } = useThroatExaminationResultsQuery(
    id,
    selectGalleryFileResultIds,
  );
  return useExaminationAzureResourcesQuery(
    ids ?? [],
    map(pick(['fileName', 'link'])),
  );
};

export const useThroatExaminationVideoFramesIds = (id: number) =>
  useThroatExaminationResultsQuery(id, selectFileResultIds);

export const useThroatExaminationVideoResultsLink = (id: number) => {
  const { data: fileId } = useThroatExaminationResultsQuery(
    id,
    ({ video }) => video?.fileId,
  );
  return useExaminationAzureResourceQuery(fileId);
};
