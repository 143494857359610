export const combineString = (
  stringArray: (string | null | undefined)[],
  separator = ' ',
): string => stringArray.filter((el) => el).join(separator);

export const combineAcronym = (
  stringArray: (string | null | undefined)[],
  separator = '',
): string =>
  stringArray
    .map((el) => (el ? el[0] : null))
    .filter((el) => el)
    .join(separator);
