import styled from 'styled-components';
import { FC } from 'react';
import { Gender } from '@higo/api';
import { Maturity } from '@higo/common/src/types';
import { BodyImage } from '@higo/common/src/components/Auscultation/HeartAuscultationLegend/BodyImage';

export const Container = styled.div`
  width: 9.75rem;
  height: 11.0625rem;
`;

export type HeartAuscultationLegendProps = {
  gender: Gender;
  maturity: Maturity;
};

const StyledFrontBodyImage = styled(BodyImage)`
  max-width: 100%;
`;

export const HeartAuscultationLegend: FC<HeartAuscultationLegendProps> = ({
  gender,
  maturity,
}) => (
  <Container id="heart-ascuation-legend">
    <StyledFrontBodyImage gender={gender} maturity={maturity} />
  </Container>
);
