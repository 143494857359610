import { IconBox, IconProps } from '../IconBox';

const RefreshIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.8 2.4A1.2 1.2 0 0 1 6 3.6V6.12A8.403 8.403 0 0 1 19.922 9.2a1.2 1.2 0 1 1-2.263.8 6.003 6.003 0 0 0-10.46-1.6H10.8a1.2 1.2 0 0 1 0 2.4h-6a1.2 1.2 0 0 1-1.2-1.2v-6a1.2 1.2 0 0 1 1.2-1.2Zm.01 10.868A1.2 1.2 0 0 1 6.34 14a6.003 6.003 0 0 0 10.46 1.6H13.2a1.2 1.2 0 1 1 0-2.4h6a1.2 1.2 0 0 1 1.2 1.2v6a1.2 1.2 0 1 1-2.4 0v-2.522A8.403 8.403 0 0 1 4.078 14.8a1.2 1.2 0 0 1 .732-1.53Z"
    />
  </IconBox>
);

export default RefreshIcon;
