import { AxiosRequestConfig } from 'axios';
import { isPast } from 'date-fns';
import memoize from 'just-memoize-last';
import { OAuth2AccessToken } from '../oAuth2AccessToken';

export interface RefreshTokenInterceptorProps {
  refreshTokenFn: (token: string) => Promise<OAuth2AccessToken>;
  getAccessToken: () => OAuth2AccessToken | undefined;
  onRefreshSuccess: (auth: OAuth2AccessToken) => Promise<void>;
  onRefreshError: (e: unknown) => Promise<void>;
}

export const createRefreshTokenInterceptor = ({
  refreshTokenFn,
  getAccessToken,
  onRefreshSuccess,
  onRefreshError,
}: RefreshTokenInterceptorProps) => {
  const memoizedRefreshToken = memoize(refreshTokenFn);

  return async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const auth = getAccessToken();
    if (auth == null) {
      return config;
    }
    if (isPast(auth.accessTokenExpiresAt)) {
      await memoizedRefreshToken(auth.refreshToken)
        .then(onRefreshSuccess)
        .catch(onRefreshError);
    }

    return config;
  };
};
