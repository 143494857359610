import React, { FC, memo, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import type { Swiper as SwiperType } from 'swiper';
import {
  Autoplay,
  EffectFade,
  Keyboard,
  Navigation,
  Pagination,
  Thumbs,
} from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css/bundle';
import {
  ArrowLeftButton,
  ArrowRightButton,
  DiagnosticFrameIcon,
  PlayButton,
  StopButton,
} from '@higo/ui';
import { DiagnosticPhotosGallerySlide } from './DiagnosticPhotosGallerySlide';
import { isNil } from 'rambda';
import { StyleProps } from '@summer/jst-react';
import { useIntl } from 'react-intl';

const GalleryContainer = styled.div`
  position: relative;
`;

const ButtonIconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 5;
  position: absolute;
  top: 1rem;
  left: 1rem;
  right: 1rem;
`;

const MiniIconWrapper = styled.div`
  z-index: 5;
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
`;

const FirstImageShortcut = styled(DiagnosticFrameIcon)`
  width: 2rem;
  height: 2rem;
`;

const MiniFirstImageShortcut = styled(DiagnosticFrameIcon)`
  width: 1rem;
  height: 1rem;
`;

const MainPhotoWrapper = styled.div`
  position: relative;
`;

const MainPhotoContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 2rem minmax(15.125rem, 1fr) 2rem;
  align-items: center;
`;

const MainPhotoSwiper = styled(Swiper)(
  ({ theme }) => css`
    &.swiper {
      margin: 0;
      .swiper-wrapper {
        .swiper-slide {
          border-radius: 1rem;
          overflow: hidden;
          display: flex;
        }
      }
    }

    & .swiper-pagination.swiper-pagination-fraction {
      ${theme.typography.h4};
      color: ${theme.palette.white};
      text-align: right;
      padding-right: 0.8125rem;
    }
  `,
);

const ThumbnailsContainer = styled.div`
  padding-top: 0.75rem;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const Thumbnails = styled(Swiper)(
  ({ theme }) => css`
    &.swiper {
      height: 3.3125rem;

      .swiper-slide {
        opacity: 0.3;

        transition: ${theme.transitions.create(['opacity'], {
          duration: theme.transitions.duration.standard,
          easing: theme.transitions.easing.easeInOut,
        })};

        &.swiper-slide-thumb-active {
          opacity: 1;
        }
      }
    }
  `,
);

const SwiperAutoPlayButton = () => {
  const swiper = useSwiper();
  const [isRunning, setIsRunning] = useState(swiper.autoplay.running);
  useEffect(() => {
    swiper.on('autoplayStart', () => setIsRunning(true));
    swiper.on('autoplayStop', () => setIsRunning(false));

    return () => {
      swiper.off('autoplayStart');
      swiper.off('autoplayStop');
    };
  }, [swiper]);

  return isRunning ? (
    <StopButton
      onClick={() => {
        swiper.autoplay.stop();
      }}
    />
  ) : (
    <PlayButton
      onClick={() => {
        if (swiper.isEnd) {
          // we do not have to listen for reaching end as this will be triggered after autoplayStart meaning swiper values will be changed
          swiper.slideTo(0);
        }
        swiper.autoplay.start();
      }}
    />
  );
};

const ThumbnailSlide = styled.img`
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: black;
`;

interface DiagnosticPhotosGalleryProps {
  resources: { link: string; fileName: string }[];
  initialSlide?: number;
  thumbnailsSlidesPerView?: number;
  Slide?: FC<JSX.IntrinsicElements['img']>;
  id?: string;
  setActiveGalleryLink?: (index: string) => void;
}

export const DiagnosticPhotosGallery: FC<
  DiagnosticPhotosGalleryProps & StyleProps
> = memo(
  ({
    style,
    className,
    resources,
    initialSlide,
    thumbnailsSlidesPerView = 8,
    id,
    setActiveGalleryLink,
    Slide = DiagnosticPhotosGallerySlide,
  }) => {
    const intl = useIntl();
    const isInitialSlideDiagnostic = !isNil(initialSlide);
    const coercedInitialSlide = initialSlide ?? 0;
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null>(null);
    const navigationPrevRef = useRef<HTMLButtonElement>(null);
    const navigationNextRef = useRef<HTMLButtonElement>(null);

    const [renderDiagnosticImageIcon, setRenderDiagnosticImageIcon] = useState(
      isInitialSlideDiagnostic,
    );

    return (
      <GalleryContainer style={style} className={className}>
        <MainPhotoContainer id={id}>
          <ArrowLeftButton ref={navigationPrevRef} id="arrow-left-button" />
          <MainPhotoWrapper id="main-photo-wrapper">
            <MainPhotoSwiper
              onSwiper={(swiper) => {
                swiper.autoplay?.stop();
                // setting autoplay duration triggers autoplay :/
              }}
              onSlideChange={(swiper) => {
                const activeImageLink = resources[swiper.activeIndex].link;
                setActiveGalleryLink && setActiveGalleryLink(activeImageLink);
                swiper.activeIndex === initialSlide
                  ? setRenderDiagnosticImageIcon(true)
                  : setRenderDiagnosticImageIcon(false);
              }}
              slidesPerView={1}
              initialSlide={coercedInitialSlide}
              thumbs={{ swiper: thumbsSwiper }}
              pagination={{
                type: 'fraction',
              }}
              modules={[
                Navigation,
                Keyboard,
                EffectFade,
                Autoplay,
                Thumbs,
                Pagination,
              ]}
              keyboard={{
                enabled: true,
              }}
              navigation={{
                prevEl: navigationPrevRef.current!,
                nextEl: navigationNextRef.current!,
              }}
              autoplay={{
                delay: 200,
                waitForTransition: false,
                stopOnLastSlide: true,
              }}
              effect="fade"
            >
              <ButtonIconWrapper slot="container-start">
                <SwiperAutoPlayButton />
                {renderDiagnosticImageIcon && <FirstImageShortcut />}
              </ButtonIconWrapper>
              {resources.map(({ link, fileName }, index) => (
                <SwiperSlide key={link} virtualIndex={index} id="slide">
                  <Slide
                    src={link}
                    alt={`${intl.formatMessage({
                      id: 'diagnosticPhotoAlt',
                    })} ${index}, ${fileName}`}
                  />
                </SwiperSlide>
              ))}
            </MainPhotoSwiper>
          </MainPhotoWrapper>

          <ArrowRightButton ref={navigationNextRef} id="arrow-right-button" />
        </MainPhotoContainer>
        <ThumbnailsContainer>
          <Thumbnails
            onSwiper={setThumbsSwiper}
            slidesPerView={thumbnailsSlidesPerView}
            spaceBetween={4}
            watchSlidesProgress
            modules={[Navigation, Thumbs]}
          >
            {resources.map(({ link, fileName }, index) => (
              <div id="photo-slide">
                <SwiperSlide key={link} virtualIndex={index}>
                  <MiniIconWrapper>
                    {index === initialSlide ? <MiniFirstImageShortcut /> : null}
                  </MiniIconWrapper>
                  <ThumbnailSlide
                    src={link}
                    alt={`${intl.formatMessage({
                      id: 'diagnosticPhotoThumbnailAlt',
                    })} ${index}, ${fileName}`}
                  />
                </SwiperSlide>
              </div>
            ))}
          </Thumbnails>
        </ThumbnailsContainer>
      </GalleryContainer>
    );
  },
);
