import { CreateExamCommentDTO } from '@higo/api/lib/models/examinationComments';
import { createExaminationComment } from '@higo/api/lib/services/messagesService';
import { useMutation, useQueryClient } from 'react-query';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';
import { DoctorQueryKey } from 'config/doctorQueryKey';
import { useDispatchDoctorTrackedType } from 'hooks/useDispatchDoctorTrackedEvent';
import {
  DoctorTrackedType,
  TrackedEventBasicData,
} from '@higo/common/lib/features/TrackedEvent';

interface CreateExaminationCommentMutationData {
  examinationId: number;
  data: CreateExamCommentDTO;
}

export const useCreateExaminationCommentMutation = (
  trackedCustomEventData?: Pick<TrackedEventBasicData, 'patientId'>,
) => {
  const queryClient = useQueryClient();
  const { authorizedAxios } = useDoctorPortalApiContext();
  const sendTrackedEvent = useDispatchDoctorTrackedType();

  return useMutation(
    ({ examinationId, data }: CreateExaminationCommentMutationData) =>
      createExaminationComment(authorizedAxios)(examinationId, data),
    {
      onSuccess: ({ examinationId }) => {
        sendTrackedEvent({
          event: DoctorTrackedType.SendChatMessage,
          eventResult: 'success',
          examinationId: examinationId,
          ...trackedCustomEventData,
        });

        queryClient.invalidateQueries([
          DoctorQueryKey.ExaminationComments,
          examinationId,
        ]);
      },

      onError: (error, variables, context) => {
        sendTrackedEvent({
          event: DoctorTrackedType.SendChatMessage,
          eventResult: 'error',
          examinationId: variables.examinationId,
          ...trackedCustomEventData,
        });

        // call global error handler
        return queryClient
          .getDefaultOptions()
          ?.mutations?.onError?.(error, variables, context);
      },
    },
  );
};
