import { CellProps, TRowEntity } from 'react-table';
import { Button } from '@higo/ui';
import { MouseEvent, useCallback } from 'react';
import { getExaminationRoute } from 'routes';
import { useNavigate } from 'react-router-dom';
import {
  useAssignExaminationMutation,
  useIsAnyAssignExaminationMutating,
} from 'hooks/mutations/assignExamination';
import { FormattedMessage } from 'react-intl';
import { TrackedAssignExaminationEventTrigger } from '@higo/common/lib/features/TrackedEvent';

export type ExaminationReviewNowCellValue = {
  examinationId: number;
  patientId?: number;
};

export const ExaminationReviewNowCellRenderer = <T extends TRowEntity>({
  cell,
  selection,
}: CellProps<T, ExaminationReviewNowCellValue>) => {
  const navigate = useNavigate();
  const examinationId = cell.value.examinationId;

  const { mutateAsync, isLoading: isMutating } = useAssignExaminationMutation(
    examinationId,
    {
      eventTrigger: TrackedAssignExaminationEventTrigger.ReviewNowButtonClick,
      patientId: cell.value.patientId,
    },
  );

  const isAnyExamMutating = useIsAnyAssignExaminationMutating();
  const isAnyExamSelected = Number(selection?.selectedIds.length) > 0;

  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();

      mutateAsync(examinationId).then(() =>
        navigate(getExaminationRoute(examinationId)),
      );
    },
    [examinationId, navigate, mutateAsync],
  );

  return (
    <Button
      color="secondary"
      size="small"
      onClick={handleClick}
      isLoading={isMutating}
      disabled={!!isAnyExamMutating || isAnyExamSelected}
      id="consult-now-button"
    >
      <FormattedMessage id="common.reviewNow" />
    </Button>
  );
};
