import { PhysicalExaminationType } from '@higo/api';
import { AuscultationExamination } from '@higo/common/src/types/auscultationExamination';

export const auscultationPointsCount: Record<AuscultationExamination, number> =
  {
    [PhysicalExaminationType.LungsAuscultation]: 12,
    [PhysicalExaminationType.HeartAuscultation]: 4,
    [PhysicalExaminationType.AbdominalAuscultation]: 2,
    [PhysicalExaminationType.Cough]: 1,
  } as const;
