import styled, { css } from 'styled-components';
import { CellProps } from 'react-table';
import clsx from 'clsx';

export type DoubleFieldRendererValue =
  | {
      highlighted: string | undefined;
      text: string | number | undefined;
      indicator?: boolean;
    }
  | string;

const Wrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    white-space: initial;
    ${theme.typography.body};
  `,
);

const Highlight = styled('strong')(
  ({ theme }) => css`
    ${theme.typography.lineClamp(1)};
  `,
);

const Text = styled('span')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.palette.secondary['500']};
    max-width: max-content;
    ${theme.typography.lineClamp(1)};

    &.indicator:before {
      content: '';
      min-width: 0.5rem;
      height: 0.5rem;
      display: inline-block;
      margin-right: 0.25rem;
      border-radius: 50%;
      background: ${theme.palette.primary['500']};
    }
  `,
);

export const DoubleFieldCellRenderer = <T extends Record<string, unknown>>({
  cell,
}: CellProps<T, DoubleFieldRendererValue>) => (
  <Wrapper>
    {typeof cell.value === 'string' ? (
      <Highlight>{cell.value}</Highlight>
    ) : (
      <>
        <Highlight>{cell.value?.highlighted ?? '-'}</Highlight>
        <Text className={clsx({ indicator: cell.value?.indicator })}>
          {cell.value?.text}
        </Text>
      </>
    )}
  </Wrapper>
);
