import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
  page: {
    fontFamily: 'NotoSans',
    paddingTop: 20,
    paddingBottom: 60,
  },
  section: {
    padding: '2 10',
    margin: '2 20',
  },
  titleSection: {
    padding: '2 10 0 10',
    margin: '0 20',
  },
  centerSection: {
    padding: '5 10 10 10',
    margin: '0 35',
  },
  titleLungs: {
    padding: '0 10 0 10',
    marginLeft: 35,
  },
  text: {
    fontSize: 8,
  },
  boldText: {
    fontSize: 8,
    fontFamily: 'NotoSansBold',
  },
  bigTitle: {
    fontSize: 14,
    fontFamily: 'NotoSansBold',
    marginRight: 30,
  },
  smallTitle: {
    fontSize: 11,
    fontFamily: 'NotoSansBold',
    marginBottom: 4,
    marginTop: 10,
  },
  bigTitleCenter: {
    fontSize: 14,
    fontFamily: 'NotoSansBold',
    marginLeft: 35,
  },
  pageInfo: {
    position: 'absolute',
    fontSize: 8,
    fontFamily: 'NotoSans',
    bottom: 15,
    left: 0,
    right: 10,
    textAlign: 'right',
  },
  halfSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '0 30',
  },
  earsSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '0',
  },
  halfBox: {
    padding: '4 15 5 15',
    width: '50%',
  },
  earHalfBox: {
    padding: '4 15 5 0',
    width: '50%',
  },
  documentInfo: {
    position: 'absolute',
    fontSize: 8,
    fontFamily: 'NotoSans',
    bottom: 25,
    left: 40,
    right: 40,
    textAlign: 'center',
    padding: 10,
  },
  asculationImage: {
    width: 100,
    marginBottom: 6,
  },
  skinThroatImage: {
    width: 220,
  },
  earImage: {
    width: 115,
  },
});
