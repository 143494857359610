import {
  Children,
  cloneElement,
  FC,
  isValidElement,
  memo,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useClickAway, useKeyPressEvent } from 'react-use';
import styled, { css } from 'styled-components';
import MenuUnstyled, {
  MenuUnstyledActions,
  MenuUnstyledProps,
} from '@mui/base/MenuUnstyled';
import { Popper } from '@higo/ui/src/components/Popper';

export interface MenuProps extends MenuUnstyledProps {
  id: string;
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const StyledMenu = styled(MenuUnstyled)(
  ({ theme }) => css`
    z-index: ${theme.layers.popper};
  `,
);

const StyledListbox = styled('ul')(
  () => css`
    margin: 0;
    padding: 0;
    max-width: 13.75rem;
  `,
);

export const Menu: FC<MenuProps> = memo(
  ({ id, children, open, anchorEl, onClose, ...props }) => {
    const popperRef = useRef<HTMLDivElement>(null);
    const menuActions = useRef<MenuUnstyledActions>(null);

    const handleClose = useCallback(() => {
      onClose();
      anchorEl?.focus();
    }, [anchorEl, onClose]);

    useKeyPressEvent('Escape', () => handleClose());
    useClickAway(popperRef, (e) => {
      const target = e.target as HTMLElement;

      if (!anchorEl?.contains(target)) {
        handleClose();
      }
    });

    const childrenWithProps = useMemo(
      () =>
        Children.map(children, (child) => {
          if (isValidElement(child)) {
            return cloneElement(child, {
              ...child.props,
              onClick: () => {
                child.props.onClick?.();
                handleClose();
              },
            });
          }
          return child;
        }),
      [children, handleClose],
    );

    return (
      <StyledMenu
        actions={menuActions}
        open={open}
        anchorEl={anchorEl}
        onClose={() => anchorEl?.focus()}
        components={{
          Root: (props) => (
            <Popper
              {...props}
              ref={popperRef}
              open={open}
              anchorEl={anchorEl}
            />
          ),
          Listbox: StyledListbox,
          ...props.components,
        }}
        componentsProps={{
          ...props.componentsProps,
          root: { placement: 'bottom-end', ...props.componentsProps?.root },
          listbox: {
            id: id,
            'aria-labelledby': anchorEl?.id,
            ...props.componentsProps?.listbox,
          },
        }}
        {...props}
      >
        {childrenWithProps}
      </StyledMenu>
    );
  },
);

Menu.displayName = 'Menu';
