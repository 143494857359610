import { RequestGenerateVisualExaminationVideoButtonProps } from 'pages/ExaminationPage/components/RequestGenerateVisualExaminationVideoButton/RequestGenerateVisualExaminationVideoButtonProps';
import { FC } from 'react';
import { useRequestGenerateThroatExaminationVideoMutation } from 'pages/ExaminationPage/components/RequestGenerateVisualExaminationVideoButton/hooks/useRequestGenerateThroatExaminationVideoMutation';

export const RequestGenerateThroatExaminationVideoButton: FC<RequestGenerateVisualExaminationVideoButtonProps> =
  ({ examinationId }) => {
    const { mutate, isSuccess } =
      useRequestGenerateThroatExaminationVideoMutation();

    return (
      <div
        style={{ width: '100%', height: '100%', lineHeight: '35px' }}
        onClick={() => (!isSuccess ? mutate(examinationId) : null)}
      >
        Full video
      </div>
    );
  };
