import { setLocale } from 'yup';

//todo: add other default messages when needed
// has to be called before each schema
export const setYupDefaultMessages = () =>
  setLocale({
    mixed: {
      required: {
        key: 'validation.required',
      },
    },
    string: {
      email: {
        key: 'validation.valid',
      },
      max: ({ max }) => ({
        key: 'validation.maxLength',
        values: { max },
      }),
    },
  });
