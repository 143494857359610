import { ExaminationTOCList } from '@higo/common/src/features/ExaminationPage/components/ExaminationPageFloatingDiagnosisWindow/ExaminationTOCList';
import styled, { css } from 'styled-components';
import { FloatingDiagnosisWindowProps } from '@higo/common/src/features/ExaminationPage/components/ExaminationPageFloatingDiagnosisWindow/floatingDiagnosisWindowProps';
import { FC } from 'react';

const Container = styled('div')(
  ({ theme }) => css`
    position: sticky;
    top: calc(17.75rem + 30%);
    transform: translateY(-50%);
    background-color: ${theme.palette.secondary['500']};
  `,
);

const ContainerTablet = styled(Container)`
  @media print {
    display: none;
  }

  right: 0;
  width: 3rem;
  height: 20.65rem;
  padding: 0.5rem;
  border-radius: 16px 0 0 16px;
`;

export const FloatingDiagnosisWindowTablet: FC<FloatingDiagnosisWindowProps> =
  ({ availableExaminationPageSections }) => (
    <ContainerTablet>
      <ExaminationTOCList
        availableExaminationPageSections={availableExaminationPageSections}
      />
    </ContainerTablet>
  );
