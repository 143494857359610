import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Heading, WarningIcon } from '@higo/ui';
import { FC, ReactNode } from 'react';

const Container = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    color: ${theme.palette.secondary['500']};
    font-size: 0.8125rem;
    max-width: 100%;
    margin-top: 10rem;
  `,
);

export interface TableInfoCardProps {
  icon?: ReactNode;
  title?: ReactNode;
  helperText?: ReactNode;
}

export const TableInfoCard: FC<TableInfoCardProps> = ({
  icon,
  title,
  helperText,
}) => (
  <Container id="table-info-card">
    {icon}
    <div id="info-card-text">
      <Heading level={4}>{title}</Heading>
    </div>
    {helperText && <div id="helper-text">{helperText}</div>}
  </Container>
);

export const TableNoDataAvailable: FC<Omit<TableInfoCardProps, 'icon'>> = (
  props,
) => (
  <TableInfoCard
    title={
      props.title ? props.title : <FormattedMessage id="noDataAvailable" />
    }
    {...props}
  />
);

export const TableDataError: FC<Omit<TableInfoCardProps, 'icon'>> = (props) => (
  <TableInfoCard
    title={<FormattedMessage id="dataFetchError.title" />}
    helperText={<FormattedMessage id="dataFetchError.helperText" />}
    icon={<WarningIcon size={64} />}
    {...props}
  />
);
