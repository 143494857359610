import styled, { css } from 'styled-components';

export const ConstraintContainer = styled('div')(
  ({ theme }) => css`
    margin: 0 auto;
    width: 100%;
    max-width: 90rem; // 1440px
    padding: 0 2.5rem;
    ${theme.media.desktop.max} {
      padding: 0 1.25rem;
    }
  `,
);
