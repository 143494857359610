import { css } from 'styled-components';

export const createAspectRatio = (w: number, h: number) => css`
  aspect-ratio: ${w} / ${h};
  display: flex;

  @supports not (aspect-ratio: ${w} / ${h}) {
    &::before {
      float: left;
      padding-top: ${(h / w) * 100}%;
      content: '';
    }

    &::after {
      display: block;
      content: '';
      clear: both;
    }
  }
`;
