import { Text } from '@higo/ui';
import { FormattedMessage } from 'react-intl';
import { appConfig } from 'config/appConfig';

// todo: extract to common add app config provider
export const AppVersion = () => (
  <Text variant="microtext" id="version-number">
    <FormattedMessage
      id="system.version"
      values={{ version: appConfig.version }}
    />
  </Text>
);
