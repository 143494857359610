import { generateUtilityClass, generateUtilityClasses } from '@mui/base';

const componentName = 'HigoInputLabel';
export const formLabelClasses = generateUtilityClasses(componentName, [
  // 'root',
  // 'focused',
  'disabled',
  // 'error',
  // 'required',
  // 'asterisk',
  // 'formControl',
  'sizeSmall',
  // 'shrink',
  // 'animated',
  // 'standard',
  // 'filled',
  // 'outlined',
]);

export function getFormLabelUtilityClasses(slot: string): string {
  return generateUtilityClass(componentName, slot);
}
