import { IconBox, IconProps } from '../IconBox';

const SurveyIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.125 5.4375C10.125 4.91974 10.5447 4.5 11.0625 4.5H12.9375C13.4553 4.5 13.875 4.91974 13.875 5.4375C13.875 5.95527 13.4553 6.37501 12.9375 6.37501H11.0625C10.5447 6.37501 10.125 5.95527 10.125 5.4375ZM8.25001 5.43745C7.21447 5.43745 6.375 6.27692 6.375 7.31245V17.625C6.375 18.6606 7.21447 19.5 8.25001 19.5H15.75C16.7856 19.5 17.625 18.6606 17.625 17.625V7.31245C17.625 6.27692 16.7856 5.43745 15.75 5.43745C15.75 6.99075 14.4909 8.24996 12.9375 8.24996H11.0625C9.50922 8.24996 8.25001 6.99075 8.25001 5.43745ZM8.25004 12C8.25004 11.4822 8.66978 11.0625 9.18755 11.0625H9.19692C9.71469 11.0625 10.1344 11.4822 10.1344 12C10.1344 12.5178 9.71469 12.9375 9.19692 12.9375H9.18755C8.66978 12.9375 8.25004 12.5178 8.25004 12ZM11.0626 12C11.0626 11.4822 11.4823 11.0625 12.0001 11.0625H14.8126C15.3304 11.0625 15.7501 11.4822 15.7501 12C15.7501 12.5178 15.3304 12.9375 14.8126 12.9375H12.0001C11.4823 12.9375 11.0626 12.5178 11.0626 12ZM8.25004 15.7501C8.25004 15.2323 8.66978 14.8126 9.18755 14.8126H9.19692C9.71469 14.8126 10.1344 15.2323 10.1344 15.7501C10.1344 16.2678 9.71469 16.6876 9.19692 16.6876H9.18755C8.66978 16.6876 8.25004 16.2678 8.25004 15.7501ZM11.0626 15.7501C11.0626 15.2323 11.4823 14.8126 12.0001 14.8126H14.8126C15.3304 14.8126 15.7501 15.2323 15.7501 15.7501C15.7501 16.2678 15.3304 16.6876 14.8126 16.6876H12.0001C11.4823 16.6876 11.0626 16.2678 11.0626 15.7501Z"
    />
  </IconBox>
);

export default SurveyIcon;
