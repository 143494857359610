import { StyleProps } from '@summer/jst-react';
import styled from 'styled-components';
import { PlayPauseButton } from './PlayPauseButton';
import { FC, ReactNode } from 'react';

const Content = styled.div`
  display: grid;
  grid-template-columns: 2rem 1fr;
  align-items: center;
  grid-column-gap: 0.75rem;
  height: 2rem;
`;

export type MissingAudioTrackProps = {
  label?: ReactNode;
  id?: string;
} & StyleProps;

export const MissingAudioTrack: FC<MissingAudioTrackProps> = ({
  label,
  id,
  ...props
}) => (
  <Content {...props}>
    <PlayPauseButton
      id={`playbutton-${id}`}
      isPlaying={false}
      disabled={true}
    />
    <div id={`label-missing-audiotrack-${id}`}>{label}</div>
  </Content>
);
