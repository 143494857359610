import { DefaultTheme } from 'styled-components';
import { colors } from './colors';
import { lightTheme } from './lightTheme';

const palette = {
  text: '#FFFFFF',
  primary: colors.blue,
  secondary: colors.grey,
  neutral: colors.neutral,
  pro: colors.pro,
  white: '#FFFFFF',
  success: '#00B818',
  warning: '#FFBF00',
  error: '#E83635',
  background: colors.grey['500'], //secondary['500']
};

export const darkTheme: DefaultTheme = {
  ...lightTheme, //todo: for now as a workaround
  palette,
  formLabel: {
    default: {
      primary: palette.white,
    },
    disabled: {
      primary: palette.secondary['300'],
    },
  },
  formHelperText: {
    default: {
      primary: palette.white,
    },
    error: {
      primary: palette.white,
    },
  },
  input: {
    default: {
      primary: palette.white, //text
      background: palette.background,
      placeholder: palette.secondary['200'], // placeholder color
      border: palette.secondary['200'],
    },
    focused: {
      border: palette.primary['500'],
      placeholder: palette.white,
    },
    hover: {
      border: palette.white,
    },
    disabled: {
      primary: palette.secondary['400'],
      background: palette.neutral['300'],
      placeholder: palette.secondary['400'], // placeholder color
      border: palette.background,
    },
    error: {
      border: palette.warning,
    },
  },
};
