import { pipe } from 'fp-ts/function';
import { Dictionary, DictionarySelector } from '@summer/jst-dictionaries';
import { useDictionaryContext } from '@summer/jst-react-dictionaries';
import { getOrElse } from 'fp-ts/Option';

export const useDictionaryMap = <
  T = string,
  U = null,
  Z extends Dictionary | any[] | unknown = [],
>(
  selector: DictionarySelector<T, U, Z>,
) =>
  pipe(
    useDictionaryContext(),
    selector,
    getOrElse(() => ({} as Dictionary<T, U, Z>)),
  );
