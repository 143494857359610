import {
  Dialog,
  DialogActions,
  DialogIcon,
  DialogTitle,
} from '@higo/ui/lib/components';
import { Button } from '@higo/ui';
import { FormattedMessage } from 'react-intl';
import React, { FC, ReactNode } from 'react';

export type ExaminationPageUnsavedDiagnosisPromptDialogProps = {
  open: boolean;
  saveDraftSlot: ReactNode;
  onClose: () => void;
  onConfirmLeave: () => void;
};
export const ExaminationPageUnsavedDiagnosisPromptDialog: FC<ExaminationPageUnsavedDiagnosisPromptDialogProps> =
  ({ open, onConfirmLeave, saveDraftSlot, onClose }) => (
    <Dialog open={open} onClose={onClose}>
      <DialogIcon type="error" />
      <DialogTitle>Exit without saving changes?</DialogTitle>
      <DialogActions>
        {saveDraftSlot}
        <Button color="danger" autoWidth onClick={onConfirmLeave}>
          <FormattedMessage id="common.yes" />
        </Button>
      </DialogActions>
    </Dialog>
  );
