import {
  mapPaginatedListContent,
  PaginatedListReqDTO,
  PaginatedListResultDTO,
  PatientMessageDTO,
} from '@higo/api/src/models';
import { flatten } from '@higo/api/src/utils';
import { stringify } from 'qs';
import { AxiosInstance } from 'axios';
import {
  mapToPatientUnreadMessage,
  PatientUnreadMessageDTO,
} from '@higo/api/src/models/patientUnreadMessageDTO';
import { ListWrapperDTO, unwrap } from '@higo/api/src/ext/listWrapper';
import {
  CreateExamCommentDTO,
  ExaminationCommentDTO,
  mapExaminationCommentsData,
} from '@higo/api/src/models/examinationComments';

export const fetchPatientsMessages =
  (axios: AxiosInstance) => (pageData?: PaginatedListReqDTO) =>
    axios
      .get<PaginatedListResultDTO<PatientMessageDTO>>(
        `/api/v2/messages/patients?${stringify(pageData, {
          allowDots: true,
        })}`,
      )
      .then(flatten);

export const fetchUnreadPatientsMessages =
  (axios: AxiosInstance) => (pageData?: PaginatedListReqDTO) =>
    axios
      .get<PaginatedListResultDTO<PatientUnreadMessageDTO>>(
        `/api/v2/messages/unread/patients?${stringify(pageData, {
          allowDots: true,
        })}`,
      )
      .then(flatten)
      .then(mapPaginatedListContent(mapToPatientUnreadMessage));

export const createExaminationComment =
  (axios: AxiosInstance) =>
  (examinationId: number, data: CreateExamCommentDTO) =>
    axios
      .post<ExaminationCommentDTO>(
        `/api/examinations/${examinationId}/comments`,
        data,
      )
      .then(flatten);

export const markExaminationCommentsAsRead =
  (axios: AxiosInstance) => (examinationId: number) =>
    axios.put(`/api/examinations/${examinationId}/comments/read`, {});

export const fetchExaminationComments =
  (axios: AxiosInstance) => (examinationId: number) =>
    axios
      .get<ListWrapperDTO<ExaminationCommentDTO>>(
        `/api/examinations/${examinationId}/comments`,
      )
      .then(flatten)
      .then(unwrap)
      .then(mapExaminationCommentsData);
