import { FC, useCallback, useMemo, useState } from 'react';
import { RequestFrameOptions } from '@higo/common/src/ext/VideoJs/controls/requestFrame';
import { VisualExaminationDiagnosticFrameModal } from '@higo/common/src/features/ExaminationPage/components/VisualExaminationDiagnosticViewer/VisualExaminationDiagnosticFrameModal';
import { VideoPlayer } from '@higo/common/src/components/VideoPlayer';

export type VisualExaminationDiagnosticVideoProps = {
  videoUrl: string;
  frameFileIds: number[];
};

const getFrameIndexBasedOnElapsedTime = (s: number) =>
  Math.round((s * 1000) / 200); // multiply by 5 (1000msc divide by 200ms as 5fps)

export const VisualExaminationDiagnosticVideo: FC<VisualExaminationDiagnosticVideoProps> =
  ({ videoUrl, frameFileIds }) => {
    const [frameFileId, setFrameFileId] = useState(NaN);
    const clearFrameFileId = useCallback(() => setFrameFileId(NaN), []);

    const options = useMemo(
      () => ({
        controlBar: {
          children: [
            { name: 'SeekBackward', seekBy: 0.20001 },
            'playToggle',
            { name: 'SeekForward', seekBy: 0.20001 },
            'progressControl',
            'remainingTimeDisplay',
            'fullscreenToggle',
          ],
        },
      }),
      [frameFileIds],
    );

    return (
      <>
        <VisualExaminationDiagnosticFrameModal
          frameFileId={frameFileId}
          onClose={clearFrameFileId}
        />
        <VideoPlayer options={options} videoUrl={videoUrl} fluid={false} />
      </>
    );
  };
