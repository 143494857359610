import { useQuery } from 'react-query';
import { fetchFile } from '@higo/api/lib/services/fileResourceService';
import { isNil } from 'rambda';
import { useExaminationApiContext } from '@higo/common/src/features/ExaminationPage/providers';
import { ExaminationQueryKey } from '@higo/common/src/features/ExaminationPage/ExaminationQueryKey';

export const useExaminationFileResourceQuery = (
  fileId?: number,
  isEnabled: boolean = true,
) => {
  const axios = useExaminationApiContext();

  return useQuery(
    [ExaminationQueryKey.SecuredFileResource, fileId],
    () => fetchFile(axios)(fileId),
    {
      enabled: !isNil(fileId) && isEnabled,
      staleTime: Infinity,
    },
  );
};
