import { useMemo } from 'react';
import { QuestionAnswerOption } from '@higo/common/src/features/FilledSurvey/model';
import { Predicate } from 'rambda';

export const useRenderableSelectedAnswers = (
  selectedAnswers: QuestionAnswerOption[],
  predicate?: Predicate<QuestionAnswerOption>,
) =>
  useMemo(
    () => (predicate ? selectedAnswers.filter(predicate) : selectedAnswers),
    [selectedAnswers, predicate],
  );
