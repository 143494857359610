/* eslint-disable  @typescript-eslint/no-explicit-any */
//todo: Fix model names, and any value type

export enum SurveyQuestionTag {
  PatientCondition = 'PATIENT_CONDITION',
  Ailments = 'AILMENTS',
  Other = 'OTHER',

  ChronicDiseases = 'CHRONIC_DISEASES',
  ExamReason = 'EXAM_REASON',

  AnamnesisMedicaments = 'A_MEDICAMENTS',
  GeneralMedicaments = 'G_MEDICAMENTS',

  Height = 'HEIGHT',
  Weight = 'WEIGHT',
  Allergies = 'ALLERGIES',

  Covid = 'COVID',

  Ear = 'EAR',
  Throat = 'THROAT',
  Temperature = 'TEMPERATURE',
  Heart = 'HEART',
  HeartRate = 'HEART_RATE',
  Skin = 'SKIN',
  Lungs = 'LUNGS',
  Cough = 'COUGH',
  Abdominal = 'ABDOMINAL',
  PhysicalExaminationSurveyIdent = 'PHYSICAL_EXAMINATION_SURVEY_IDENT',

  ForMen = 'FOR_MEN',
  ForOther = 'FOR_OTHER',
  ForUnknown = 'FOR_UNKNOWN',
  ForWomen = 'FOR_WOMEN',
  OnlyMen = 'ONLY_MEN',
  OnlyWomen = 'ONLY_WOMEN',
}

export enum SurveyAnswerTag {
  Menopause = 'MENOPAUSE',
  Pregnancy = 'PREGNANCY',
  Cigaretes = 'CIGARETTES',

  ForMen = 'FOR_MEN',
  ForOther = 'FOR_OTHER',
  ForUnknown = 'FOR_UNKNOWN',
  ForWomen = 'FOR_WOMEN',
  OnlyMen = 'ONLY_MEN',
  OnlyWomen = 'ONLY_WOMEN',
}

export type SurveyAgeConditionOperator =
  | 'EQ'
  | 'GE'
  | 'GT'
  | 'LE'
  | 'LT'
  | 'NE';

export type SurveyGenderTagType =
  | 'FOR_MEN'
  | 'FOR_OTHER'
  | 'FOR_UNKNOWN'
  | 'FOR_WOMEN'
  | 'ONLY_MEN'
  | 'ONLY_WOMEN';

export enum SurveyGenderTag {
  ForMen = 'FOR_MEN',
  ForOther = 'FOR_OTHER',
  ForUnknown = 'FOR_UNKNOWN',
  ForWomen = 'FOR_WOMEN',
  OnlyMen = 'ONLY_MEN',
  OnlyWomen = 'ONLY_WOMEN',
}

export enum SurveyType {
  General = 'G',
  GeneralKids = 'GK',
  AnamnesisKids = 'AK',
}

export enum QuestionType {
  OPEN = 'O',
  MULTIPLE_CLOSED = 'M',
  SINGLE_CLOSED = 'S',
}

export enum OpenAnswerType {
  TEXT = 'TEXT',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  FILE = 'FILE',
  INTEGER = 'INTEGER',
  BIG_DECIMAL = 'BIG_DECIMAL',
}

export enum AnswerTriggeredActionType {
  Intervention = 'INTERVENTION',
  StartLoop = 'START_LOOP',
  FinishLoop = 'FINISH_LOOP',
}

export interface AnswerTriggeredActionDTO {
  id: number;
  message: string;
  type: AnswerTriggeredActionType;
}

export interface AnswerEnableQuestionDTO {
  answerCode: string;
  answerId: number;
}

export interface QuestionAnswerDTO {
  ageConditions: SurveyAgeConditionsDTO;
  code: string;
  id: number;
  isExcluding: boolean;
  answerTags: SurveyAnswerTag[];
  triggeredAction?: AnswerTriggeredActionDTO;
  userFriendlyLabel?: string;
  value: string;
}

export interface UserSurveyAnswerDTO {
  answer?: string;
  answersId?: number[];
  questionId: number;
}

export interface QuestionWithUserAnswerDTO {
  surveyQuestionDTO: SurveyQuestionDTO;
  userSurveyAnswerDTO: UserSurveyAnswerDTO;
}

export interface UserAnsweredSurveyDTO {
  id: number;
  createDate: string;
  surveyId: number;
  surveyType: SurveyType;
  patientId: number;
  name: string;
  lang: string;
  questionsWithUserAnswers: QuestionWithUserAnswerDTO[];
}

export enum SurveyAgeConditionsConjunction {
  And = 'AND',
  Or = 'OR',
}
export interface SurveyAgeConditionOptionDTO {
  age: number;
  operator: SurveyAgeConditionOperator;
}

export interface SurveyAgeConditionsDTO {
  ageConditionOptions: SurveyAgeConditionOptionDTO[];
  conjunction: SurveyAgeConditionsConjunction;
}

export interface SurveyQuestionDTO {
  ageConditions: SurveyAgeConditionsDTO;
  answerEnableQuestions: AnswerEnableQuestionDTO[];
  code: string;
  conditionalActions: any[];
  id: number;
  level: number;
  loopLevel?: number;
  numericQuestionUnit?: any;
  openAnswerType: OpenAnswerType;
  questionAnswers: QuestionAnswerDTO[];
  questionType: QuestionType;
  surveyQuestionTags: SurveyQuestionTag[]; // in clarification
  userFriendlyLabel?: string;
  value: any; // ??
}

export interface SurveyDTO {
  id: number;
  createDate: string;
  surveyId: number;
  surveyType: SurveyType;
  patientId: number;
  name: string;
  lang: string;
  questionsWithUserAnswers: QuestionWithUserAnswerDTO[];
}

export interface SurveyAilments {
  code: string;
  id: number;
  value: string;
}
