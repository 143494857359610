export enum ExaminationPageSectionId {
  ExaminationContext = 'examinationContext',
  VitalSigns = 'vitalSigns',
  AnamnesisSurvey = 'anamnesisSurvey',
  Skin = 'skin',
  Heart = 'heart',
  Lungs = 'lungs',
  Cough = 'cough',
  Ears = 'ears',
  Throat = 'throat',
  Abdominal = 'abdominal',
  Diagnosis = 'diagnosis',
}
