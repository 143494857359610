import { StyleProps } from '@summer/jst-react';
import clsx from 'clsx';
import { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';

export interface SkeletonProps extends StyleProps {
  fill?: boolean;
}

interface SkeletonComponentProps {
  $fill?: boolean;
}

const loading = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const SkeletonComponent = styled('div')<SkeletonComponentProps>(
  ({ $fill }) => css`
    --skeleton-base-color: #e0e0e0;
    --skeleton-highlight-color: #f2f2f2;

    background-color: var(--skeleton-base-color);

    display: flex;
    line-height: 1;

    border-radius: 0.35rem;
    position: relative;
    overflow: hidden;
    z-index: 1;

    width: 100%;
    height: 100%;
    ${$fill &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    `};

    & + & {
      margin-top: 0.25rem;
    }

    &::after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background: linear-gradient(
        90deg,
        var(--skeleton-base-color),
        var(--skeleton-highlight-color),
        var(--skeleton-base-color)
      );
      transform: translateX(-100%);

      animation-name: ${loading};
      animation-duration: 1.5s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    }
  `,
);

export const Skeleton: FC<SkeletonProps> = ({ fill, className, ...props }) => (
  <SkeletonComponent
    $fill={fill}
    className={clsx(className, 'skeleton')}
    {...props}
  />
);
