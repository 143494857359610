import { ElementType, FC } from 'react';
import styled, { FontSize } from 'styled-components';

export interface TitleProps {
  level: 1 | 2 | 3 | 4 | 5;
}

const levelFontSizeMap: Record<TitleProps['level'], FontSize> = {
  1: 'xxl',
  2: 'xl',
  3: 'l',
  4: 'm',
  5: 's',
};

const HeadingBase: FC<TitleProps & { as?: ElementType }> = ({
  children,
  level,
  as: Component = `h${level}`,
  ...rest
}) => <Component {...rest}>{children}</Component>;

export const Heading = styled(HeadingBase)`
  font-weight: 700;
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: ${({ theme, level }) => theme.fontSize[levelFontSizeMap[level]]};
`;
