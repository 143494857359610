import { ExaminationDTOStatus } from '@higo/api/src/models/examinationDTO';
import { ExaminationPatientDTOPatientType } from '@higo/api/src/models/examinationPatientDTO';
import { ExaminationType } from './examinationDetailsDTO';
import { ExaminationDoctorDTO } from '@higo/api/src/models/examinationDoctorDTO';
import { evolve } from 'rambda';
import { parseJSON } from 'date-fns';

export interface PatientExaminationsDTO {
  // boardQuestion: QuestionWithUserAnswerDTO;
  createDate: string;
  doctor: ExaminationDoctorDTO;
  examinationId: number;
  patientType: ExaminationPatientDTOPatientType;
  status: ExaminationDTOStatus;
  type: ExaminationType;
  anamnesisSurveyFilled: boolean;
}

// todo
export enum PatientExaminationListSortByField {
  EXAM_CREATE_DATE = 'EXAM_CREATE_DATE',
  EXAM_TYPE = 'EXAM_TYPE',
  HIGO_MODE = 'HIGO_MODE',
  DOCTOR_FIRSTNAME = 'DOCTOR_FIRSTNAME',
  DOCTOR_LASTNAME = 'DOCTOR_LASTNAME',
  DIAGNOSIS = 'DIAGNOSIS',
  EXAM_STATUS = 'EXAM_STATUS',
}

export type PatientExaminations = Omit<PatientExaminationsDTO, 'createDate'> & {
  createDate: Date;
};

export const mapToPatientExaminations: (
  a: PatientExaminationsDTO,
) => PatientExaminations = evolve({
  createDate: parseJSON,
});
