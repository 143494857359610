import { useQuery } from 'react-query';
import { fetchSkinExaminationResults } from '@higo/api/lib/services/physicalExaminationService';
import { useExaminationApiContext } from '@higo/common/src/features/ExaminationPage/providers';
import { ExaminationQueryKey } from '@higo/common/src/features/ExaminationPage/ExaminationQueryKey';
import { SkinExaminationDTO } from '@higo/api/lib/models/examinationResultsDTO';

export const useSkinExaminationResultsQuery = <TData = SkinExaminationDTO>(
  id: number,
  select?: (data: SkinExaminationDTO) => TData,
) => {
  const axios = useExaminationApiContext();

  return useQuery(
    [ExaminationQueryKey.SkinExaminationResults, id],
    () => fetchSkinExaminationResults(axios)(id),
    {
      enabled: !isNaN(id),
      select,
    },
  );
};
