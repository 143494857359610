import { FC, ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export type InfoBarLabeledValueProps = {
  label: ReactNode;
  id?: string;
};
export const InfoBarLabeledValue: FC<InfoBarLabeledValueProps> = ({
  label,
  children,
  id,
}) => (
  <Container>
    <span id={`${id}-label`}>{label}:</span>
    <b id={`${id}-value`}>{children ?? '-/-'}</b>
  </Container>
);
