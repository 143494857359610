import { FC, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { isNil } from 'rambda';
import { CloseIcon, IconButton, LinkButton } from '@higo/ui';
import { StorageKey } from 'config/storageKey';
import { appConfig } from 'config/appConfig';
import { FormattedMessage } from 'react-intl';

const FixedContainer = styled('div')(
  ({ theme }) => css`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${theme.layers.cookiebar};
    border-top: 1px solid ${theme.palette.neutral['400']};
    padding: 1.5rem;
    min-height: 5rem;
    background: ${theme.palette.white};
  `,
);

const CookieWrapper = styled('div')(
  () => css`
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 auto;
    max-width: 61.25rem;
  `,
);

const CookieMessage = styled('div')(
  ({ theme }) => css`
    ${theme.typography.body};
    color: ${theme.palette.secondary['400']};
  `,
);

const CloseButton = styled(IconButton)`
  margin-left: 1rem;
  min-width: 1.5rem;
  max-width: 1.5rem;
  height: 1.5rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
`;

const Link = styled(LinkButton).attrs({ as: 'a', target: '_blank' })`
  color: ${({ theme }) => theme.palette.primary['500']};
`;

export const CookieInfo: FC = () => {
  const [visible, setIsVisible] = useState(
    isNil(localStorage.getItem(StorageKey.CookiePolicyDismissed)),
  );

  const handleCloseClick = useCallback(() => {
    setIsVisible(false);
    localStorage.setItem(
      StorageKey.CookiePolicyDismissed,
      JSON.stringify(true),
    );
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <FixedContainer>
      <CookieWrapper>
        <CookieMessage>
          <FormattedMessage
            id="cookie.message"
            values={{
              termsAndConditionsLink: (
                <Link href={appConfig.termsAndConditionsLink}>
                  <FormattedMessage id="cookie.termsAndCondition" />
                </Link>
              ),
              privacyPolicyLink: (
                <Link href={appConfig.privacyPolicyLink}>
                  <FormattedMessage id="cookie.privacyPolicy" />
                </Link>
              ),
            }}
          />
        </CookieMessage>
        <CloseButton
          onClick={handleCloseClick}
          icon={<CloseIcon />}
          size="small"
          color="secondary"
        />
      </CookieWrapper>
    </FixedContainer>
  );
};
