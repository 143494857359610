import { FC, useEffect } from 'react';
import { useQuery } from 'react-query';
import { ApiQueryKey, fetchDictionary } from '@higo/api';
import { DictionaryProvider } from '@summer/jst-react-dictionaries';
import { AxiosInstance } from 'axios';

export interface DictionaryProviderProps {
  axios: AxiosInstance;
  languageCode: string;
}

export const useAsyncDictionaryState = () => {
  const { isLoading, isFetching } = useQuery(ApiQueryKey.Dictionary, {
    enabled: false,
  });

  return {
    isLoading,
    isFetching,
  };
};

/**
 * App scope AsyncDictionaryProvider (not one from jst-react-dictionaries)
 */
export const AsyncDictionaryProvider: FC<DictionaryProviderProps> = ({
  axios,
  languageCode,
  children,
}) => {
  const { data, refetch } = useQuery(
    ApiQueryKey.Dictionary,
    fetchDictionary(axios),
  );

  useEffect(() => {
    refetch();
  }, [languageCode, refetch]);

  return (
    <DictionaryProvider dictionary={data ?? {}}>{children}</DictionaryProvider>
  );
};
