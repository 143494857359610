import { IconBox, IconProps } from '../IconBox';

const RoundSkinIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.973 24c6.613 0 11.973-5.373 11.973-12S18.586 0 11.973 0C5.361 0 0 5.373 0 12s5.36 12 11.973 12Zm-3.378-9.807c0 2.257 1.845 4.093 4.112 4.093 2.268 0 4.113-1.836 4.113-4.093v-3.655c0-2.66-2.175-4.824-4.847-4.824-2.672 0-4.846 2.164-4.846 4.824v3.655a.44.44 0 0 0 .881 0v-3.655c0-2.176 1.779-3.947 3.965-3.947 2.187 0 3.965 1.77 3.965 3.947v3.655a3.227 3.227 0 0 1-3.23 3.216 3.227 3.227 0 0 1-3.231-3.216v-3.655c0-1.37 1.12-2.485 2.496-2.485a2.494 2.494 0 0 1 2.497 2.485v4.02c0 .564-.461 1.023-1.028 1.023s-1.028-.459-1.028-1.023v-4.02a.44.44 0 0 0-.881 0v4.02a.293.293 0 0 1-.588 0v-4.02c0-.564.461-1.023 1.028-1.023s1.028.46 1.028 1.023v4.02a.44.44 0 0 0 .881 0v-4.02c0-1.048-.856-1.9-1.909-1.9s-1.909.852-1.909 1.9v3.655a2.64 2.64 0 0 0 2.643 2.63 2.64 2.64 0 0 0 2.644-2.63v-3.655a3.374 3.374 0 0 0-3.378-3.362 3.374 3.374 0 0 0-3.378 3.362v3.655Z"
    />
  </IconBox>
);

export default RoundSkinIcon;
