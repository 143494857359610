import {
  differenceInYears,
  differenceInMonths,
  parseJSON,
  parse,
  isValid,
} from 'date-fns';
import { UnitValue, UnitValueType } from '@higo/common/src/types';

const coerceDate = (date: Date | string) => {
  if (typeof date === 'string') {
    const possibleDate = parseJSON(date);

    return isValid(possibleDate)
      ? possibleDate
      : parse(date, 'yyyy-MM-dd', new Date());
  }
  return date as Date;
};

export const getAgeInYears = (date: Date | string) =>
  differenceInYears(new Date(), coerceDate(date));

export const getAgeInMonths = (date: Date | string) =>
  differenceInMonths(new Date(), coerceDate(date));

export const getAge = (date: Date | string): UnitValue => {
  const yearsAge = getAgeInYears(date);

  return {
    value: yearsAge > 0 ? yearsAge : getAgeInMonths(date),
    unit: yearsAge > 0 ? UnitValueType.Years : UnitValueType.Months,
  };
};
