export interface ApiErrorItem {
  errorCode: string;
  message: string;
  fieldErrorList?: ApiFieldError[]; // legacy property?
}

export interface ApiFieldError {
  field: string;
  message: string;
}

export interface ApiError {
  errors: ApiErrorItem[];
}

export interface OAuthError {
  error: string;
  error_description: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isOAuthError(a: any): a is OAuthError {
  return a.hasOwnProperty('error') && a.hasOwnProperty('error_description');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isApiError(a: any): a is ApiError {
  return a.hasOwnProperty('errors') && Array.isArray((a as ApiError).errors);
}

export interface TranslatedError {
  data?: unknown;
  message?: string | string[];
  originalError?: Error;
}
