import { Heading, Text } from '@higo/ui';
import {
  SetNewPasswordForm,
  SetNewPasswordFormModel,
} from './SetNewPasswordForm';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from 'routes';
import { useSetNewPasswordMutation } from './useSetNewPasswordMutation';

const StyledText = styled(Text)`
  margin-bottom: 1.5rem;
`;

export type SetNewPasswordPageParams = {
  token: string;
};

export const SetNewPasswordPage = () => {
  const { token } = useParams<SetNewPasswordPageParams>();
  const navigate = useNavigate();
  const mutation = useSetNewPasswordMutation();

  if (!token) {
    navigate(routes.root);
    return null;
  }

  const requestSetNewPassword = ({
    password,
    passwordConfirmation,
  }: SetNewPasswordFormModel) =>
    mutation
      .mutateAsync({
        newPassword: password,
        repeatNewPassword: passwordConfirmation,
        token,
      })
      .then(() => {
        navigate(routes.root);
      });

  return (
    <>
      <Heading level={1}>
        <FormattedMessage id="authentication.setNewPassword.newPassword" />
      </Heading>
      <StyledText variant="body2">
        <FormattedMessage id="authentication.setNewPassword.description" />
      </StyledText>
      <SetNewPasswordForm onSubmit={requestSetNewPassword} />
    </>
  );
};
