import { ExaminationDTOStatus } from '@higo/api/src/models/examinationDTO';
import {
  ExaminationDiagnosis,
  ExaminationDiagnosisDTO,
  mapToExaminationDiagnosis,
} from '@higo/api/src/models/examinationDiagnosisDTO';
import { ExaminationDoctorDTO } from '@higo/api/src/models/examinationDoctorDTO';
import { ExaminationPatientDTO } from '@higo/api/src/models/examinationPatientDTO';
import { evolve } from 'rambda';
import { parseJSON } from 'date-fns';
import { safeEvolveProp } from '@higo/api/src/utils';

export interface AssignedExaminationDTO {
  id: number;
  createDate: string;
  patient: ExaminationPatientDTO;
  previousDiagnosis?: ExaminationDiagnosisDTO;
  previousDoctor: ExaminationDoctorDTO;
  status: ExaminationDTOStatus;
  anamnesisSurveyFilled: boolean;
}

export enum AssignedExaminationSortByField {
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  DEVICE_CREATE_DATE = 'DEVICE_CREATE_DATE',
  DEVICE_SERIAL_NUMBER = 'DEVICE_SERIAL_NUMBER',
  DIAGNOSE_DATE = 'DIAGNOSE_DATE',
  EXAM_CREATE_DATE = 'EXAM_CREATE_DATE',
  EXAM_END_SESSION_DATE = 'EXAM_END_SESSION_DATE',
  EXAM_STATUS = 'EXAM_STATUS',
  EXAM_UPDATE_DATE = 'EXAM_UPDATE_DATE',
  GENDER = 'GENDER',
  HIGO_MODE = 'HIGO_MODE',
  PATIENT_APPOINTMENT_DATE = 'PATIENT_APPOINTMENT_DATE',
  PATIENT_CREATE_DATE = 'PATIENT_CREATE_DATE',
  PATIENT_FIRST_NAME = 'PATIENT_FIRST_NAME',
  PATIENT_LAST_NAME = 'PATIENT_LAST_NAME',
  PREVIOUS_DOCTOR_LASTNAME = 'PREVIOUS_DOCTOR_LASTNAME',
}

export type AssignedExamination = Omit<
  AssignedExaminationDTO,
  'createDate' | 'previousDiagnosis'
> & {
  createDate: Date;
  previousDiagnosis?: ExaminationDiagnosis;
};

export const mapToAssignedExamination: (
  a: AssignedExaminationDTO,
) => AssignedExamination = evolve({
  createDate: parseJSON,
  previousDiagnosis: safeEvolveProp(mapToExaminationDiagnosis),
});
