import styled, { css, DefaultTheme } from 'styled-components';
import { FC } from 'react';
import { StyleProps } from '@summer/jst-react';

export interface TextProps {
  variant: 'default' | 'bold' | 'body2' | 'microtext';
  id?: string;
}

const textVariantStyles = (
  theme: DefaultTheme,
  variant: TextProps['variant'] = 'default',
) =>
  ({
    default: css`
      font-size: ${theme.fontSize.xs};
    `,
    bold: css`
      font-size: ${theme.fontSize.s};
    `,
    body2: css`
      font-size: ${theme.fontSize.s};
      font-weight: 400;
      line-height: 1.125rem;
    `,
    microtext: css`
      font-size: ${theme.fontSize.xs};
      font-weight: 600;
      line-height: 0.625rem;
      color: ${theme.palette.secondary['500']};
      padding: 0.5rem 0;
    `,
  }[variant]);

const TextBase = styled.p<{ $variant: TextProps['variant'] }>`
  padding: 0;
  margin: 0;

  ${({ theme }) =>
    css`
      font-family: ${theme.fontFamily.primary};
    `}

  ${({ theme, $variant }) => textVariantStyles(theme, $variant)}
`;

export const Text: FC<TextProps & StyleProps> = ({
  children,
  variant,
  id,
  ...props
}) => (
  <TextBase {...props} $variant={variant} id={id}>
    {children}
  </TextBase>
);
