import styled from 'styled-components';
import { AspectRatio } from '@higo/ui';
import { FC } from 'react';
import { StyleProps } from '@summer/jst-react';

const Container = styled.div`
  margin: 0 auto;
  position: relative;
  justify-content: center;
  display: grid;
  grid-template-columns: minmax(0, 42rem);
  max-width: 44rem;
`;

const Content = styled(AspectRatio)`
  max-width: 40rem;
  display: block;
`;

export const FullFrameDiagnosticPhotosGalleryContainer: FC<StyleProps> = ({
  children,
  ...props
}) => (
  <Container {...props} id="full-frame-gallery-container">
    <Content ratio="8x7">{children}</Content>
  </Container>
);
