import React, { FC, memo, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Heading, RHFTextField, WarningIcon } from '@higo/ui';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'rambda';
import { inputUnstyledClasses } from '@mui/base';
import {
  ExaminationPageSummaryFormField,
  useExaminationPageSummaryFormTranslations,
} from '@higo/common/src/features/ExaminationPage/sections/ExaminationPageSummarySection/examinationPageSummaryFormConfig';
import { PatientOverview } from '@higo/common/src/features/ExaminationPage/models';
import { ExaminationPageSectionId } from '@higo/common/src/features/ExaminationPage/models/examinationPageSectionId';
import { ExaminationPageSection } from '@higo/common/src/features/ExaminationPage/sections/ExaminationPageSection';

const WarningInfo = styled('div')(
  ({ theme }) => css`
    width: max-content;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 8px;
    background-color: ${theme.palette.warning};
    color: ${theme.palette.secondary['900']};

    ${theme.typography.body2}
  `,
);

const StyledWarningIcon = styled(WarningIcon)`
  margin-right: 0.5rem;
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: min-content 17.5rem;
  justify-content: space-between;
`;

export interface ExaminationPageSummarySectionProps {
  patientOverview: PatientOverview;
  isReadOnly?: boolean;
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DiagnosisRHFTextField = styled(RHFTextField)`
  & .${inputUnstyledClasses.input} {
    min-height: 4rem;
    resize: none;
  }
`;

export const ExaminationPageSummarySection: FC<ExaminationPageSummarySectionProps> =
  memo(({ patientOverview, isReadOnly = true, children }) => {
    const allergies = useMemo(
      () => patientOverview.allergies.map(({ value }) => value).toString(),
      [patientOverview],
    );

    const translations = useExaminationPageSummaryFormTranslations();

    return (
      <ExaminationPageSection
        sectionId={ExaminationPageSectionId.Diagnosis}
        title={<FormattedMessage id="examinationPage.section.summary.title" />}
      >
        <Content id="summary-section-content">
          <div id="summary-notes-container">
            <div id="summary-header">
              <Heading level={4}>
                {translations[ExaminationPageSummaryFormField.Notes].label}
              </Heading>
            </div>
            <DiagnosisRHFTextField
              readOnly={isReadOnly}
              disabled={isReadOnly}
              hiddenLabel
              id="notes-field"
              name={ExaminationPageSummaryFormField.Notes}
              placeholder={
                !isReadOnly
                  ? translations[ExaminationPageSummaryFormField.Notes]
                      .placeholder
                  : undefined
              }
              rows={4}
              multiline
            />
          </div>
          <div>
            <Heading level={4}>
              {translations[ExaminationPageSummaryFormField.Diagnosis].label}
            </Heading>
            <DiagnosisRHFTextField
              readOnly={isReadOnly}
              disabled={isReadOnly}
              hiddenLabel
              name={ExaminationPageSummaryFormField.Diagnosis}
              placeholder={
                !isReadOnly
                  ? translations[ExaminationPageSummaryFormField.Diagnosis]
                      .placeholder
                  : undefined
              }
              rows={4}
              multiline
            />
          </div>
          <div>
            <Heading level={4}>
              {translations[ExaminationPageSummaryFormField.Medicines].label}
            </Heading>
            {!isEmpty(patientOverview.allergies) && (
              <WarningInfo>
                <div id="warning-info">
                  <StyledWarningIcon />
                  <b id="allergies-text">
                    <FormattedMessage id={'common.allergies'} />:
                  </b>
                  {` ${allergies}`}
                </div>
              </WarningInfo>
            )}
            <DiagnosisRHFTextField
              readOnly={isReadOnly}
              disabled={isReadOnly}
              hiddenLabel
              name={ExaminationPageSummaryFormField.Medicines}
              placeholder={
                !isReadOnly
                  ? translations[ExaminationPageSummaryFormField.Medicines]
                      .placeholder
                  : undefined
              }
              rows={4}
              multiline
            />
          </div>
          <div>
            <Heading level={4}>
              {
                translations[ExaminationPageSummaryFormField.Recommendations]
                  .label
              }
            </Heading>
            <DiagnosisRHFTextField
              readOnly={isReadOnly}
              disabled={isReadOnly}
              hiddenLabel
              name={ExaminationPageSummaryFormField.Recommendations}
              placeholder={
                !isReadOnly
                  ? translations[
                      ExaminationPageSummaryFormField.Recommendations
                    ].placeholder
                  : undefined
              }
              rows={4}
              multiline
            />
          </div>
          {children && <ButtonsContainer>{children}</ButtonsContainer>}
        </Content>
      </ExaminationPageSection>
    );
  });
