import { ThemeProvider } from 'styled-components';
import { useEffect, useMemo } from 'react';
import { AppRouter } from 'AppRouter';
import { GlobalStyle, lightTheme } from '@higo/ui';
import { CookieInfo } from 'components/CookieInfo';
import { useGetAvailableLanguageCode } from 'hooks/useGetAvailableLanguageCode';
import 'react-toastify/dist/ReactToastify.min.css';
import { CustomToastContainer } from 'components/CustomToastContainer';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { useQueryClient } from 'react-query';
import { hydrateAPIOAuth2AccessToken } from 'services/apiOAuth2AccessTokenService';
import { useAsyncDictionaryState } from '@higo/common/lib/features/Dictionary';
import { useQueryStorage } from '@higo/common/lib/QueryStorage';
import { useAppLocale } from '@higo/common/lib/features/Locale';
import { useIdleDetector } from 'pages/LoginPage/hooks/useIdleDetector';
import { useWarnToastDetector } from 'pages/LoginPage/hooks/useWarnToastDetector';

const useHydrateQueryClient = () => {
  const queryClient = useQueryClient();
  const queryStorage = useQueryStorage();

  useMemo(() => {
    hydrateAPIOAuth2AccessToken(queryClient, queryStorage);
  }, [queryClient, queryStorage]);
};

export const App = () => {
  useHydrateQueryClient();
  useWarnToastDetector();
  useIdleDetector();

  const { isLoading: isDictionaryLoading } = useAsyncDictionaryState();
  const { changeLocale } = useAppLocale();
  const getAvailableLanguageCode = useGetAvailableLanguageCode();
  const { isAutoLogin } = useIsLoggedIn();

  useEffect(() => {
    !isAutoLogin && changeLocale(getAvailableLanguageCode());
    // todo: verify changeLocale implementation (might require rewrite to useRef)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoLogin]);

  if (isAutoLogin || isDictionaryLoading) {
    return <></>; // todo: add loader
  }

  // todo: add app veil on dictionary fetch ( other fetches)

  return (
    <ThemeProvider theme={lightTheme}>
      <GlobalStyle />
      <AppRouter />
      <CookieInfo />
      <CustomToastContainer hideProgressBar />
    </ThemeProvider>
  );
};

export default App;
