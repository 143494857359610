import styled, { css } from 'styled-components';

export const VerticalDivider = styled('div')(
  ({ theme }) => css`
    height: 100%;
    width: 2px;
    border-radius: 1px;
    background-color: ${theme.palette.neutral['500']};
  `,
);
