import { FcmMessageCode, FcmMessagePayloadData } from 'types/fcmMessage';
import EventEmitter from 'eventemitter3';
import { evolve } from 'rambda';

type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;

export enum AppEvent {
  FcmNewExam = 'FCM_NEW_EXAM',
  FcmNewComment = 'FCM_NEW_COMMENT',
  FcmExaminationTaken = 'FCM_EXAM_TAKEN_BY_OTHER_DOCTOR',
  FcmVideoCreated = 'FCM_VIDEO_CREATED',
  FcmVideoFailed = 'FCM_VIDEO_FAILED',
  FcmSurveyFilled = 'EXAMINATION_SURVEY_FILLED',
}

export type AppNewExamEventPayload = {
  code: 'NEW_EXAM';
  examId: number;
};

export type AppNewCommentEventPayload = {
  code: 'NEW_COMMENT';
  examId: number;
};

export type AppExaminationTakenEventPayload = {
  code: 'EXAM_TAKEN_BY_OTHER_DOCTOR';
  examId: number;
};

export type AppVideoCreatedPayload = {
  code: 'EXAMINATION_VIDEO_SUCCESSFULLY_CREATED';
  examId: number;
  status: string;
  type: string;
  user_id: string;
};

export type AppVideoFailedPayload = {
  code: 'EXAMINATION_VIDEO_CREATION_FAILED';
  examId: number;
  status: string;
  type: string;
  user_id: string;
};

export type AppSurveyFilledPayload = {
  code: 'EXAMINATION_SURVEY_FILLED';
  user_id: string;
};

export interface AppEventPayload {
  [AppEvent.FcmNewExam]: [AppNewExamEventPayload];
  [AppEvent.FcmNewComment]: [AppNewCommentEventPayload];
  [AppEvent.FcmExaminationTaken]: [AppExaminationTakenEventPayload];
  [AppEvent.FcmVideoCreated]: [AppVideoCreatedPayload];
  [AppEvent.FcmVideoFailed]: [AppVideoFailedPayload];
  [AppEvent.FcmSurveyFilled]: [AppSurveyFilledPayload];
}

export const fcmMessageCodeAppEventMap: PartialRecord<
  FcmMessageCode | string,
  EventEmitter.EventNames<AppEventPayload>
> = {
  [FcmMessageCode.NewExam]: AppEvent.FcmNewExam,
  [FcmMessageCode.NewComment]: AppEvent.FcmNewComment,
  [FcmMessageCode.ExamTakenByDoctor]: AppEvent.FcmExaminationTaken,
  [FcmMessageCode.VideoCreated]: AppEvent.FcmVideoCreated,
  [FcmMessageCode.VideoFailed]: AppEvent.FcmVideoFailed,
  [FcmMessageCode.SurveyFilled]: AppEvent.FcmSurveyFilled,
};

const parseIntValue = (v: string) => parseInt(v, 10);

const fcmDataAppEventPayloadDataMapper = {
  [AppEvent.FcmNewExam]: evolve({ examId: parseIntValue }),
  [AppEvent.FcmNewComment]: evolve({ examId: parseIntValue }),
  [AppEvent.FcmExaminationTaken]: evolve({ examId: parseIntValue }),
  [AppEvent.FcmVideoCreated]: evolve({ examId: parseIntValue }),
  [AppEvent.FcmVideoFailed]: evolve({ examId: parseIntValue }),
  [AppEvent.FcmSurveyFilled]: evolve({ examId: parseIntValue }),
};

// todo: require improved typing for remapping
export const fcmMessageDataToAppEventArgs = <
  T extends EventEmitter.EventNames<AppEventPayload>,
>(
  eventName: T,
  data: FcmMessagePayloadData,
) => {
  const mapper = fcmDataAppEventPayloadDataMapper[eventName];
  return [mapper ? mapper(data) : data] as EventEmitter.EventArgs<
    AppEventPayload,
    T
  >;
};
