export const prepareFilesForSingleDownload = (files: string[]) => {
  files.map((file) => {
    fetch(file, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'audio/wav',
        'Access-Control-Allow-Origin': 'blob.core.windows.net',
        'Access-Control-Allow-Headers':
          'Origin, X-Requested-With, Content-Type, Accept',
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `higo_examination.wav`);

        document.body.appendChild(link);
        link.click();

        link?.parentNode?.removeChild(link);
      });
  });
};
