export const breakpoints: Media.Breakpoints = {
  tablet: 768,
  desktop: 1366,
} as const;

export const isDesktopMediaQuery = `(min-width: ${breakpoints.desktop}px)`;
export const isTabletQuery = `(max-width: ${breakpoints.desktop - 1}px)`;

const generateMediaQuery = (parts: string[]) => `@media ${parts.join(' and ')}`;

export const media = (
  Object.keys(breakpoints) as Array<keyof Media.Breakpoints>
).reduce((media, breakpoint) => {
  const screen = `only screen`;
  const min = `(min-width: ${breakpoints[breakpoint]}px)`;
  const max = `(max-width: ${breakpoints[breakpoint] - 1}px)`;
  const portrait = `(orientation: portrait)`;
  const landscape = `(orientation: landscape)`;

  media[breakpoint] = {
    min: generateMediaQuery([screen, min]),
    max: generateMediaQuery([screen, max]),
    minPortrait: generateMediaQuery([screen, min, portrait]),
    minLandscape: generateMediaQuery([screen, min, landscape]),
    maxPortrait: generateMediaQuery([screen, max, portrait]),
    maxLandscape: generateMediaQuery([screen, max, landscape]),
  };
  return media;
}, {} as Media.MediaQuery);
