import { IconBox, IconProps } from '../IconBox';

const StopIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.25 3A2.25 2.25 0 0 0 6 5.25v13.5a2.25 2.25 0 0 0 4.5 0V5.25A2.25 2.25 0 0 0 8.25 3ZM15.75 3a2.25 2.25 0 0 0-2.25 2.25v13.5a2.25 2.25 0 0 0 4.5 0V5.25A2.25 2.25 0 0 0 15.75 3Z"
    />
  </IconBox>
);

export default StopIcon;
