import { FC, memo, useMemo } from 'react';
import {
  LiteralUnion,
  MultipleFieldErrors,
  Ref,
  RegisterOptions,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { isNil } from 'rambda';
import equal from 'fast-deep-equal/react';

// Extending the Message type so it's not only a string
export declare type Message = string | { key: string; values?: object };

export declare type ExtFieldError = {
  type: LiteralUnion<keyof RegisterOptions, string>;
  ref?: Ref;
  types?: MultipleFieldErrors;
  message?: Message;
};

export interface ErrorMessageProps {
  label?: string;
  error?: ExtFieldError;
}

const areEqual = (prevProps: ErrorMessageProps, nextProps: ErrorMessageProps) =>
  equal(prevProps?.error, nextProps?.error) &&
  prevProps.label === nextProps.label;

export const ErrorMessage: FC<ErrorMessageProps> = memo(({ label, error }) => {
  const intl = useIntl();
  return useMemo(() => {
    if (isNil(error)) {
      return null;
    } else if (typeof error.message === 'string') {
      return (
        <>
          {intl.formatMessage(
            { id: error.message },
            {
              field: label && intl.formatMessage({ id: label }),
            },
          )}
        </>
      );
    } else {
      return (
        <>
          {intl.formatMessage(
            {
              id: error.message?.key,
            },
            {
              field: label && intl.formatMessage({ id: label }),
              ...error.message?.values,
            },
          )}
        </>
      );
    }
  }, [error, intl, label]);
}, areEqual);
