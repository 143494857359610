import {
  forwardRef,
  MouseEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { useClickAway, useKeyPressEvent } from 'react-use';
import PopperUnstyled, { PopperUnstyledProps } from '@mui/base/PopperUnstyled';

export type PopperProps = PopperUnstyledProps;

export const usePopper = () => {
  const popperRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const isOpened = useMemo(() => !!anchorEl, [anchorEl]);

  const toggle = useCallback((e: MouseEvent<HTMLElement>) => {
    setAnchorEl((anchorEl) => (!anchorEl ? e.currentTarget : null));
  }, []);

  const close = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useKeyPressEvent('Escape', () => close());
  useClickAway(popperRef, (e) => {
    const target = e.target as HTMLElement;

    if (!anchorEl?.contains(target)) {
      setAnchorEl(null);
    }
  });

  return { popperRef, isOpened, anchorEl, toggle, close };
};

const StyledPopper = styled(PopperUnstyled)(
  ({ theme }) => css`
    padding: 0.5rem;
    min-width: 8rem;

    background: ${theme.palette.white};
    box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.25);
    border-radius: 1rem;
  `,
);

export const Popper = forwardRef<HTMLDivElement, PopperProps>(
  ({ children, ...props }, ref) => (
    <StyledPopper ref={ref} {...props}>
      {children}
    </StyledPopper>
  ),
);

Popper.displayName = 'Popper';
