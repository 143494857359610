import React, { FC, memo } from 'react';
import { MeasurementEntry } from '@higo/common/src/features/ExaminationPage/sections/VitalSignsSection/common/MeasurementEntry';
import { MeasurementSkeleton } from '@higo/common/src/features/ExaminationPage/sections/VitalSignsSection/common/MeasurementSkeleton';
import { MeasurementRenderer } from '@higo/common/src/components/MeasurementRenderer';
import { useTemperaturePhysicalExaminationQuery } from '@higo/common/src/features/ExaminationPage/hooks/queries';

export type TemperatureMeasurementEntryProps = {
  isAvailable?: boolean;
  examinationId: number;
};

export const TemperatureMeasurementEntry: FC<TemperatureMeasurementEntryProps> =
  memo(({ examinationId, isAvailable }) => {
    const { data, isLoading } = useTemperaturePhysicalExaminationQuery(
      examinationId,
      isAvailable,
    );

    return (
      <MeasurementEntry
        title="lifeSigns.temperature"
        measurement={
          isLoading ? (
            <MeasurementSkeleton />
          ) : (
            <MeasurementRenderer data={data} />
          )
        }
      ></MeasurementEntry>
    );
  });
