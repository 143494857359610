import { createContext, FC, useContext, useState } from 'react';

export interface QueryStorage {
  delete: (queryKey: string) => void;
  read: <T>(queryKey: string) => T | undefined;
  write: <T>(queryKey: string, data: T) => void;
}

const Context = createContext<QueryStorage>({
  delete: () => {
    throw new Error('Provide query storage implementation');
  },
  read: () => {
    throw new Error('Provide query storage implementation');
  },
  write: () => {
    throw new Error('Provide query storage implementation');
  },
});

export const QueryStorageProvider: FC<{ queryStorage: QueryStorage }> = ({
  children,
  queryStorage,
}) => {
  const [context] = useState<QueryStorage>(queryStorage);

  return <Context.Provider value={context}>{children}</Context.Provider>;
};
export const useQueryStorage = () => useContext(Context);
