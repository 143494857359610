import en from './assets/en';
import { keys } from 'rambda';
import { LazyLocaleDefinition } from '@higo/common/src/features/Locale/localeDefinition';

export const availableLocaleDefinitions: Record<string, LazyLocaleDefinition> =
  {
    en: () => Promise.resolve(en),
    nl: () => import('./assets/nl').then((x) => x.default),
    bg: () => import('./assets/bg').then((x) => x.default),
    it: () => import('./assets/it').then((x) => x.default),
    pl: () => import('./assets/pl').then((x) => x.default),
    ro: () => import('./assets/ro').then((x) => x.default),
    hu: () => import('./assets/hu').then((x) => x.default),
  };

export const availableLocaleCodes = keys(availableLocaleDefinitions);

export const defaultLocaleCode: keyof typeof availableLocaleDefinitions = 'en';
