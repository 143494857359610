import styled, { css } from 'styled-components';
import { QuestionAnswerRenderer } from '@higo/common/src/features/FilledSurvey/ComponentRenderers/QuestionAnswerRenderer';
import { QuestionType } from '@higo/api';
import { SurveyRenderer } from '@higo/common/src/features/FilledSurvey/SurveyRenderer';
import { CopiedTextOnly } from '@higo/common/src/components/CopiedTextOnly';
import { AnsweredQuestionRendererProps } from '@higo/common/src/features/FilledSurvey/ComponentRenderers/AnsweredQuestionRendererProps';
import { FC, memo } from 'react';
import { useRenderableSelectedAnswers } from '@higo/common/src/features/FilledSurvey/ComponentRenderers/useRenderableAnswers';

const Container = styled('span')(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-column-gap: 0.5rem;
    margin-bottom: 0.5rem;
    position: relative;
    z-index: 0;

    // todo extract to function
    &:after {
      content: '';
      background: ${theme.palette.neutral[300]};
      position: absolute;
      top: -0.25rem;
      bottom: -0.25rem;
      left: -0.5rem;
      right: -0.5rem;
      z-index: -1;
      opacity: 0;
      border-radius: 0.5rem;
      transition: ${theme.transitions.create(['opacity'], {
        duration: theme.transitions.duration.short,
        easing: theme.transitions.easing.easeInOut,
      })};
    }
    &:hover:after {
      opacity: 1;
    }
  `,
);

const QuestionLabel = styled('span')(
  ({ theme }) => css`
    ${theme.typography.body};
    color: ${theme.palette.secondary['500']};
  `,
);

const AnswerText = styled('span')(
  ({ theme }) => css`
    ${theme.typography.subtitle2};
  `,
);

/**
 * Open questions doesn't have child questions
 */
export const AnsweredQuestionRootRowRenderer: FC<AnsweredQuestionRendererProps> =
  memo(({ answeredQuestion, level, ...rest }) => {
    const renderableSelectedAnswers = useRenderableSelectedAnswers(
      answeredQuestion.selectedAnswers,
      rest?.options?.isQuestionAnswerOptionRenderable,
    );

    return (
      <Container>
        <QuestionLabel>
          {answeredQuestion.questionUserFriendlyLabel ??
            answeredQuestion.question}
          <CopiedTextOnly> </CopiedTextOnly>
        </QuestionLabel>
        <AnswerText>
          {QuestionType.OPEN === answeredQuestion.questionType ? (
            <span>
              <QuestionAnswerRenderer
                answered={answeredQuestion.answered}
                answer={answeredQuestion.answer}
                type={answeredQuestion.openAnswerType}
                questionType={answeredQuestion.questionType}
              />
            </span>
          ) : (
            renderableSelectedAnswers.map((answer, i, arr) => (
              <span key={answer.code}>
                <QuestionAnswerRenderer
                  answer={answer}
                  questionType={
                    answeredQuestion.questionType as
                      | QuestionType.SINGLE_CLOSED
                      | QuestionType.MULTIPLE_CLOSED
                  } // don't want to add another check
                />
                <SurveyRenderer
                  answeredQuestionsList={answer.childQuestions}
                  level={level + 1}
                  {...rest}
                />
                {i < arr.length - 1 && `; `}
              </span>
            ))
          )}
        </AnswerText>
        <CopiedTextOnly>{'\r\n'}</CopiedTextOnly>
      </Container>
    );
  });
