import SliderUnstyled, {
  sliderUnstyledClasses,
} from '@mui/base/SliderUnstyled';
import styled, { css } from 'styled-components';
import { alpha } from '@mui/system';

export const Slider = styled(SliderUnstyled)(
  ({ theme }) => css`
    color: ${theme.palette.primary[500]};
    height: 0.25rem;
    width: 100%;
    display: inline-block;
    position: relative;
    cursor: pointer;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      opacity: 1;
    }

    &.${sliderUnstyledClasses.disabled} {
      cursor: not-allowed;
      color: #bdbdbd;
    }

    & .${sliderUnstyledClasses.rail} {
      display: block;
      position: absolute;
      width: 100%;
      height: 0.25rem;
      border-radius: 0.1875rem;
      background-color: ${theme.palette.secondary[100]};
    }

    & .${sliderUnstyledClasses.track} {
      display: block;
      position: absolute;
      height: 0.25rem;
      border-radius: 0.1875rem;
      background-color: currentColor;
    }

    & .${sliderUnstyledClasses.thumb} {
      position: absolute;
      width: 0.875rem;
      height: 0.875rem;
      margin-left: -6px;
      margin-top: -5px;
      box-sizing: border-box;
      border-radius: 50%;
      outline: 0;
      border: 2px solid currentColor;
      background-color: #fff;

      box-shadow: 0px 1px 1px rgba(187, 190, 204, 0.45);

      :hover,
      &.${sliderUnstyledClasses.focusVisible} {
        box-shadow: 0 0 0 0.25rem ${alpha(theme.palette.primary[500], 0.15)};
      }
      &.${sliderUnstyledClasses.active} {
        box-shadow: 0 0 0 0.25rem ${alpha(theme.palette.primary[500], 0.3)};
        background-color: ${theme.palette.neutral[300]};
      }
    }
  `,
);
