export enum ExaminationPatientDTOPatientType {
  FAMILY = 'FAMILY',
  PRO = 'PRO',
  FAMILY_AND_PRO = 'FAMILY_AND_PRO',
}

export interface PartnerDetailsDTO {
  id: number;
  name: string;
  parentPartner: PartnerDetailsDTO;
}

export interface ExaminationPatientDTO {
  id: number;
  idNumber?: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  gender: string;
  partnerDetails?: PartnerDetailsDTO;
  type: ExaminationPatientDTOPatientType;
}

export type HigoMode = ExaminationPatientDTOPatientType;
