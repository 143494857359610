import { RequestGenerateVisualExaminationVideoButtonProps } from 'pages/ExaminationPage/components/RequestGenerateVisualExaminationVideoButton/RequestGenerateVisualExaminationVideoButtonProps';
import { FC } from 'react';
import { useRequestGenerateRightEarExaminationVideoMutation } from './hooks/useRequestGenerateRightEarExaminationVideoMutation';

export const RequestGenerateRightEarExaminationVideoButton: FC<RequestGenerateVisualExaminationVideoButtonProps> =
  ({ examinationId }) => {
    const { mutate, isSuccess } =
      useRequestGenerateRightEarExaminationVideoMutation();

    return (
      <div
        style={{ width: '100%', height: '100%', lineHeight: '35px' }}
        onClick={() => (!isSuccess ? mutate(examinationId) : null)}
      >
        Full video
      </div>
    );
  };
