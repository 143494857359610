import { CellProps } from 'react-table';
import { useAgeLabel } from 'hooks/useAgeLabel';

export const AgeCellRenderer = <T extends Record<string, unknown>>({
  cell,
}: CellProps<T, string>) => {
  const ageLabel = useAgeLabel(cell.value);

  return <span>{ageLabel}</span>;
};
