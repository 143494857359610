import { CellProps } from 'react-table';
import { SelfDiagnosedIndicator } from 'components/SelfDiagnosedIndicator';

export type DiagnosedByCellValue = {
  id: number;
  firstName: string;
  lastName: string;
};

export const DiagnosedByCellRenderer = <T extends Record<string, unknown>>({
  cell,
}: CellProps<T, DiagnosedByCellValue>) =>
  cell.value ? (
    <span>
      <SelfDiagnosedIndicator doctorId={cell.value.id} />
      {`${cell.value.firstName} ${cell.value.lastName}`}
    </span>
  ) : (
    <span></span>
  );
