import {
  Examination,
  ExaminationDTO,
  mapToExamination,
} from '@higo/api/src/models/examinationDTO';
import { UserBaseDTO } from '@higo/api/src/models/userBaseDTO';
import {
  mapShareExaminationPatient,
  ShareExaminationPatient,
  ShareExaminationPatientDTO,
} from '@higo/api/src/models/shareExaminationPatientDTO';
import { PhysicalExaminationDetailsDTO } from '@higo/api/src/models/examinationDetailsDTO';
import { evolve } from 'rambda';

export interface ExaminationDetailsWithPatientDTO {
  examination: ExaminationDTO;
  executedBy: UserBaseDTO;
  patient: ShareExaminationPatientDTO;
  physicalExaminationDetails: PhysicalExaminationDetailsDTO[];
}

export interface ExaminationDetailsWithPatient
  extends Omit<ExaminationDetailsWithPatientDTO, 'examination' | 'patient'> {
  examination: Examination;
  patient: ShareExaminationPatient;
}

export const mapToExaminationDetailsWithPatient: (
  dto: ExaminationDetailsWithPatientDTO,
) => ExaminationDetailsWithPatient = evolve({
  examination: mapToExamination,
  patient: mapShareExaminationPatient,
});
