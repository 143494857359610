import Axios, { AxiosError, AxiosResponse } from 'axios';
import qs from 'qs';

export const flatten = <T>(response: AxiosResponse<T>) => response.data;

export const flattenError = <T, R>(
  error: AxiosError<T>,
  mapFn?: (error: AxiosError<T>) => R,
) => {
  if (Axios.isCancel(error)) {
    return error;
  } else {
    return mapFn?.(error) ?? error;
  }
};

export const qsArrayParamsSerializer = (data: unknown[]) =>
  qs.stringify(data, {
    arrayFormat: 'repeat',
  });
