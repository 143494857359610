import { useFormState, useWatch } from 'react-hook-form';
import { useExaminationPageDiagnosisAutoSave } from './useExaminationPageDiagnosisAutosave';
import React, { forwardRef, memo, useEffect, useImperativeHandle } from 'react';
import { mapToExaminationDoctorDiagnosisDTO } from '@higo/common/lib/features/ExaminationPage/models';
import { UnsavedDiagnosisPrompt } from './UnsavedDiagnosisPrompt';
import { Button, SaveIcon } from '@higo/ui';
import { FormattedMessage } from 'react-intl';
import { AutoSaveIndicator } from './AutoSaveIndicator';
import { ReactQueryAutoSaveSaveStatus } from '@higo/common/lib/hooks';
import { useQueryClient } from 'react-query';
import { ExaminationQueryKey } from '@higo/common/lib/features/ExaminationPage/ExaminationQueryKey';

export type ExaminationPageDiagnosisAutoSaveRef = {
  saveDraft: () => void;
};

export type ExaminationPageDiagnosisAutoSaveProps = {
  examinationId: number;
  onStatusChange?: (status: ReactQueryAutoSaveSaveStatus) => void;
};
export const ExaminationPageDiagnosisAutoSave = memo(
  forwardRef<
    ExaminationPageDiagnosisAutoSaveRef,
    ExaminationPageDiagnosisAutoSaveProps
  >(({ examinationId, onStatusChange }, ref) => {
    const examinationPageSummaryFormValue = useWatch();
    const { isDirty } = useFormState();
    const queryClient = useQueryClient();

    const {
      setDraft,
      save: saveDraft,
      saveStatus: saveDraftStatus,
    } = useExaminationPageDiagnosisAutoSave(examinationId);

    useImperativeHandle(ref, () => ({
      saveDraft: () => {},
    }));

    const isDraftSaving = 'saving' === saveDraftStatus;

    useEffect(() => {
      isDirty &&
        setDraft(
          mapToExaminationDoctorDiagnosisDTO(examinationPageSummaryFormValue),
        );
    }, [setDraft, examinationPageSummaryFormValue, isDirty]);

    useEffect(() => {
      onStatusChange?.(saveDraftStatus);
      queryClient.invalidateQueries([ExaminationQueryKey.ExaminationDiagnosis]);
    }, [onStatusChange, saveDraftStatus, queryClient]);

    return (
      <>
        <UnsavedDiagnosisPrompt
          block={'saved' !== saveDraftStatus}
          saveDraftSlot={
            <Button
              color="secondary"
              autoWidth
              endIcon={<SaveIcon />}
              onClick={saveDraft}
              isLoading={isDraftSaving}
              disabled={isDraftSaving}
            >
              <FormattedMessage id="examinationPage.button.saveAsDraft" />
            </Button>
          }
        />
        {isDraftSaving && <AutoSaveIndicator />}
      </>
    );
  }),
);
