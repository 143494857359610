import { QueryClient, useQuery } from 'react-query';
import { DoctorQueryKey } from 'config/doctorQueryKey';
import { SerializedOAuth2AccessToken } from '@higo/api';
import { QueryStorage } from '@higo/common/lib/QueryStorage';
import { OAuth2AccessToken } from '@higo/common/lib/ext/axios';
import { createAPIOAuth2AccessToken } from '@higo/common/lib/utils/axios';

/**
 * Special query used for caching APIOAuth2AccessToken.
 * Doesn't fetch data itself. Data is manually populated from:
 * - refresh token query
 * - login mutation
 */

export const useAPIOAuth2AccessTokenQuery = () =>
  useQuery<OAuth2AccessToken | undefined>(DoctorQueryKey.APIOAuth2AccessToken, {
    enabled: false,
    refetchOnMount: false,
  });

export const setAPIOAuth2AccessToken = (
  queryClient: QueryClient,
  queryStorage: QueryStorage,
  data: OAuth2AccessToken | undefined,
) => {
  queryClient.setQueryData(DoctorQueryKey.APIOAuth2AccessToken, data);
  data
    ? queryStorage.write(DoctorQueryKey.APIOAuth2AccessToken, {
        accessToken: data.accessToken,
        refreshToken: data.refreshToken,
      })
    : queryStorage.delete(DoctorQueryKey.APIOAuth2AccessToken);
};
export const clearAPIOAuth2AccessToken = (
  queryClient: QueryClient,
  queryStorage: QueryStorage,
) => setAPIOAuth2AccessToken(queryClient, queryStorage, undefined);

export const hydrateAPIOAuth2AccessToken = (
  queryClient: QueryClient,
  queryStorage: QueryStorage,
) => {
  const serialized = queryStorage.read<SerializedOAuth2AccessToken>(
    DoctorQueryKey.APIOAuth2AccessToken,
  );

  queryClient.setQueryData(
    DoctorQueryKey.APIOAuth2AccessToken,
    serialized
      ? createAPIOAuth2AccessToken(
          serialized.accessToken,
          serialized.refreshToken,
        )
      : undefined,
  );

  return !!serialized;
};
