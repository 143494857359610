import { ReactElement } from 'react';
import { isNil } from 'rambda';
import SelectUnstyled, {
  SelectOption as SelectOptionType,
  SelectUnstyledProps,
  TSelectValue,
} from '@mui/base/SelectUnstyled';
import { StyleProps } from '@summer/jst-react';
import { SelectRoot } from '@higo/ui/src/components/Select/components/SelectRoot';
import { SelectListbox } from '@higo/ui/src/components/Select/components/SelectListbox';
import { SelectPopper } from '@higo/ui/src/components/Select/components/SelectPopper';
import {
  OptionProps,
  SelectOption,
} from '@higo/ui/src/components/Select/components/SelectOption';

export type SelectProps<T extends {}> = Omit<
  SelectUnstyledProps<T>,
  'onChange'
> & {
  onChange: (value: T | null) => void; // quick and dirty workaround for breaking change@mui/base@5.0.0-alpha.98
} & (
    | {
        options?: never;
        children: Array<ReactElement<OptionProps>>;
      }
    | {
        options: SelectOptionType<T>[];
        children?: never;
      }
  );

export const Select = <T extends TSelectValue>({
  placeholder = '',
  options = [],
  disabled = false,
  error = false,
  children,
  onChange,
  ...props
}: SelectProps<T> & StyleProps) => (
  <SelectUnstyled
    {...props}
    onChange={(event, newValue) => onChange?.(newValue)}
    disabled={disabled || (options.length === 0 && isNil(children))}
    components={{
      Root: SelectRoot,
      Listbox: SelectListbox,
      Popper: SelectPopper,
      ...props.components,
    }}
    componentsProps={{
      ...props.componentsProps,
      root: {
        placeholder,
        error,
        ...props.componentsProps?.root,
      },
      listbox: {
        ...props.componentsProps?.listbox,
      },
    }}
  >
    {children ??
      options.map((option) => (
        <SelectOption
          key={option.value as string | number}
          value={option.value}
          disabled={option.disabled}
          id={option.value as string | undefined}
        >
          {option.label}
        </SelectOption>
      ))}
  </SelectUnstyled>
);
