import { Button, ChevronIcon } from '@higo/ui';
import { FormattedMessage } from 'react-intl';
import { MouseEvent, useCallback } from 'react';
import { useAssignExaminationMutation } from 'hooks/mutations/assignExamination';
import { TrackedAssignExaminationEventTrigger } from '@higo/common/lib/features/TrackedEvent';
import styled from 'styled-components';
import { ExaminationQueryKey } from '@higo/common/lib/features/ExaminationPage/ExaminationQueryKey';
import { useQueryClient } from 'react-query';

export type AssignFromExamPageButtonProps = {
  examinationId: number;
  patientId?: number;
};

export const AssignFromExamPageButton = ({
  examinationId,
  patientId,
}: AssignFromExamPageButtonProps) => {
  const queryClient = useQueryClient();

  const StyledButton = styled(Button)`
    height: 70px;
    margin: 50px auto;
  `;
  const { mutateAsync } = useAssignExaminationMutation(examinationId, {
    eventTrigger: TrackedAssignExaminationEventTrigger.AssignButtonClick,
    patientId: patientId,
  });

  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      mutateAsync(examinationId).then(() =>
        queryClient.invalidateQueries([ExaminationQueryKey.ExaminationDetails]),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [examinationId, mutateAsync],
  );

  return (
    <StyledButton
      onClick={handleClick}
      size="small"
      endIcon={<ChevronIcon direction="right" />}
      id="assign-from-exam-page-button"
      color="secondary"
    >
      <FormattedMessage id="common.reviewNow" />
    </StyledButton>
  );
};
