import { useDoctorPortalApiContext } from 'contexts/ApiContext';
import { markExaminationCommentsAsRead } from '@higo/api';
import { useMutation, useQueryClient } from 'react-query';
import { messagesQueryKeys } from 'config/doctorQueryKey';

export const useMarkExaminationCommentsAsReadMutation = (
  examinationId: number,
) => {
  const { authorizedAxios } = useDoctorPortalApiContext();
  const queryClient = useQueryClient();

  return useMutation(
    () => markExaminationCommentsAsRead(authorizedAxios)(examinationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(messagesQueryKeys.unreadList());
      },
    },
  );
};
