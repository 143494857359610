import { useQueryClient } from 'react-query';
import { DoctorQueryKey } from 'config/doctorQueryKey';
import { useSearchParams } from 'react-router-dom';
import { UserSettings } from 'models/user/userSettings';
import {
  availableLocaleCodes,
  defaultLocaleCode,
  stripRegionDesignator,
} from '@higo/common/lib/features/Locale';
import { readLocalLanguage } from '@higo/common/lib/features/Locale/languageLocalPersistor';

const isLanguageAvailable = (code: string) =>
  availableLocaleCodes.find((x) => x === stripRegionDesignator(code));

export const useGetAvailableLanguageCode = () => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  return () => {
    const profileLanguageCode = queryClient.getQueryData<UserSettings>(
      DoctorQueryKey.UserSettings,
    )?.LANGUAGE;
    const urlLangParamCode = searchParams.get('lang');
    const browserLanguageCode = navigator.language;
    const localStorageCode = readLocalLanguage();

    const resultingLanguage =
      (urlLangParamCode && isLanguageAvailable(urlLangParamCode)) ??
      (profileLanguageCode && isLanguageAvailable(profileLanguageCode)) ??
      (localStorageCode && isLanguageAvailable(localStorageCode)) ??
      (browserLanguageCode && isLanguageAvailable(browserLanguageCode)) ??
      defaultLocaleCode;

    return resultingLanguage;
  };
  // add loader, block until ready
};
