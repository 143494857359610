import { useQuery } from 'react-query';
import { fetchHeartAuscultationPhysicalExamination } from '@higo/api/lib/services';
import { useExaminationApiContext } from '@higo/common/src/features/ExaminationPage/providers';
import { ExaminationQueryKey } from '@higo/common/src/features/ExaminationPage/ExaminationQueryKey';
import { HeartAuscultationPhysicalExaminationDTO } from '@higo/api/lib/models/physicalExaminations';
import { useExaminationAzureResourcesQuery } from '@higo/common/src/features/ExaminationPage/hooks/queries/useExaminationAzureResourcesQuery';
import { pipe, prop } from 'rambda';
import {
  indexByFileName,
  remapToFileIdArray,
} from '@higo/common/src/utils/fileResourceUtils';

export const useHeartAuscultationPhysicalExaminationQuery = <
  TData = HeartAuscultationPhysicalExaminationDTO,
>(
  examinationId: number,
  select?: (data: HeartAuscultationPhysicalExaminationDTO) => TData,
) => {
  const axios = useExaminationApiContext();

  return useQuery(
    [ExaminationQueryKey.HeartAuscultationPhysicalExamination, examinationId],
    async () => fetchHeartAuscultationPhysicalExamination(axios)(examinationId),
    {
      enabled: !isNaN(examinationId),
      select,
    },
  );
};

const selectFileResultIds: (
  data: HeartAuscultationPhysicalExaminationDTO,
) => number[] = pipe(prop('heartAuscultationResults'), remapToFileIdArray);

export const useHeartAuscultationPhysicalExaminationResourceFiles = (
  id: number,
) => {
  const { data: ids } = useHeartAuscultationPhysicalExaminationQuery(
    id,
    selectFileResultIds,
  );
  return useExaminationAzureResourcesQuery(ids ?? [], indexByFileName);
};
