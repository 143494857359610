import {
  Page,
  Text,
  View,
  Document,
  Svg,
  Line,
  Font,
  Image,
} from '@react-pdf/renderer';
import font from '@higo/doctor/src/components/ShareExaminationModal/PDFdocument/fonts/NotoSans-Regular.ttf';
import boldFont from '@higo/doctor/src/components/ShareExaminationModal/PDFdocument/fonts/NotoSans-SemiBold.ttf';
import { indexBy } from 'rambda';
import { format, isValid } from 'date-fns';
import { styles } from '@higo/doctor/src/components/ShareExaminationModal/PDFdocument/PDFstyles';
import { PDFdocumentProps } from './types';

Font.register({
  family: 'NotoSans',
  src: font,
});

Font.register({
  family: 'NotoSansBold',
  src: boldFont,
});

export const PDFdocument = ({ ...props }: PDFdocumentProps) => {
  console.log('console z PDF document', props.skinImage);
  const examinationCreateDate =
    props.examData.details.examination.sessionEndTime ??
    props.examData.details.examination.createDate;
  const formattedExamDate = isValid(examinationCreateDate)
    ? format(examinationCreateDate, 'dd/MM/yyyy, HH:mm')
    : null;

  const formattedCreatingPDFDate = format(new Date(), 'dd/MM/yyyy, HH:mm');

  const takenDate = props.examData.details.examination.takenDate as
    | number
    | Date;

  const formattedTakenDate = isValid(takenDate)
    ? format(takenDate, 'dd/MM/yyyy, HH:mm')
    : null;

  const birthDate = props.examData.details.patient.dateOfBirth;

  const formattedBirthDate = isValid(birthDate)
    ? format(birthDate, 'dd/MM/yyyy')
    : null;

  const temperature = props.temperatureData?.value
    ? `${props.temperatureData?.value} ${props.temperatureData?.unit} `
    : '-';
  const heartRate = props.heartRateData?.value
    ? `${props.heartRateData?.value} ${props.heartRateData?.unit} `
    : '-';

  const medicinesFromGeneral = props.examData.patientOverview.medications.map(
    (item) => item.value,
  );
  const medicinesFromSurvey =
    props.examData.answeredAnamnesisSurvey?.taggedQuestions?.A_MEDICAMENTS?.map(
      (item) => item.answer,
    );

  const repeatedSurveyQuestions = ['AK23Q06', 'AK23Q07', 'AK23Q08', 'AK23Q09']; //medicaments and covid ones

  const surveyAnswers =
    props.examData.answeredAnamnesisSurvey?.surveyTreeList.map(
      (item) =>
        !repeatedSurveyQuestions.includes(item.questionCode) &&
        `${item.questionUserFriendlyLabel} ${
          item.answer
            ? item.answer
            : item.selectedAnswers.map((item) => {
                if (!item.childQuestions || item.childQuestions.length === 0) {
                  return `${
                    item.userFriendlyLabel ? item.userFriendlyLabel : item.value
                  }; `;
                } else {
                  return `${
                    item.userFriendlyLabel ? item.userFriendlyLabel : item.value
                  } ${item.childQuestions.map((item) =>
                    !repeatedSurveyQuestions.includes(item.questionCode)
                      ? `${
                          item.questionUserFriendlyLabel
                            ? item.questionUserFriendlyLabel
                            : item.question
                        } ${
                          item.answer
                            ? item.answer
                            : item.selectedAnswers.map((item) =>
                                item.userFriendlyLabel
                                  ? item.userFriendlyLabel
                                  : item.value,
                              )
                        }; `
                      : null,
                  )}
        `;
                }
              })
        }`.replace(/,/g, ''),
    );

  const selectedAilments = [
    props.examData.answeredAnamnesisSurvey?.taggedQuestions.AILMENTS,
  ]?.[0]?.[0]?.selectedAnswers;

  const selectedAilmentsCodes =
    selectedAilments && indexBy('code')(selectedAilments);

  const selectableAilments = [
    props.examData.answeredAnamnesisSurvey?.taggedQuestions.AILMENTS,
  ]?.[0]?.[0]?.selectableAnswers;

  const notReportedAilments =
    selectableAilments &&
    selectableAilments.filter(
      ({ code }) => !selectedAilmentsCodes?.hasOwnProperty(code),
    );

  const notReportertedAilmentsList = notReportedAilments?.map(
    (item) => item.value !== null && `${item.value}, `,
  );

  const HorizontalLine = () => (
    <Svg height="7" width="570">
      <Line
        x1="30"
        y1="5"
        x2="560"
        y2="5"
        strokeWidth={1}
        stroke="rgb(0,0,0)"
      />
    </Svg>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.text}>
            <Text style={styles.boldText}>Exam Data: </Text>{' '}
            {props.examData.mode} • Exam date:{' '}
            <Text style={styles.boldText}>{formattedExamDate} </Text>• Medical
            Operator:{' '}
            <Text style={styles.boldText}>
              {props.examData.details.executedBy.firstName}{' '}
              {props.examData.details.executedBy.lastName}
            </Text>
          </Text>
        </View>
        <HorizontalLine />
        <View style={styles.section}>
          <Text style={styles.boldText}>Patient data:</Text>
          <Text style={styles.bigTitleCenter}>
            {props.examData.details.patient.firstName}{' '}
            {props.examData.details.patient.lastName}
            {'    '}
            <Text style={styles.boldText}>
              ID: {props.examData.details.patient.idNumber} Birth date:{' '}
              {formattedBirthDate}
            </Text>
          </Text>
        </View>
        <HorizontalLine />
        <View style={styles.titleSection}>
          <Text style={styles.bigTitle}>
            Exam summary{' '}
            <Text style={styles.text}>
              {' '}
              (diagnosed {formattedTakenDate &&
                `on ${formattedTakenDate}`} by{' '}
              {props.examData.details.executedBy.firstName}{' '}
              {props.examData.details.executedBy.lastName})
            </Text>
          </Text>
        </View>
        <View style={styles.centerSection}>
          <Text style={styles.smallTitle}>Diagnosis</Text>
          <Text style={styles.text}>
            {props.diagnosisData?.diagnosis || '-'}
          </Text>
          <Text style={styles.smallTitle}>Recommendations</Text>
          <Text style={styles.text}>
            {props.diagnosisData?.recommendation || '-'}
          </Text>
          <Text style={styles.smallTitle}>Medicines</Text>
          <Text style={styles.text}>
            {props.diagnosisData?.medications || '-'}
          </Text>
        </View>
        <HorizontalLine />
        <View style={styles.titleSection}>
          <Text style={styles.bigTitle}>Examination Card</Text>
        </View>
        <View style={styles.centerSection}>
          <View wrap={false}>
            <Text style={styles.smallTitle}>Vital signs</Text>
            <Text style={styles.text}>
              Temperature: {temperature}, Heart rate: {heartRate}
            </Text>
          </View>
          <View wrap={false}>
            <Text style={styles.smallTitle}>Medicines taken by patient</Text>
            <Text style={styles.text}>
              {medicinesFromGeneral.length !== 0 && `${medicinesFromGeneral}, `}
              {medicinesFromSurvey ? `${medicinesFromSurvey}` : '-'}
            </Text>
          </View>

          <View wrap={false}>
            <Text style={styles.smallTitle}>Anamnesis survey</Text>
            <Text style={styles.text}>
              {surveyAnswers ? surveyAnswers : '-'}
            </Text>
          </View>

          <View wrap={false}>
            <Text style={styles.smallTitle}>Medical Operator Comment</Text>
            <Text style={styles.text}>
              {props.MOnotes ? props.MOnotes.noteText : '-'}
            </Text>
          </View>
        </View>
        <View wrap={false}>
          <HorizontalLine />
          <View wrap={false} style={styles.centerSection}>
            <Text style={styles.text}>
              Not reportes ailments: {notReportertedAilmentsList}
            </Text>
          </View>
          <HorizontalLine />
        </View>
        <Text style={styles.documentInfo} fixed>
          {`The document was generated on ${formattedCreatingPDFDate} by ${props.doctorData?.firstName} ${props.doctorData?.lastName}.`}
        </Text>
        <Text
          style={styles.pageInfo}
          render={({ pageNumber, totalPages }) =>
            `page ${pageNumber} of ${totalPages}`
          }
          fixed
        />
        <View wrap={false} style={styles.halfSection}>
          {props.skinImage && (
            <View wrap={false} style={styles.halfBox}>
              <Text style={styles.smallTitle}>
                Skin exam{' '}
                <Text style={styles.text}>
                  ({!props.isSkinImageDiagnostic && 'no '}diagnostic frame)
                </Text>
              </Text>
              <Image source={props.skinImage} style={styles.skinThroatImage} />
            </View>
          )}
          {props.throatImage && (
            <View wrap={false} style={styles.halfBox}>
              <Text style={styles.smallTitle}>
                Throat exam{' '}
                <Text style={styles.text}>
                  ({!props.isThroatImageDiagnostic && 'no '}diagnostic frame)
                </Text>
              </Text>
              <Image
                source={props.throatImage}
                style={styles.skinThroatImage}
              />
            </View>
          )}
          {props.leftEarImage || props.rightEarImage ? (
            <View wrap={false} style={styles.earHalfBox}>
              <Text style={styles.smallTitle}>Ear exams</Text>
              <View wrap={false} style={styles.earsSection}>
                {props.leftEarImage && (
                  <View wrap={false} style={styles.earHalfBox}>
                    <Text style={styles.text}>
                      Left ({!props.isLeftEarImageDiagnostic && 'no '}diagnostic
                      frame)
                    </Text>
                    <Image
                      source={props.leftEarImage}
                      style={styles.earImage}
                    />
                  </View>
                )}
                {props.rightEarImage && (
                  <View wrap={false} style={styles.halfBox}>
                    <Text style={styles.text}>
                      Right ({!props.isRightEarImageDiagnostic && 'no '}
                      diagnostic frame)
                    </Text>
                    <Image
                      source={props.rightEarImage}
                      style={styles.earImage}
                    />
                  </View>
                )}
              </View>
            </View>
          ) : null}
        </View>
        {props.lungsFrontDataToPDF && (
          <>
            <View wrap={false}>
              <View wrap={false} style={styles.titleLungs}>
                <Text style={styles.smallTitle}>Lung auscultation</Text>
              </View>

              <View wrap={false} style={styles.halfSection}>
                <View wrap={false} style={styles.halfBox}>
                  <Text style={styles.boldText}>Anterior</Text>

                  <Image
                    source={props.lungsFrontAsculationImageToShow}
                    style={styles.asculationImage}
                  />

                  <Text style={styles.text}>{props.lungsFrontDataToPDF}</Text>
                </View>
                <View wrap={false} style={styles.halfBox}>
                  <Text style={styles.boldText}>Posterior</Text>
                  <Image
                    source={props.lungsBackAsculationImageToShow}
                    style={styles.asculationImage}
                  />
                  <Text style={styles.text}>{props.lungsBackDataToPDF}</Text>
                </View>
              </View>
            </View>
          </>
        )}

        <View wrap={false} style={styles.halfSection}>
          {props.heartDataToPDF && (
            <View wrap={false} style={styles.halfBox}>
              <Text style={styles.smallTitle}>Heart auscultation</Text>
              <Image
                source={props.heartAsculationImageToShow}
                style={styles.asculationImage}
              />
              <Text style={styles.text}>{props.heartDataToPDF}</Text>
            </View>
          )}

          {props.abdominalDataToPDF && (
            <View wrap={false} style={styles.halfBox}>
              <Text style={styles.smallTitle}>Auscultation of the abdomen</Text>
              <Image
                source={props.abdomenImage}
                style={styles.asculationImage}
              />
              <Text style={styles.text}>{props.abdominalDataToPDF}</Text>
            </View>
          )}

          {props.coughDataToPDF && (
            <View wrap={false} style={styles.halfBox}>
              <Text style={styles.smallTitle}>Cough</Text>
              <Text style={styles.text}>{props.coughDataToPDF}</Text>
            </View>
          )}
        </View>
        <Text style={styles.documentInfo} fixed>
          {`The document was generated on ${formattedCreatingPDFDate} by ${props.doctorData?.firstName} ${props.doctorData?.lastName}.`}
        </Text>
        <Text
          style={styles.pageInfo}
          render={({ pageNumber, totalPages }) =>
            `page ${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
