import { useQuery } from 'react-query';
import { DoctorQueryKey } from 'config/doctorQueryKey';
import { fetchUserSettings } from 'services/userService';
import { useProfileDataQuery } from 'hooks/queries/useProfileDataQuery';
import { useGetAvailableLanguageCode } from 'hooks/useGetAvailableLanguageCode';
import { useAppLocale } from '@higo/common/lib/features/Locale';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';

export const useUserSettings = () => {
  const { authorizedAxios } = useDoctorPortalApiContext();

  /**
   * We could load profile data when authToken is available,
   * however it's possible that authToken won't be valid for doctor portal,
   * therefore we wait until we get profile data
   */
  const { isSuccess } = useProfileDataQuery();

  const { changeLocale } = useAppLocale();
  const getAvailableLanguageCode = useGetAvailableLanguageCode();

  return useQuery(
    [DoctorQueryKey.UserSettings],
    () => fetchUserSettings(authorizedAxios)(),
    {
      enabled: isSuccess,
      onSuccess: (x) => {
        // console.log('loaded', x); // todo: check why this is executed 3 times after login
        changeLocale(getAvailableLanguageCode());
      },
    },
  );
};
