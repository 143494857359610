import { FC, useMemo } from 'react';
import { ExaminationPageSectionId } from '../models';
import { FormattedMessage } from 'react-intl';
import { AuscultationGroup } from '@higo/common/src/components';
import styled from 'styled-components';
import { ExaminationPageSection } from './ExaminationPageSection';
import {
  useAbdominalAuscultationPhysicalExaminationQuery,
  useAbdominalAuscultationPhysicalExaminationResourceFiles,
} from '@higo/common/src/features/ExaminationPage/hooks/queries';
import { buildAuscultationPoints } from '@higo/common/src/features/ExaminationPage/utils/buildAuscultationPoints';
import { auscultationPointsCount } from '@higo/common/src/config/auscultationPointsCount';
import { PhysicalExaminationType } from '@higo/api';

const AuscultationGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export interface AbdominalAuscultationSectionProps {
  examinationId: number;
}

export const AbdominalAuscultationSection: FC<AbdominalAuscultationSectionProps> =
  ({ examinationId }) => {
    const { data } =
      useAbdominalAuscultationPhysicalExaminationQuery(examinationId);

    const { data: fileResourceMap } =
      useAbdominalAuscultationPhysicalExaminationResourceFiles(examinationId);

    const auscultationPoints = useMemo(
      () =>
        buildAuscultationPoints(
          data?.abdominalAuscultationResults ?? [],
          fileResourceMap ?? {},
          auscultationPointsCount[
            PhysicalExaminationType.AbdominalAuscultation
          ],
        ),
      [data?.abdominalAuscultationResults, fileResourceMap],
    );

    const filesIds = useMemo(
      () => auscultationPoints.filter((x) => x.file).map((x) => x.file?.fileId),
      [auscultationPoints],
    );

    return (
      // eslint-disable-next-line react/jsx-no-undef
      <ExaminationPageSection
        title={
          <FormattedMessage id="examinationPage.section.abdominalAuscultation.title" />
        }
        sectionId={ExaminationPageSectionId.Abdominal}
        audioDownload="double"
        filesIds={filesIds}
      >
        <AuscultationGroupContainer>
          <AuscultationGroup auscultationPoints={auscultationPoints} />
        </AuscultationGroupContainer>
      </ExaminationPageSection>
    );
  };
