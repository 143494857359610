import { useQuery } from 'react-query';
import { fetchExaminationDiagnosis } from '@higo/api/lib/services';
import { useExaminationApiContext } from '@higo/common/src/features/ExaminationPage/providers';
import { ExaminationQueryKey } from '@higo/common/src/features/ExaminationPage/ExaminationQueryKey';

export const useExaminationDiagnosisQuery = (
  examinationId: number,
  enabled: boolean = true,
) => {
  const axios = useExaminationApiContext();

  return useQuery(
    [ExaminationQueryKey.ExaminationDiagnosis, examinationId],
    () => fetchExaminationDiagnosis(axios)(examinationId),
    {
      enabled,
      refetchOnMount: true,
      refetchInterval: 2000,
      refetchIntervalInBackground: false,
    },
  );
};
