import styled from 'styled-components';
import FormControlUnstyled from '@mui/base/FormControlUnstyled';
import composeClasses from '@mui/base/composeClasses';
import { FormControlUnstyledProps } from '@mui/base';
import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import {
  formControlClasses,
  getFromControlClasses,
} from '@higo/ui/src/components/FormControl/formControlClasses';
import { HelperText } from '@higo/ui/src/components/HelperText';
import { FormLabel } from '@higo/ui/src/components/FormLabel';
import { FormattedMessage } from 'react-intl';

/**
 * Might require some refactoring related to MUI state / styling system
 * @param ownerState
 */
const useUtilityClasses = (ownerState: Record<string, boolean | undefined>) => {
  const { sizeSmall, horizontal } = ownerState;
  const slots = {
    root: ['root', sizeSmall && 'sizeSmall', horizontal && 'horizontal'],
  };

  return composeClasses(slots, getFromControlClasses, undefined);
};

// function ControlStateDisplay() {
//   const formControlContext = useFormControlUnstyledContext();
//   if (formControlContext === undefined) {
//     return null;
//   }
//
//   const { filled, focused } = formControlContext;
//   return (
//     <p>
//       {filled ? 'filled' : 'empty'}&nbsp;|&nbsp;
//       {focused ? 'focused' : 'not focused'}
//     </p>
//   );
// }

const FormControlRoot = styled(FormControlUnstyled)`
  &.${formControlClasses.horizontal} {
    display: grid;
    align-items: baseline;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content;
    grid-column-gap: 1rem;
  }

  margin-bottom: 1rem;

  &.${formControlClasses.sizeSmall} {
    margin-bottom: 0.5rem;
  }
`;

export type FormControlProps = {
  label?: string;
  hiddenLabel?: boolean;
  horizontal?: boolean;
  helperText?: ReactNode;
  fullWidth?: boolean; // todo: not-implemented
  size?: 'small' | 'large'; // todo: requires fixing ( some sort unification)
} & FormControlUnstyledProps;

export const FormControl: FC<FormControlProps> = ({
  className,
  size,
  label,
  hiddenLabel,
  children,
  helperText,
  horizontal,
  ...props
}) => {
  const ownerState = {
    sizeSmall: !!size?.small,
    horizontal: horizontal,
  };

  const classes = useUtilityClasses(ownerState);

  return (
    <FormControlRoot className={clsx(classes.root, className)} {...props}>
      {!hiddenLabel && (
        <FormLabel size={size} id={`label-${label}`}>
          <FormattedMessage id={label} />
        </FormLabel>
      )}
      <div id={label}>
        {children}
        <HelperText>{helperText}</HelperText>
      </div>
      {/*<ControlStateDisplay />*/}
    </FormControlRoot>
  );
};
