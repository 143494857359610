import { PhysicalExaminationType } from '@higo/api';

export enum DoctorTrackedType {
  DoctorLogin = 'doctorLogin',
  AssignExamination = 'assignExamination',
  UnassignExamination = 'unassignExamination',
  SendChatMessage = 'sendChatMessage',
  SaveExaminationDiagnosis = 'saveExaminationDiagnosis',
  RequestVisualExaminationVideo = 'requestVisualExaminationVideo',
  ButtonClick = 'buttonClick',
}

export enum TrackedEventButtonClickAction {
  OpenPatientExaminationHistoryModal = 'openPatientExaminationHistoryModal',
  OpenShareExaminationModal = 'openShareExaminationModal',
  ShareExaminationViaEmail = 'shareExaminationViaEmail',
  ShareExaminationViaLink = 'shareExaminationViaLink',
  ShareExaminationViaPDF = 'shareExaminationViaPDF',
  OpenPatientChatModal = 'openPatientChatModal',
  OpenGeneralSurveyModal = 'openGeneralSurveyModal',
  SaveExaminationDiagnosisDraft = 'saveExaminationDiagnosisDraft',
}

export enum TrackedAssignExaminationEventTrigger {
  AssignButtonClick = 'assignButtonClick',
  ReviewNowButtonClick = 'reviewNowButtonClick',
  AssignMultipleButtonClick = 'assignMultipleButtonClick',
}

export type TrackedAsyncEventResult = 'success' | 'error';

export type TrackedEventBasicData = {
  doctorId?: number;
  patientId?: number; // Public id or actually record ID as idNumber shouldn't float carelessly
  examinationId?: number;
};

export type TrackedAsyncEvent = {
  event: string;
  eventTrigger?: string;
  eventResult: TrackedAsyncEventResult;
} & TrackedEventBasicData;

export type TrackedButtonClickEvent = {
  event: DoctorTrackedType.ButtonClick;
  eventAction: string;
} & TrackedEventBasicData;

export type TrackedRequestVisualExaminationVideoEvent = {
  event: DoctorTrackedType.RequestVisualExaminationVideo;
  physicalExaminationType:
    | PhysicalExaminationType.LeftEar
    | PhysicalExaminationType.RightEar
    | PhysicalExaminationType.Skin
    | PhysicalExaminationType.Throat;
};

export type TrackedSaveExaminationDiagnosisEvent = {
  event: DoctorTrackedType.SaveExaminationDiagnosis;
  selectedHeartAuscultationTags: number;
  selectedLungsAuscultationTags: number;
};

export type TrackedEvent =
  | TrackedAsyncEvent
  | TrackedButtonClickEvent
  | TrackedRequestVisualExaminationVideoEvent
  | TrackedSaveExaminationDiagnosisEvent;
