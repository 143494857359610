import { IconBox, IconProps } from '../IconBox';

const ShieldIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.6 5.999A14.345 14.345 0 0 0 12 2.333 14.345 14.345 0 0 0 21.4 6c.132.78.2 1.583.2 2.4 0 6.27-4.007 11.605-9.6 13.582C6.407 20.003 2.4 14.67 2.4 8.4c0-.818.068-1.62.2-2.401Zm13.848 4.45a1.2 1.2 0 0 0-1.697-1.698L10.8 12.703 9.248 11.15a1.2 1.2 0 1 0-1.697 1.697l2.4 2.4a1.2 1.2 0 0 0 1.697 0l4.8-4.8Z"
    />
  </IconBox>
);

export default ShieldIcon;
