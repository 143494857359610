import React, { FC, memo, useState, useMemo } from 'react';
import styled, { css } from 'styled-components';
import {
  DownloadFileIcon,
  EmailIcon,
  Heading,
  IconButton,
  LinkIcon,
  Modal,
  ShareIcon,
} from '@higo/ui';
import { useCreateShareExaminationLinkMutation } from './useCreateShareExaminationLinkMutation';
import { FormattedMessage, useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useDispatchDoctorTrackedType } from 'hooks/useDispatchDoctorTrackedEvent';
import {
  DoctorTrackedType,
  TrackedEventButtonClickAction,
} from '@higo/common/lib/features/TrackedEvent';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ExaminationPageModel } from '@higo/common/lib/features/ExaminationPage/models';
import { ExaminationDoctorDiagnosisDTO } from '@higo/api/lib/models/examinationDoctorDiagnosisDTO';
import { PDFdocument } from './PDFdocument/PDFdocument';
import { useMedicalOperatorNotesQuery } from '@higo/common/lib/features/ExaminationPage/hooks/queries';
import {
  useHeartRatePhysicalExaminationQuery,
  useTemperaturePhysicalExaminationQuery,
  useHeartAuscultationPhysicalExaminationQuery,
  useLungsAuscultationPhysicalExaminationQuery,
  useAbdominalAuscultationPhysicalExaminationQuery,
  useCoughAuscultationPhysicalExaminationQuery,
} from '@higo/common/lib/features/ExaminationPage/hooks/queries/physicalExamination';
import { useProfileDataQuery } from 'hooks/queries/useProfileDataQuery';
import abdomen from '@higo/doctor/src/components/ShareExaminationModal/asculationsImagesForPDF/abdomen/belly.png';
import { asculationPointsCreate } from './utils/asculationPointsPDFCreate';
import {
  heartAsculationImages,
  lungsBackAsculationImages,
  lungsFrontAsculationImages,
} from './utils/PDFimagesMap';
import { ReactQueryAutoSaveSaveStatus } from '@higo/common/lib/hooks/useReactQueryAutoSave';

const ContentContainer = styled.div`
  padding: 2rem;
  width: 12.5625rem;
`;

const StyledHeading = styled(Heading)`
  margin: 0 0 1rem;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListElement = styled('li')(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1rem 1fr;
    grid-column-gap: 1rem;
    align-items: center;
    padding: 0.5rem 0.75rem;

    color: ${theme.palette.secondary['500']};
    ${theme.typography.subtitle1};

    &:hover {
      cursor: pointer;
      background-color: ${theme.palette.neutral[300]};
      border-radius: 0.5rem;
    }
  `,
);

interface ShareExaminationModalProps {
  examinationId: number;
  examData: ExaminationPageModel;
  diagnosisData?: ExaminationDoctorDiagnosisDTO;
  diagnosisAutoSaveState: ReactQueryAutoSaveSaveStatus | undefined;
  skinImageForPDF?: string;
  isSkinImageDiagnostic?: boolean | '';
  throatImageForPDF?: string;
  isThroatImageDiagnostic?: boolean | '';
  rightEarImageForPDF?: string;
  isRightEarImageDiagnostic: boolean | '';
  leftEarImageForPDF?: string;
  isLeftEarImageDiagnostic: boolean | '';
  isThroatDataLoading: boolean;
  isSkinDataLoading: boolean;
  isRightEarDataLoading: boolean;
  isLeftEarDataLoading: boolean;
}

export const ShareExaminationModal: FC<ShareExaminationModalProps> = memo(
  ({ ...props }: ShareExaminationModalProps) => {
    const [visible, setVisible] = useState(false);
    const sendTrackedEvent = useDispatchDoctorTrackedType();

    const { mutateAsync: generateShareLink } =
      useCreateShareExaminationLinkMutation();
    const intl = useIntl();

    const { data: MOnotes } = useMedicalOperatorNotesQuery(props.examinationId);
    const note = useMemo(() => MOnotes?.[0], [MOnotes]);

    const { data: heartRateData, isLoading: isLoadingHeartrate } =
      useHeartRatePhysicalExaminationQuery(props.examinationId);

    const { data: temperatureData, isLoading: isLoadingTemp } =
      useTemperaturePhysicalExaminationQuery(props.examinationId);

    const { data: profileData, isLoading: isLoadingProfile } =
      useProfileDataQuery();

    const { data: heartData, isLoading: isLoadingHeart } =
      useHeartAuscultationPhysicalExaminationQuery(props.examinationId);

    const { data: lungsData, isLoading: isLoadingLungs } =
      useLungsAuscultationPhysicalExaminationQuery(props.examinationId);

    const { data: abdominalData, isLoading: isLoadingAbdominal } =
      useAbdominalAuscultationPhysicalExaminationQuery(props.examinationId);
    const { data: coughData, isLoading: isLoadingCough } =
      useCoughAuscultationPhysicalExaminationQuery(props.examinationId);

    const isSomeDataLoading =
      props.diagnosisAutoSaveState === 'saving' ||
      props.diagnosisAutoSaveState === 'unsaved' ||
      props.isThroatDataLoading ||
      props.isSkinDataLoading ||
      props.isLeftEarDataLoading ||
      props.isRightEarDataLoading ||
      isLoadingHeartrate ||
      isLoadingTemp ||
      isLoadingProfile ||
      isLoadingHeart ||
      isLoadingLungs ||
      isLoadingAbdominal ||
      isLoadingCough;

    const heartDataToShowInPDF = asculationPointsCreate(
      heartData?.heartAuscultationResults,
      4,
      props.diagnosisData?.heartAuscultation,
    )
      .toString()
      .replace(/,/g, '');

    const lungsDataToPDF = asculationPointsCreate(
      lungsData?.lungsResults,
      12,
      props.diagnosisData?.lungAuscultation,
    );

    const lungsFrontDataToPDF = lungsDataToPDF
      ?.slice(0, 6)
      .toString()
      .replace(/,/g, '');
    const lungsBackDataToPDF = lungsDataToPDF
      ?.slice(-6)
      .toString()
      .replace(/,/g, '');

    const abdominalDataToPDF = asculationPointsCreate(
      abdominalData?.abdominalAuscultationResults,
      2,
    )
      .toString()
      .replace(/,/g, '');

    const coughDataToPDF =
      coughData?.coughResults.length !== 0 ? 'Point 1 - conducted' : undefined;

    const patientGender = props.examData.patient.gender;
    const patientMaturity = props.examData.patient.maturity;

    const heartAsculationImageToShow =
      heartAsculationImages[patientGender][patientMaturity];

    const lungsBackAsculationImageToShow =
      lungsBackAsculationImages[patientGender][patientMaturity];
    const lungsFrontAsculationImageToShow =
      lungsFrontAsculationImages[patientGender][patientMaturity];

    const handleSendEmail = () =>
      generateShareLink(props.examinationId).then(({ fullLink }) => {
        sendTrackedEvent({
          event: DoctorTrackedType.ButtonClick,
          eventAction: TrackedEventButtonClickAction.ShareExaminationViaEmail,
          examinationId: props.examinationId,
        });

        window.location.href = `mailto:?subject=${encodeURIComponent(
          intl.formatMessage({ id: 'share.sendViaEmail.subject' }),
        )}&body=${encodeURIComponent(
          intl.formatMessage(
            { id: 'share.sendViaEmail.body' },
            { link: fullLink },
          ),
        )}`;
      });

    const handleCopyLink = () =>
      generateShareLink(props.examinationId).then(({ fullLink }) => {
        sendTrackedEvent({
          event: DoctorTrackedType.ButtonClick,
          eventAction: TrackedEventButtonClickAction.ShareExaminationViaLink,
          examinationId: props.examinationId,
        });

        navigator.clipboard.writeText(fullLink).then(() =>
          toast.success(
            intl.formatMessage({
              id: 'share.copyLink.copiedToClipboard',
            }),
          ),
        );
      });

    return (
      <>
        <Modal
          visible={visible}
          onClose={() => {
            setVisible(false);
          }}
        >
          <ContentContainer>
            <StyledHeading level={3}>
              <FormattedMessage id="share.share" />
            </StyledHeading>
            <List>
              <ListElement onClick={handleSendEmail} id="send-via-email-link">
                <EmailIcon />
                <FormattedMessage id="share.sendViaEmail" />
              </ListElement>
              <ListElement id="print-pdf-link">
                <DownloadFileIcon />
                <PDFDownloadLink
                  document={
                    <PDFdocument
                      examData={props.examData}
                      diagnosisData={props.diagnosisData}
                      MOnotes={note}
                      heartRateData={heartRateData}
                      temperatureData={temperatureData}
                      doctorData={profileData}
                      skinImage={props.skinImageForPDF && props.skinImageForPDF}
                      isSkinImageDiagnostic={
                        props.skinImageForPDF && props.isSkinImageDiagnostic
                      }
                      throatImage={
                        props.throatImageForPDF && props.throatImageForPDF
                      }
                      isThroatImageDiagnostic={
                        props.throatImageForPDF && props.isThroatImageDiagnostic
                      }
                      rightEarImage={props.rightEarImageForPDF}
                      isRightEarImageDiagnostic={
                        props.isRightEarImageDiagnostic
                      }
                      leftEarImage={props.leftEarImageForPDF}
                      isLeftEarImageDiagnostic={props.isLeftEarImageDiagnostic}
                      heartAsculationImageToShow={heartAsculationImageToShow}
                      lungsBackAsculationImageToShow={
                        lungsBackAsculationImageToShow
                      }
                      lungsFrontAsculationImageToShow={
                        lungsFrontAsculationImageToShow
                      }
                      abdomenImage={abdomen}
                      heartDataToPDF={
                        heartData?.heartAuscultationResults.length !== 0
                          ? heartDataToShowInPDF
                          : undefined
                      }
                      lungsFrontDataToPDF={
                        lungsData?.lungsResults.length !== 0
                          ? lungsFrontDataToPDF
                          : undefined
                      }
                      lungsBackDataToPDF={
                        lungsData?.lungsResults.length !== 0
                          ? lungsBackDataToPDF
                          : undefined
                      }
                      abdominalDataToPDF={
                        abdominalData?.abdominalAuscultationResults.length !== 0
                          ? abdominalDataToPDF
                          : undefined
                      }
                      coughDataToPDF={
                        coughData?.coughResults.length !== 0
                          ? coughDataToPDF
                          : undefined
                      }
                    />
                  }
                  fileName={`higo-exam-${props.examinationId}.pdf`}
                >
                  {({ loading }) =>
                    loading || isSomeDataLoading ? (
                      <FormattedMessage id="share.creatingPDF" />
                    ) : (
                      <FormattedMessage id="share.printToPDF" />
                    )
                  }
                </PDFDownloadLink>
              </ListElement>

              <ListElement onClick={handleCopyLink} id="copy-link">
                <LinkIcon />
                <FormattedMessage id="share.copyLink" />
              </ListElement>
            </List>
          </ContentContainer>
        </Modal>

        <IconButton
          color="secondary"
          size="small"
          icon={<ShareIcon />}
          id="share-button"
          onClick={() => {
            setVisible(true);
            sendTrackedEvent({
              event: DoctorTrackedType.ButtonClick,
              eventAction:
                TrackedEventButtonClickAction.OpenShareExaminationModal,
              examinationId: props.examinationId,
            });
          }}
        />
      </>
    );
  },
);
