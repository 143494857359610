import { useQuery } from 'react-query';
import { fetchAbdominalAuscultationPhysicalExamination } from '@higo/api/lib/services';
import { useExaminationApiContext } from '@higo/common/src/features/ExaminationPage/providers';
import { ExaminationQueryKey } from '@higo/common/src/features/ExaminationPage/ExaminationQueryKey';
import { AbdominalAuscultationPhysicalExaminationDTO } from '@higo/api/lib/models/physicalExaminations';
import { pipe, prop } from 'rambda';
import {
  indexByFileName,
  remapToFileIdArray,
} from '@higo/common/src/utils/fileResourceUtils';
import { useExaminationAzureResourcesQuery } from '@higo/common/src/features/ExaminationPage/hooks/queries/useExaminationAzureResourcesQuery';

export const useAbdominalAuscultationPhysicalExaminationQuery = <
  TData = AbdominalAuscultationPhysicalExaminationDTO,
>(
  examinationId: number,
  select?: (data: AbdominalAuscultationPhysicalExaminationDTO) => TData,
) => {
  const axios = useExaminationApiContext();

  return useQuery(
    [
      ExaminationQueryKey.AbdominalAuscultationPhysicalExamination,
      examinationId,
    ],
    async () =>
      fetchAbdominalAuscultationPhysicalExamination(axios)(examinationId),
    {
      enabled: !isNaN(examinationId),
      select,
    },
  );
};

const selectFileResultIds: (
  data: AbdominalAuscultationPhysicalExaminationDTO,
) => number[] = pipe(prop('abdominalAuscultationResults'), remapToFileIdArray);

export const useAbdominalAuscultationPhysicalExaminationResourceFiles = (
  id: number,
) => {
  const { data: ids } = useAbdominalAuscultationPhysicalExaminationQuery(
    id,
    selectFileResultIds,
  );
  return useExaminationAzureResourcesQuery(ids ?? [], indexByFileName);
};
