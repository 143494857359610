import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';
import { cloneElement, forwardRef, ReactElement } from 'react';

import { buttonUnstyledClasses } from '@mui/base';
import { BaseButton, BaseButtonProps, BaseButtonSize } from './BaseButton';
import { IconProps } from '../IconBox';

const sizeStyleMap: Record<
  BaseButtonSize,
  FlattenInterpolation<ThemeProps<DefaultTheme>>
> = {
  default: css`
    width: 3rem;

    &:not(.${buttonUnstyledClasses.disabled}) {
      &:hover {
        padding-bottom: 0.5rem;
      }
    }
  `,
  small: css`
    width: 2rem;

    &:not(.${buttonUnstyledClasses.disabled}) {
      &:hover {
        padding-bottom: 0.25rem;
      }
    }
  `,
};

const StyledButton = styled(BaseButton)<BaseButtonProps>(
  ({ theme, size }) =>
    css`
      transition: ${theme.transitions.create(['padding'], {
        duration: theme.transitions.duration.shortest,
      })};

      ${sizeStyleMap[size ?? 'default']}
    `,
);

export type IconButtonProps = {
  icon: ReactElement<IconProps>;
} & Omit<BaseButtonProps, 'children'>;

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon, ...props }, ref) => (
    <StyledButton {...props} ref={ref}>
      {cloneElement(icon, { ...icon.props, size: 16 })}
    </StyledButton>
  ),
);

IconButton.displayName = 'IconButton';
