import { FC, Fragment, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { StyleProps } from '@summer/jst-react';

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.25rem;
  height: fit-content;
`;

export const ExaminationOverviewInfoListLabel = styled('div')(
  ({ theme }) => css`
    ${theme.typography.body2}
    color: ${theme.palette.secondary['500']};
    max-width: 8rem;
  `,
);

export const ExaminationOverviewInfoListValue = styled('div')(
  ({ theme }) => css`
    ${theme.typography.subtitle2}
    color: ${theme.palette.secondary['900']};
  `,
);

export type ExaminationOverviewInfoListProps = {
  items: { translationKey: string; value: ReactNode }[];
} & StyleProps;

export const OverviewInfoList: FC<ExaminationOverviewInfoListProps> = ({
  style,
  className,
  items,
}) => (
  <Container style={style} className={className}>
    {items.map(({ translationKey, value }) => (
      <Fragment key={translationKey}>
        <ExaminationOverviewInfoListLabel id={`${translationKey}-label`}>
          <FormattedMessage id={translationKey} />:
        </ExaminationOverviewInfoListLabel>
        <ExaminationOverviewInfoListValue id={`${translationKey}-value`}>
          {value}
        </ExaminationOverviewInfoListValue>
      </Fragment>
    ))}
  </Container>
);
