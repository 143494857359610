import { FC } from 'react';
import { Modal, ModalProps } from '@higo/ui/src/components/Modal';
import styled from 'styled-components';

export type DialogProps = Omit<ModalProps, 'visible'> & {
  open: boolean;
};

const DialogLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 24rem;
  padding: 2rem;
`;

export const Dialog: FC<DialogProps> = ({
  open,
  style,
  className,
  children,
  ...props
}) => {
  return (
    <Modal visible={open} {...props}>
      <DialogLayout style={style} className={className}>
        {children}
      </DialogLayout>
    </Modal>
  );
};
