import React from 'react';
import styled, { css } from 'styled-components';
import { LoaderIcon } from '@higo/ui';

const Container = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
`;

const StyledLoaderIcon = styled(LoaderIcon)(
  ({ theme }) => css`
    color: ${theme.palette.primary[500]};
  `,
);

export const AutoSaveIndicator = () => (
  <Container>
    <StyledLoaderIcon />
  </Container>
);
