import { FC, memo, useMemo, useRef } from 'react';
import styled, { css, CSSProperties } from 'styled-components';
import { StyleProps } from '@summer/jst-react';
import { createPortal } from 'react-dom';
import { useKeyPressEvent } from 'react-use';
import { Transition, TransitionStatus } from 'react-transition-group';
import CloseIcon from '@higo/ui/src/components/icons/CloseIcon';
import { RoundIconButton } from '@higo/ui/src/components/buttons/RoundIconButton';
import { ClickAwayListener } from '@mui/base';
import { ENTERED } from 'react-transition-group/Transition';

export interface SideSheetProps extends StyleProps {
  visible: boolean;
  anchorSelector?: string;
  onClose: () => void;
}

const Overlay = styled('div')(
  ({ theme }) => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${theme.layers.modal};

    display: flex;
    justify-content: flex-end;
    background: rgba(23, 24, 31, 0.6);

    opacity: 0;
    transition: ${theme.transitions.create('opacity', {
      duration: theme.transitions.duration.standard,
    })};
  `,
);

const Wrapper = styled('aside')(
  ({ theme }) => css`
    position: relative;
    max-width: max-content;
    background: ${theme.palette.white};
    pointer-events: all;

    transform: translateX(100%);
    transition: ${theme.transitions.create('transform', {
      duration: theme.transitions.duration.standard,
    })};
  `,
);

const CloseButton = styled(RoundIconButton).attrs({ icon: <CloseIcon /> })`
  position: absolute;
  top: 1rem;
  right: calc(100% + 1rem);
`;

const transitionStyles: Partial<Record<TransitionStatus, CSSProperties>> = {
  entered: { opacity: 1, transform: 'translateX(0)' },
  unmounted: { pointerEvents: 'none' },
  exited: { pointerEvents: 'none' },
};

export const SideSheet: FC<SideSheetProps> = memo<SideSheetProps>(
  ({ children, visible, anchorSelector = 'body', onClose, ...props }) => {
    const ref = useRef(null);

    useKeyPressEvent('Escape', () => {
      visible && onClose();
    });

    const rootElement = useMemo(
      () => document.querySelector(anchorSelector),
      [anchorSelector],
    );

    if (!rootElement) {
      return null;
    }

    return createPortal(
      <Transition in={visible} timeout={100} nodeRef={ref}>
        {(state) => (
          <Overlay ref={ref} style={transitionStyles[state]} {...props}>
            <ClickAwayListener
              onClickAway={() => {
                ENTERED === state && onClose();
              }}
            >
              <Wrapper style={transitionStyles[state]}>
                <CloseButton
                  id="close-button"
                  onClick={onClose}
                  color="secondary"
                />
                {children}
              </Wrapper>
            </ClickAwayListener>
          </Overlay>
        )}
      </Transition>,
      rootElement,
    );
  },
);

SideSheet.displayName = 'SideSheet';
