import {
  examinationPageSummaryFormDefaultValues,
  ExaminationPageSummaryFormValue,
} from '@higo/common/src/features/ExaminationPage/sections/ExaminationPageSummarySection/examinationPageSummaryFormConfig';
import { AuscultationDTO } from '@higo/api/lib/models/auscultationDTO';
import { evolve, ifElse, indexBy, isEmpty, isNil, pipe, values } from 'rambda';
import { ExaminationDoctorDiagnosisDTO } from '@higo/api/lib/models/examinationDoctorDiagnosisDTO';
import { purge } from '@higo/common/src/utils';
import { getAuscultationPointTagFieldName } from '@higo/common/src/features/ExaminationPage/utils/buildAuscultationPoints';

export const bodyAuscultationPointCount = {
  Lungs: 12,
  Heart: 4,
};

type DiagnosisAuscultationTagsFormValue = Record<
  string,
  {
    measurementPoint: number;
    tags: string[];
  }
>;

type DiagnosisAuscultationFormValue = {
  tags: DiagnosisAuscultationTagsFormValue;
};

export type ExaminationPageDiagnosisFormValue = {
  heartAuscultation?: DiagnosisAuscultationFormValue;
  lungAuscultation?: DiagnosisAuscultationFormValue;
} & ExaminationPageSummaryFormValue;

const getAuscultationPointTagsDefaultValue = (
  pointsCount: number,
): DiagnosisAuscultationTagsFormValue =>
  indexBy(
    ({ measurementPoint }) =>
      getAuscultationPointTagFieldName(measurementPoint),
    new Array(pointsCount).fill(0).map((_, i) => ({
      measurementPoint: i + 1, // array index 0
      tags: [],
    })),
  );

export const examinationPageDiagnosisDefaultValue: ExaminationPageDiagnosisFormValue =
  {
    heartAuscultation: {
      tags: getAuscultationPointTagsDefaultValue(
        bodyAuscultationPointCount.Heart,
      ),
    },
    lungAuscultation: {
      tags: getAuscultationPointTagsDefaultValue(
        bodyAuscultationPointCount.Lungs,
      ),
    },
    ...examinationPageSummaryFormDefaultValues,
  };

const mapToAuscultationDTO: (
  v: DiagnosisAuscultationFormValue,
) => AuscultationDTO = evolve({
  tags: (v: DiagnosisAuscultationTagsFormValue) =>
    values(v).filter(({ tags }) => !isEmpty(tags)),
});

export const mapToExaminationDoctorDiagnosisDTO: (
  model: ExaminationPageDiagnosisFormValue,
) => ExaminationDoctorDiagnosisDTO | undefined = ifElse(
  pipe(purge, isEmpty),
  (_) => undefined,
  pipe(
    ({
      note,
      diagnosis,
      medicines,
      recommendations,
      lungAuscultation,
      heartAuscultation,
    }) => ({
      timestamp: new Date().toISOString(),
      comment: note,
      diagnosis: diagnosis,
      medications: medicines,
      recommendation: recommendations,
      lungAuscultation:
        lungAuscultation && mapToAuscultationDTO(lungAuscultation),
      heartAuscultation:
        heartAuscultation && mapToAuscultationDTO(heartAuscultation),
    }),
    purge,
  ),
);

const mapToDiagnosisAuscultationFormValue = (
  dto: AuscultationDTO,
  pointsCount: number,
): DiagnosisAuscultationFormValue => {
  let result = getAuscultationPointTagsDefaultValue(pointsCount);

  dto.tags.forEach(({ measurementPoint, tags }) => {
    result[getAuscultationPointTagFieldName(measurementPoint)].tags = tags;
  });

  return {
    tags: result,
  };
};

export const mapToExaminationPageDiagnosisFormValue: (
  dto: ExaminationDoctorDiagnosisDTO | undefined,
) => ExaminationPageDiagnosisFormValue = ifElse(
  isNil,
  (_) => examinationPageDiagnosisDefaultValue,
  (dto) => ({
    ...examinationPageDiagnosisDefaultValue,
    note: dto.comment,
    diagnosis: dto.diagnosis,
    medicines: dto.medications,
    recommendations: dto.recommendation,
    lungAuscultation:
      dto.lungAuscultation &&
      mapToDiagnosisAuscultationFormValue(
        dto.lungAuscultation,
        bodyAuscultationPointCount.Lungs,
      ),
    heartAuscultation:
      dto.heartAuscultation &&
      mapToDiagnosisAuscultationFormValue(
        dto.heartAuscultation,
        bodyAuscultationPointCount.Heart,
      ),
  }),
);
