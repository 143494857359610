import styled, { css } from 'styled-components';
import { Input } from '@higo/ui/src/components/Input';
import { inputUnstyledClasses } from '@mui/base';
import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useRef,
  useState,
} from 'react';
import SearchIcon from './icons/SearchIcon';
import { UseInputParameters } from '@mui/base/InputUnstyled/useInput.types';
import { SingleLineInputUnstyledProps } from '@mui/base/InputUnstyled/InputUnstyled.types';

export interface SearchInputProps
  extends Omit<SingleLineInputUnstyledProps & UseInputParameters, 'onSubmit'> {
  placeholder?: string;
  onSubmit?: (value: string) => void;
  id?: string;
}

const StyledInput = styled(Input)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    border: 1px solid ${theme.input.default.border};
    border-radius: 0.5rem;

    .${inputUnstyledClasses.input} {
      background: transparent;
      box-shadow: none !important;
      border: none !important;
      color: ${theme.input.default.primary};
    }
    &:not(.${inputUnstyledClasses.disabled}) {
      &:hover {
        border-color: ${theme.input.hover.border};
      }

      &.${inputUnstyledClasses.focused} {
        border-color: ${theme.input.focused.border};
        .${inputUnstyledClasses.input} {
          ::placeholder {
            color: ${theme.input.focused.placeholder};
          }
        }
      }
    }

    &.${inputUnstyledClasses.disabled} {
      cursor: not-allowed;
      background-color: ${theme.input.disabled.background};
      border-color: ${theme.input.disabled.border};
      color: ${theme.input.disabled.primary};

      .${inputUnstyledClasses.input} {
        background-color: ${theme.input.disabled.background};
      }
    }
  `,
);

const SearchAdornment = styled('button')(
  ({ theme }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0 0 0.5rem;
    padding: 0;

    background: transparent;
    border: none;
    &:not([disabled]) {
      color: ${theme.input.default.placeholder};
    }
  `,
);

export const SearchInput: FC<SearchInputProps> = ({ onSubmit, ...props }) => {
  const [value, setValue] = useState('');

  const ref = useRef<HTMLInputElement>(null);

  const handleSubmit = useCallback(() => {
    onSubmit?.(value);
    setValue('');
  }, [value, onSubmit]);

  const handleOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  return (
    <StyledInput
      ref={ref}
      size="small"
      multiline={false}
      value={value}
      onChange={handleOnChange}
      onKeyDown={handleKeyDown}
      startAdornment={
        <SearchAdornment
          disabled={props.disabled}
          onClick={handleSubmit}
          id="search-button"
        >
          <SearchIcon size={22} />
        </SearchAdornment>
      }
      {...props}
    />
  );
};
