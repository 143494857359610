import { IconBox, IconProps } from '../IconBox';

const TooltipIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12Zm-13.5 0a1.5 1.5 0 0 1 3 0v6a1.5 1.5 0 0 1-3 0v-6ZM12 4.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
    />
  </IconBox>
);

export default TooltipIcon;
