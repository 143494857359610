import { useAudioReactPlayer } from './useAudioReactPlayer';
import { FC } from 'react';
import ReactPlayer, { Config } from 'react-player';
import { PlayPauseButton } from './PlayPauseButton';

const config: Config = {
  file: {
    forceAudio: true,
  },
};

export type AudioPlaybackButtonProps = {
  url?: string;
  isLoading?: boolean;
  id?: string;
};

export const AudioPlaybackButton: FC<AudioPlaybackButtonProps> = ({
  url,
  isLoading,
  id,
}) => {
  const { playerRef, state, api, playerProps } = useAudioReactPlayer();

  return (
    <div id={id}>
      {url && (
        <ReactPlayer
          ref={playerRef}
          config={config}
          url={url}
          width={0}
          height={0}
          playing={state.playing}
          {...playerProps}
        />
      )}
      <PlayPauseButton
        isLoading={isLoading || !state.ready}
        isPlaying={state.playing}
        played={state.played}
        onClick={api.playPause}
        disabled={!state.ready}
        id="playbutton"
      />
    </div>
  );
};
