import { Gender } from '@higo/api/lib/models/gender';
import { createElement, FC } from 'react';
import { ResponsiveImage } from '@higo/ui';
import { Maturity } from '@higo/common/src/types';
import manFront from './man_front.png';
import manFront2 from './man_front@2x.png';
import manFront4 from './man_front@4x.png';
import womanFront from './woman_front.png';
import womanFront2 from './woman_front@2x.png';
import womanFront4 from './woman_front@4x.png';
import boyFront from './boy_front.png';
import boyFront2 from './boy_front@2x.png';
import boyFront4 from './boy_front@4x.png';
import girlFront from './girl_front.png';
import girlFront2 from './girl_front@2x.png';
import girlFront4 from './girl_front@4x.png';
import babyFront from './baby_front.png';
import babyFront2 from './baby_front@2x.png';
import babyFront4 from './baby_front@4x.png';
import { BodyImageProps } from '@higo/common/src/components/Auscultation/bodyImageProps';

const manFrontBody = {
  srcSet: [
    {
      src: manFront,
      media: '1x',
    },
    {
      src: manFront2,
      media: '2x',
    },
    {
      src: manFront4,
      media: '4x',
    },
  ],
};
const boyFrontBody = {
  srcSet: [
    {
      src: boyFront,
      media: '1x',
    },
    {
      src: boyFront2,
      media: '2x',
    },
    {
      src: boyFront4,
      media: '4x',
    },
  ],
};
const babyFrontBody = {
  srcSet: [
    {
      src: babyFront,
      media: '1x',
    },
    {
      src: babyFront2,
      media: '2x',
    },
    {
      src: babyFront4,
      media: '4x',
    },
  ],
};

const bodies = {
  [Gender.Male]: {
    [Maturity.Adult]: manFrontBody,
    [Maturity.Teen]: boyFrontBody,
    [Maturity.Baby]: babyFrontBody,
  },
  [Gender.Female]: {
    [Maturity.Adult]: {
      srcSet: [
        {
          src: womanFront,
          media: '1x',
        },
        {
          src: womanFront2,
          media: '2x',
        },
        {
          src: womanFront4,
          media: '4x',
        },
      ],
    },
    [Maturity.Teen]: {
      srcSet: [
        {
          src: girlFront,
          media: '1x',
        },
        {
          src: girlFront2,
          media: '2x',
        },
        {
          src: girlFront4,
          media: '4x',
        },
      ],
    },
    [Maturity.Baby]: babyFrontBody,
  },
  [Gender.Other]: {
    [Maturity.Adult]: manFrontBody,
    [Maturity.Teen]: boyFrontBody,
    [Maturity.Baby]: babyFrontBody,
  },
  [Gender.Unknown]: {
    [Maturity.Adult]: manFrontBody,
    [Maturity.Teen]: boyFrontBody,
    [Maturity.Baby]: babyFrontBody,
  },
};

export const FrontBodyImage: FC<BodyImageProps> = ({
  gender,
  maturity,
  ...params
}) =>
  createElement(ResponsiveImage, {
    ...params,
    ...bodies[gender][maturity],
    alt: 'Lungs auscultation front points image',
  });
