import { useQuery } from 'react-query';
import { fetchHeartRateWavPhysicalExamination } from '@higo/api/lib/services';
import { useExaminationApiContext } from '@higo/common/src/features/ExaminationPage/providers';
import { ExaminationQueryKey } from '@higo/common/src/features/ExaminationPage/ExaminationQueryKey';

export const useHeartRateWavPhysicalExaminationQuery = (
  examinationId: number,
  enabled = !isNaN(examinationId),
) => {
  const axios = useExaminationApiContext();

  return useQuery(
    [ExaminationQueryKey.HeartRateWavPhysicalExamination, examinationId],
    async () => fetchHeartRateWavPhysicalExamination(axios)(examinationId),
    {
      enabled,
    },
  );
};
