import { Measurement, MeasurementUnit } from '@higo/api/lib/models/measurement';
import { defaultNAValue } from '@higo/common/src/utils';
import { PartialRecord } from '@higo/common/src/types';
import { isNil } from 'rambda';

export const formatTemperatureMeasurement = (temperature?: Measurement) =>
  temperature ? `${temperature?.value} °${temperature?.unit}` : defaultNAValue;

export const formatDefaultMeasurement = (pulse?: Measurement) =>
  pulse ? `${pulse?.value} ${pulse?.unit}` : defaultNAValue;

const measurementUnitFormat: PartialRecord<
  MeasurementUnit,
  (measurement: Measurement) => string
> = {
  C: formatTemperatureMeasurement,
};

export const formatMeasurement = (measurement?: Measurement) => {
  if (isNil(measurement)) {
    return defaultNAValue;
  }

  const formatter =
    measurementUnitFormat[measurement.unit] ?? formatDefaultMeasurement;
  return formatter(measurement);
};
