import { IconBox, IconProps } from '../IconBox';

const ArrowLeftIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.06 4.94a1.5 1.5 0 0 1 0 2.12L11.122 12l4.94 4.94a1.5 1.5 0 0 1-2.122 2.12l-6-6a1.5 1.5 0 0 1 0-2.12l6-6a1.5 1.5 0 0 1 2.122 0Z"
    />
  </IconBox>
);

export default ArrowLeftIcon;
