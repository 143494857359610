import styled, { css } from 'styled-components';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { ExaminationPageSectionId } from '../../models';
import { ExaminationPageSection } from '../ExaminationPageSection';
import { ContextPhoto } from './ContextPhoto';
import { AspectRatio } from '@higo/ui/lib/components';
import { isNil } from 'rambda';
import { useExaminationFileResourceQuery } from '../../hooks/queries';
import { Skeleton } from '@higo/ui';
import { VideoPlayer } from '@higo/common/src/components/VideoPlayer';

const ContextPhotoSection = styled.div<{ bothFiles: boolean }>`
  display: grid;
  grid-template-rows: 18.0625rem;
  grid-template-columns: ${(props) =>
    props.bothFiles === true ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)'};
  grid-column-gap: 0.5rem;

  .vjs-big-play-button {
    top: 40%;
  }
`;

const StyledAspectRatio = styled(AspectRatio)(
  ({ theme }) => css`
    border-radius: 0.5rem;
    background-color: ${theme.palette.neutral['500']};
    max-width: 18.0625rem;
    overflow: hidden;
  `,
);

interface ExaminationContextSectionProps {
  contextPhotoFileId: number | undefined;
  contextVideoFileId: number | undefined;
  isLoading?: boolean;
  hasBothContextFiles: boolean;
}

export const ExaminationPageContextSection: FC<ExaminationContextSectionProps> =
  ({
    contextPhotoFileId,
    contextVideoFileId,
    isLoading,
    hasBothContextFiles,
  }) => {
    const { data: contextPhotoSrc, isLoading: isContextPhotoLoading } =
      useExaminationFileResourceQuery(contextPhotoFileId);
    const { data: contextVideoSrc, isLoading: isContextVideoLoading } =
      useExaminationFileResourceQuery(contextVideoFileId);

    return (
      <ExaminationPageSection
        sectionId={ExaminationPageSectionId.ExaminationContext}
        title={
          <FormattedMessage id="examinationPage.section.examinationContext.title" />
        }
        divider={null}
      >
        <div id="context-section-container">
          <ContextPhotoSection bothFiles={hasBothContextFiles}>
            {isNil(contextPhotoFileId) ? null : (
              <StyledAspectRatio>
                <ContextPhoto
                  src={contextPhotoSrc?.link}
                  alt="Context photo"
                  isLoading={isLoading || isContextPhotoLoading}
                />
              </StyledAspectRatio>
            )}

            {isNil(contextVideoFileId) ? null : (
              <StyledAspectRatio>
                {isLoading || (isContextVideoLoading && <Skeleton />)}

                {contextVideoSrc?.link && (
                  <VideoPlayer videoUrl={contextVideoSrc?.link} fluid={true} />
                )}
              </StyledAspectRatio>
            )}
          </ContextPhotoSection>
        </div>
      </ExaminationPageSection>
    );
  };
