import { QuestionType } from '@higo/api';
import { createElement, FC, memo } from 'react';
import {
  OpenQuestionAnswerProps,
  OpenQuestionAnswerRenderer,
} from '@higo/common/src/features/FilledSurvey/ComponentRenderers/OpenQuestionAnswerRenderer';
import {
  SingleClosedQuestionAnswerProps,
  SingleClosedQuestionAnswerRenderer,
} from '@higo/common/src/features/FilledSurvey/ComponentRenderers/SingleClosedQuestionAnswerRenderer';

const createRenderer = (props: QuestionAnswerRendererProps) => {
  switch (props.questionType) {
    case QuestionType.OPEN:
      return createElement(OpenQuestionAnswerRenderer, props);
    case QuestionType.SINGLE_CLOSED:
    case QuestionType.MULTIPLE_CLOSED:
      return createElement(SingleClosedQuestionAnswerRenderer, props);
  }
};

export type QuestionAnswerRendererProps =
  | OpenQuestionAnswerProps
  | SingleClosedQuestionAnswerProps;

export const QuestionAnswerRenderer: FC<QuestionAnswerRendererProps> = memo(
  (props) => {
    return createRenderer(props);
  },
);
