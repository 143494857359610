import { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useProfileDataQuery } from 'hooks/queries/useProfileDataQuery';
import {
  Avatar,
  combineString,
  Menu,
  MenuButton,
  MenuItem,
  useMenu,
} from '@higo/ui';
import { useLogout } from 'hooks/useLogout';
import { UserRole } from '@higo/api';
import { useUserRole } from 'hooks/useUserRole';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { routes } from 'routes';

const rolePrefixMap = {
  [UserRole.Doctor]: 'Dr',
  [UserRole.SuperDoctor]: 'Dr',
  [UserRole.MedicalOperator]: '',
  [UserRole.DemoAccount]: '',
} as const;

const baseLinkButton = `
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
  width: 100%;
  height: 100%;
`;

const Wrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.palette.text};
    margin-left: 0.5rem;
  `,
);

const Text = styled('div')(
  ({ theme }) => css`
    ${theme.typography.lineClamp(1)}
    ${theme.typography.body};
    max-width: 10rem;
    font-weight: 700;
    margin-right: 0.5rem;

    ${theme.media.desktop.max} {
      display: none;
    }
  `,
);

const StyledMenu = styled(Menu)(
  ({ theme }) => css`
    z-index: ${theme.layers.popper};
  `,
);

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

const ButtonLink = styled(Link)`
  ${baseLinkButton}
`;

const ButtonAnchor = styled('a')`
  ${baseLinkButton}
`;

const LogoutButton = styled(Link)(
  ({ theme }) => css`
    && {
      color: ${theme.palette.error};
      ${baseLinkButton}
      padding: 6px;
    }
  `,
);

export const HeaderProfileMenu: FC = () => {
  const { data: profile } = useProfileDataQuery();
  const { role } = useUserRole();

  const fullName = useMemo(() => {
    const rolePrefix = role ? rolePrefixMap[role] : null;

    return combineString([rolePrefix, profile?.firstName, profile?.lastName]);
  }, [profile, role]);

  const logout = useLogout();

  const { isOpened, anchorEl, toggle, close } = useMenu();

  return (
    <Wrapper>
      <Text id="user-name-text">{fullName}</Text>
      <MenuButton
        id="profile-button"
        menuId="profile-menu"
        isMenuOpened={isOpened}
        onClick={toggle}
      >
        <Avatar
          firstName={profile?.firstName}
          lastName={profile?.lastName}
          color="secondary"
        />
      </MenuButton>

      <StyledMenu
        id="profile-menu"
        anchorEl={anchorEl}
        open={isOpened}
        onClose={close}
      >
        <StyledMenuItem>
          <ButtonLink to={routes.root} id="dashboard-profile-button">
            <FormattedMessage id="header.profileMenu.dashboard" />
          </ButtonLink>
        </StyledMenuItem>
        <StyledMenuItem>
          <ButtonLink
            to="/dashboard/profile/settings"
            id="settings-profile-button"
          >
            <FormattedMessage id="header.profileMenu.settings" />
          </ButtonLink>
        </StyledMenuItem>
        <StyledMenuItem>
          <ButtonAnchor
            href="https://higosense.com/terms-of-use-and-privacy-policy"
            target="_blank"
            rel="noreferrer"
            id="formal-data-profile-button"
          >
            <FormattedMessage id="header.profileMenu.formalData" />
          </ButtonAnchor>
        </StyledMenuItem>
        <StyledMenuItem>
          <LogoutButton to="#" onClick={logout} id="logout-profile-button">
            <FormattedMessage id="header.profileMenu.logout" />
          </LogoutButton>
        </StyledMenuItem>
      </StyledMenu>
    </Wrapper>
  );
};
