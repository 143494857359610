import { IconBox, IconProps } from '../IconBox';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoaderIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 5.5C8.41015 5.5 5.5 8.41015 5.5 12H2.5C2.5 6.75329 6.75329 2.5 12 2.5C17.2467 2.5 21.5 6.75329 21.5 12H18.5C18.5 8.41015 15.5899 5.5 12 5.5Z"
    />
  </IconBox>
);

const AnimatedLoaderIcon = styled(LoaderIcon)`
  animation: ${rotate} 1s linear infinite;
`;
export default AnimatedLoaderIcon;
