import { createContext, FC, useContext, useEffect, useState } from 'react';
import EventEmitter from 'eventemitter3';
import { AppEventPayload } from 'types/appEvent';

const Context =
  createContext<EventEmitter<AppEventPayload> | undefined>(undefined);

export const EventEmitterProvider: FC = ({ children }) => {
  const [value] = useState(() => new EventEmitter<AppEventPayload>());
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useEventEmitter = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error('ApiContext not provided');
  }

  return ctx;
};

// fn should be stable
export const useOnEventEmitterMessage = <
  T extends EventEmitter.EventNames<AppEventPayload>,
>(
  event: T,
  fn: EventEmitter.EventListener<AppEventPayload, T>,
) => {
  const ctx = useEventEmitter();

  useEffect(() => {
    ctx.on(event, fn);

    return () => {
      ctx.off(event, fn);
    };
  }, [ctx, event, fn]);
};
