import styled from 'styled-components';
import { BackBodyImage } from './BodyImage/BackBodyImage';
import { LungsAuscultationLegendContainer } from './LungsAuscultationLegendContainer';
import { FormattedMessage } from 'react-intl';
import { LungsAuscultationLegendProps } from './LungsAuscultationLegendProps';
import { FC } from 'react';

const StyledBackBodyImage = styled(BackBodyImage)`
  max-width: 100%;
`;

export const LungsBackAuscultationLegend: FC<LungsAuscultationLegendProps> = ({
  gender,
  maturity,
}) => {
  return (
    <LungsAuscultationLegendContainer
      heading={<FormattedMessage id="lungAuscultation.posterior" />}
    >
      <StyledBackBodyImage gender={gender} maturity={maturity} />
    </LungsAuscultationLegendContainer>
  );
};
