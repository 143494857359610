import { useMutation } from 'react-query';
import { fetchAuthToken } from '@higo/api/lib/services';
import { OAuth2AccessTokenReqDTO } from '@higo/api/lib/models';
import { mapToOAuth2AccessToken } from '@higo/common/lib/utils/axios';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';
import { DoctorMutationKey } from 'config/doctorMutationKey';

export const useAuthTokenMutation = () => {
  const { baseAxios } = useDoctorPortalApiContext();

  return useMutation(
    DoctorMutationKey.AuthToken,
    (dto: OAuth2AccessTokenReqDTO) =>
      fetchAuthToken(baseAxios)(dto).then(mapToOAuth2AccessToken),
  );
};
