import { HistoryIcon, IconButton, isTabletQuery, ManagedModal } from '@higo/ui';
import React, { FC, memo } from 'react';
import {
  PatientExaminationsTable,
  PatientExaminationsTableProps,
} from 'components/PatientExaminationsTable';
import useMedia from 'react-use/lib/useMedia';
import { useDispatchDoctorTrackedType } from 'hooks/useDispatchDoctorTrackedEvent';
import {
  DoctorTrackedType,
  TrackedEventButtonClickAction,
} from '@higo/common/lib/features/TrackedEvent';

export type PatientExaminationsModalProps = PatientExaminationsTableProps;

export const PatientExaminationsModal: FC<PatientExaminationsModalProps> = memo(
  (props) => {
    const deviceQuery = useMedia(`${isTabletQuery}`);
    const sendTrackedEvent = useDispatchDoctorTrackedType();

    return (
      <ManagedModal
        initialVisible={false}
        fullscreen={deviceQuery}
        bigModal={true}
        trigger={
          <IconButton
            color="secondary"
            size="small"
            icon={<HistoryIcon />}
            id="patient-exams-button"
            onClick={() => {
              sendTrackedEvent({
                event: DoctorTrackedType.ButtonClick,
                eventAction:
                  TrackedEventButtonClickAction.OpenPatientExaminationHistoryModal,
                patientId: props.patientId,
              });
            }}
          />
        }
      >
        <PatientExaminationsTable {...props} />
      </ManagedModal>
    );
  },
);
