import styled, { css } from 'styled-components';
import { useLoginProcess } from 'pages/LoginPage/hooks/useLoginProcess';
import { useIsLoggedIn } from 'hooks/useIsLoggedIn';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LoginForm, LoginFormModel } from 'pages/LoginPage/LoginForm';
import { Heading, LinkButton, LogoIcon } from '@higo/ui';
import { FormattedMessage } from 'react-intl';
import { getDashboardChildRoute, routes } from 'routes';
import { useProfileDataQuery } from 'hooks/queries';
import { useDispatchDoctorTrackedType } from 'hooks/useDispatchDoctorTrackedEvent';
import { DoctorTrackedType } from '@higo/common/lib/features/TrackedEvent';

const Header = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  grid-gap: 0.5rem;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
`;

const StyledLoginForm = styled(LoginForm)`
  margin-bottom: 0.5rem;
`;

const FormFooter = styled.div`
  text-align: right;
`;

export const DemoTitle = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.primary['500']};
    margin-left: 0.5rem;
  `,
);

export const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const postLoginRoute =
    (location.state as { from: Location })?.from?.pathname ||
    getDashboardChildRoute(routes.dashboard.unassigned); // todo: fix typing and links

  const sendTrackedEvent = useDispatchDoctorTrackedType();
  const loginProcess = useLoginProcess();
  const handleLoginSubmit = (value: LoginFormModel) => {
    return loginProcess.login({
      username: value.login,
      password: value.password,
    });
  };

  const { data: profile } = useProfileDataQuery();
  const { isLoggedIn } = useIsLoggedIn();
  const isDemoEnvironment = process.env.DEMO_ENV === 'true';

  useEffect(() => {
    if (isLoggedIn && profile) {
      sendTrackedEvent({
        event: DoctorTrackedType.DoctorLogin,
        eventResult: 'success',
        doctorId: profile.userId,
      });
      navigate(postLoginRoute);
    }
  }, [isLoggedIn, postLoginRoute, navigate, profile, sendTrackedEvent]);

  return (
    <>
      <Header id="login-header">
        <LogoIcon size={32} />
        <Heading level={1}>
          Doctor Portal {isDemoEnvironment && <DemoTitle>Demo</DemoTitle>}
        </Heading>
      </Header>
      <StyledLoginForm
        onSubmit={handleLoginSubmit}
        isLoading={loginProcess.isLoading}
      />
      <FormFooter>
        <LinkButton
          as={Link}
          to={routes.resetPasswordRoute}
          id="forgot-password-link"
        >
          <FormattedMessage id="authentication.forgotPassword" />
        </LinkButton>
      </FormFooter>
    </>
  );
};
