import { Select } from '@higo/ui';
import styled, { css } from 'styled-components';
import { useAppLocale } from './AppLocaleProvider';
import { useDictionaryCollector } from '@summer/jst-react-dictionaries';
import { FC, useMemo } from 'react';
import { collectLanguageDictionaryItems } from '@higo/common/src/features/Dictionary';

const StyledSelect = styled(Select)<{ $fullWidth: boolean }>(({ $fullWidth }) =>
  $fullWidth
    ? css`
        width: 100%;
      `
    : css`
        width: 8rem;
      `,
);

interface LanguageSwitchProps {
  fullWidth?: boolean;
}

// todo: add other languages and filter non available on backend

// fetch from environment available languages?
export const LanguageSwitch: FC<LanguageSwitchProps> = ({
  fullWidth = false,
}) => {
  // todo: use dictionary query, filterout unavailable languages
  const { current, changeLocale } = useAppLocale();
  const languages = useDictionaryCollector(collectLanguageDictionaryItems);
  const unsuportedLanguages = ['ar-AR'];

  const languageToShow = languages.filter(
    (item) => !unsuportedLanguages.includes(item.code),
  );

  const options = useMemo(
    () =>
      languageToShow.map(({ name, code }) => ({
        label: name,
        value: code,
      })),
    [languageToShow],
  );

  return (
    <StyledSelect
      value={current.code}
      options={options}
      onChange={(code) => code && changeLocale(code as string)}
      $fullWidth={fullWidth}
      id="language-dropdown"
    ></StyledSelect>
  );
};
