export function groupByMultiple<P extends string, T>(
  groupFn: (x: T) => P[],
  list: T[],
) {
  // todo: fix typing issues for curried version
  // if (arguments.length === 1)
  //   return (_list: T[]) => groupByMultiple(groupFn, _list);

  const result = {} as Record<P, T[]>;
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    const keys = groupFn(item) ?? [];

    keys.forEach((key) => {
      if (!result[key]) {
        result[key] = [];
      }

      result[key].push(item);
    });
  }

  return result;
}
