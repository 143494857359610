import { Gender } from '@higo/api/src/models/gender';
import {
  ExaminationPatientDTOPatientType,
  PartnerDetailsDTO,
} from '@higo/api/src/models/examinationPatientDTO';
import { evolve } from 'rambda';
import { parse, parseJSON } from 'date-fns';

export interface ShareExaminationPatientDTO {
  avatarFileId?: number;
  createDate?: string;
  dateOfBirth: string;
  deviceProfileId?: number;
  firstName: string;
  gender: Gender;
  id: number;
  idNumber?: string;
  isPrivate?: boolean;
  isPrivateConfirmed?: boolean;
  partnerDetails?: PartnerDetailsDTO;
  lastName: string;
  patientStatus?: 'ACTIVE' | 'ARCHIVED';
  profileColor?: string;
  type: ExaminationPatientDTOPatientType; //todo: fixme
}

export type ShareExaminationPatient = Omit<
  ShareExaminationPatientDTO,
  'createDate' | 'dateOfBirth'
> & {
  createDate?: Date;
  dateOfBirth: Date;
};

export const mapShareExaminationPatient: (
  a: ShareExaminationPatientDTO,
) => ShareExaminationPatient = evolve({
  dateOfBirth: (dob: string) => parse(dob, 'yyyy-MM-dd', new Date()),
  createDate: parseJSON,
});
