import styled, { css } from 'styled-components';
import SimpleBar from 'simplebar-react';

export const TableScrollableContent = styled(SimpleBar)<{
  $scrollbarOffset?: number;
}>(
  ({ theme, $scrollbarOffset = 20 }) => css`
    padding: 0 1rem 0.5rem 0;

    //                 header | pagination | tabs | table's padding
    height: calc(100vh - 3.5rem - 4.25rem - 5rem - 1.5rem);
    &.has-full-height {
      height: inherit;
    }

    .simplebar-scrollbar:before {
      background: ${theme.palette.neutral['500']} !important;
      opacity: 1;
    }
    .simplebar-horizontal {
      right: ${$scrollbarOffset}px;
      height: 0.5rem !important;
    }
    .simplebar-vertical {
      right: -0.85rem;
    }
  `,
);
