import { RequestGenerateVisualExaminationVideoButtonProps } from 'pages/ExaminationPage/components/RequestGenerateVisualExaminationVideoButton/RequestGenerateVisualExaminationVideoButtonProps';
import { FC } from 'react';
import { useRequestGenerateSkinExaminationVideoMutation } from './hooks/useRequestGenerateSkinExaminationVideoMutation';

export const RequestGenerateSkinExaminationVideoButton: FC<RequestGenerateVisualExaminationVideoButtonProps> =
  ({ examinationId }) => {
    const { mutate, isSuccess } =
      useRequestGenerateSkinExaminationVideoMutation();

    return (
      <div
        style={{ width: '100%', height: '100%', lineHeight: '35px' }}
        onClick={() => (!isSuccess ? mutate(examinationId) : null)}
      >
        Full video
      </div>
    );
  };
