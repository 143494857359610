import { FC, useMemo } from 'react';
import { Heading, Pagination, Table, usePagination } from '@higo/ui';
import styled from 'styled-components';
import { usePatientExaminationListQuery } from 'components/PatientExaminationsTable/usePatientExaminationListQuery';
import {
  mapToPatientExamsList,
  PatientExamsListItem,
  usePatientExamsConfig,
} from 'components/PatientExaminationsTable/config';
import { useNavigate } from 'react-router-dom';
import { getExaminationRoute } from 'routes';
import { FormattedMessage } from 'react-intl';

const StyledHeading = styled(Heading)``;

export type PatientExaminationsTableProps = {
  patientId: number;
};

export const PatientExaminationsTable: FC<PatientExaminationsTableProps> = ({
  patientId,
}) => {
  const navigate = useNavigate();
  const config = usePatientExamsConfig();
  const pagination = usePagination();

  const { data, isFetching, isError } = usePatientExaminationListQuery(
    patientId,
    {
      page: pagination.page,
      pageSize: pagination.pageSize,
    },
  );

  const tableData: PatientExamsListItem[] = useMemo(
    () => mapToPatientExamsList(data?.content ?? []),
    [data?.content],
  );

  return (
    <>
      <StyledHeading level={2}>
        <div id="history-modal">
          <FormattedMessage id="patientHistoryModal.title" />
        </div>
      </StyledHeading>
      <Table
        {...config}
        data={tableData}
        pageSize={pagination.pageSize}
        isLoading={isFetching || isError}
        onRowClick={(row) => navigate(getExaminationRoute(row.original.id))}
      />
      <Pagination
        {...pagination}
        totalPages={data?.totalPages ?? 0}
        currentPageLabelSlot={
          <FormattedMessage
            id="pagination.pageCurrentOfTotal"
            values={{
              current: pagination.page,
              total: data?.totalPages ?? 0,
            }}
          />
        }
        perPageLabel={<FormattedMessage id="pagination.examsPerPage" />}
      />
    </>
  );
};
