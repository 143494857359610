import { useMemo } from 'react';
import {
  combineString,
  DoubleFieldCellRenderer,
  DoubleFieldRendererValue,
  getTimeMarkers,
  TimeMarkerCellRenderer,
  TimeMarkerCellRendererValue,
} from '@higo/ui';
import { TableOptions } from 'react-table';
import { AgeCellRenderer } from 'components/TableRenderers/AgeCellRenderer';
import { PatientMessage } from '@higo/api';
import { DateWaitingCellRenderer } from 'components/TableRenderers/DateWaitingCellRenderer';
import { MessagesListMessageExcerptCellRenderer } from 'pages/MessagesListPage/renderers/MessagesListMessageExcerptCellRenderer';
import { TableSortDesc } from '@higo/ui/lib/components/Table';
import {
  MessagesListActionsCellRenderer,
  MessagesListActionsCellRendererProps,
} from 'pages/MessagesListPage/renderers/MessagesListActionsCellRenderer';
import { useIntl } from 'react-intl';

export type MessagesListRow = {
  id: number;
  timeMarker: TimeMarkerCellRendererValue;
  createDate: Date;
  patient: {
    fullNameId: DoubleFieldRendererValue;
    gender: string;
    age: string;
  };
  messageExcerpt: string;
  actions: MessagesListActionsCellRendererProps;
};

export const useMessagesListConfig = () => {
  const intl = useIntl();

  return useMemo(
    (): Omit<TableOptions<MessagesListRow>, 'data'> => ({
      columns: [
        {
          accessor: 'timeMarker',
          width: 30,
          isMarker: true,
          Cell: TimeMarkerCellRenderer,
        },
        {
          width: 50,
          Header: intl.formatMessage({ id: 'table.header.dateWaiting' }),
          accessor: 'createDate',
          Cell: DateWaitingCellRenderer,
        },
        {
          Header: intl.formatMessage({ id: 'table.header.patientId' }),
          accessor: 'patient.fullNameId',
          width: 100,
          Cell: DoubleFieldCellRenderer,
        },
        {
          Header: intl.formatMessage({ id: 'table.header.gender' }),
          accessor: 'patient.gender',
          width: 50,
        },
        {
          Header: intl.formatMessage({ id: 'table.header.age' }),
          accessor: 'patient.age',
          width: 50,
          Cell: AgeCellRenderer,
        },
        {
          Header: 'Message preview',
          accessor: 'messageExcerpt',
          Cell: MessagesListMessageExcerptCellRenderer,
        },
        {
          accessor: 'actions',
          width: 50,
          isSticky: true,
          Cell: MessagesListActionsCellRenderer,
        },
      ],
    }),
    [intl],
  );
};

export const mapToMessagesList = (
  messages: PatientMessage[],
): MessagesListRow[] => {
  const timeMarkers = getTimeMarkers({
    timestamps: messages.map((msg) => msg.createDate),
    sortDirection: TableSortDesc, // backend should return ordered by create/session end date in descending order
  });

  return messages.map((msg, index) => ({
    id: msg.id,
    timeMarker: timeMarkers[index],
    createDate: msg.createDate,
    patient: {
      fullNameId: {
        highlighted: combineString([
          msg.patient.firstName,
          msg.patient.lastName,
        ]),
        text: msg.patient?.id,
      },
      gender: msg.patient?.gender,
      age: msg.patient?.dateOfBirth,
    },
    messageExcerpt: msg.message,
    actions: {
      chatModal: {
        patient: {
          id: msg.patient.id,
          firstName: msg.patient?.firstName,
          lastName: msg.patient?.lastName,
        },
        examinationId: msg.examinationId,
      },
    },
  }));
};
