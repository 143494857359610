import styled, { css } from 'styled-components';
import { FC } from 'react';
import { StyleProps } from '@summer/jst-react';
import clsx from 'clsx';

type Ratio = '1x1' | '3x4' | '4x3' | '8x7';

const aspectRatioClasses = {
  '1x1': 'ar-1x1',
  '3x4': 'ar-3x4',
  '4x3': 'ar-4x3',
  '8x7': 'ar-8x7',
} as const;

const ratioProps: {
  ratio: Ratio;
  aspectRatio: string;
  paddingFallback: string;
}[] = [
  {
    ratio: '1x1',
    aspectRatio: '1 / 1',
    paddingFallback: '100%',
  },
  {
    ratio: '3x4',
    aspectRatio: '3 / 4',
    paddingFallback: '133.3%',
  },
  {
    ratio: '4x3',
    aspectRatio: '4 / 3',
    paddingFallback: '75%',
  },
  {
    ratio: '8x7',
    aspectRatio: '8 / 7',
    paddingFallback: '87.5%',
  },
];

const Container = styled('div')`
  display: flex;

  ${ratioProps.map(
    ({ ratio, aspectRatio, paddingFallback }) => css`
      &.${aspectRatioClasses[ratio]} {
        aspect-ratio: ${aspectRatio};

        @supports not (aspect-ratio: ${aspectRatio}) {
          &::before {
            float: left;
            padding-top: ${paddingFallback};
            content: '';
          }

          &::after {
            display: block;
            content: '';
            clear: both;
          }
        }
      }
    `,
  )}
`;

export interface AspectRatioProps {
  ratio?: Ratio;
}

export const AspectRatio: FC<AspectRatioProps & StyleProps> = ({
  className,
  children,
  ratio = '1x1',
}) => (
  <Container className={clsx(className, aspectRatioClasses[ratio])}>
    {children}
  </Container>
);
