import { TableOptions, TRowEntity } from 'react-table';
import { SkeletonTR } from './TR';
import { SkeletonTD } from './TD';
import { range } from 'rambda';
import { Skeleton } from '@higo/ui/src/components/Skeleton';

export const TBodySkeleton = <T extends TRowEntity>({
  columns,
  pageSize = 5,
}: Pick<TableOptions<T>, 'columns' | 'pageSize'>) => (
  <>
    {range(0, pageSize).map((num) => (
      <SkeletonTR key={num}>
        {columns.map((column, index) => (
          <SkeletonTD
            key={String(column.accessor ?? column.id ?? index)}
            $width={column.width ?? 150}
          >
            <Skeleton />
          </SkeletonTD>
        ))}
      </SkeletonTR>
    ))}
  </>
);
