// Isolate usePrompt re-renders
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Transition } from 'history';
import { useBeforeUnload } from 'react-use';
import { ExaminationPageUnsavedDiagnosisPromptDialog } from 'pages/ExaminationPage/ExaminationPageUnsavedDiagnosisPromptDialog';
import { toast } from 'react-toastify';
import { useBlocker } from '@higo/common/lib/hooks';

export const UnsavedDiagnosisPrompt: FC<{
  block: boolean;
  saveDraftSlot: ReactNode;
}> = ({ block, saveDraftSlot }) => {
  const intl = useIntl();
  const [transition, setTransition] =
    useState<Transition | undefined>(undefined);

  useBeforeUnload(
    block,
    intl.formatMessage({ id: 'notification.diagnosisUnsaved' }),
  );

  // blocker function has to be stable
  useBlocker(setTransition, block);

  const handleConfirmLeave = () => {
    const tx = transition;
    setTransition(undefined);
    tx?.retry();
  };

  useEffect(() => {
    if (transition && !block) {
      transition.retry();
      toast.success(
        intl.formatMessage({ id: 'notification.diagnosisDraftSaved' }),
      );
    }
  }, [intl, block, transition]);

  if (!transition) {
    return null;
  }

  return (
    <ExaminationPageUnsavedDiagnosisPromptDialog
      open={!!transition}
      saveDraftSlot={saveDraftSlot}
      onConfirmLeave={handleConfirmLeave}
      onClose={() => setTransition(undefined)}
    />
  );
};
