import styled, { css } from 'styled-components';

export const SelectListbox = styled('ul')(
  ({ theme }) => css`
    width: var(--select-width, 12.8125rem);
    max-height: 16.25rem;
    padding: 0.5rem;
    margin: 0.25rem 0;
    font-family: ${theme.fontFamily.primary};
    overflow: auto;
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.primary['500']};
    border-radius: 0.5rem;
    box-shadow: 0px 8px 24px rgba(23, 24, 31, 0.23);
  `,
);
