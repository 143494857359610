import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { saveFinalExaminationDiagnosis } from '@higo/api/lib/services/examinationService';
import { ExaminationDoctorDiagnosisDTO } from '@higo/api/lib/models/examinationDoctorDiagnosisDTO';
import { useDoctorPortalApiContext } from 'contexts/ApiContext';
import { ExaminationQueryKey } from '@higo/common/lib/features/ExaminationPage/ExaminationQueryKey';
import { useDispatchDoctorTrackedType } from 'hooks/useDispatchDoctorTrackedEvent';
import { DoctorTrackedType } from '@higo/common/lib/features/TrackedEvent';
import { countSelectedAuscultationTags } from '@higo/common/lib/features/ExaminationPage/utils';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

export const useSaveFinalExaminationDiagnosisMutation = (
  examinationId: number,
) => {
  const { authorizedAxios } = useDoctorPortalApiContext();
  const queryClient = useQueryClient();
  const sendTrackedEvent = useDispatchDoctorTrackedType();
  const navigate = useNavigate();
  const intl = useIntl();

  return useMutation(
    (dto: ExaminationDoctorDiagnosisDTO) =>
      saveFinalExaminationDiagnosis(authorizedAxios)(examinationId, dto),

    {
      onSuccess: async (data, variables) => {
        sendTrackedEvent({
          event: DoctorTrackedType.SaveExaminationDiagnosis,
          eventResult: 'success',
          examinationId,
          selectedHeartAuscultationTags: countSelectedAuscultationTags(
            variables.heartAuscultation?.tags ?? [],
          ),
          selectedLungsAuscultationTags: countSelectedAuscultationTags(
            variables.lungAuscultation?.tags ?? [],
          ),
        });

        await queryClient.invalidateQueries([
          ExaminationQueryKey.ExaminationDetails,
          examinationId,
        ]);
        await queryClient.invalidateQueries([
          ExaminationQueryKey.ExaminationDiagnosis,
          examinationId,
        ]);

        toast.success(
          intl.formatMessage({
            id: 'toast.diagnosis.sent',
          }),
        );

        navigate('/dashboard/assigned');
      },

      onError: (error, variables, context) => {
        sendTrackedEvent({
          event: DoctorTrackedType.SaveExaminationDiagnosis,
          eventResult: 'error',
          examinationId,
          selectedHeartAuscultationTags: countSelectedAuscultationTags(
            variables.heartAuscultation?.tags ?? [],
          ),
          selectedLungsAuscultationTags: countSelectedAuscultationTags(
            variables.lungAuscultation?.tags ?? [],
          ),
        });

        // call global error handler
        return queryClient
          .getDefaultOptions()
          ?.mutations?.onError?.(error, variables, context);
      },
    },
  );
};
