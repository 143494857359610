import { useEffect } from 'react';
import {
  eachMinuteEmitter,
  TimeObserver,
} from '@higo/common/src/services/tickEmitter';

export const useEachMinute = (callback: TimeObserver) => {
  useEffect(() => {
    callback();
    eachMinuteEmitter.attach(callback);

    return () => eachMinuteEmitter.detach(callback);
  }, [callback]);
};
