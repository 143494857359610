import { FC, useCallback, useState } from 'react';
import { HeaderTimer } from 'components/Header/HeaderTimer';
import { FormatDate, FormatHour } from 'components/FormatDate';
import { useEachSecond } from '@higo/common/lib/hooks';

export const HeaderDateTimer: FC = () => {
  const [time, setTime] = useState(() => new Date());

  const updateTime = useCallback(() => setTime(new Date()), []);

  useEachSecond(updateTime);

  return (
    <HeaderTimer
      Time={<FormatHour>{time}</FormatHour>}
      Label={<FormatDate format="EEE, MMMM dd">{time}</FormatDate>}
      id="header-date-timer"
      idLabel="header-date-label"
    />
  );
};
